/* ---- COLORS ---- */
import * as THREE from "three";

// TODO: nuke this abomination holy fuck
//  like it's a good idea, but just execute better damn
export class UIColor {
    static Default = "bg-neutral-600";
    static Window = "bg-neutral-800";
    static MinorText = "text-neutral-400";
    static GreenText = "text-emerald-400";
    static LightInput = "bg-slate-200";
    static DarkInput = "bg-slate-700";
    static Blue = "bg-blue-600";
    static BlueText = "text-blue-500";
    static Red = "bg-rose-700";
    static RedText = "text-rose-500";
    static Green = "bg-emerald-600";
    static DarkGreen = "bg-emerald-700";
    static Yellow = "bg-yellow-600";
    static YellowText = "text-yellow-500";
    static DarkYellow = "bg-yellow-700";
    static White = "bg-white";
    static WhiteText = "text-white";
    static Black = "bg-black";
    static BlackText = "text-black";
}

export const ClickableUIStyle = {
    [UIColor.Default]: UIColor.Default + " hover:bg-neutral-500 disabled:bg-neutral-700",
    [UIColor.LightInput]: UIColor.LightInput + " disabled:bg-neutral-600 text-black disabled:text-neutral-400",
    [UIColor.DarkInput]: UIColor.DarkInput + " hover:bg-slate-600 disabled:bg-slate-700",
    [UIColor.Blue]: UIColor.Blue + " hover:bg-blue-800 disabled:bg-blue-900",
    [UIColor.Red]: UIColor.Red + " hover:bg-rose-800 disabled:bg-rose-900",
    [UIColor.Green]: UIColor.Green + " hover:bg-emerald-700 disabled:bg-emerald-900",
    [UIColor.DarkGreen]: UIColor.DarkGreen + " hover:bg-emerald-800 disabled:bg-emerald-900",
    [UIColor.Yellow]: UIColor.Yellow + " hover:bg-yellow-800 disabled:bg-yellow-900",
    [UIColor.DarkYellow]: UIColor.DarkYellow + " hover:bg-yellow-800 disabled:bg-yellow-900",
    [UIColor.White]: UIColor.White,
    [UIColor.Black]: UIColor.Black,
}

/* ---- CAMERA ---- */
export const ANIMATION_DURATION = 200;
export const FOV = 85;

export const SMALL_LOLLIPOP_SIZE = 0.05;
export const LARGE_LOLLIPOP_SIZE = 0.5;

export const REGULAR_ORBIT_OFFSET = 4;

export const MIN_ROUTE_ZOOM_DISTANCE = 2.0;
export const MAX_ROUTE_ZOOM_DISTANCE = 6.0;

export const MIN_HOLD_ZOOM_DISTANCE = 1.0;
export const MAX_HOLD_ZOOM_DISTANCE = 3.0;

export const MIN_FLY_ANGLE = -25.0;
export const DEFAULT_FLY_ANGLE = 15.0;
export const MAX_FLY_ANGLE = 55.0;
export const MIN_HEIGHT = 1.0;
export const MAX_HEIGHT = 6.0;


/* ---- SCENE ---- */
export const TOP_COLOR = new THREE.Color(0xf0f5f0);
export const BOTTOM_COLOR = new THREE.Color(0x695442);
export const SPHERE_RADIUS = 40;
export const SPHERE_OFFSET = 40;
export const SPHERE_EXPONENT = 1.0;
export const SPHERE_RESOLUTION = 12;

export const LIGHT_COLOR = 0xffffff;
export const LIGHT_INTENSITY = 10;

export const EXTRA_LOW_OPACITY = 0.15;
export const LOW_OPACITY = 0.35;
export const OPACITY_CHANGE_DURATION = 150;  // ms


/* ---- MISC ---- */
export const MAX_FPS = 60;

export const MOCK = process.env.REACT_APP_MOCK;