import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Logo = ({ className = "h-10 sm:h-16", clickable = false }) => {
    const [logoSrc, setLogoSrc] = useState("/logo.svg");

    useEffect(() => {
        const img = new Image();
        img.src = "/logo.svg";
        img.onerror = () => setLogoSrc("/logo.png");
    }, []);

    const imgElement = (
        <img
            src={logoSrc}
            alt="Climbuddy.com logo"
            className={`${className} ${clickable ? "hover:opacity-50" : ""}`}
        />
    );

    return clickable ? <Link to="/">{imgElement}</Link> : imgElement;
};

export default Logo;
