import {useContext, useState} from "react";
import {UIColor} from "../Config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Trans, useTranslation} from "react-i18next";
import {BasicButton} from "../common/Button";
import TextInput from "../common/TextInput";
import SubmitDiscardButtons from "../common/SubmitDiscardButtons";
import {WallActions, WallContext, WallDispatchContext} from "../contexts/WallContext";
import Expandable from "../common/Expandable";

export default function CircuitSystemSelector() {
    const wall = useContext(WallContext);
    const wallDispatch = useContext(WallDispatchContext);

    const [circuits, setCircuits] = useState([...wall.value.circuits].concat([{name: "", color: "#000000"}]));
    const [initialCircuits, setInitialCircuits] = useState(JSON.parse(JSON.stringify(circuits)));

    const {t} = useTranslation();

    const [removingIndexes, setRemovingIndexes] = useState([]);
    const [lastRemovingIndexes, setLastRemovingIndexes] = useState([]);

    const handleChange = (index, field, value) => {
        const newItems = [...circuits];
        newItems[index][field] = value;
        setCircuits(newItems);
    };

    const addItem = () => {
        setCircuits([...circuits, {name: "", color: "#000000"}]);
    };

    const removeItem = (index) => {
        setRemovingIndexes(values => [...values, index]);
    };

    async function onSubmit(event) {
        event.preventDefault();

        let newWall = wall.value.shallowClone();
        newWall.circuits = circuits.filter((_, i) => !removingIndexes.includes(i) && i !== circuits.length - 1);

        wallDispatch({
            type: WallActions.SetMetadata,
            metadata: newWall.metadata,
            postSuccessHooks: () => {
                setInitialCircuits(JSON.parse(JSON.stringify(circuits)));
                setLastRemovingIndexes(JSON.parse(JSON.stringify(removingIndexes)));
            }
        });
    }

    const onDiscard = () => {
        setCircuits(JSON.parse(JSON.stringify(initialCircuits)));
        setRemovingIndexes(JSON.parse(JSON.stringify(lastRemovingIndexes)))
    };

    const isModified =
        (JSON.stringify(circuits) !== JSON.stringify(initialCircuits))
        || (JSON.stringify(removingIndexes) !== JSON.stringify(lastRemovingIndexes));

    const loading = wall.status.isLoading(WallActions.SetMetadata);

    const hasEmptyName = circuits.some((circuit, index) =>
        index !== circuits.length - 1 && circuit.name.trim() === ""
    );

    return (
        <div className="rounded-xl bg-neutral-700 px-3 py-3">
            <form className="w-full">
                {circuits.map((item, index) => {
                    const isEmpty = item.name.trim() === "";
                    return (
                        <div key={index}
                             className={`flex gap-2 items-center transition-all overflow-hidden 
                         ${(removingIndexes.includes(index)) || (index === circuits.length - 1)
                                 ? "max-h-0 opacity-0" : "max-h-96 opacity-100 pb-1"}`}>
                            <TextInput
                                textSize={""}
                                placeholder={t("circuit_system_selector.circuit_color")}
                                value={item.name}
                                color={isEmpty ? UIColor.Red : UIColor.Default}
                                onChange={(e) => handleChange(index, "name", e.target.value)}
                                width="w-full"
                                className={"flex-grow"}
                                tabIndex={(removingIndexes.includes(index)) || (index === circuits.length - 1) ? -1 : undefined}
                            />
                            <input
                                type="color"
                                value={item.color}
                                className="min-w-8 w-fit rounded-lg cursor-pointer hover:opacity-25"
                                onChange={(e) => handleChange(index, "color", e.target.value)}
                                tabIndex={(removingIndexes.includes(index)) || (index === circuits.length - 1) ? -1 : undefined}
                            />
                            <BasicButton
                                type="button"
                                onClick={() => removeItem(index)}
                                tabIndex={(removingIndexes.includes(index)) || (index === circuits.length - 1) ? -1 : undefined}
                            >
                                <FontAwesomeIcon icon={faMinus}/>
                            </BasicButton>
                        </div>
                    );
                })}
                <div className="flex justify-end items-center" onClick={addItem}>
                    <p className={`flex-grow ${UIColor.MinorText} text-center text-sm pr-2`}>
                        <Trans
                            i18nKey="circuit_system_selector.add_circuit"
                            components={{strong: <strong/>}}
                        />
                    </p>
                    <BasicButton className="h-full" type="button">
                        <FontAwesomeIcon icon={faPlus}/>
                    </BasicButton>
                </div>
                <Expandable isOpen={isModified}>
                    <div className={"m-5"}/>
                    <SubmitDiscardButtons
                        onSubmit={onSubmit}
                        onDiscard={onDiscard}
                        submitEnabled={isModified && !hasEmptyName}
                        discardEnabled={!loading && isModified}
                        isLoading={loading}
                    />
                </Expandable>
            </form>
        </div>
    );
}
