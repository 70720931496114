import {useState} from "react";
import {BasicButton} from "./Button";
import TextInput from "./TextInput";
import {UIColor} from "../Config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Trans, useTranslation} from "react-i18next";

export default function EditableSocialDictionary({initialValues, onChange}) {
    const [items, setItems] = useState(initialValues ?? []);

    const {t} = useTranslation();

    const handleChange = (index, field, value) => {
        const newItems = [...items];
        newItems[index][field] = value;

        setItems(newItems);
        onChange(newItems);
    };

    const addItem = () => {
        const newItems = [...items];
        newItems.push({website: "", url: ""});

        setItems(newItems);
        onChange(newItems);
    };

    const removeItem = (index) => {
        const newItems = items.filter((_, i) => i !== index);

        setItems(newItems);
        onChange(newItems);
    };

    return (
        <div className="space-y-2 w-full min-w-16">
            {items.map((item, index) => (
                <div key={index} className="flex gap-2">
                    <TextInput
                        textSize={""}
                        placeholder={t("side_overview.name")}
                        value={item.website}
                        color={UIColor.Default}
                        onChange={(e) => handleChange(index, "website", e.target.value)}
                        width="w-auto min-w-12"
                    />
                    <TextInput
                        textSize={""}
                        width={"w-full"}
                        placeholder={t("side_overview.url")}
                        value={item.url}
                        color={UIColor.Default}
                        onChange={(e) => handleChange(index, "url", e.target.value)}
                        className="flex-grow"
                    />
                    <BasicButton type={"button"} onClick={() => removeItem(index)}>
                        <FontAwesomeIcon icon={faMinus}/>
                    </BasicButton>
                </div>
            ))}
            <div className={"flex justify-end items-center"}>
                <p className={`flex-grow text-center ${UIColor.MinorText} text-sm pr-2`}>
                    <Trans
                        i18nKey={"side_overview.add_links"}
                        components={{strong: <strong/>}}
                    />
                </p>
                <BasicButton onClick={addItem} className={"h-full"} type={"button"}>
                    <FontAwesomeIcon icon={faPlus}/>
                </BasicButton>
            </div>
        </div>
    );
}
