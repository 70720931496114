import {useCallback, useContext, useEffect, useState} from "react";

import {OrbitControls, Raycasting, TopDownControls} from "./Controls";
import {WallContext} from "../../contexts/WallContext";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import OutlineType from "../canvas/OutlineType";
import {Hold, Route} from "../../model/Wall";
import * as config from "../../Config";
import {LOW_OPACITY, SMALL_LOLLIPOP_SIZE, UIColor} from "../../Config";
import {AppContext} from "../../AppContext";
import {PhaseButtons} from "../proposal_edit/PhaseButtons";
import {HoldPanel, HoldPopupType} from "../proposal_edit/HoldPanel";
import AddPhasePanel from "../proposal_edit/AddPhasePanel";
import PreviousNextButton, {PreviousNextButtonType} from "./PreviousNextButton";
import {Sphere} from "three";
import {CanvasContext} from "../../contexts/CanvasContext";


class ViewMode {
    static RouteOrbit = 0;
    static HoldOrbit = 1;
    static TopDown = 2;
}


export default function AddPhaseController() {
    const wall = useContext(WallContext);
    const canvas = useContext(CanvasContext);

    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const [started, setStarted] = useState(false);  // to initialize in orbit mode

    const [viewMode, setViewMode] = useState({mode: ViewMode.TopDown, object: null});

    const [route, setRoute] = useState(null);
    const [routeOrder, setRouteOrder] = useState([]);

    const [holdPopup, setHoldPopup] = useState(null);

    const onToggleRouteHold = useCallback(hold => {
        console.assert(route !== null);

        if (route === null) {
            return;
        }

        proposalDispatch({
            type: ProposalActions.ToggleRouteHold,
            hold: hold,
            route: route,
        });

        setViewMode({
            mode: ViewMode.RouteOrbit,
            object: route,
            index: routeOrder.indexOf(route),
        });
    }, [proposalDispatch, route, routeOrder]);

    const onNewRoute = useCallback(hold => {
        proposalDispatch({
            type: ProposalActions.NewRoute,
            hold: hold,
            setRoute: setRoute
        });
    }, [proposalDispatch, setRoute]);

    const onToggleHold = useCallback(hold => {
        proposalDispatch({
            type: ProposalActions.ToggleHold,
            hold: hold,
        });

        setViewMode({
            mode: ViewMode.RouteOrbit,
            object: route,
            index: routeOrder.indexOf(route),
        });
    }, [proposalDispatch, route, routeOrder]);

    const onCombineRoutes = useCallback((route1, route2) => {
        console.assert(route1 !== null && route2 !== null && route1 !== route2);

        if (route1 === null || route2 === null || route1 === route2) {
            return;
        }

        proposalDispatch({
            type: ProposalActions.CombineRoutes,
            route1: route1,
            route2: route2,
        });

        setViewMode({
            mode: ViewMode.RouteOrbit,
            object: route1,
            index: routeOrder.indexOf(route1),
        });
    }, [proposalDispatch, routeOrder]);

    const onControlsUpdate = useCallback(() => {
        canvas.render();
    }, [canvas]);

    const onPreviousRoute = useCallback(() => {
        const index = routeOrder.indexOf(route);
        if (index !== -1) {
            const newIndex = (index - 1 + routeOrder.length) % routeOrder.length;
            setRoute(routeOrder[newIndex]);
        }
    }, [routeOrder, route]);

    const onFirstRoute = useCallback(() => {
        setRoute(routeOrder[0]);
    }, [routeOrder]);

    const onNextRoute = useCallback(() => {
        const index = routeOrder.indexOf(route);
        if (index !== -1) {
            const newIndex = (index + 1) % routeOrder.length;
            setRoute(routeOrder[newIndex]);
        }
    }, [routeOrder, route]);

    const onLastRoute = useCallback(() => {
        setRoute(routeOrder[routeOrder.length - 1]);
    }, [routeOrder]);

    const onHoldHover = useCallback(raycastEvent => {
        const result = raycastEvent.result;

        if (result.hit) {
            if (AppContext.Mutable.isCanvasDragging)
                return;

            const hitObject = result.target.object;

            if (hitObject instanceof Hold) {
                canvas.setOutlinedObjects(OutlineType.HOVER, [hitObject.holdMesh]);
            } else if (hitObject instanceof Route) {
                canvas.setOutlinedObjects(OutlineType.HOVER, hitObject.holds.map(hold => hold.holdMesh));
            }

            canvas.render();
            return;
        } else {
            canvas.setOutlinedObjects(OutlineType.HOVER, []);
        }

        canvas.render();
    }, [canvas]);

    const onHoldClick = useCallback(raycastEvent => {
        const result = raycastEvent.result;
        const hold = result.target.object;

        // This feels weird, so we're not doing this
        //
        // // if we're not orbiting and click on the route, start orbiting again
        // if (hold.route !== null && hold.route === route && viewMode.mode === ViewMode.TopDown) {
        //     setViewMode({
        //         mode: ViewMode.RouteOrbit,
        //         object: route,
        //         index: routeOrder.indexOf(route),
        //     });
        //     return;
        // }

        // otherwise if we're not already orbiting the hold, go to orbit
        if (viewMode.mode !== ViewMode.HoldOrbit || viewMode.object !== hold) {
            setViewMode({
                mode: ViewMode.HoldOrbit,
                object: hold,
            });
        }

        let willNotAddHoldIDs = proposal.getWillNotAddHoldIDs();

        // if it has no route, and it will not be added, it has been deleted
        // in that case, only opion is to add it back...
        if (hold.route === null && willNotAddHoldIDs.includes(hold.id)) {
            setHoldPopup({
                hold: hold,
                type: HoldPopupType.ToBeDeleted,
                onUndelete: () => onToggleHold(hold)
            });

            return;
        }

        // no route selected (i.e. we're in topdown mode)
        let popupType, onInclude, onExclude, onView, onNew, onDelete, onCombine;
        if (!route) {
            // no route at all - can only create a new route or remove
            if (hold.route === null) {
                popupType = HoldPopupType.NoRoute;
            } else {
                popupType = HoldPopupType.SomeRoute;
                onView = () => setRoute(hold.route);
            }
        } else {
            if (hold.route === route) {
                popupType = HoldPopupType.CurrentRoute;

                onExclude = () => onToggleRouteHold(hold);

            } else {
                if (hold.route === null) {
                    popupType = HoldPopupType.NoRoute;
                } else {
                    popupType = HoldPopupType.DifferentRoute;
                    onView = () => setRoute(hold.route);

                    onCombine = () => onCombineRoutes(
                        route,
                        hold.route,
                    );
                }

                onInclude = () => onToggleRouteHold(hold);
            }
        }

        // when it's just one-hold route, having new route doesn't make sense,
        // unless we're looking at a different hold
        if (route && route.holds.length === 1 && hold === route.holds[0]) {
            onNew = undefined;
        } else {
            onNew = () => onNewRoute(hold);
        }

        onDelete = () => onToggleHold(hold);

        setHoldPopup({
            hold: hold,
            type: popupType,
            onInclude: onInclude,
            onExclude: onExclude,
            onView: onView,
            onNew: onNew,
            onDelete: onDelete,
            onCombine: onCombine,
        });

    }, [route, viewMode, proposal, routeOrder, onToggleHold, onToggleRouteHold, onCombineRoutes, onNewRoute]);

    const onHoldMiss = useCallback(raycastEvent => {
        if (viewMode.mode === ViewMode.HoldOrbit) {
            if (!route) {
                setViewMode({
                    mode: ViewMode.TopDown,
                    object: null,
                });
            } else {
                setViewMode({
                    mode: ViewMode.RouteOrbit,
                    object: route,
                    index: routeOrder.indexOf(route),
                });
            }

        } else if (viewMode.mode === ViewMode.RouteOrbit) {
            setViewMode({
                mode: ViewMode.TopDown,
                object: null,
            });
        }

        setHoldPopup(null);
    }, [route, routeOrder, viewMode]);

    useEffect(() => {
        if (routeOrder.length === 0 || started) {
            return;
        }

        setViewMode({
            mode: ViewMode.RouteOrbit,
            object: routeOrder[0],
            index: 0,
        });

        setRoute(routeOrder[0]);
        setStarted(true);
    }, [started, routeOrder]);

    useEffect(() => {
        setRouteOrder(Route.sortByPosition(proposal.routes))
    }, [proposal]);

    useEffect(() => {
        if (!route) {
            return;
        }

        setViewMode({
            mode: ViewMode.RouteOrbit,
            object: route,
            index: routeOrder.indexOf(route)
        });

        setHoldPopup(null);
        setRoute(route);
    }, [route]);

    useEffect(() => {
        for (const hold of wall.value.holds.values()) {
            canvas.markForOpacityChange(hold.holdMesh, 0);
        }

        canvas.startOpacityChange()
    }, [])

    useEffect(() => {
        if (viewMode.mode !== ViewMode.HoldOrbit) {
            for (const hold of proposal.holds.values()) {
                canvas.markForOpacityChange(hold.holdMesh, 1);
            }
        } else {
            // highlight the viewed object and hide everything else
            canvas.markForOpacityChange(viewMode.object.holdMesh, 1);

            for (const hold of proposal.holds.values()) {
                if (hold === viewMode.object) {
                    continue;
                }

                canvas.markForOpacityChange(hold.holdMesh, LOW_OPACITY);
            }
        }

        canvas.startOpacityChange();
    }, [canvas, viewMode])

    useEffect(() => {
        if (proposal === null) {
            return;
        }

        // TODO: this is duplicit code with RemovePhaseController; make utility function
        let addedHoldIDs = proposal.getAddedHoldIDs();
        let addedRouteIDs = proposal.getAddedRouteIDs();

        let addedHolds = [...proposal.holds.values()].filter(hold => addedHoldIDs.includes(hold.id));
        let addedRoutes = proposal.routes.filter(route => addedRouteIDs.includes(route.id));

        let addedHoldObjects = addedHolds
            .map(hold => hold.holdMesh);

        let addedRouteObjects = addedRoutes
            .map(route => route.holds.map(hold => hold.holdMesh))
            .flat();

        canvas.setOutlinedObjects(
            OutlineType.CLIMBED_ROUTE,
            addedHoldObjects.concat(addedRouteObjects),
        );

        let willNotAddHoldIDs = proposal.getWillNotAddHoldIDs();

        let willNotAddHolds = [...proposal.holds.values()].filter(hold => willNotAddHoldIDs.includes(hold.id));

        let willNotAddHoldObjects = willNotAddHolds
            .map(hold => hold.holdMesh);

        canvas.setOutlinedObjects(
            OutlineType.ATTEMPTED_ROUTE,
            willNotAddHoldObjects,
        );
    }, [canvas, proposal, wall])

    useEffect(() => {
        if (route) {
            canvas.setOutlinedObjects(
                OutlineType.HIGHLIGHT,
                [...route.holds.values().map(hold => hold.holdMesh)]
            );
        } else {
            canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
        }
    }, [route, proposal, canvas])

    useEffect(() => {
        let controls;

        if (viewMode.mode !== ViewMode.TopDown && viewMode.object) {
            controls = new OrbitControls(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);

            let orbitTarget, cameraPosition, viewingDirection;

            // this is a bit disgusting; removing all holds will make this code crash,
            // which means that the route has no more holds and we switch to a different one
            try {
                orbitTarget = viewMode.object.getCenter();
                cameraPosition = viewMode.object.getViewingPoint((config.MIN_ROUTE_ZOOM_DISTANCE + config.MAX_ROUTE_ZOOM_DISTANCE) / 2);
                viewingDirection = viewMode.object.getNormal().multiplyScalar(-1);
            } catch (error) {
                const newRoute = routeOrder[Math.min(viewMode.index, routeOrder.length - 1)];

                if (!newRoute) {
                    setViewMode({
                        mode: ViewMode.TopDown,
                        object: null,
                    });
                    setRoute(null);
                } else {
                    setRoute(newRoute);
                }

                return;
            }

            controls.register(
                orbitTarget, cameraPosition, viewingDirection,
                config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
            );

            controls.addEventListener("control_escape_event", onHoldMiss);
        } else {
            controls = new TopDownControls(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);
            controls.register(null, wall.value, true, 2);
        }
        controls.addEventListener("change", onControlsUpdate);

        return () => {
            controls.removeEventListener("change", onControlsUpdate);
            controls.removeEventListener("control_escape_event", onHoldMiss);
            controls.unregister();
        };
    }, [canvas, viewMode, route, wall, onControlsUpdate, onHoldMiss]);

    useEffect(() => {
        if (proposal === null)
            return;

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera);
        for (let hold of proposal.holds.values()) {
            raycasting.addTarget(hold, [hold.holdMesh]);
            raycasting.addApproachTarget(hold, [hold.holdMesh], [new Sphere(hold.getCenter(), SMALL_LOLLIPOP_SIZE)]);
        }

        raycasting.addEventListener("object_hover", onHoldHover);
        raycasting.addEventListener("object_approach", onHoldHover);
        raycasting.addEventListener("object_click", onHoldClick);
        raycasting.addEventListener("object_approach_click", onHoldClick);
        raycasting.addEventListener("object_miss_click", onHoldMiss);
        raycasting.register();
        return () => {
            raycasting.removeEventListener("object_hover", onHoldHover);
            raycasting.removeEventListener("object_approach", onHoldHover);
            raycasting.removeEventListener("object_click", onHoldClick);
            raycasting.removeEventListener("object_approach_click", onHoldClick);
            raycasting.removeEventListener("object_miss_click", onHoldMiss);
            raycasting.reset();
            raycasting.unregister();
        };
    }, [canvas, onHoldClick, onHoldHover, onHoldMiss, proposal]);

    return (<>
        <PhaseButtons/>

        {route && routeOrder && <PreviousNextButton
            type={PreviousNextButtonType.Left}
            onOne={onPreviousRoute}
            onAll={onFirstRoute}
            buttonColor={UIColor.Green}
            disabled={routeOrder.indexOf(route) === 0}
        />}

        {route && routeOrder && <PreviousNextButton
            type={PreviousNextButtonType.Right}
            onOne={onNextRoute}
            onAll={onLastRoute}
            buttonColor={UIColor.Green}
            disabled={routeOrder.indexOf(route) === routeOrder.length - 1}
        />}

        {holdPopup ?
            <HoldPanel
                type={holdPopup.type}
                onCancel={() => setHoldPopup(null)}
                onInclude={holdPopup.onInclude}
                onExclude={holdPopup.onExclude}
                onView={holdPopup.onView}
                onNew={holdPopup.onNew}
                onDelete={holdPopup.onDelete}
                onUndelete={holdPopup.onUndelete}
                onCombine={holdPopup.onCombine}
            />
            :
            <AddPhasePanel
                route={route}
                routeOrder={routeOrder}
            />
        }
    </>);
}