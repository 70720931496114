import React from "react";
import {UIColor} from "../Config";
import {useTranslation} from "react-i18next";

const YellowGreenProgressBar = (
    {
        yellowProgress = 0,
        greenProgress = 0,
        aborting,
        yellowText = "Compressing...",
        greenText = "Uploading...",
        overrideMessage = null,
        height=""
    }) => {
    const {t} = useTranslation();

    const isYellow = yellowProgress < 100 && greenProgress === 0;
    const isDone = yellowProgress >= 100 && greenProgress >= 100;

    const progress = isYellow ? yellowProgress : greenProgress;

    const progressColor = isYellow ? UIColor.Yellow : UIColor.Green;

    const message = overrideMessage
        ?? (aborting ? t("progress_bar.aborting") : isDone ? t("progress_bar.done") : isYellow ? yellowText : greenText);

    return (
        <div className={`w-full max-w-md mx-auto ${height}`}>
            <div
                className={`${isYellow ? UIColor.Default : UIColor.Yellow} relative w-full h-full rounded-md overflow-hidden`}>
                <div
                    className={`h-full ${progressColor}`}
                    style={{width: `${progress}%`}}
                />
                <span className="animate-pulse absolute inset-0 flex justify-center items-center">
          {message}
        </span>
            </div>
        </div>
    );
};

export default YellowGreenProgressBar
