import Canvas from "./scene/canvas/Canvas";
import {useWallReducer, WallActions, WallContext, WallDispatchContext} from "./contexts/WallContext";
import {backend, ErrorType, BackendResult} from "./model/Backend";
import "./i18n";

import {useEffect, useState} from "react";
import * as TWEEN from "@tweenjs/tween.js"
import SidePanel from "./side_panel/SidePanel";
import {UserActions, UserContext, UserDispatchContext, useUserReducer} from "./contexts/UserContext";
import {ProposalContext, ProposalDispatchContext, useProposalReducer} from "./contexts/ProposalContext";
import {SpecialStates} from "./common/Utils";
import {CanvasContext, CanvasStateContext, SetCanvasContext, SetCanvasStateContext} from "./contexts/CanvasContext";
import {ErrorPage, GlobalInfoOverlay, ProgressInfo} from "./GlobalInfoOverlay";
import NotificationBox from "./NotificationBox";
import {UiContext, UiDispatchContext, useUiReducer} from "./contexts/UiContext";
import {SetReadyContext} from "./contexts/ReadyContext";
import {
    LeaderboardActions,
    LeaderboardContext,
    LeaderboardDispatchContext,
    useLeaderboardReducer
} from "./contexts/LeaderboardContext";
import HelpBox from "./help/HelpBox";
import {useTranslation} from "react-i18next";
import {BlobUrlProvider} from "./contexts/BlobURLContext";
import {NotificationContext, NotificationDispatchContext, useNotificationReducer} from "./contexts/NotificationContext";
import LoadingTrackerNotificationBridge from "./LoadingTrackerNotificationBridge";

// This is declared globally because we really don't want to load the wall twice under any circumstances.
let wallPromise = null;

function animate() {
    requestAnimationFrame(animate);
    TWEEN.update();
}

animate();

function App() {
    const [notifications, notificationsDispatch] = useNotificationReducer();

    const [wall, wallDispatch] = useWallReducer();
    const [wallProgress, setWallProgress] = useState(0.0);
    const [wallMotto, setWallMotto] = useState(null);

    const [ready, setReady] = useState(false);

    const [error, setError] = useState(null);

    const [canvasState, setCanvasState] = useState(null);
    const [canvas, setCanvas] = useState(null);
    const [leaderboard, leaderboardDispatch] = useLeaderboardReducer();
    const [user, userDispatch] = useUserReducer();
    const [ui, uiDispatch] = useUiReducer();

    const [proposal, proposalDispatch] = useProposalReducer();

    const [gotWallId, setGotWallId] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        backend.initWallId().then(() => setGotWallId(true))
            .catch((error) => {
                console.error(error);
                setError(BackendResult.FromError(ErrorType.PageDoesNotExist));
            });
    }, []);

    useEffect(() => {
        document.body.style.overflowY = "hidden";

        return () => {
            document.body.style.overflowY = "";
        };
    }, []);

    useEffect(() => {
        if (!gotWallId) {
            return;
        }

        if (!backend.valid) {
            setError(BackendResult.FromError(ErrorType.UnexpectedError));
            return;
        }

        if (wallPromise === null) {
            wallPromise = backend.loadWall((progress, motto) => {
                setWallProgress(progress);
                setWallMotto(motto);
            }, t);
        }

        wallPromise
            .then(wallResult => wallDispatch({type: WallActions.OnLoaded, wall: wallResult}))
            .catch(e => {
                console.error(e)
                setError(BackendResult.FromError(ErrorType.WallDataFailed));
            });
    }, [gotWallId]);

    useEffect(() => {
        if (!gotWallId) {
            return;
        }

        if (user.value === null) {
            userDispatch({type: UserActions.TryRestoreLogin});
            leaderboardDispatch({type: LeaderboardActions.Update});
        }

        leaderboardDispatch({type: LeaderboardActions.Update})
    }, [gotWallId]);

    // TODO: I don't have time to do the error boundaries properly, but FIXME!
    if (error) {
        return <ErrorPage backendError={error}/>
    }

    if (wall.value === null) {
        return (
            <GlobalInfoOverlay>
                <ProgressInfo progress={wallProgress} motto={wallMotto}/>
            </GlobalInfoOverlay>
        );
    }

    return (
        <SetReadyContext.Provider value={setReady}>
            {!ready ?
                <GlobalInfoOverlay>
                    <ProgressInfo progress={1.0} motto={t("loading.finalizing")}/>
                </GlobalInfoOverlay>
                : ""}

            <WallContext.Provider value={wall}>
                <WallDispatchContext.Provider value={wallDispatch}>
                    <NotificationContext.Provider value={notifications}>
                        <NotificationDispatchContext.Provider value={notificationsDispatch}>
                            <BlobUrlProvider>
                                <LeaderboardDispatchContext.Provider value={leaderboardDispatch}>
                                    <LeaderboardContext.Provider value={leaderboard}>
                                        <UserContext.Provider value={user}>
                                            <UserDispatchContext.Provider value={userDispatch}>
                                                <ProposalContext.Provider value={proposal}>
                                                    <ProposalDispatchContext.Provider value={proposalDispatch}>
                                                        <CanvasContext.Provider value={canvas}>
                                                            <SetCanvasContext.Provider value={setCanvas}>
                                                                <CanvasStateContext.Provider value={canvasState}>
                                                                    <SetCanvasStateContext.Provider
                                                                        value={setCanvasState}>
                                                                        <UiContext.Provider value={ui}>
                                                                            <UiDispatchContext.Provider
                                                                                value={uiDispatch}>
                                                                                <Canvas/>
                                                                                <NotificationBox/>
                                                                                <LoadingTrackerNotificationBridge
                                                                                    trackers={[
                                                                                        wall.status,
                                                                                        user.status,
                                                                                    ]}/>
                                                                                <SidePanel/>
                                                                                <HelpBox/>
                                                                            </UiDispatchContext.Provider>
                                                                        </UiContext.Provider>
                                                                    </SetCanvasStateContext.Provider>
                                                                </CanvasStateContext.Provider>
                                                            </SetCanvasContext.Provider>
                                                        </CanvasContext.Provider>
                                                    </ProposalDispatchContext.Provider>
                                                </ProposalContext.Provider>
                                            </UserDispatchContext.Provider>
                                        </UserContext.Provider>
                                    </LeaderboardContext.Provider>
                                </LeaderboardDispatchContext.Provider>
                            </BlobUrlProvider>
                        </NotificationDispatchContext.Provider>
                    </NotificationContext.Provider>
                </WallDispatchContext.Provider>
            </WallContext.Provider>
        </SetReadyContext.Provider>
    );
}

export default App;
