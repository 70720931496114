import { forwardRef } from "react";
import BasicInputLabel from "./InputLabel";
import { UIColor } from "../Config";

const BasicTimeInput = forwardRef(function BasicTimeInput(
    {
        className = "",
        name,
        value,
        shadow = false,
        defaultValue = null,
        minValue = null,
        maxValue = null,
        background = UIColor.DarkInput,
        padding = "py-1.5 px-4",
        label,
        onChange,
        onBlur,
    },
    ref
) {
    const classes = `w-fit ${padding} ${background} ${shadow ? "shadow-neutral-800 shadow-medium" : ""} rounded ${className}`;
    const inputElement = (
        <input
            type="time"
            name={name}
            value={value}
            defaultValue={defaultValue}
            min={minValue}
            max={maxValue}
            className={classes}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
        />
    );

    if (label) {
        return (
            <>
                <BasicInputLabel label={label} />
                <div>
                    {inputElement}
                </div>
            </>
        );
    }
    return inputElement;
});

export default BasicTimeInput;
