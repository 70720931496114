import ModalWindow from "../../common/ModalWindow";
import {BasicButton} from "../../common/Button";
import {useContext, useState} from "react";
import BasicDateInput from "../../common/DateInput";
import {DateUtils} from "../../common/Utils";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGear, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

export default function ModifyActivitiesModal({activities, onClose, ...props}) {
    const userDispatch = useContext(UserDispatchContext);
    const user = useContext(UserContext);

    const [newDateString, setNewDateStringString] = useState(null);

    const [submittedType, setSubmittedType] = useState(null);

    const {t, i18n} = useTranslation();

    if (activities === null)
        return <></>;

    const isSaving = user.status.isLoading(UserActions.ModifyActivities)
        || user.status.isLoading(UserActions.RemoveActivities)
        || user.status.isLoading();

    function onSubmit(submitEvent) {
        if (user === null) {
            console.error("Modifying activity when user is null!")
            return;
        }

        submitEvent.preventDefault();

        let newDate = DateUtils.stringToDate(newDateString);

        if (newDate === null) {
            onClose();
            return;
        }

        let modifiedActivities = [];

        for (let i = 0; i < activities.length; i++) {
            let activity = activities[i];

            let modifiedActivity = activity.clone();
            modifiedActivity.timestamp = DateUtils.stringToDate(newDateString, activity.timestamp);

            modifiedActivities.push(modifiedActivity);
        }

        setSubmittedType(UserActions.ModifyActivities);

        userDispatch({
            type: UserActions.ModifyActivities,
            id: UserActions.ModifyActivities,
            activities: modifiedActivities,
            postSuccessHooks: () => {
                setSubmittedType(null);
                onClose();
            }
        });
    }

    function onRemove() {
        if (user === null) {
            console.error("Removing activity when user is null!")
            return;
        }

        setSubmittedType(UserActions.RemoveActivities);

        userDispatch({
            type: UserActions.RemoveActivities,
            id: UserActions.RemoveActivities,
            activities: activities,
            postSuccessHooks: () => {
                setSubmittedType(null);
                onClose();
            }
        });
    }

    const dateString = DateUtils.toPrettyDay(activities[0].timestamp, i18n.language);

    const modifyingText = <p className={UIColor.MinorText}>
        {t("modify_activities.modifying_1")}
        {" "}
        <strong>{activities.length}</strong>
        {" "}
        {activities.length === 1 ? t("modify_activities.modifying_activity") : t("modify_activities.modifying_activities")}
        {" "}
        {t("modify_activities.modifying_2")}
        {" "}
        <strong>{dateString}</strong>.
    </p>;

    return (
        <ModalWindow handleClose={onClose} title={t("modify_activities.modify_activities")} {...props}>
            <form onSubmit={onSubmit}>
                {modifyingText}
                <div className="mb-6"></div>
                <BasicDateInput
                    defaultValue={activities[0].timestamp}
                    minValue={activities[0].route.creationUtcTimestamp}
                    maxValue={new Date()}
                    onChange={(e) => setNewDateStringString(e.target.value)}
                />
                <div className="mb-8"></div>
                <div className="flex">
                    <BasicButton
                        buttonColor={UIColor.Red} className="w-full"
                        type="button"
                        onClick={onRemove}
                        disabled={isSaving}
                    >
                        {(isSaving && submittedType === UserActions.RemoveActivities) ? <SpinLoadingIndicator/> :
                            <> {activities.length === 1 ? t("modify_activities.remove") : t("modify_activities.remove_all")}
                                <FontAwesomeIcon icon={faTrash} className="pl-1.5"/> </>}
                    </BasicButton>
                    <div className="m-1"></div>
                    <BasicButton type="submit" buttonColor={UIColor.Blue}
                                 className="w-full"
                                 disabled={newDateString === null || isSaving}
                    >
                        {(isSaving && submittedType === UserActions.ModifyActivities) ? <SpinLoadingIndicator/> :
                            <> {activities.length === 1 ? t("modify_activities.update") : t("modify_activities.update_all")}
                                <FontAwesomeIcon icon={faGear} className="pl-1.5"/> </>}
                    </BasicButton>
                </div>
            </form>
        </ModalWindow>
    )
}
