import React, {useRef, useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUpload} from "@fortawesome/free-solid-svg-icons";
import {UIColor} from "../Config";
import {BasicButton} from "./Button";
import {WarningMessage} from "./WarningMessage";

function FileInput({onChange, disabled, acceptedExtensions = []}) {
    const {t} = useTranslation();

    const inputRef = useRef();

    const [fileCount, setFileCount] = useState(0);
    const [invalidFiles, setInvalidFiles] = useState([]);
    const [warning, setWarning] = useState(false);

    const handleFileChange = (event) => {
        const files = event.target.files;
        const invalidFiles = [];

        // Check each file's extension against the accepted extensions
        Array.from(files).forEach((file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!acceptedExtensions.includes(fileExtension)) {
                invalidFiles.push(file.name);
            }
        });

        setFileCount(event.target.files.length);
        setInvalidFiles(invalidFiles);

        // Set warning state
        if (invalidFiles.length > 0) {
            setWarning(true);
        } else {
            setWarning(false);
        }

        const customEvent = {
            target: {
                files,
                invalidFiles: invalidFiles,
            },
        };

        onChange(customEvent);
    };

    return (
        <div className="relative">
            <input
                type="file"
                ref={inputRef}
                multiple
                onChange={handleFileChange}
                disabled={disabled}
                className="hidden"
            />
            <BasicButton
                onClick={() => inputRef.current.click()}
                disabled={disabled}
                className={"w-full"}
            >
                <FontAwesomeIcon icon={faUpload} className="mr-2"/>
                <span> {fileCount === 0
                    ? t("file_input.no_files")
                    : `${fileCount} ${t("file_input.yes_files")}`} </span>
            </BasicButton>

            {warning && invalidFiles.length > 0 && (<>
                <div className={"mb-5"}></div>
                <WarningMessage
                    title={t("file_input.invalid_extensions")}
                    text={<>{invalidFiles.length} <Trans
                        i18nKey={"file_input.invalid_extensions_description"}
                        components={{strong: <strong/>}}
                    /></>}
                >
                    <div className={`${UIColor.Yellow} rounded px-2 py-3 overflow-scroll`}>
                        <ul className="list-disc pl-5 text-sm text-nowrap">
                            {invalidFiles.map((file, index) => (
                                <li key={index}><code>{file}</code></li>
                            ))}
                        </ul>
                    </div>
                </WarningMessage>
            </>)}
        </div>
    );
}

export default FileInput;
