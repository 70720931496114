import {useEffect, useMemo, useRef} from "react";
import Hammer from "hammerjs";
import Window from "./Window";
import {faGrip} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function DraggableWindow(
    {
        children,
        offsets = null,
        defaultPosition = null,
        dragSymbol = false,
        nonDraggableSelector = ".non-draggable",
        ...props
    }
) {
    const infoPanel = useRef();

    const currentPlacementIdx = useRef(defaultPosition ?? 0);

    // Dynamic PanelPlacements class that can use custom placements
    const PanelPlacements = useMemo(() => {
        class PanelPlacements {
            static FullyShown = new PanelPlacements(() => (window.innerHeight - (10 + getHeight())));

            constructor(topValueSupplier) {
                this.topValueSupplier = topValueSupplier;
            }

            topValue() {
                return this.topValueSupplier();
            }

            static getValues() {
                return [this.FullyShown, ...(offsets ?? []).map(offset => new PanelPlacements(() => window.innerHeight - offset))];
            }

            static getIndex(value) {
                return this.getValues().indexOf(value);
            }

            static valueIndexFromTop(topValue) {
                const diffs = this.getValues().map(placement => Math.abs(placement.topValue() - topValue));
                return diffs.indexOf(Math.min(...diffs));
            }
        }

        return PanelPlacements;
    }, []);

    function getHeight() {
        return infoPanel.current ? parseInt(window.getComputedStyle(infoPanel.current).height) : 0;
    }

    function snapToNearestPlacement() {
        if (infoPanel.current === null)
            return;

        const currentTop = parseInt(window.getComputedStyle(infoPanel.current).top);
        let placementIdx = PanelPlacements.valueIndexFromTop(currentTop);
        animatePlacement(placementIdx);
    }

    function animatePlacement(placementIdx) {
        const values = PanelPlacements.getValues();
        if (placementIdx >= 0 && placementIdx < values.length) {
            const top = values[placementIdx].topValue();
            infoPanel.current.style.transition = "top 0.3s ease-in-out";
            infoPanel.current.style.top = top + "px";
            currentPlacementIdx.current = placementIdx;
        }
    }

    function setPlacement(placementIdx) {
        const values = PanelPlacements.getValues();
        if (placementIdx >= 0 && placementIdx < values.length) {
            const top = values[placementIdx].topValue();
            infoPanel.current.style.transition = null;
            infoPanel.current.style.top = top + "px";
            currentPlacementIdx.current = placementIdx;
        }
    }

    function setTop(targetValue) {
        if (infoPanel.current) {
            infoPanel.current.style.transition = null;
            infoPanel.current.style.top = targetValue;
        }
    }

    useEffect(() => {
        if (!infoPanel.current) return;

        let topStart;
        let dragStartY;
        let isDragging = false;
        let shouldIgnoreDrag = false;

        function onPanStart(event) {
            // Check if the drag started on a non-draggable element
            if (event.srcEvent && event.srcEvent.target) {
                // Check if the target or any of its parents match the non-draggable selector
                let el = event.srcEvent.target;
                while (el && el !== infoPanel.current) {
                    if (el.matches && el.matches(nonDraggableSelector)) {
                        shouldIgnoreDrag = true;
                        return;
                    }
                    el = el.parentElement;
                }
            }

            shouldIgnoreDrag = false;
            isDragging = true;
            dragStartY = event.center.y;
            topStart = parseInt(window.getComputedStyle(infoPanel.current).top);

            // Disable pointer events to "eat" clicks during drag
            infoPanel.current.style.pointerEvents = 'none';
        }

        function onPan(event) {
            if (shouldIgnoreDrag) return;
            if (!isDragging) return;

            let newTop = topStart + event.center.y - dragStartY;

            const height = getHeight();
            let newBottom = window.innerHeight - (newTop + height);
            newBottom = Math.min(newBottom, 10);

            newTop = window.innerHeight - (newBottom + height);
            setTop(newTop + "px");
        }

        function onPanEnd(event) {
            if (shouldIgnoreDrag) {
                shouldIgnoreDrag = false;
                return;
            }

            if (!isDragging) return;
            isDragging = false;

            // Re-enable pointer events once drag is over
            infoPanel.current.style.pointerEvents = 'auto';

            const currentTop = parseInt(window.getComputedStyle(infoPanel.current).top);
            let newPlacementIdx = PanelPlacements.valueIndexFromTop(currentTop);

            const placementValues = PanelPlacements.getValues();
            if (newPlacementIdx === currentPlacementIdx.current) {
                if (event.deltaY > 40) {
                    newPlacementIdx = Math.min(newPlacementIdx + 1, placementValues.length - 1);
                } else if (event.deltaY < -40) {
                    newPlacementIdx = Math.max(newPlacementIdx - 1, 0);
                }
            }
            animatePlacement(newPlacementIdx);
        }

        // Setup Hammer.js for touch gestures
        const hammer = new Hammer(infoPanel.current);
        hammer.get("pan").set({direction: Hammer.DIRECTION_VERTICAL});
        hammer.on("panstart", onPanStart);
        hammer.on("pan", onPan);
        hammer.on("panend", onPanEnd);

        // Handle window resize
        window.addEventListener("resize", snapToNearestPlacement);

        // Set initial placement
        setPlacement(currentPlacementIdx.current);

        // Setup resize observer to handle content height changes
        const observer = new ResizeObserver(() => {
            snapToNearestPlacement();
        });

        observer.observe(infoPanel.current);

        // Cleanup
        return () => {
            hammer.off("pan panstart panend");
            hammer.destroy();
            window.removeEventListener("resize", snapToNearestPlacement);
            observer.disconnect();
        }
    }, [PanelPlacements, nonDraggableSelector]);

    return (
        <Window
            id="info-panel"
            ref={infoPanel}
            className={`z-10 absolute right-4`}
            {...props}
        >
            {
                dragSymbol ? <FontAwesomeIcon
                    icon={faGrip}
                    className="absolute text-2xl top-4 left-1/2 -translate-x-1/2 cursor-pointer"
                    onClick={() => {
                        if (currentPlacementIdx.current === 0) {
                            animatePlacement(PanelPlacements.getValues().length - 1)
                        } else {
                            animatePlacement(0);
                        }
                    }}
                /> : <></>
            }

            {children}
        </Window>
    );
}
