import {useContext, useEffect} from "react";
import {Raycasting, TopDownControls} from "./Controls";
import {RouteFocusState} from "../canvas/CanvasState";
import * as THREE from "three";
import OutlineType from "../canvas/OutlineType";
import {LARGE_LOLLIPOP_SIZE, LOW_OPACITY} from "../../Config";
import {HoldType} from "../../model/Wall";
import {UserContext} from "../../contexts/UserContext";
import {RouteStateType} from "../../model/User";
import {WallContext} from "../../contexts/WallContext";
import {CanvasContext, CanvasStateContext, SetCanvasStateContext} from "../../contexts/CanvasContext";

export default function TopDownController({viewedRoutes}) {
    const user = useContext(UserContext);

    const wall = useContext(WallContext);
    const canvas = useContext(CanvasContext);

    const canvasState = useContext(CanvasStateContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    useEffect(() => {
        // make non-route holds lower opacity
        for (const hold of wall.value.holds.values()) {
            const opacity = hold.type === HoldType.Hold && hold.route === null ? LOW_OPACITY : 1;

            canvas.markForOpacityChange(hold.holdMesh, opacity);
        }

        // hide filtered-out routes
        for (const route of wall.value.routes) {
            if (!viewedRoutes.includes(route)) {
                for (const hold of route.holds) {
                    canvas.markForOpacityChange(hold.holdMesh, 0);
                }
            }
        }

        canvas.startOpacityChange()
    }, [canvas, viewedRoutes, wall])

    useEffect(() => {
        if (canvas === null || wall .value=== null)
            return;

        const routeStates = user.value !== null
            ? user.value.getRoutesClimbingStates(viewedRoutes)
            : Array.from({length: viewedRoutes.length}, () => RouteStateType.Untouched);

        canvas.setOutlinedObjects(
            OutlineType.ATTEMPTED_ROUTE,
            viewedRoutes
                .filter((_, idx) => routeStates[idx] === RouteStateType.Attempted)
                .flatMap(route => route.holds.map(hold => hold.holdMesh))
        );
        canvas.setOutlinedObjects(
            OutlineType.CLIMBED_ROUTE,
            viewedRoutes
                .filter((_, idx) => routeStates[idx] === RouteStateType.Climbed || routeStates[idx] === RouteStateType.Flashed)
                .flatMap(route => route.holds.map(hold => hold.holdMesh))
        );

        canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
        canvas.setOutlinedObjects(OutlineType.HOVER, []);

        canvas.render();
    }, [canvas, user, wall, viewedRoutes])

    useEffect(() => {
        const controls = new TopDownControls(canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation);

        controls.addEventListener("change", () => canvas.render());
        controls.register(null, wall.value, canvasState.animate);

        return () => {
            controls.unregister();
        }

    }, [canvas, canvasState, wall]);

    useEffect(() => {
        if (wall .value=== null || canvas === null)
            return;

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera, true);
        raycasting.addEventListener("object_hover", onRouteHover);
        raycasting.addEventListener("object_approach", onRouteHover);
        raycasting.addEventListener("object_click", onRouteClick);
        raycasting.addEventListener("object_approach_click", onRouteClick);

        function onRouteHover(raycastEvent) {
            const result = raycastEvent.result;

            if (result.hit) {
                const hitRoute = result.target.object;
                if (hitRoute === null) {
                    canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
                } else {
                    canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, hitRoute.holds.map(hold => hold.holdMesh));
                }
            } else {
                canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);
            }
            canvas.render();
        }

        function onRouteClick(raycastEvent) {
            const result = raycastEvent.result;
            console.assert(result.hit);
            const route = result.target.object;
            if (route === null)
                return;

            setCanvasState(new RouteFocusState(route));
        }

        for (const route of viewedRoutes) {
            const holds = route.holds;
            raycasting.addTarget(route, holds.map(hold => hold.holdMesh));
            raycasting.addApproachTarget(route, holds.map(hold => hold.holdMesh), holds.map(hold => new THREE.Sphere(hold.getCenter(), LARGE_LOLLIPOP_SIZE)));
        }

        raycasting.register();

        canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);

        return () => {
            raycasting.unregister();
        }
    }, [canvas, setCanvasState, viewedRoutes]);

    useEffect(() => {
        canvas.render();
    });
}
