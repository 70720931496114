/* Definition for buttons that modify the state of climbing a route. */

import {RouteStateType, UserActivity, UserActivityType} from "../../model/User";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarCheck, faCheck, faFlag, faRepeat} from "@fortawesome/free-solid-svg-icons";
import {BasicButton} from "../../common/Button";
import {DateUtils} from "../../common/Utils";
import {useContext, useState} from "react";
import {useTranslation} from "react-i18next";
import {UserActions, UserContext} from "../../contexts/UserContext";


export default function RouteActivityButtons(
    {
        route,
        handleActivityAdd,
        handleActivityRemove,
        className
    }) {
    const user = useContext(UserContext);

    const [confirmActivity, setConfirmActivity] = useState(false);
    const [confirmActivityText, setConfirmActivityText] = useState(null);

    const {t} = useTranslation();

    const routeState = user.value == null ? RouteStateType.Untouched : user.value.getRouteClimbingState(route);

    let climbActivities = [];
    if (user.value !== null) {
        for (const type of [UserActivityType.Flash, UserActivityType.Climb, UserActivityType.Repeat]) {
            climbActivities = climbActivities.concat(user.value.getActivities(type, route));
        }
    }

    // sort so we can check whether we climbed it today
    climbActivities.sort((a, b) => {
        return a.timestamp - b.timestamp;
    }).reverse();

    let todayActivity = null;
    if (climbActivities.length && DateUtils.isSameDay(new Date(Date.now()), climbActivities[0].timestamp)) {
        todayActivity = climbActivities[0];
    }

    function confirmWrapper(f, activities) {
        // for only 1 activity we're chilling
        if (climbActivities.length <= 1) {
            f(activities);
            return;
        }

        // for more activities, we want to confirm before nuking repeats
        if (!confirmActivity) {
            setConfirmActivity(true);
            setConfirmActivityText(t("route_panel.remove_repeats"));
        } else {
            f(activities, () => {
                setConfirmActivity(false);
                setConfirmActivityText(null)
            });
        }
    }

    let isLoading = user.status.isLoading(UserActivity.getTrackingId(route, climbActivities[0]?.type, UserActions.RemoveActivities))

    for (const type of [UserActivityType.Flash, UserActivityType.Climb, UserActivityType.Repeat, UserActivityType.Attempt]) {
        isLoading ||= user.status.isLoading(UserActivity.getTrackingId(route, type, UserActions.AddActivities))
    }

    // TODO: refactor this absolute dogshit jesus christ Tom
    return (
        <div key={"route-activity-buttons"} className={`flex justify-center space-x-3 ${className}`}>
            {(routeState === RouteStateType.Climbed || routeState === RouteStateType.Flashed) &&
                <BasicButton
                    padding="px-2 py-1.5"
                    onClick={() => {
                        // Only remove today's repeat -- if we sent that day, allow to repeat as well
                        // This is otherwise too confusing for the user
                        if (todayActivity !== null && todayActivity.type === UserActivityType.Repeat) {
                            handleActivityRemove([todayActivity]);

                            setConfirmActivity(false);
                            setConfirmActivityText(null)
                        } else {
                            handleActivityAdd(UserActivityType.Repeat);

                            setConfirmActivity(false);
                            setConfirmActivityText(null)
                        }
                    }}
                    buttonColor={UserActivityType.Color(UserActivityType.Repeat)}
                    className={(todayActivity !== null && todayActivity.type === UserActivityType.Repeat) ? `shadow-large shadow-neutral-600 transition-all duration-75` : ' transition-all duration-75'}
                    disabled={isLoading}
                >
                    <div className="flex items-center">
                        <span><FontAwesomeIcon icon={faRepeat} className="pr-1.5"/></span>
                        <strong
                            className="px-2 bg-neutral-800 bg-opacity-50 rounded-lg"><code>{climbActivities.length}</code></strong>
                        {todayActivity !== null && todayActivity.type === UserActivityType.Repeat ?
                            <span><FontAwesomeIcon icon={faCalendarCheck} className="pr-0.5 pl-2"/></span> : ""}
                    </div>
                </BasicButton>
            }
            {
                (routeState !== RouteStateType.Climbed && routeState !== RouteStateType.Flashed) &&
                <BasicButton
                    buttonColor={RouteStateType.Color(RouteStateType.Attempted)}
                    onClick={
                        () => {
                            if (routeState === RouteStateType.Attempted)
                                handleActivityRemove(UserActivityType.Attempt);
                            else
                                handleActivityAdd(UserActivityType.Attempt);
                        }
                    }
                    disabled={isLoading}
                    className={`min-w-0 ${routeState === RouteStateType.Attempted ? 'shadow-large shadow-rose-600 transition-all duration-75' : ' transition-all duration-75'}`}
                >
                    <p className="truncate">
                        <FontAwesomeIcon icon={faFlag}/>
                        <span className="ml-2">{t("route_state_type")[RouteStateType.Attempted]}</span>
                        {routeState === RouteStateType.Attempted && <FontAwesomeIcon icon={faCheck} className="ml-2"/>}
                    </p>
                </BasicButton>
            }
            {(routeState === RouteStateType.Climbed || routeState === RouteStateType.Attempted) &&
                <BasicButton
                    buttonColor={RouteStateType.Color(RouteStateType.Climbed)}
                    onClick={
                        () => {
                            if (routeState === RouteStateType.Climbed)
                                confirmWrapper(handleActivityRemove, climbActivities);
                            else
                                handleActivityAdd(UserActivityType.Climb);
                        }
                    }
                    disabled={isLoading}
                    className={`min-w-0 ${routeState === RouteStateType.Climbed ? "shadow-large shadow-emerald-600 transition-all duration-75" : ' transition-all duration-75 '}`}
                >
                    <p className={"truncate"}>
                        <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Climbed)} className="mr-2"/>
                        <span>{confirmActivityText ?? t("route_state_type")[RouteStateType.Climbed]}</span>
                        {routeState === RouteStateType.Climbed && <FontAwesomeIcon icon={faCheck} className="ml-2"/>}
                    </p>
                </BasicButton>
            }
            {routeState === RouteStateType.Flashed &&
                <BasicButton
                    buttonColor={RouteStateType.Color(RouteStateType.Flashed)}
                    onClick={() => confirmWrapper(handleActivityRemove, climbActivities)}
                    disabled={isLoading}
                    className={`min-w-0 ${routeState === RouteStateType.Flashed ? "shadow-large shadow-emerald-700 transition-all duration-75" : ' transition-all duration-75'}`}
                >
                    <p className="truncate">
                        <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Flashed)} className="mr-2"/>
                        <span>{confirmActivityText ?? t("route_state_type")[RouteStateType.Flashed]}</span>
                        <FontAwesomeIcon icon={faCheck} className="ml-2"/>
                    </p>
                </BasicButton>
            }
            {routeState === RouteStateType.Untouched &&
                <div className={`inline-flex rounded divide-x-2 divide-opacity-40 divide-white min-w-0`}>
                    <BasicButton
                        className="rounded-l min-w-0"
                        round={false}
                        buttonColor={RouteStateType.Color(RouteStateType.Climbed)}
                        onClick={() => handleActivityAdd(UserActivityType.Climb)}
                        disabled={isLoading}
                    >
                        <p className="truncate">
                            <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Climbed)} className="mr-2"/>
                            <span>{t("route_state_type")[RouteStateType.Climbed]}</span>
                        </p>
                    </BasicButton>
                    <BasicButton
                        className="rounded-r"
                        round={false}
                        buttonColor={RouteStateType.Color(RouteStateType.Flashed)}
                        onClick={() => handleActivityAdd(UserActivityType.Flash)}
                        disabled={isLoading}
                    >
                        <FontAwesomeIcon icon={RouteStateType.Icon(RouteStateType.Flashed)}/>
                    </BasicButton>
                </div>
            }
        </div>
    );
}
