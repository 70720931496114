import {useCallback, useContext, useEffect, useState} from "react";

import {Raycasting, TopDownControls} from "./Controls";
import {WallContext} from "../../contexts/WallContext";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import OutlineType from "../canvas/OutlineType";
import {Hold, Route} from "../../model/Wall";
import {EXTRA_LOW_OPACITY, SMALL_LOLLIPOP_SIZE} from "../../Config";
import {AppContext} from "../../AppContext";
import {Sphere} from "three";
import {PhaseButtons} from "../proposal_edit/PhaseButtons";
import RemovePhasePanel from "../proposal_edit/RemovePhasePanel";
import {CanvasContext} from "../../contexts/CanvasContext";

export default function RemovePhaseController() {
    const wall = useContext(WallContext);
    const canvas = useContext(CanvasContext);

    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const [shown, setShown] = useState(true);

    useEffect(() => {
        for (const hold of proposal.holds.values()) {
            canvas.markForOpacityChange(hold.holdMesh, 0);
        }

        canvas.startOpacityChange()
    }, [])

    const onHover = useCallback(raycastEvent => {
        const result = raycastEvent.result;

        if (result.hit) {
            if (AppContext.Mutable.isCanvasDragging)
                return;

            const hitObject = result.target.object;

            if (hitObject instanceof Hold) {
                canvas.setOutlinedObjects(OutlineType.HOVER, [hitObject.holdMesh]);
            } else if (hitObject instanceof Route) {
                canvas.setOutlinedObjects(OutlineType.HOVER, hitObject.holds.map(hold => hold.holdMesh));
            }

            canvas.render();
            return;
        } else {
            canvas.setOutlinedObjects(OutlineType.HOVER, []);
        }

        canvas.render();
    }, [canvas])

    const onClick = useCallback(raycastEvent => {
        const result = raycastEvent.result;

        const clickedObject = result.target.object;

        if (clickedObject instanceof Hold) {
            proposalDispatch({
                type: ProposalActions.ToggleRemoveHold,
                hold: clickedObject.id,
            });
        } else if (clickedObject instanceof Route) {
            proposalDispatch({
                type: ProposalActions.ToggleRemoveRoute,
                route: clickedObject.id,
            });
        }
    }, [proposalDispatch])

    useEffect(() => {
        function onControlsUpdate() {
            canvas.render();
        }

        const controls = new TopDownControls(
            canvas.getCanvasElement(),
            canvas.camera,
            canvas.cameraAnimation
        );

        controls.addEventListener("change", onControlsUpdate);

        controls.register(null, wall.value);

        canvas.render();

        return () => {
            controls.unregister();
        }
    }, [])

    useEffect(() => {
        if (proposal === null)
            return;

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera, true);

        raycasting.addEventListener("object_hover", onHover);
        raycasting.addEventListener("object_approach", onHover);
        raycasting.addEventListener("object_click", onClick);
        raycasting.addEventListener("object_approach_click", onClick);

        let removedHoldIDs = proposal.getRemovedHoldIDs();
        let removedRouteIDs = proposal.getRemovedRouteIDs();

        for (const hold of wall.value.holds.values()) {
            if (hold.route !== null) {
                continue;
            }

            if (!shown && removedHoldIDs.includes(hold.id)) {
                continue;
            }

            raycasting.addTarget(hold, [hold.holdMesh]);
            raycasting.addApproachTarget(hold, [hold.holdMesh], [new Sphere(hold.getCenter(), SMALL_LOLLIPOP_SIZE)]);
        }

        for (const route of wall.value.routes) {
            if (!shown && removedRouteIDs.includes(route.id)) {
                continue;
            }

            raycasting.addTarget(route, route.holds.map(hold => hold.holdMesh));
            raycasting.addApproachTarget(route, route.holds.map(hold => hold.holdMesh), route.holds.map(hold => new Sphere(hold.getCenter(), SMALL_LOLLIPOP_SIZE)));
        }

        raycasting.register();

        return () => {
            raycasting.unregister();
        }
    }, [proposal, shown]);

    useEffect(() => {
        if (wall.value === null || canvas === null || proposal === null)
            return;

        let removedHoldIDs = proposal.getRemovedHoldIDs();
        let removedRouteIDs = proposal.getRemovedRouteIDs();

        let removedHolds = [...wall.value.holds.values()].filter(hold => removedHoldIDs.includes(hold.id));
        let removedRoutes = wall.value.routes.filter(route => removedRouteIDs.includes(route.id));

        let opacity = shown ? 1 : EXTRA_LOW_OPACITY;

        // make entire wall visible by default
        for (const hold of wall.value.holds.values()) {
            canvas.markForOpacityChange(hold.holdMesh, 1);
        }

        // opacity of holds/routes removed so far
        for (const hold of removedHolds) {
            canvas.markForOpacityChange(hold.holdMesh, opacity);
        }

        for (const route of removedRoutes) {
            for (const hold of route.holds) {
                canvas.markForOpacityChange(hold.holdMesh, opacity);
            }
        }

        canvas.startOpacityChange()

        let removedRouteObjects = removedRoutes
            .map(route => route.holds.map(hold => hold.holdMesh))
            .flat();

        let removedHoldObjects = removedHolds
            .map(hold => hold.holdMesh);

        canvas.setOutlinedObjects(
            OutlineType.ATTEMPTED_ROUTE,
            shown ? removedRouteObjects.concat(removedHoldObjects) : [],
        );

        canvas.setOutlinedObjects(OutlineType.HOVER, []);
        canvas.setOutlinedObjects(OutlineType.CLIMBED_ROUTE, []);
        canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, []);

        canvas.render();
    }, [proposal, canvas, wall, shown]);

    return (<>
        <PhaseButtons/>
        <RemovePhasePanel
            shown={shown}
            setShown={setShown}
        />
    </>);
}