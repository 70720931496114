import * as TWEEN from "@tweenjs/tween.js";
import * as THREE from "three";
import * as config from "../../Config"

export default class CameraAnimation {
    constructor(canvas) {
        this.canvas = canvas;
        this.camera = canvas.camera;
        this.tween = null;
    }

    animate(targetPosition, targetQuaternion, duration = config.ANIMATION_DURATION, curve = TWEEN.Easing.Quadratic.InOut) {
        if (this.tween !== null && this.tween.isPlaying()) {
            this.tween.stop();
        }

        this.tween = new TWEEN.Tween([this.camera.position, this.camera.quaternion])
            .to([targetPosition, targetQuaternion], duration)
            .easing(curve)
            .onUpdate(() => {
                this.canvas.render();
            })
            .onComplete(() => {
                setTimeout(() => {
                    this.canvas.render();
                });
            })
            .startFromCurrentValues();
    }

    animateLookAt(targetPosition, targetLookAt, duration = config.ANIMATION_DURATION) {
        const rotationMatrix = new THREE.Matrix4();
        rotationMatrix.lookAt(targetPosition, targetLookAt, this.camera.up);
        const targetQuaternion = new THREE.Quaternion();
        targetQuaternion.setFromRotationMatrix(rotationMatrix);
        this.animate(targetPosition, targetQuaternion, duration);
    }

    animateRotate(distance, angle, duration = config.ANIMATION_DURATION) {
        const camera = this.camera;
        const target = new THREE.Vector3();
        camera.getWorldDirection(target);
        target.multiplyScalar(distance).add(camera.position);

        const startPosition = camera.position.clone();
        const center = target;

        const startAngle = Math.atan2(startPosition.z - center.z, startPosition.x - center.x);

        new TWEEN.Tween({t: 0})
            .to({t: 1}, duration)
            .easing(TWEEN.Easing.Quartic.In)
            .onUpdate((obj) => {
                const currentAngle = startAngle + obj.t * angle;
                camera.position.set(
                    center.x + Math.cos(currentAngle) * distance,
                    startPosition.y,
                    center.z + Math.sin(currentAngle) * distance
                );
                camera.lookAt(center);
                this.canvas.render();
            })
            .start();
    }


    animateZoomIn(distance, rotation, duration = config.ANIMATION_DURATION) {
        const camera = this.camera;
        const direction = new THREE.Vector3();
        camera.getWorldDirection(direction);

        const startPosition = camera.position.clone();
        const targetPosition = startPosition.clone().addScaledVector(direction, distance);

        const rotationAxis = new THREE.Vector3(0, 0, 1);
        const startRotation = camera.quaternion.clone();

        new TWEEN.Tween({t: 0})
            .to({t: 1}, duration)
            .easing(TWEEN.Easing.Quartic.Out)
            .onUpdate((obj) => {
                camera.position.lerpVectors(startPosition, targetPosition, obj.t);
                const incrementalRotation = new THREE.Quaternion().setFromAxisAngle(rotationAxis, obj.t * rotation);
                camera.quaternion.copy(startRotation).multiply(incrementalRotation);
                this.canvas.render();
            })
            .start();
    }

    savePosition() {
        this.savedPosition = this.camera.position.clone();
        this.savedQuaternion = this.camera.quaternion.clone();
    }

    restorePosition(duration = config.ANIMATION_DURATION, speed = undefined) {
        if (this.savedPosition && this.savedQuaternion) {
            if (speed !== undefined) {
                const currentPos = this.camera.position;
                const delta = this.savedPosition.clone().sub(currentPos).length();
                duration = delta / speed * 1000; // Convert to milliseconds
            }

            this.animate(this.savedPosition, this.savedQuaternion, duration, TWEEN.Easing.generatePow(1).In);
        }
    }

    isPlaying() {
        return this.tween !== null && this.tween.isPlaying();
    }
}
