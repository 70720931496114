import {Group, Scene} from "three";

export class RebuildPhase {
    static Remove = 0;
    static Add = 1;
}

export class RebuildStatus {
    static Running = 1;
    static Completed = 2;
    static Failed = 3;
}

export default class RebuildProposal {
    constructor(metadata, holds = null, holdsMesh = null, routes = null, diff = null) {
        this.metadata = metadata;

        this.holds = holds;
        this.holdsMesh = holdsMesh;
        this.routes = routes;

        // this has the following format:
        //  {
        //      "routes": {
        //          "added": [...],
        //          "removed": [...],
        //          "merged": [...]
        //      }, "holds": {
        //          "added": [...],
        //          "removed": [...],
        //          "merged": [...]
        //      }
        //  }
        this.diff = JSON.parse(JSON.stringify(diff));

        this.beingReviewed = false;
        this.saving = false;
        this.savingError = null;

        this.phase = 0;
    }

    getRemovedHoldIDs() {
        return this.diff.holds.removed || [];
    }

    getRemovedRouteIDs() {
        return this.diff.routes.removed || [];
    }

    getAddedHoldIDs() {
        return this.diff.holds.added || [];
    }

    getAddedRouteIDs() {
        return this.diff.routes.added || []
    }

    getWillNotAddHoldIDs() {
        let non_route_holds = Array.from([...this.holds.values()]
            .filter(hold => hold.route === null)
            .map(hold => hold.id))

        let added = this.getAddedHoldIDs();

        return non_route_holds.filter(hold => !added.includes(hold));
    }

    toggleRemovedHold(holdID) {
        const idx = this.diff.holds.removed.indexOf(holdID);

        if (idx === -1) {
            this.diff.holds.removed.push(holdID);
        } else {
            this.diff.holds.removed.splice(idx, 1);
        }
    }

    toggleRemovedRoute(routeID) {
        const idx = this.diff.routes.removed.indexOf(routeID);

        if (idx === -1) {
            this.diff.routes.removed.push(routeID);
        } else {
            this.diff.routes.removed.splice(idx, 1);
        }
    }

    toggleAddHold(holdID) {
        const idx = this.diff.holds.added.indexOf(holdID);

        if (idx === -1) {
            this.diff.holds.added.push(holdID);
        } else {
            this.diff.holds.added.splice(idx, 1);
        }
    }

    toggleAddRoute(routeID) {
        const idx = this.diff.routes.added.indexOf(routeID);

        if (idx === -1) {
            this.diff.routes.added.push(routeID);
        } else {
            this.diff.routes.added.splice(idx, 1);
        }
    }

    filterEmptyRoutes() {
        const removedIds = this.routes
            .filter(route => route.holds.length === 0)
            .map(route => route.id);

        this.routes = this.routes.filter(route => route.holds.length > 0);

        this.diff.routes.added = this.diff.routes.added.filter(id => !removedIds.includes(id));
    }

    shallowClone() {
        const clone = new RebuildProposal(
            this.metadata,
            this.holds,
            this.holdsMesh,
            this.routes,
            this.diff,
        );

        clone.beingReviewed = this.beingReviewed;
        clone.saving = this.saving;
        clone.savingError = this.savingError;

        clone.phase = this.phase;

        return clone;
    }

    static NotReady(metadata) {
        return new RebuildProposal(metadata);
    }

    getNewMesh(wall) {
        const newHoldsMesh = new Scene();
        const newHoldsMetadata = [];

        const routeGroups = new Map();

        function getOrCreateGroup(routeId) {
            if (!routeGroups.has(routeId)) {
                const group = new Group();
                group.name = routeId;
                routeGroups.set(routeId, group);
                newHoldsMesh.add(group);
            }
            return routeGroups.get(routeId);
        }

        wall.holdsMesh.traverse(hold => {
            if (hold.type === "Mesh") {
                const holdObject = wall.holds.get(hold.name);

                if (
                    !this.diff.holds.removed.includes(hold.name)
                    && !this.diff.routes.removed.includes(holdObject.route.id)
                ) {
                    console.debug("Keeping", hold.name);
                    const clonedHold = hold.clone();

                    if (holdObject.route?.id) {
                        getOrCreateGroup(holdObject.route.id).add(clonedHold);
                    } else {
                        newHoldsMesh.add(clonedHold);
                    }

                    newHoldsMetadata.push(holdObject.metadata);
                }
            }
        });

        this.holdsMesh.traverse(hold => {
            if (hold.type === "Mesh") {
                const holdObject = this.holds.get(hold.name);

                if (
                    this.diff.holds.added.includes(hold.name)
                    || this.diff.routes.added.includes(holdObject.route.id)
                ) {
                    console.debug("Adding", hold.name);
                    const clonedHold = hold.clone();

                    if (holdObject.route?.id) {
                        getOrCreateGroup(holdObject.route.id).add(clonedHold);
                    } else {
                        newHoldsMesh.add(clonedHold);
                    }

                    newHoldsMetadata.push(holdObject.metadata);
                }
            }
        });

        newHoldsMesh.userData.holds = newHoldsMetadata;

        return newHoldsMesh;
    }

    getNewRoutes(wall) {
        const newRoutes = [];

        for (const route of wall.routes) {
            if (!this.diff.routes.removed.includes(route.id)) {
                newRoutes.push(route);
            }
        }

        for (const route of this.routes) {
            if (this.diff.routes.added.includes(route.id)) {
                route.creationUtcTimestamp = new Date();

                newRoutes.push(route);
            }
        }

        return newRoutes;
    }
}