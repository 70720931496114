const CircuitTape = ({circuitColor}) => {
    if (!circuitColor) return null;

    return (
        <div
            className="opacity-75 h-2.5 w-full flex-grow bg-gradient-to-l from-transparent"
            style={{
                backgroundImage: `linear-gradient(to left, transparent, ${circuitColor})`,
                clipPath: "polygon(0% 50%, 5px 100%, 100% 100%, 100% 0%, 5px 0%)",
            }}
        />
    );
};

export default CircuitTape;
