import { useState } from "react";
import { ObjectWithTracker } from "./ContextUtils";
import {BackendResult, ErrorType} from "../model/Backend";

/**
 * Creates a base action class for simple state updates
 */
export class SimpleAction {
    constructor(newStateCreator, postSuccessHooks = null) {
        this.newStateCreator = newStateCreator;
        this.postSuccessHooks = postSuccessHooks;
    }

    execute(setState) {
        setState(this.newStateCreator);
        this.postSuccessHooks?.();
    }
}

/**
 * Creates a base action class for state updates with side effects
 */
export class ActionWithSideEffects {
    constructor(backendPromise, trackingId, onSuccess, postSuccessHooks = null) {
        this.backendPromise = backendPromise;
        this.trackingId = trackingId;
        this.onSuccess = onSuccess;
        this.postSuccessHooks = postSuccessHooks;
    }

    execute(setState) {
        const trackingId = this.trackingId;
        const onSuccess = this.onSuccess;
        const postSuccessHooks = this.postSuccessHooks;

        function modifyStateWithResult(oldState, actionResult) {
            let newState = oldState.clone();
            newState.status.stopLoading(trackingId);
            if (actionResult.isSuccess()) {
                let result = onSuccess(newState, actionResult);

                postSuccessHooks?.();

                if (result)
                    newState = result;
            } else {
                newState.status.setError(actionResult, trackingId);
            }
            return newState;
        }

        function onResponse(result) {
            setState(s => modifyStateWithResult(s, result))
        }

        this.backendPromise
            .then(onResponse)
            .catch(error => {
                console.error(error);

                // TODO: no unexpected error!?!

                onResponse(BackendResult.FromError(ErrorType.UnexpectedError));
            });

        setState(s => {
            const clonedState = s.clone();
            clonedState.status.deleteAllErrors();
            clonedState.status.startLoading(this.trackingId);
            return clonedState;
        });
    }
}

/**
 * Creates a generic reducer hook that can be used with different state types
 * @param {Function} actionCreator - Function that creates actions based on action data
 * @param {any} initialState - The initial state
 * @param {boolean} withTracker - Whether the state should be wrapped with ObjectWithTracker
 * @returns {Array} - [state, dispatch] tuple
 */
export function useReducer(actionCreator, initialState = null, withTracker = true) {
    const [state, setState] = useState(
        withTracker ? new ObjectWithTracker(initialState) : initialState
    );

    function dispatch(...actionData) {
        for (const a of actionData) {
            console.log("Dispatching action: ", a);

            if (withTracker && a.trackingId && state.status.isLoading(a.trackingId)) {
                console.warn("Action is already being performed, ignoring " + a.type);
                continue;
            }

            const action = actionCreator(state, a);
            action.execute(setState);
        }
    }

    return [state, dispatch];
}

/**
 * Creates a simple reducer function for state that doesn't need tracking or async operations
 * @param {Function} reducerFunction - Classic reducer function (state, action) => newState
 * @returns {Function} - Action creator function that returns SimpleAction
 */
export function createSimpleActionCreator(reducerFunction) {
    return (state, action) => {
        return new SimpleAction(
            currentState => reducerFunction(currentState, action),
            action.postSuccessHooks
        );
    };
}

/**
 * Utility for simulating successful backend calls
 * Used for temporary or offline modes where we want to avoid real server communication
 *
 * @param {boolean} shouldSimulate - Whether to simulate the backend call
 * @param {Function} backendFunction - The actual backend function to call if not simulating
 * @param {any} params - Parameters to pass to the backend function
 * @returns {Promise} - A promise that resolves with a success result or the actual backend call
 */
export function simulateBackendSuccess(shouldSimulate, backendFunction, params) {
    if (shouldSimulate) {
        return new Promise((resolve) => {
            resolve(BackendResult.EmptySuccess());
        });
    } else {
        return backendFunction(params);
    }
}
