import * as THREE from "three";
import {Canvg} from 'canvg';
import HoldIcon from "./HoldIcon";

class VectorUtils {
    static averageVectors3(vectors) {
        const average = new THREE.Vector3();
        for (let vector of vectors) {
            average.add(vector);
        }
        average.divideScalar(vectors.length);
        return average;
    }
}

class NumberUtils {
    static weightedAverage(values, weights) {
        console.assert(values.length === weights.length);
        let sum = 0;
        let weightsSum = 0;
        for (let i = 0; i < values.length; i++) {
            sum += values[i] * weights[i];
            weightsSum += weights[i];
        }
        return sum / weightsSum;
    }
}

class UiUtils {
    static consumeMouseEvents(...elements) {
        for (let element of elements) {
            element.addEventListener("click", (event) => event.stopPropagation());
            element.addEventListener("dblclick", (event) => event.stopPropagation());
            element.addEventListener("mousemove", (event) => event.stopPropagation());
        }
    }

    static confirmAsync(msg) {
        return new Promise((resolve, reject) => {
            let confirmed = window.confirm(msg);
            return resolve(confirmed);
        });
    }
}

class StringUtils {
    // From https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid.
    static generateId() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    static isValidUuid(s) {
        return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(s);
    }
}

class FilesUtils {
    static getExtension(filename) {
        const parts = filename.split(".");
        if (parts.length === 1 || (parts[0] === "" && parts.length === 2)) {
            return "";
        }
        return "." + parts.pop();
    }
}

class CookieUtils {
    static setCookie(name, value) {
        document.cookie = name + "=" + value + "; Path=/;";
    }

    static deleteCookie(name) {
        document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
    }
}

class DateUtils {
    static isSameDay(date1, date2) {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    }


    /**
     * 3. 2. 2024
     */
    static toFormalDay(date) {
        return `${date.getDate()}. ${date.getMonth() + 1}. ${date.getFullYear()}`
    }

    /**
     * Wed, May 29, 2024 (localized)
     */
    static toPrettyDay(date, locale = "en-US") {
        return date.toLocaleDateString(locale, {
            year: "numeric", month: "long", day: "numeric"
        });
    }

    static stringToDate(string, date = null) {
        try {
            const [year, month, day] = string.split('-').map(Number);

            if (date)
                return new Date(
                    year, month - 1, day,
                    date.getHours(), date.getMinutes(), date.getSeconds()
                );
            else
                return new Date(year, month - 1, day);
        } catch (e) {
            return null;
        }
    }
}


class AssertUtils {
    static assertDefined(...args) {
        for (let i = 0; i < args.length; i++) {
            console.assert(args[i] !== undefined, `${i + 1}th argument was undefined.`);
        }
    }
}

class SpecialStates {
    static ToBeLoaded = new SpecialStates("to_be_loaded");
    static NonePending = new SpecialStates("none_pending");

    static CreateErrorState(error) {
        return new SpecialStates.ErrorState(error);
    }

    constructor(tag) {
        this.tag = tag;
    }

    toString() {
        return `[${this.tag}]`;
    }

    static ErrorState = class ErrorState {
        constructor(error) {
            this.error = error;
        }
    }
}

class ImageUtils {
    static async getRandomHoldImage(size) {
        size ??= 256

        // Create an offscreen canvas
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width = size;
        canvas.height = size;

        // Read the SVG blob as text
        const svgText = new HoldIcon().generateRandomHold();

        // Use Canvg to render the SVG on the canvas
        const v = await Canvg.fromString(context, svgText);
        await v.render();

        // Convert the canvas to a WebP blob
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                resolve(blob);
            }, 'image/webp');
        });
    }

    static async base64ToBlob(base64, contentType) {
        return new Promise((resolve, reject) => {
            try {
                const byteCharacters = atob(base64);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], {type: contentType});
                resolve(blob);
            } catch (error) {
                reject(error);
            }
        });
    }

    static async blobToBase64(blob) {
        return new Promise((resolve, _) => {
            var reader = new FileReader();
            reader.onload = function () {
                var dataUrl = reader.result;
                resolve(dataUrl.split(',')[1]);
            };
            reader.readAsDataURL(blob);
        });
    }

    static async resizeImage(file, size) {
        size ??= 256

        let bitmap;
        if (typeof file === 'string' && file.startsWith('data:image')) {
            // Input is a base-64 encoded image
            const base64Response = await fetch(file);
            const blob = await base64Response.blob();
            bitmap = await createImageBitmap(blob);
        } else if (file instanceof File || file instanceof Blob) {
            bitmap = await createImageBitmap(file);
        } else {
            throw new Error('Input must be a base-64 encoded image string or a File object');
        }

        const {width, height} = bitmap

        const ratio = Math.max(size / width, size / height)

        const x = (size - (width * ratio)) / 2
        const y = (size - (height * ratio)) / 2

        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        canvas.width = size
        canvas.height = size

        ctx.drawImage(bitmap, 0, 0, width, height, x, y, width * ratio, height * ratio)

        return new Promise(resolve => {
            canvas.toBlob(blob => {
                resolve(blob)
            }, 'image/webp', 1)
        })
    }

    static async processProfileImage(file) {
        return new Promise(async (resolve, reject) => {
            try {
                const resizedImage = await ImageUtils.resizeImage(file);
                const base64String = await ImageUtils.blobToBase64(resizedImage);

                // Resolve with the data to be used in the then() block
                resolve({resizedImage, base64String});
            } catch (error) {
                // Reject the promise with the error
                reject(error);
            }
        })
    }
}

function unimplementedFunction(...args) {
    throw new Error("Unimplemented function - was probably left as a default value although it should have been overwritten.");
}

export {
    VectorUtils,
    NumberUtils,
    UiUtils,
    StringUtils,
    FilesUtils,
    CookieUtils,
    DateUtils,
    AssertUtils,
    SpecialStates,
    ImageUtils,
    unimplementedFunction,
};