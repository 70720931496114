import Window from "../../common/Window";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faTrash} from "@fortawesome/free-solid-svg-icons";
import Spacer from "../../common/Spacer";
import {useContext} from "react";
import PhasePanelButton from "./PhasePanelButton";
import {Trans, useTranslation} from "react-i18next";
import DraggableWindow from "../../common/DraggableWindow";

export default function RemovePhasePanel({shown, setShown}) {
    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const {t} = useTranslation();

    return (<>
        <DraggableWindow
            overflow="visible"
            offsets={[70]}
        >
            <div
                className={`absolute -translate-y-1/2 flex justify-center w-full gap-4`}>
                <PhasePanelButton
                    color={UIColor.Red}
                    onClick={() => proposalDispatch({type: ProposalActions.NextPhase})}
                    text={t("rebuild.remove_phase.remove_all")}
                    icon={faTrash}
                />

                <BasicButton
                    className="text-xl shadow-medium rounded-xl w-14"
                    round={false}
                    onClick={() => setShown(!shown)}
                >
                    <FontAwesomeIcon icon={shown ? faEye : faEyeSlash}/>
                </BasicButton>
            </div>

            <div className={"px-4 py-6 pt-8"}>
                <p className={"text-lg text-center"}>
                    <strong
                        className={"text-xl"}>{proposal?.getRemovedRouteIDs()?.length}</strong> {t("rebuild.objects.route",
                    {count: proposal?.getRemovedRouteIDs()?.length})}<span
                    className={"px-3"}/><strong
                    className={"text-xl"}>{proposal?.getRemovedHoldIDs()?.length}</strong> {t("rebuild.objects.object",
                    {count: proposal?.getRemovedRouteIDs()?.length})}
                </p>
                <Spacer spacing={"mt-4 mb-4"}/>
                <p className={`${UIColor.MinorText} text-justify`}>
                    <Trans
                        i18nKey="rebuild.remove_phase.warning"
                        components={{strong: <strong/>}}
                    />
                </p>
            </div>
        </DraggableWindow>
    </>);
}
