import {Trans, useTranslation} from "react-i18next";
import {faDiscord, faInstagram, faTiktok, faXTwitter, faYoutube} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UIColor} from "../Config";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import ModalWindow from "./ModalWindow";
import TextLink from "./TextLink";
import Expandable from "./Expandable";
import {BasicButton} from "./Button";

const Footer = ({color = undefined, padding = "py-4", tight = false}) => {
    const {t} = useTranslation();

    const [showAGPLDisclosure, setShowAGPLDisclosure] = useState(false);
    const [showAGPLText, setShowAGPLText] = useState(false);

    const [textContent, setTextContent] = useState('');

    useEffect(() => {
        fetch('../licenses/AGPL')
            .then(response => response.text())
            .then(data => setTextContent(data))
            .catch(error => console.error('Error loading text file:', error));
    }, []);

    return (
        <>
            <footer className={`${padding} flex flex-col gap-4 ${color ?? ""} z-50`}>
                <div className={`flex ${tight ? "flex-col" : ""} gap-2 justify-center`}>
                    <div className={`flex justify-center items-center space-x-2 sm:space-x-3 text-xl sm:text-3xl`}>
                        <a href="https://x.com/Climbuddy" className="hover:opacity-50">
                            <FontAwesomeIcon icon={faXTwitter}/>
                        </a>
                        <a href="https://www.instagram.com/Climbuddy/" className="hover:opacity-50">
                            <FontAwesomeIcon icon={faInstagram}/>
                        </a>
                        <a href="https://www.youtube.com/@Climbuddy" className="hover:opacity-50">
                            <FontAwesomeIcon icon={faYoutube}/>
                        </a>
                        <a href="https://www.tiktok.com/@climbuddy" className="hover:opacity-50">
                            <FontAwesomeIcon icon={faTiktok}/>
                        </a>
                        <a href="https://discord.gg/azjDzZu7sV" className="hover:opacity-50">
                            <FontAwesomeIcon icon={faDiscord}/>
                        </a>
                    </div>
                    <div className={`justify-center pl-2 ${tight ? "text-center" : ""}`}>
                        <p className="text-sm sm:text-lg hover:opacity-50 font-bold">
                            <a href="https://blog.climbuddy.com">
                                blog.climbuddy.com
                            </a>
                        </p>
                        <p className={`${UIColor.MinorText} text-xs sm:text-sm`}>{t("about_us.blog_content")}</p>
                    </div>
                </div>
                <div className={`px-4 flex justify-center gap-x-6 gap-y-1 text-xs sm:text-sm flex-wrap`}>
                    <Link to="/terms_of_use" className="truncate hover:opacity-50">{t("about_us.terms_of_use")}</Link>
                    <Link to="/privacy_policy"
                          className="truncate hover:opacity-50">{t("about_us.privacy_policy")}</Link>
                    <button onClick={() => setShowAGPLDisclosure(true)} type={"button"}
                            className="truncate hover:opacity-50">
                        {t("about_us.open_source_licenses")}
                    </button>
                </div>
            </footer>

            <ModalWindow
                title={t("about_us.open_source_licenses")}
                show={showAGPLDisclosure} handleClose={() => setShowAGPLDisclosure(false)}>
                <p>
                    {<Trans i18nKey={"about_us.open_source_1"} components={{strong: <strong/>}}/>}
                </p>
                <div className={"p-2"}/>
                <p>
                    {<Trans i18nKey={"about_us.open_source_2"} components={{
                        TextLink: <TextLink href={"mailto:contact@climbuddy.com"}/>,
                        strong: <strong/>
                    }}/>}
                </p>
                <div className={"p-2"}/>
                {!showAGPLText ? <div className={"flex justify-center"}><BasicButton
                    onClick={() => setShowAGPLText(true)}
                >
                    Show License
                </BasicButton></div> : <div className={`overflow-scroll bg-white bg-opacity-10 rounded p-2`}>
                    <pre className={"text-xs"}>
                    {textContent}
                    </pre>
                </div>}

            </ModalWindow>
        </>
    );
};

export default Footer;