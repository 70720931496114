import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faArrowsUpDownLeftRight,
    faHandPointer,
    faRotate
} from '@fortawesome/free-solid-svg-icons'
import {UIColor} from "../Config";
import Window from "../common/Window";
import {UserContext} from "../contexts/UserContext";
import {useContext, useEffect, useState} from "react";
import {CanvasContext, CanvasStateContext} from "../contexts/CanvasContext";
import {RouteFocusState} from "../scene/canvas/CanvasState";
import {Euler, Vector3} from "three";
import {useTranslation} from "react-i18next";

export default function HelpBox() {
    const canvas = useContext(CanvasContext);
    const canvasState = useContext(CanvasStateContext);

    const [showHelpBox, setShowHelpBox] = useState(true);

    const [shouldFadeOut, setShouldFadeOut] = useState(false);

    const [moveCompleted, setMoveCompleted] = useState(false);
    const [turnCompleted, setTurnCompleted] = useState(false);
    const [clickCompleted, setClickCompleted] = useState(false);

    const {t, i18n} = useTranslation();

    useEffect(() => {
        const tutorialCookie = document.cookie.split('; ').find(row => row.startsWith('tutorialCompleted='));
        if (tutorialCookie && tutorialCookie.split('=')[1] === 'true') {
            setShowHelpBox(false);
        }
    }, []);

    useEffect(() => {
        if (moveCompleted && turnCompleted && clickCompleted) {
            document.cookie = "tutorialCompleted=true; path=/; max-age=31536000"; // 1 year expiration
        }
    }, [moveCompleted, turnCompleted, clickCompleted]);

    useEffect(() => {
        if (moveCompleted && turnCompleted && clickCompleted) {
            // start fadeout after last goal animation
            setTimeout(() => {
                setShouldFadeOut(true);
            }, 500);

            setTimeout(() => {
                setShowHelpBox(false);
                document.cookie = "tutorialCompleted=true; path=/; max-age=31536000"; // Set cookie
            }, 1500);
        }
    }, [moveCompleted, turnCompleted, clickCompleted]);

    useEffect(() => {
        if (canvasState instanceof RouteFocusState) {
            setClickCompleted(true);
        }
    }, [canvasState]);

    const EPSILON = 0.001;

    useEffect(() => {
        if (!canvas?.camera) return;

        const previousPosition = new Vector3();
        const previousRotation = new Euler();
        const hasMoved = { current: false };

        const interval = setInterval(() => {
            if (!canvas?.camera) return;

            const { position, rotation } = canvas.camera;

            // Wait for initial movement
            if (!hasMoved.current) {
                if (!position.equals(new Vector3(0, 0, 0)) ||
                    !rotation.equals(new Euler(0, 0, 0))) {
                    hasMoved.current = true;
                    previousPosition.copy(position);
                    previousRotation.copy(rotation);
                }
                return;
            }

            // Check position change
            if (previousPosition.distanceTo(position) > EPSILON) {
                setMoveCompleted(true);
                previousPosition.copy(position);
            }

            // Check rotation change
            if (Math.abs(previousRotation.x - rotation.x) > EPSILON ||
                Math.abs(previousRotation.y - rotation.y) > EPSILON ||
                Math.abs(previousRotation.z - rotation.z) > EPSILON) {
                setTurnCompleted(true);
                previousRotation.copy(rotation);
            }
        }, 100);

        return () => clearInterval(interval);
    }, [canvas]);

    if (!showHelpBox) {
        return null;
    }

    return (
        <>
            <Window
                width={"w-fit"}
                className={`flex flex-col gap-4 text-2xl absolute left-1/2 -translate-x-1/2 top-4 p-3 transition-opacity duration-1000 ${shouldFadeOut ? "opacity-0" : "opacity-100"}`}
                padding="px-3 py-1.5"
                overflow={'none'}
            >
                <div className="flex gap-3">
                    <div className="relative">
                        <div
                            className={`absolute flex flex-col gap-1 w-12 items-center transition-all duration-1000 ${moveCompleted ? "scale-[2.0] opacity-0" : "scale-100 opacity-100"}`}>
                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight}/>
                            <div className="relative">
                                <p className={`text-sm ${UIColor.MinorText}`}>{t("help_box.move")}</p>
                            </div>
                        </div>
                        <div className={`flex flex-col gap-1 w-12 items-center`}>
                            <FontAwesomeIcon icon={faArrowsUpDownLeftRight}
                                             className={moveCompleted ? UIColor.GreenText : ""}/>
                            <div className="relative">
                                <p className={`text-sm ${moveCompleted ? UIColor.GreenText : UIColor.MinorText}`}>{t("help_box.move")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        <div
                            className={`absolute flex flex-col gap-1 w-12 items-center transition-all duration-1000 ${turnCompleted ? "scale-[2.0] opacity-0" : "scale-100 opacity-100"}`}>
                            <FontAwesomeIcon icon={faRotate}/>
                            <div className="relative">
                                <p className={`text-sm ${UIColor.MinorText}`}>{t("help_box.turn")}</p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-1 w-12 items-center">
                            <FontAwesomeIcon icon={faRotate} className={turnCompleted ? UIColor.GreenText : ""}/>
                            <div className="relative">
                                <p className={`text-sm ${turnCompleted ? UIColor.GreenText : UIColor.MinorText}`}>{t("help_box.turn")}</p>
                            </div>
                        </div>
                    </div>
                    <div className="relative">
                        {
                            <div
                                className={`absolute flex flex-col gap-1 w-12 items-center transition-all duration-1000 ${clickCompleted ? "scale-[2.0] opacity-0" : "scale-100 opacity-100"}`}>
                                <FontAwesomeIcon icon={faHandPointer}/>
                                <div className="relative">
                                    <p className={`text-sm ${UIColor.MinorText}`}>{t("help_box.click")}</p>
                                </div>
                            </div>
                        }
                        <div className="flex flex-col gap-1 w-12 items-center">
                            <FontAwesomeIcon icon={faHandPointer} className={clickCompleted ? UIColor.GreenText : ""}/>
                            <div className="relative">
                                <p className={`text-sm ${clickCompleted ? UIColor.GreenText : UIColor.MinorText}`}>{t("help_box.click")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Window>
        </>
    );
}