import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import {useEffect, useState} from "react";
import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {BackendResult} from "./model/Backend";
import {ErrorFormatter} from "./common/ErrorFromBackendResult";
import Footer from "./common/Footer";
import Logo from "./common/Logo";
import {UIColor} from "./Config";
import {ExpandableLanguageSelector} from "./common/LanguageSelector";

export function GlobalInfoOverlay({children, header = true, footer = true}) {
    return (
        <div
            className={`fixed top-0 left-0 w-full h-full ${UIColor.Window} flex flex-col justify-center items-center text-white z-50`}>
            {
                header ?
                    <div className={`absolute top-0 w-full bg-slate-800 shadow-medium flex justify-center items-center`}>
                        <div className={"absolute right-3"}>
                            <ExpandableLanguageSelector/>
                        </div>
                        <div className={"flex items-baseline justify-center px-2 py-4"}>
                            <Logo clickable={true}/>
                        </div>
                    </div> : <></>
            }
            {children}
            {
                footer ?
                    <div className={`absolute bottom-0 w-full bg-slate-800 shadow-medium`}>
                        <Footer/>
                    </div> : <></>
            }
        </div>
    )
}

export function ProgressInfo({progress, size = 100, motto = null}) {
    const circumference = 2 * Math.PI * (size / 2 - 10);
    const strokeWidth = 10;
    const radius = size / 2 - strokeWidth;
    const progressText = `${Math.floor(progress * 100)}%`

    const [offset, setOffset] = useState(0);
    useEffect(() => {
        const progressOffset = (1 - progress) * circumference;
        setOffset(progressOffset);
    }, [progress, circumference]);

    return (
        <div className="flex items-center flex-col">
            {motto ? <p className="mb-6 text-2xl">{motto}</p> : ""}
            <svg
                width={size}
                height={size}
                viewBox={`0 0 ${size} ${size}`}
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    stroke="#18181b"
                    strokeWidth={strokeWidth}
                    fill="transparent"
                    r={size / 2 - 10}
                    cx={size / 2}
                    cy={size / 2}
                />
                <circle
                    stroke="#d4d4d4"
                    strokeWidth={strokeWidth + 0.5}
                    fill="transparent"
                    strokeDasharray={circumference}
                    strokeDashoffset={offset}
                    strokeLinecap="round"
                    r={radius}
                    cx={size / 2}
                    cy={size / 2}
                    transform={`rotate(-90 ${size / 2} ${size / 2})`}
                />
                <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    dy=".38em"
                    dx="0.05em"
                    fontSize={(size * 0.2) + "px"}
                    fill="white"
                >
                    {progressText}
                </text>
            </svg>
        </div>
    );
}

export function ErrorPage({backendError}) {
    let error = useRouteError();

    if (backendError)
        error = backendError;

    let errorStatus = null;
    let errorMessage = ErrorFormatter("unexpected_error");

    if (error instanceof BackendResult) {
        errorMessage = ErrorFormatter(error.errors);
    } else if (error instanceof Response) {
        errorStatus = error.status;
        errorMessage = ErrorFormatter("unexpected_error");
        error.text().then((message) => {
            errorMessage = message;
        }).catch(() => {
            errorMessage = ErrorFormatter("unexpected_error");
        });
    } else if (isRouteErrorResponse(error)) {
        errorStatus = error.status;
        errorMessage = error.statusText;

        if (error.status === 404) {
            errorMessage = ErrorFormatter("page_does_not_exist");
        }
    }

    return (
        <GlobalInfoOverlay>
            <div className="flex flex-col justify-center items-center px-4">
                <FontAwesomeIcon icon={faTriangleExclamation} className="my-auto text-red-400" size="5x"/>
                <div className="mb-6"></div>
                {errorStatus && <p className="text-4xl text-center text-red-400 font-bold">{errorStatus}</p>}
                <p className="text-2xl text-center text-red-400">{errorMessage}</p>
            </div>
        </GlobalInfoOverlay>
    );
}
