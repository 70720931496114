import {useContext, useEffect, useState} from "react";
import {GRADE_SYSTEMS} from "../model/Climbing";
import {WallContext} from "../contexts/WallContext";
import {SingleRangeSlider} from "./RangeSliders";

export default function GradePicker({onChange, selectedGrade, setSelectedGrade}) {
    const wall = useContext(WallContext);

    const [values, setValues] = useState([]);

    useEffect(() => {
        let grading = wall.value.grading;

        const system = GRADE_SYSTEMS[grading.type];
        const values = system.enumerate(grading.min, grading.max);

        values.unshift(null);

        setValues(values);
    }, [wall]);

    return <SingleRangeSlider
        values={values}
        value={selectedGrade}
        setValue={setSelectedGrade}
        onChange={onChange}
    />
}
