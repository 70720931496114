import {RouteStateType, UserActivityType} from "../../model/User";
import {Fragment, useContext, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {WallContext} from "../../contexts/WallContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBookmark, faList, faListCheck, faListOl, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {RouteFocusState} from "../../scene/canvas/CanvasState";
import {SetCanvasStateContext} from "../../contexts/CanvasContext";
import {ClickableUIStyle, UIColor} from "../../Config";
import {ScreenDimensions, useScreenDimensions} from "../../common/CustomHooks";
import {Trans, useTranslation} from "react-i18next";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import {BasicButton} from "../../common/Button";
import {Route} from "../../model/Wall";


function ProgressGridCell(
    {
        state, points = null, grade = null, isMostRecent = false, isBookmarked, onClick
    },
    key) {
    let icon = isBookmarked ? <FontAwesomeIcon icon={faBookmark}/> :
        <FontAwesomeIcon icon={RouteStateType.Icon(state)}/>;

    if (points && grade) {
        console.error("Can't have both points and grade! Removing points.")
        points = null;
    }

    return (
        <Fragment key={key}>
            <div
                className={`${isMostRecent ? 'border-yellow-500' : 'border-transparent'} border-2 ${ClickableUIStyle[UserActivityType.Color(state)]} p-4 rounded-lg cursor-pointer`}
                onClick={onClick}
            >
                {points !== null || grade !== null ?
                    <div className="absolute -translate-x-1/2 -translate-y-1/2 text-lg">
                        <div className="blur-[2px] opacity-20 text-2xl">
                            {icon}
                        </div>
                    </div> : ""}
                <div className="absolute -translate-x-1/2 -translate-y-1/2 text-lg">
                    {points === null && grade === null ? icon : <p>{points ?? grade}</p>}
                </div>
            </div>
        </Fragment>
    );
}

export default function ProgressGrid({onCellClick, usePoints = false, useGrades = false, sortPoints = false}) {
    const user = useContext(UserContext);
    const wall = useContext(WallContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    let progressList = [];

    // Collect routes and their points into an array
    let routePointsArray = [];

    for (const route of Route.sortByDate(wall.value.routes)) {
        let points = user.value?.getRoutePoints(route);
        routePointsArray.push({route, points});
    }

    // If usePoints is true, sort the array in decreasing order of points if sortPoints is specified
    if (usePoints && sortPoints) {
        routePointsArray.sort((a, b) => b.points - a.points);
    }

    let recentRoutes = wall.value.getMostRecentRoutes();

    // if all are recent, none are recent
    if (recentRoutes.length === wall.value.routes.length) {
       recentRoutes = [];
    }

    for (const {route, points} of routePointsArray) {
        let state = user.value?.getRouteClimbingState(route);

        let pointString = null;
        if (points != null && usePoints) {
            if (points > 1 || points === 0) {
                pointString = points.toFixed(0);
            } else {
                pointString = points.toFixed(1);
            }
        }

        if (points != null && usePoints) {
            if (points !== 0)
                pointString = <strong>{pointString}</strong>
            else
                pointString = <span className="text-white text-opacity-50">{pointString}</span>
        }

        let gradeString = null;
        if (useGrades) {
            if (route.grade)
                gradeString = route.grade;
            else
                gradeString = <FontAwesomeIcon icon={faQuestion}/>

            if (state !== RouteStateType.Flashed && state !== RouteStateType.Climbed) {
                gradeString = <span className="opacity-50">{gradeString}</span>;
            } else {
                gradeString = <strong>{gradeString}</strong>;
            }
        }

        progressList.push(
            <ProgressGridCell
                key={route.id}
                state={state}
                points={pointString}
                grade={gradeString}
                isBookmarked={user.value?.isBookmarked(route)}
                onClick={() => {
                    onCellClick();
                    setCanvasState(new RouteFocusState(route));
                }}
                isMostRecent={recentRoutes.includes(route)}
            />
        );
    }

    return <>
        <div className="flex gap-1.5 flex-wrap justify-center">
            {progressList}
        </div>
        {recentRoutes.size > 0 &&
            <p className={"text-center pt-2"}>
                <Trans
                    i18nKey={"account_info.progress_grid_most_recent"}
                    components={{Yellow: <span className={UIColor.YellowText}/>}}
                />
            </p>
        }
    </>;
}

export function InteractiveProgressGrid() {
    const [gridType, setGridType] = useState(0);

    const wall = useContext(WallContext);

    const user = useContext(UserContext);

    const uiStateDispatch = useContext(UiDispatchContext);
    const [screenWidth, _] = useScreenDimensions();

    const {t} = useTranslation();

    let climbed = 0;
    for (const state of user.value.getRoutesClimbingStates(wall.value.routes)) {
        if (state === RouteStateType.Climbed || state === RouteStateType.Flashed) {
            climbed += 1;
        }
    }

    let gridTypeInformation;
    if (gridType === 0) {
        gridTypeInformation = <>{climbed}/{wall.value.routes.length}</>;
    } else if (gridType === 1) {
        const highestGrade = wall.value.routes
            .filter(route => user.value.isRouteClimbed(route))
            .map(obj => obj.grade)
            .filter(difficulty => difficulty != null).sort().pop();

        gridTypeInformation = <>{highestGrade ?? "-"}</>;
    } else {
        let points = user.value.getRoutesPoints(wall.value.routes);
        gridTypeInformation = <>{points.toFixed(0)}</>;
    }

    // TODO: eww duplicit
    function closeSidePanelIfMedium() {
        if (screenWidth <= ScreenDimensions.MOBILE_WIDTH) {
            uiStateDispatch({type: UiActions.CloseSidePanel});
        }
    }

    return <>
        <h2 className="text-2xl flex">
            <BasicButton onClick={() => setGridType((gridType + 1) % 3)} padding="py-0.5 px-2">
                <FontAwesomeIcon icon={gridType === 0 ? faListCheck : (gridType === 1 ? faList : faListOl)}/>
            </BasicButton>
            <span className="pl-3 py-0.5">
                        {gridType === 0 ?
                            t("account_info.progress_grid_progress") :
                            gridType === 1 ?
                                t("account_info.progress_grid_grades") :
                                t("account_info.progress_grid_points")}
                    </span>
            <span className="grow"></span>
            <span className="py-0.5">
                        <code className="text-neutral-500">[</code>
                        <code>{gridTypeInformation}</code>
                        <code className="text-neutral-500">]</code>
                    </span>
        </h2>
        <div className="mb-4"></div>

        <ProgressGrid
            onCellClick={closeSidePanelIfMedium}
            useGrades={gridType === 1}
            usePoints={gridType === 2}
        />
    </>
}
