import {forwardRef} from "react";
import BasicInputLabel from "./InputLabel";
import {UIColor} from "../Config";

const BasicNumberInput = forwardRef(function BasicNumberInput(
    {
        className = "",
        defaultValue = null,
        minValue = null,
        maxValue = null,
        step = 1,
        background = UIColor.DarkInput,
        label,
        onChange,
        onBlur,
    },
    ref
) {
    const classes = `w-fit py-1 px-4 ${background} rounded text-lg ${className}`;
    const inputElement =
        <input
            type="number"
            defaultValue={defaultValue}
            min={minValue}
            max={maxValue}
            step={step}
            className={classes}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
        />;

    if (label) {
        return (
            <>
                <BasicInputLabel label={label}/>
                <div>
                    {inputElement}
                </div>
            </>
        );
    }

    return inputElement;
});

export default BasicNumberInput;
