import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTriangleExclamation} from '@fortawesome/free-solid-svg-icons';
import {Trans, useTranslation} from "react-i18next";
import TextLink from "./TextLink";
import {ErrorType} from "../model/Backend";

/**
 * For localized formatting of error message texts, such as hyperlinks to contact the developers, etc...
 *
 * Expects a string such as one of BackendErrorType
 */
export function ErrorFormatter(translationKey) {
    const {t} = useTranslation();

    const errors = t("error");

    // this can only happen because of shit coding, but it happens C:
    if (!(translationKey in errors)) {
        console.error(`Error not found: ${translationKey}`);
        translationKey = ErrorType.UnexpectedError;
    }

    return <Trans
        i18nKey={`error.${translationKey}`}
        components={{
            contact: <span className={"font-bold"}><TextLink
                color={""}
                href={"mailto:support@climbuddy.com"}>
                {t("error.components.contact")}
            </TextLink></span>
        }}
    />
}

export default function ErrorFromBackendResult({data, spacing="m-4"}) {
    if (!data || data.isSuccess() || !data.errors)
        return <></>;

    return <>
        {data.errors.map((error, index) => {
            console.log(error);

            return <div key={`error-${error}-${index}`} className={`flex justify-center space-x-4 ${spacing}`}>
                <FontAwesomeIcon icon={faTriangleExclamation} className={`my-auto text-red-400 text-xl`}/>
                <p className="text-red-400">
                    {ErrorFormatter(error)}
                </p>
            </div>
        })
        }
    </>;
}
