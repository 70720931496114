import SignIn from "./SignIn";
import AccountInfo from "./AccountInfo";
import {useContext} from "react";
import {UserContext} from "../../contexts/UserContext";
import {WallContext} from "../../contexts/WallContext";

export default function SidePersonal() {
    const user = useContext(UserContext);
    const wall = useContext(WallContext);

    if (wall.value!== null && user.value !== null)
        user.value.wireUpWallRoutes(wall.value);

    return user.value ? <AccountInfo/> : <SignIn/>;
}