import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function ClickableFontAwesomeIcon({icon, color="text-white", onClick, className, isClicked, inverseHover=false}) {
    let text = '';

    // isClicked essentially inverts the state
    inverseHover |= isClicked;

    if (!inverseHover)
        text = "text-opacity-100 hover:text-opacity-50"
    else
        text = "text-opacity-50 hover:text-opacity-100"

    return <FontAwesomeIcon
        icon={icon} onClick={onClick}
        className={`${className} ${color} cursor-pointer ${text}`}
    />
}
