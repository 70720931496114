import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight} from '@fortawesome/free-solid-svg-icons';
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";

export class PreviousNextButtonType {
    static Left = 'left';
    static Right = 'right';
}

const PreviousNextButton = ({type, onOne, onAll = null, ...props}) => {
    let topRounding = type === PreviousNextButtonType.Left ? 'rounded-tr-2xl' : 'rounded-tl-2xl';
    let bottomRounding = type === PreviousNextButtonType.Left ? 'rounded-br-2xl' : 'rounded-bl-2xl';

    // hidden offsets, so that clicking on the buttons is easier
    let hiddenTopOffset = type === PreviousNextButtonType.Left ? "pr-4 pt-6" : "pl-4 pt-6";
    let hiddenBottomOffset = type === PreviousNextButtonType.Left ? "pr-4 pb-6" : "pl-4 pb-6";

    return (
        <div key={`button-${type}`}
             className={`absolute flex flex-col ${type}-0 top-1/2 -translate-y-1/2`}
        >
            <div className={hiddenTopOffset + " " + (onAll ? "" : hiddenBottomOffset)} onClick={onOne}>
                <BasicButton
                    round={false}
                    padding={"w-8 pt-2 pb-1"}
                    className={`${onAll === null ? bottomRounding : ''} ${topRounding} transition-colors duration-75 shadow-medium`}
                    {...props}
                >
                    <FontAwesomeIcon icon={type === PreviousNextButtonType.Left ? faAngleLeft : faAngleRight}
                                     className="text-center text-xl"/>
                </BasicButton>
            </div>

            {onAll && <>
                <div className={`h-0.5`}/>
                <div className={hiddenBottomOffset} onClick={onAll}>
                    <BasicButton
                        buttonColor="green"
                        round={false}
                        padding={"w-8 pb-2 pt-1"}
                        className={`${bottomRounding} transition-colors duration-75 shadow-medium`}
                        {...props}
                    >
                        <FontAwesomeIcon
                            icon={type === PreviousNextButtonType.Left ? faAngleDoubleLeft : faAngleDoubleRight}
                            className="text-center text-xl"/>
                    </BasicButton>
                </div>
            </>}
        </div>
    );
}

export default PreviousNextButton;
