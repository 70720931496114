import {Fragment, useContext, useRef, useState} from "react";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import BasicTextInput from "../../common/TextInput";
import {BasicButton} from "../../common/Button";
import RegisterModal from "./RegisterModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faRightToBracket} from "@fortawesome/free-solid-svg-icons";
import {UIColor} from "../../Config";
import {LeaderboardActions, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";
import BasicInputLabel from "../../common/InputLabel";
import ForgotPasswordModal from "./ForgotPasswordModal";
import TextLink from "../../common/TextLink";
import {useTranslation} from "react-i18next";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import {NotificationActions, NotificationDispatchContext, NotificationType} from "../../contexts/NotificationContext";

export default function SignIn() {
    const [registeringOpen, setRegisteringOpen] = useState(false);
    const [user, userDispatch] = [useContext(UserContext), useContext(UserDispatchContext)];

    const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);

    const loginEmail = useRef(null);
    const loginPassword = useRef(null);

    const leaderboardDispatch = useContext(LeaderboardDispatchContext);
    const uiStateDispatch = useContext(UiDispatchContext);

    const notificationDispatch = useContext(NotificationDispatchContext);

    const {t} = useTranslation();

    function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (user.status.isLoading(UserActions.Login))
            return; // this probably shouldn't happen

        const loginData = {
            email: loginEmail.current.value,
            password: loginPassword.current.value,
            keepSignedIn: true,
        };

        userDispatch({
            type: UserActions.Login,
            loginData: loginData,
            postSuccessHooks: () => {
                uiStateDispatch({type: UiActions.CloseSidePanel})

                notificationDispatch({
                    type: NotificationActions.Add,
                    payload: {
                        type: NotificationType.Success,
                        message: t("sign_in.success")
                    }
                })
            }
        });

        leaderboardDispatch({type: LeaderboardActions.Update});
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <h2 className="text-4xl text-center">{t("sign_in.sign_in")}</h2>
                <div className="mb-5"></div>
                <p className={UIColor.MinorText}>{t("sign_in.to_do_what")}</p>
                <div className="mb-8"></div>
                <BasicTextInput ref={loginEmail} type="email" name="email" autoComplete="username" required={true}
                                label={t("register.email")}/>
                <div className="mb-4"></div>
                <div className="flex justify-between items-baseline">
                    <BasicInputLabel label={t("password.password")}/>
                    <div className={`text-center ${UIColor.MinorText} text-sm`}><p><TextLink
                        bold={true} onClick={() => setForgotPasswordOpen(true)}
                    >{t("password.forgot_password")}?</TextLink></p></div>
                </div>
                <BasicTextInput ref={loginPassword} type="password" name="password"
                                autoComplete="current-password" required={true}/>
                <div className="mb-8"></div>
                <BasicButton type="submit" disabled={user.status.isLoading(UserActions.Login)}
                             buttonColor={UIColor.Blue}
                             className="w-full">
                    {!user.status.isLoading(UserActions.Login) ? <p>
                            {t("sign_in.sign_in")} <FontAwesomeIcon icon={faRightToBracket}
                                                                    className="pl-1"></FontAwesomeIcon>
                        </p> :
                        <SpinLoadingIndicator/>}
                </BasicButton>
            </form>
            <div className="mb-8"></div>
            <div className={`text-center ${UIColor.MinorText}`}><p>{t("sign_in.no_account")} <TextLink
                bold={true} onClick={() => setRegisteringOpen(true)}>{t("register.register")}.</TextLink></p></div>
            <RegisterModal onClose={() => setRegisteringOpen(false)} show={registeringOpen}/>
            <ForgotPasswordModal onClose={() => setForgotPasswordOpen(false)} show={forgotPasswordOpen}/>
        </>
    );
}