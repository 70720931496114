import ModalWindow from "../common/ModalWindow";
import {useContext, useState} from "react";
import {backend} from "../model/Backend";
import {BasicButton} from "../common/Button";
import {UIColor} from "../Config";
import TextLink from "../common/TextLink";
import Spacer from "../common/Spacer";
import ErrorFromBackendResult from "../common/ErrorFromBackendResult";
import {Trans, useTranslation} from "react-i18next";
import FileInput from "../common/FileInput";
import {ProposalActions, ProposalDispatchContext} from "../contexts/ProposalContext";
import DynamicUploadButton from "../common/DynamicUploadButton";

class UploadState {
    static NoFiles = 0;
    static BadFilesSelected = 1;
    static FilesSelected = 2;
    static Ongoing = 3;
    static Success = 4;
}

export default function NewRebuildModal({handleClose, ...props}) {
    const [state, setState] = useState(UploadState.NoFiles);
    const [errorMessage, setErrorMessage] = useState(null);

    const [files, setFiles] = useState([]);

    const {t} = useTranslation();

    const proposalDispatch = useContext(ProposalDispatchContext);

    function onFilesChange(event) {
        setErrorMessage(null);

        if (event.target.files.length === 0) {
            setState(UploadState.NoFiles);
        } else {
            if (event.target.invalidFiles.length === 0) {
                setState(UploadState.FilesSelected);
            } else {
                setState(UploadState.BadFilesSelected);
            }
        }

        setFiles(event.target.files);
    }

    function uploadFunction(...args) {
        return backend.uploadNewRebuild(files, ...args)
    }

    return <ModalWindow
        handleClose={handleClose}
        topColor={UIColor.Green}
        title={t(state === UploadState.Success ? "rebuild.new_rebuild.title_success" : "rebuild.new_rebuild.title")}
        justify={true}
        {...props}
    >
        {
            (state === UploadState.Success) ? <>
                <p className={"text-lg"}>
                    <Trans
                        i18nKey="rebuild.new_rebuild.successfully_uploaded"
                        components={{strong: <strong/>}}
                    />
                </p>

                <div className="mb-3"></div>

                <p>
                    {t("rebuild.new_rebuild.what_now")}
                </p>

                <div className="mb-5"></div>

                <div className="flex justify-center">
                    <BasicButton onClick={handleClose}>
                        {t("rebuild.new_rebuild.close")}
                    </BasicButton>
                </div>
            </> : <>
                <p className={"text-lg"}>
                    <Trans
                        i18nKey="rebuild.new_rebuild.instructions"
                        components={{strong: <strong/>, em: <em/>}}
                    />
                </p>

                <div className="mb-5"></div>

                <FileInput
                    onChange={onFilesChange}
                    disabled={state === UploadState.Uploading || state === UploadState.Aborting}
                    acceptedExtensions={["jpg", "jpeg", "png"]}
                />

                <div className="mb-5"></div>

                {
                    state !== UploadState.BadFilesSelected &&
                    <DynamicUploadButton
                        buttonText={t("rebuild.new_rebuild.start")}
                        uploadFunction={uploadFunction}
                        onStart={() => {
                            setErrorMessage(null);
                            setState(UploadState.Ongoing);
                        }}
                        onSuccess={() => {
                            setErrorMessage(null);
                            setState(UploadState.Success);
                            proposalDispatch({type: ProposalActions.EnteredNewRebuild});
                        }}
                        onError={result => {
                            setState(UploadState.FilesSelected);
                            setErrorMessage(result);
                        }}
                        onAbort={() => {
                            setState(UploadState.FilesSelected);
                            setErrorMessage(null);
                        }}
                        disabled={state === UploadState.NoFiles}
                        textSize={"text-xl"}
                    />
                }

                <div className="mb-5"></div>
                <ErrorFromBackendResult data={errorMessage}/>

                <Spacer spacing={"mt-6 mb-6"}/>

                <p className={UIColor.MinorText}>
                    <Trans
                        i18nKey="rebuild.new_rebuild.more_instructions"
                        components={{
                            strong: <strong/>,
                            TextLink: <TextLink href="https://blog.climbuddy.com/scanning/"/>
                        }}
                    />
                </p>
            </>
        }
    </ModalWindow>;
}