import {useEffect, useContext, useRef} from "react";
import {NotificationDispatchContext, NotificationActions, NotificationType} from "./contexts/NotificationContext";

/**
 * A component that bridges LoadingTracker errors with the notification system.
 * It observes changes to the LoadingTracker errors and displays notifications accordingly.
 *
 * @param {LoadingTracker[]} props.trackers An array of LoadingTracker instances to observe
 * @returns {null} This component doesn't render anything
 */
export default function LoadingTrackerNotificationBridge(
    {
        trackers,
    }) {
    const dispatch = useContext(NotificationDispatchContext);
    const prevErrorsRef = useRef(new Map());

    useEffect(() => {
        if (!trackers || !trackers.length) return;

        // Create a merged map of all current errors across all trackers
        const currentErrors = new Map();

        trackers.forEach((tracker, trackerIndex) => {
            if (!tracker) return;

            // Add a prefix to the error ID to avoid collisions between different trackers
            tracker.getErrors().forEach((errorData, id) => {
                const uniqueId = `tracker-${trackerIndex}-${id}`;
                currentErrors.set(uniqueId, errorData);
            });
        });

        // Check for new errors
        currentErrors.forEach((errorData, id) => {
            const prevError = prevErrorsRef.current.get(id);

            // If this is a new error or a different error
            if (!prevError || JSON.stringify(prevError) !== JSON.stringify(errorData)) {
                dispatch({
                    type: NotificationActions.Add,
                    payload: {
                        type: NotificationType.Error,
                        error: errorData.errors[0],
                    }
                });
            }
        });

        // Update the previous errors reference
        prevErrorsRef.current = new Map(currentErrors);

    }, [trackers, dispatch]);

    // This component doesn't render anything
    return null;
}
