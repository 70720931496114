import {WallActions, WallContext, WallDispatchContext} from "../../contexts/WallContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ProfilePicture from "../account/ProfilePicture";
import {useContext, useRef, useState} from "react";
import {
    faCamera,
    faCheck,
    faChevronDown,
    faChevronUp,
    faClock,
    faEdit,
    faEye,
    faLocationDot,
    faTimes,
    faUpRightFromSquare
} from "@fortawesome/free-solid-svg-icons";
import {UIColor} from "../../Config";
import GradeBreakdownChart from "../account/charts/GradeBreakdownChart";
import * as brands from "@fortawesome/free-brands-svg-icons";
import {BasicButton} from "../../common/Button";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../contexts/UserContext";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {ImageUtils} from "../../common/Utils";
import TextInput from "../../common/TextInput";
import TimeInput from "../../common/TimeInput";
import EditableSocialDictionary from "../../common/EditableSocialDictionary";
import {backend} from "../../model/Backend";
import {CanvasContext} from "../../contexts/CanvasContext";
import SubmitDiscardButtons from "../../common/SubmitDiscardButtons";
import Expandable from "../../common/Expandable";
import {NotificationActions, NotificationDispatchContext, NotificationType} from "../../contexts/NotificationContext";

const getFontAwesomeIcon = (name) => {
    const normalized = `fa${name.trim().charAt(0).toUpperCase() + name.trim().slice(1).toLowerCase()}`;

    if (brands[normalized] !== undefined) {
        return <FontAwesomeIcon icon={brands[normalized]} className={"text-xl"}/>
    }

    return null;
};

class Mode {
    static Editable = "editable";
    static View = "view";
}

export default function SideOverview() {
    const wall = useContext(WallContext);
    const user = useContext(UserContext);
    const wallDispatch = useContext(WallDispatchContext);

    const [isExpanded, setIsExpanded] = useState(false);

    const [mode, setMode] = useState(Mode.View);
    const [madeChanges, setMadeChanges] = useState(false);

    const [updateInitialCameraState, setUpdateInitialCameraState] = useState(false);

    const [selectedImageString, setSelectedImageString] = useState(wall.value.imageString);
    const [selectedImageURL, setSelectedImageURL] = useState(wall.value.imageURL);

    const imageInputRef = useRef(null);

    const [items, setItems] = useState(null);

    const {t} = useTranslation();

    let todayIndex = new Date().getDay();
    todayIndex = (todayIndex - 1 + 7) % 7;

    const daysOfWeek = t("statistics.user_activity_chart.weekdays");

    const today = daysOfWeek[todayIndex];

    let editable = user.value?.isOwner;

    async function setProfileImage(file) {
        ImageUtils.processProfileImage(file)
            .then(({resizedImage, base64String}) => {
                setSelectedImageString(base64String);
                setSelectedImageURL(URL.createObjectURL(resizedImage));
                setMadeChanges(true);
            })
            .catch(error => {
                // TODO: make this manually trigger an error
                // setError(t("error.loading_image"));
            });
    }

    let openStatusText;
    let todayTextColor;

    if (wall.value.openingHours.hasOwnProperty(todayIndex)
        && wall.value.openingHours[todayIndex].start
        && wall.value.openingHours[todayIndex].end) {
        const currentHours = wall.value.openingHours[todayIndex];
        const openTime = currentHours.start;
        const closeTime = currentHours.end;

        const now = new Date();
        const nowHours = now.getHours();
        const nowMinutes = now.getMinutes();

        // Split times to hours and minutes
        const [openHours, openMinutes] = openTime.split(":").map(num => parseInt(num));
        const [closeHours, closeMinutes] = closeTime.split(":").map(num => parseInt(num));

        // Check if the current time is between opening and closing times
        const isOpen =
            (nowHours > openHours || (nowHours === openHours && nowMinutes >= openMinutes)) &&
            (nowHours < closeHours || (nowHours === closeHours && nowMinutes < closeMinutes));

        todayTextColor = isOpen ? UIColor.GreenText : UIColor.RedText;

        openStatusText = isOpen ? <><span
                className={`${todayTextColor} font-bold`}>{t("side_overview.open")} </span> – {t("side_overview.open_until")} {closeTime}</>
            : <><span
                className={`${todayTextColor} font-bold`}>{t("side_overview.closed")}</span> – {t("side_overview.closed_until")} {openTime}</>;
    } else {
        todayTextColor = UIColor.YellowText;

        openStatusText = <span className={`${todayTextColor} font-bold`}>{t("side_overview.dont_know")}!</span>;
    }

    const notificationDispatch = useContext(NotificationDispatchContext);

    const canvas = useContext(CanvasContext);

    async function onSubmit(event) {
        event.preventDefault();

        const formData = new FormData(event.target);
        const values = Object.fromEntries(formData.entries());

        let newWall = wall.value.shallowClone();
        newWall.name = values.name.trim();
        newWall.location = values.location;

        // Assign opening hours as a dictionary
        newWall.openingHours = {};
        for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
            newWall.openingHours[dayIndex] = {
                start: values[`${dayIndex}-start`] || "", // Default to empty string if missing
                end: values[`${dayIndex}-end`] || ""
            };
        }

        newWall.socials = items;
        newWall.imageString = selectedImageString;

        if (updateInitialCameraState) {
            newWall.startingTransform = {
                position: canvas.camera.position.toArray(),
                quaternion: canvas.camera.quaternion.toArray(),
            }
        }

        wallDispatch({
            type: WallActions.SetMetadata,
            metadata: newWall.metadata,
            postSuccessHooks: () => {
                setMode(Mode.View);

                notificationDispatch({
                    type: NotificationActions.Add,
                    payload: {
                        type: NotificationType.Success,
                        message: t("side_overview.success")
                    }
                })
            }
        });
    }

    const formRef = useRef(null);

    const nonEmptySocials = wall.value.getNonEmptySocials();

    return (
        <form onSubmit={onSubmit} ref={formRef}>
            <div className="flex justify-center items-center space-x-4">
                <ProfilePicture
                    imageEnabledObject={wall.value}
                    overrideURL={selectedImageURL}
                    pictureSize="w-10 h-10"
                    onClick={
                        editable ? () => {
                            imageInputRef.current?.click();
                            setMode(Mode.Editable);
                        } : null
                    }/>
                <input
                    type="file"
                    ref={imageInputRef}
                    onChange={async (event) => {
                        if (event.target.files.length === 0)
                            return;

                        await setProfileImage(event.target.files[0])
                    }}
                    className="hidden"
                />
                {
                    mode === Mode.Editable ? <TextInput
                        name="name"
                        textSize="text-2xl"
                        color={UIColor.Default}
                        onChange={() => setMadeChanges(true)}
                        placeholder={wall.value.name || t("side_overview.name_placeholder")}
                        defaultValue={wall.value.name}
                    /> : <h2 key="name" className={`text-3xl text-center}`}>
                        {wall.value.name || t("side_overview.no_name_set")}
                    </h2>
                }
                {
                    editable ? (mode === Mode.View ?
                        <div className="flex text-xl flex-grow justify-end">
                            <ClickableFontAwesomeIcon
                                icon={faEdit}
                                onClick={() => {
                                    setMode(Mode.Editable);
                                    setIsExpanded(true);
                                    setMadeChanges(false);
                                    setUpdateInitialCameraState(false);
                                }}
                            /></div> : (
                            <div className="flex gap-2 text-2xl">
                                {madeChanges ? <ClickableFontAwesomeIcon
                                    icon={faCheck}
                                    color={UIColor.GreenText}
                                    onClick={() => formRef.current.requestSubmit()}
                                /> : <></>}
                                <ClickableFontAwesomeIcon
                                    icon={madeChanges ? faTimes : faEye}
                                    color={madeChanges ? UIColor.RedText : null}
                                    onClick={() => {
                                        setMode(Mode.View);
                                        setSelectedImageURL(null);
                                    }}

                                />
                            </div>
                        )) : <></>
                }
            </div>
            <div className="mb-6"></div>

            <div className="rounded-xl bg-neutral-700 px-3 py-3">
                {
                    mode === Mode.Editable ? (<>
                            <div className="flex items-start space-x-2">
                                <div className="w-8 flex justify-center pl-1">
                                    <FontAwesomeIcon icon={faLocationDot} className="text-xl"/>
                                </div>
                                <TextInput
                                    textArea={true}
                                    shadow={true}
                                    className="text-3xl "
                                    name="location"
                                    color={UIColor.Default}
                                    onChange={() => setMadeChanges(true)}
                                    placeholder={wall.value.location || t("side_overview.location_placeholder")}
                                    defaultValue={wall.value.location}
                                />
                            </div>
                            <div className="mb-5"/>
                        </>
                    ) : (
                        wall.value.location && (
                            <>
                                <div className="flex items-start space-x-2">
                                    <div className="w-8 flex justify-center pl-1">
                                        <FontAwesomeIcon icon={faLocationDot} className="text-xl"/>
                                    </div>
                                    <div key="location" className={"whitespace-pre-line italic"}>
                                        {wall.value.location}
                                    </div>
                                </div>
                                <div className="mb-5"/>
                            </>
                        )
                    )
                }

                <div className="flex flex-col">
                    <div className="flex items-center space-x-2 cursor-pointer"
                         onClick={() => setIsExpanded(!isExpanded)}>
                        <div className="w-8 flex justify-center pl-1">
                            <FontAwesomeIcon icon={faClock} className="text-lg"/>
                        </div>
                        <p>
                            {openStatusText}
                        </p>
                        <button className="ml-auto" type="button">
                            <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} className="text-lg"/>
                        </button>
                    </div>
                    <Expandable isOpen={isExpanded}>
                        <div
                            className={`mt-2 pl-9 ${mode === Mode.Editable ? '' : 'pr-9'} text-sm flex flex-col gap-1`}>
                            {[...Array(7).keys()].map((dayIndex) => {
                                const hours = wall.value.openingHours[dayIndex];

                                return (
                                    <div className={"flex justify-center"} key={`day-${dayIndex}`}>
                    <span
                        className={`${daysOfWeek[dayIndex] === today ? `font-bold ${todayTextColor}` : ""} flex-grow truncate py-1`}>
                        {daysOfWeek[dayIndex]}
                    </span>
                                        {
                                            mode === Mode.Editable ?
                                                <div className="flex space-x-2 items-center">
                                                    <TimeInput
                                                        name={`${dayIndex}-start`}
                                                        defaultValue={hours?.start}
                                                        onChange={() => setMadeChanges(true)}
                                                        background={UIColor.Default}
                                                        padding={"py-1 px-1"}
                                                    />
                                                    <span>–</span>
                                                    <TimeInput
                                                        name={`${dayIndex}-end`}
                                                        defaultValue={hours?.end}
                                                        onChange={() => setMadeChanges(true)}
                                                        background={UIColor.Default}
                                                        padding={"py-1 px-1"}
                                                    />
                                                </div>
                                                : <div className={"flex justify-end"}> <span
                                                    className={daysOfWeek[dayIndex] === today ? todayTextColor : ""}>{(hours?.start && hours?.end) ? `${hours.start} - ${hours.end}` : t("side_overview.not_set")}</span>
                                                </div>
                                        }
                                    </div>
                                );
                            })}
                        </div>
                    </Expandable>
                </div>

                {
                    mode === Mode.Editable ? <>
                        <div className="mb-6"/>
                        <div className="flex items-start space-x-2">
                            <div className="w-8 pt-2 flex justify-center pl-1">
                                <FontAwesomeIcon icon={faUpRightFromSquare} className="text-xl"/>
                            </div>
                            <EditableSocialDictionary
                                initialValues={wall.value.socials}
                                onChange={items => {
                                    setMadeChanges(true);
                                    setItems(items);
                                }}/>
                        </div>
                    </> : (nonEmptySocials.length !== 0) ? <>
                        <div className="mb-6"/>
                        <div className="flex items-start space-x-2">
                            <div className="w-8 flex items-start justify-center pl-1">
                                <FontAwesomeIcon icon={faUpRightFromSquare} className="text-lg"/>
                            </div>
                            <div className="flex gap-2 flex-wrap">
                                {nonEmptySocials.map((social, index) =>
                                    social.website && social.url ? (
                                        <BasicButton
                                            key={index}
                                            onClick={() => window.location.href = social.url}
                                            padding={"py-1 px-2"}
                                            buttonColor={UIColor.Default}
                                            className={"shadow-neutral-800 shadow-medium rounded-lg"}
                                        >
                                            <div className={"flex items-center space-x-2"}>
                                                {getFontAwesomeIcon(social.website) || ""}
                                                {social.website.toLowerCase() === t("side_overview.website").toLowerCase() ?
                                                    <span>{t("side_overview.website")}</span> :
                                                    <span>{social.website}</span>
                                                }
                                            </div>
                                        </BasicButton>
                                    ) : <></>)}
                            </div>
                        </div>
                    </> : <></>
                }
                {
                    (user.value != null && user.value.isGod && mode === Mode.Editable) ? (<>
                            <div className="mb-3"></div>
                            <div className="flex items-start space-x-2">
                                <div className="w-8 flex items-start justify-center pl-1">
                                    <FontAwesomeIcon icon={faCamera} className="text-lg"/>
                                </div>
                                <BasicButton
                                    buttonColor={updateInitialCameraState ? UIColor.Green : UIColor.Default}
                                    onClick={() => {
                                        setUpdateInitialCameraState(!updateInitialCameraState)
                                        setMadeChanges(true);
                                    }}
                                    className="w-full"
                                    type={"button"}
                                >
                                    Set current camera as default
                                </BasicButton>
                            </div>
                        </>
                    ) : (
                        <></>
                    )
                }

                {
                    mode === Mode.Editable ? <>
                        <div className="mb-4"></div>
                        <SubmitDiscardButtons
                            onSubmit={() => formRef.current.requestSubmit()}
                            onDiscard={() => {
                                setMode(Mode.View);
                                setSelectedImageURL(null);
                            }}
                            submitEnabled={madeChanges}
                            discardEnabled={true}
                            isLoading={wall.status.isLoading(WallActions.SetMetadata)}
                        />
                    </> : <></>
                }
            </div>
            <div className="mb-3"></div>
            <GradeBreakdownChart top={false}/>
        </form>
    );
}
