import {useContext, useState} from "react";
import {Bar, BarChart, Legend, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {UIColor} from "../../../Config";
import {BasicButton} from "../../../common/Button";
import Window from "../../../common/Window";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserActivityType} from "../../../model/User";
import BasicNumberInput from "../../../common/NumberInput";
import {WallContext} from "../../../contexts/WallContext";
import {SetCanvasStateContext} from "../../../contexts/CanvasContext";
import {RouteFocusState} from "../../../scene/canvas/CanvasState";
import {Trans, useTranslation} from "react-i18next";

const KEYS = [UserActivityType.Dislike, UserActivityType.Like, UserActivityType.Soft, UserActivityType.Hard];

const COLORS = {
    [UserActivityType.Dislike]: "#6b7280",
    [UserActivityType.Like]: "#e5e7eb",
    [UserActivityType.Hard]: "#ca8a04",
    [UserActivityType.Soft]: "#854d0e",
};

const HOVER_COLOR = "#404040";

const TEXT_COLORS = {
    [UserActivityType.Dislike]: "text-neutral-400",
    [UserActivityType.Like]: "text-neutral-100",
    [UserActivityType.Hard]: "text-yellow-500",
    [UserActivityType.Soft]: "text-yellow-700",
};

export default function PopularityChart() {
    const [showPopularity, setShowPopularity] = useState(true);
    const [showDifficulty, setShowDifficulty] = useState(false);
    const [sortBy, setSortBy] = useState(null);
    const [topN, setTopN] = useState(0);

    const wall = useContext(WallContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    const data = {};

    wall.value.routes.forEach((route, idx) => {
        data[`Route ${idx}`] = {
            'id': route.id,
            [UserActivityType.Like]: route.likes,
            [UserActivityType.Dislike]: route.dislikes,
            [UserActivityType.Soft]: route.softRatings,
            [UserActivityType.Hard]: route.hardRatings,
        }
    });

    const {t, i18n} = useTranslation();

    const customTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            return (
                <Window className={"p-3"} width={"w-fit"}>
                    <p className="text-xl font-bold pb-2">{payload[0].payload.id}</p>
                    {KEYS.map((key) => (
                        <p key={key}>
                        <span className={`${TEXT_COLORS[key]}`}>
                            {t("user_activity_type")[key]}:
                        </span>{" "}
                            {Math.abs(payload[0].payload[key])}
                        </p>
                    ))}
                </Window>
            );
        }
        return null;
    };

    const customLegend = () => (
        <div className="flex flex-wrap justify-center gap-x-5 gap-y-1 mt-2">
            {KEYS.map((key) => (
                <div key={key} className={`flex items-center gap-2 ${TEXT_COLORS[key]}`}>
                    <FontAwesomeIcon icon={UserActivityType.Icon(key)}/>
                    <span>{t("user_activity_type")[key]}</span>
                </div>
            ))}
        </div>
    );

    const toggleGroup = (group) => {
        if (group === "popularity") {
            setShowPopularity(!showPopularity);

            if (showPopularity && (sortBy === UserActivityType.Like || sortBy === UserActivityType.Dislike)) {
                setSortBy(null);
            }

        } else if (group === "difficulty") {
            setShowDifficulty(!showDifficulty);

            if (showDifficulty && (sortBy === UserActivityType.Soft || sortBy === UserActivityType.Hard)) {
                setSortBy(null);
            }
        }
    };

    const toggleSort = (type) => {
        // Only toggle the sort if the corresponding group is visible
        if (showPopularity && (type === UserActivityType.Like || type === UserActivityType.Dislike)) {
            if (sortBy === type) {
                setSortBy(null);
            } else {
                setSortBy(type);
            }
        } else if (showDifficulty && (type === UserActivityType.Soft || type === UserActivityType.Hard)) {
            if (sortBy === type) {
                setSortBy(null);
            } else {
                setSortBy(type);
            }
        } else {
        }
    };

    const formattedData = Object.entries(data).map(([id, {...KEYS}]) => ({
        id,
        [UserActivityType.Like]: KEYS[UserActivityType.Like],
        [UserActivityType.Dislike]: -KEYS[UserActivityType.Dislike],
        [UserActivityType.Hard]: KEYS[UserActivityType.Hard],
        [UserActivityType.Soft]: -KEYS[UserActivityType.Soft],
    }));

    // Define secondary sorting keys
    const secondarySortKey = {
        [UserActivityType.Like]: UserActivityType.Dislike,
        [UserActivityType.Dislike]: UserActivityType.Like,
        [UserActivityType.Hard]: UserActivityType.Soft,
        [UserActivityType.Soft]: UserActivityType.Hard,
    };

    // Sort data based on selected sorting type
    const sortedData = [...formattedData].sort((a, b) => {
        if (a[sortBy] !== b[sortBy]) {
            return (a[sortBy] > b[sortBy] ? -1 : 1) *
                (sortBy === UserActivityType.Like || sortBy === UserActivityType.Hard ? -1 : 1);
        }

        // Secondary sorting
        const secondaryKey = secondarySortKey[sortBy];
        if (secondaryKey && a[secondaryKey] !== b[secondaryKey]) {
            if (sortBy === UserActivityType.Like || sortBy === UserActivityType.Hard) {
                return a[secondaryKey] > b[secondaryKey] ? 1 : -1;
            } else {
                return a[secondaryKey] > b[secondaryKey] ? -1 : 1;
            }
        }

        return 0;
    });

    const limitedData = topN > 0 ? sortedData.slice(-topN) : sortedData;

    // Compute Y-axis domain dynamically since Recharts act a little sus with negative values
    const allValues = limitedData.flatMap(d => [
        showPopularity ? [d[UserActivityType.Like], d[UserActivityType.Dislike]] : [],
        showDifficulty ? [d[UserActivityType.Soft], d[UserActivityType.Hard]] : []
    ]).flat();

    const maxY = Math.max(0, ...allValues);
    const minY = Math.min(0, ...allValues);
    const padding = (maxY - minY) * 0.05;

    return (
        <div className="flex flex-col items-center">
            <h3 className="text-2xl text-center">{t("statistics.popularity_chart.name")}</h3>
            <p className={`${UIColor.MinorText} italic text-center`}>{t("statistics.popularity_chart.description_1")}</p>
            <p className={`${UIColor.MinorText} italic text-center`}><Trans
                i18nKey="statistics.popularity_chart.description_2"
                components={{strong: <strong/>}}
            /></p>
            <div className={"p-2"}></div>

            <ResponsiveContainer className={"w-full"} height={300}>
                <BarChart
                    barGap={0}
                    data={limitedData}
                    stackOffset="sign"
                    onClick={e => {
                        if (wall.value=== null || setCanvasState === null) {
                            return;
                        }

                        if (!e.activeLabel) {
                            return;
                        }

                        let routeId = data[e.activeLabel].id;

                        for (let i = 0; i < wall.value.routes.length; i++) {
                            const route = wall.value.routes[i];

                            if (route.id === routeId) {
                                setCanvasState(new RouteFocusState(route));
                            }
                        }
                    }}
                >
                    <XAxis dataKey="id"/>
                    <YAxis allowDecimals={false}/>
                    <Tooltip content={customTooltip} cursor={{fill: HOVER_COLOR}}/>
                    <Legend content={customLegend}/>

                    <ReferenceLine y={0} stroke="#000"/>

                    {/* Bars for likes and dislikes (above X-axis) */}
                    {showPopularity &&
                        <Bar dataKey={UserActivityType.Dislike} stackId="a" fill={COLORS[UserActivityType.Dislike]}/>}
                    {showPopularity &&
                        <Bar dataKey={UserActivityType.Like} stackId="a" fill={COLORS[UserActivityType.Like]}/>}

                    {/* Bars for soft and hard (below X-axis) */}
                    {showDifficulty &&
                        <Bar dataKey={UserActivityType.Soft} stackId="b" fill={COLORS[UserActivityType.Soft]}/>}
                    {showDifficulty &&
                        <Bar dataKey={UserActivityType.Hard} stackId="b" fill={COLORS[UserActivityType.Hard]}/>}
                </BarChart>
            </ResponsiveContainer>
            <div className={"p-2"}/>
            <div className="flex w-full gap-2">
                <BasicButton
                    className={"w-full"}
                    buttonColor={`${showPopularity ? UIColor.Blue : UIColor.Default}`}
                    onClick={() => toggleGroup("popularity")}
                >
                    {t("statistics.popularity_chart.popularity")}
                </BasicButton>
                <BasicButton
                    className={"w-full"}
                    buttonColor={`${showDifficulty ? UIColor.Blue : UIColor.Default}`}
                    onClick={() => toggleGroup("difficulty")}
                >
                    {t("statistics.popularity_chart.difficulty")}
                </BasicButton>
            </div>
            <div className={"p-1"}/>
            <div className="flex w-full gap-2 items-center">
                <div className={"text-nowrap"}>
                    <p className={"text-right"}>{t("statistics.popularity_chart.sort_by")}</p>
                </div>
                <BasicButton
                    className={"w-full"}
                    padding={'py-1 px-2'}
                    buttonColor={`${sortBy === UserActivityType.Like && showPopularity ? UIColor.Blue : UIColor.Default}`}
                    onClick={() => toggleSort(UserActivityType.Like)}
                    disabled={!showPopularity}
                >
                    <FontAwesomeIcon icon={UserActivityType.Icon(UserActivityType.Like)}/>
                </BasicButton>
                <BasicButton
                    className={"w-full"}
                    padding={'py-1 px-2'}
                    buttonColor={`${sortBy === UserActivityType.Dislike && showPopularity ? UIColor.Blue : UIColor.Default}`}
                    onClick={() => toggleSort(UserActivityType.Dislike)}
                    disabled={!showPopularity}
                >
                    <FontAwesomeIcon icon={UserActivityType.Icon(UserActivityType.Dislike)}/>
                </BasicButton>
                <BasicButton
                    className={"w-full"}
                    padding={'py-1 px-2'}
                    buttonColor={`${sortBy === UserActivityType.Soft && showDifficulty ? UIColor.Blue : UIColor.Default}`}
                    onClick={() => toggleSort(UserActivityType.Soft)}
                    disabled={!showDifficulty}
                >
                    <FontAwesomeIcon icon={UserActivityType.Icon(UserActivityType.Soft)}/>
                </BasicButton>
                <BasicButton
                    className={"w-full"}
                    padding={'py-1 px-2'}
                    buttonColor={`${sortBy === UserActivityType.Hard && showDifficulty ? UIColor.Blue : UIColor.Default}`}
                    onClick={() => toggleSort(UserActivityType.Hard)}
                    disabled={!showDifficulty}
                >
                    <FontAwesomeIcon icon={UserActivityType.Icon(UserActivityType.Hard)}/>
                </BasicButton>
            </div>
            <div className={"p-1"}/>
            <div className="flex w-full gap-2 items-center">
                <div className={"text-nowrap"}><p
                    className={"text-right"}>{t("statistics.popularity_chart.limit_to")}</p></div>
                <BasicNumberInput
                    defaultValue={topN}
                    minValue={0}
                    onChange={(e) => setTopN(parseInt(e.target.value, 10))}
                    className={"w-full"}
                    background={UIColor.Default}
                />
            </div>
        </div>
    );
}
