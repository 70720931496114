import React from "react";
import {ClickableUIStyle, UIColor} from "../Config";

function BasicButton(
    {
        children,
        onClick,
        disabled = null,
        className = "",
        padding = "py-2 px-3",
        buttonColor = UIColor.Default,
        round = true,
        type = null,
        href = null,
        transition = true,
        ...rest
    }, key) {

    className += ` ${ClickableUIStyle[buttonColor]} ${transition ? "transition-colors" : ""} text-white disabled:text-opacity-25 font-medium ${round ? "rounded" : ""} ${className}`;

    return href ? (
        <a className={className + " inline-block"} href={href} key={key} {...rest}>
            <div className={padding}>
                {children}
            </div>
        </a>
    ) : (
        <button className={className} onClick={onClick} key={key} disabled={disabled} type={type} {...rest}>
            <div className={padding}>
                {children}
            </div>
        </button>
    );
}

export {BasicButton};

