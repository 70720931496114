import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faXmark} from "@fortawesome/free-solid-svg-icons";
import Window from "./Window";
import {UIColor} from "../Config";


const TopPart = ({handleClose, title, titleElement, topColor}) => {
    let button = (handleClose !== null) ? <button
        className="absolute top-5 right-3 cursor-pointer text-2xl rounded px-2 py-0 bg-transparent hover:opacity-50 transition"
        onClick={handleClose}>
        <FontAwesomeIcon icon={faXmark}/>
    </button> : "";

    if (title || titleElement)
        return <div className={`sticky top-0 z-20 ${topColor} px-12 py-4`}>
            {titleElement ?? <h1 className="text-3xl text-center">{title}</h1>}
            {button}
        </div>
    else
        return button
}

export default function ModalWindow(
    {
        children,
        topColor = UIColor.Default,
        className = "",
        handleClose = null,
        justify = false,
        title = undefined,
        titleElement = undefined,
        show = true,
        padding = "p-6",
        ...props
    }
) {
    function onCloseWrapper(e) {
        if (e.target !== e.currentTarget)
            return;

        handleClose?.();
    }

    return (
        <div
            className={`overflow-scroll transition-all duration-75 ${show ? "opacity-100 visible" : "opacity-0 invisible"} fixed top-0 left-0 w-full h-full z-50 flex justify-center items-center ${handleClose !== null ? 'bg-black/50' : ''}`}
            onClick={onCloseWrapper}>
            <Window className={`${className} relative`} justify={justify} {...props}>
                <TopPart handleClose={handleClose} title={title} titleElement={titleElement} topColor={topColor} />
                <div className={padding}>
                    {children}
                </div>
            </Window>
        </div>
    );
}