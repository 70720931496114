import './Policies.css';
import TextLink from "../common/TextLink";

export function Contact() {
    return <>
        <p>If you have any questions about this privacy policy or your personal data, please contact us at one of the
            following:</p>
        <ul>
            <li><strong>Email:</strong> <TextLink href="mailto:contact@climbuddy.com">contact@climbuddy.com</TextLink>
            </li>
            <li><strong>Address:</strong> Kurzova 2222/16, 155 00, Praha 5 - Stodůlky, Czech Republic</li>
        </ul>
    </>;
}
