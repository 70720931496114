import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs: ["en", "de", "cs"],
        fallbackLng: "en",
        detection: {
            order: ["cookie", "localStorage", "navigator"],
            caches: ["cookie", "localStorage"],
            cookieMinutes: 525600,
        },
        backend: {
            loadPath: "/locales/{{lng}}/translation.json", // JSON files per language
        },
        interpolation: {escapeValue: false},
        returnObjects: true,
    });

export default i18n;
