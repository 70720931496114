import {AssertUtils, DateUtils} from "../common/Utils";
import {
    faBolt,
    faBookmark,
    faExclamation,
    faFaceSmileBeam,
    faFaceTired,
    faMountain,
    faQuestion,
    faRepeat,
    faThumbsDown,
    faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import {
    faFaceSmileBeam as farFaceSmileBeam,
    faFaceTired as farFaceTired,
    faThumbsDown as farThumbsDown,
    faThumbsUp as farThumbsUp
} from "@fortawesome/free-regular-svg-icons";

import {UIColor} from "../Config";

class User {
    constructor(email, nickname, imageString, activities, isOwner, isTemporary = false, isVerified = false, isGod = false) {
        AssertUtils.assertDefined(email, activities, isOwner);

        this.email = email;
        this.nickname = nickname;

        this.imageString = imageString;

        this.activities = activities;
        this.preCalculateRouteActivityMap();

        this.isOwner = isOwner;
        this.isTemporary = isTemporary;
        this.isGod = isGod;

        this.isVerified = isVerified;
    }

    preCalculateRouteActivityMap() {
        const map = {};

        this.activities.forEach(activity => {
            if (!map[activity.route.id])
                map[activity.route.id] = {};

            if (!map[activity.route.id][activity.type])
                map[activity.route.id][activity.type] = [];

            map[activity.route.id][activity.type].push(activity);
        });

        this.routeActivityMap = map;
    }

    static createTemporary() {
        return new User(
            null,
            null,
            null,
            [],
            false,
            true,
            false,
            false,
        );
    }

    static async fromMetadata(metadata) {
        const activities = metadata.activities.map(a => new UserActivity({
            id: a.id,
            type: a.type,
            route: a.route,
            timestamp: new Date(a.timestamp),
            isOnWall: false,  // is set in wireUp
        }));

        activities.sort((a1, a2) => a1.timestamp - a2.timestamp);

        return new User(
            metadata.email, metadata.nickname, metadata.photo, activities,
            metadata.isOwner, false, metadata.isVerified, metadata.isGod
        );
    }

    wireUpWallRoutes(wall) {
        if (this.routesWiredUp)
            return;

        this.routesWiredUp = true;

        const wallRoutes = Object.fromEntries(wall.routes.map(r => [r.id, r]))
        for (let activity of this.activities) {
            if (activity.route.id in wallRoutes) {
                activity.route = wallRoutes[activity.route.id];
                activity.isOnWall = true;
            } else {
                activity.isOnWall = false;
            }
        }
    }

    getRoutesPoints(routes) {
        let points = 0;
        for (const route of routes)
            points += this.getRoutePoints(route);

        return points;
    }

    getRoutePoints(route) {
        let state = this.getRouteClimbingState(route);

        switch (state) {
            case RouteStateType.Flashed:
                return 100 / (route.repeats) * 1.5;
            case RouteStateType.Climbed:
                return 100 / (route.repeats);
            default:
                return 0
        }
    }

    isRouteClimbed(route) {
        return this.getRouteClimbingState(route) in [RouteStateType.Flashed, RouteStateType.Climbed];
    }

    getRouteClimbingState(route) {
        for (const type of [UserActivityType.Flash, UserActivityType.Climb, UserActivityType.Attempt]) {
            let activity = this.getActivity(type, route);

            if (activity)
                return activity.type;
        }

        return RouteStateType.Untouched;
    }

    getRoutesClimbingStates(routes) {
        return routes.map(route => this.getRouteClimbingState(route));
    }

    isBookmarked(route) {
        return this.getActivity(UserActivityType.Bookmark, route) !== null;
    }

    getActivity(type, route) {
        const routeActivities = this.routeActivityMap[route.id];
        const activities = routeActivities && routeActivities[type];

        if (!activities) {
            return null;
        }

        if (activities.length > 1) {
            throw new Error(`Multiple activities found for type "${type}" and route ID "${route.id}".`);
        }

        return activities[0];
    }

    getActivities(type, route) {
        const routeActivities = this.routeActivityMap[route.id];
        return routeActivities && routeActivities[type] ? routeActivities[type] : [];
    }

    addActivity(activity) {
        this.activities.push(activity);
        this.activities.sort((a1, a2) => a1.timestamp - a2.timestamp);
        this.preCalculateRouteActivityMap()

        // newly climbed route
        if (activity.type === UserActivityType.Climb) {
            activity.route.climbedCount += 1;
        } else if (activity.type === UserActivityType.Flash) {
            activity.route.flashedCount += 1;
        } else if (activity.type === UserActivityType.Attempt) {
            activity.route.attemptedCount += 1;
        }

        // locally modify like/dislike count
        if (activity.type === UserActivityType.Like) {
            activity.route.likes += 1;
        } else if (activity.type === UserActivityType.Dislike) {
            activity.route.dislikes += 1;
        } else if (activity.type === UserActivityType.Soft) {
            activity.route.softRatings += 1;
        } else if (activity.type === UserActivityType.Hard) {
            activity.route.hardRatings += 1;
        }
    }

    removeActivity(activity) {
        this.activities = this.activities.filter(a => !(activity.id === a.id));
        this.preCalculateRouteActivityMap()

        // newly unclimbed route
        if (activity.type === UserActivityType.Climb) {
            activity.route.climbedCount -= 1;
        } else if (activity.type === UserActivityType.Flash) {
            activity.route.flashedCount -= 1;
        } else if (activity.type === UserActivityType.Attempt) {
            activity.route.attemptedCount -= 1;
        }

        // locally modify like/dislike count
        if (activity.type === UserActivityType.Like) {
            activity.route.likes -= 1;
        } else if (activity.type === UserActivityType.Dislike) {
            activity.route.dislikes -= 1;
        } else if (activity.type === UserActivityType.Soft) {
            activity.route.softRatings -= 1;
        } else if (activity.type === UserActivityType.Hard) {
            activity.route.hardRatings -= 1;
        }
    }

    removeAllActivities() {
        while (this.activities.length > 0) {
            this.removeActivity(this.activities[0]);
        }
    }

    modifyActivity(activity) {
        this.activities = this.activities.filter(a => !(activity.id === a.id));
        this.activities.push(activity);
        this.activities.sort((a1, a2) => a1.timestamp - a2.timestamp);
        this.preCalculateRouteActivityMap()

        // locally modify like/dislike count
        // since modify could only happen when changing from like to dislike,
        if (activity.type === UserActivityType.Like) {
            activity.route.likes += 1;
            activity.route.dislikes -= 1;
        } else if (activity.type === UserActivityType.Dislike) {
            activity.route.likes -= 1;
            activity.route.dislikes += 1;
        } else if (activity.type === UserActivityType.Soft) {
            activity.route.softRatings += 1;
            activity.route.hardRatings -= 1;
        } else if (activity.type === UserActivityType.Hard) {
            activity.route.softRatings -= 1;
            activity.route.hardRatings += 1;
        }
    }

    activitiesByDayAndRoute() {
        let activityOrder = [UserActivityType.Attempt, UserActivityType.Climb, UserActivityType.Flash, UserActivityType.Repeat];
        let activitiesByDayAndRoute = {};

        // Group activities by day and route
        for (let i = this.activities.length - 1; i >= 0; i--) {
            const activity = this.activities[i];
            if (!activityOrder.includes(activity.type))
                continue;

            const activityDate = new Date(activity.timestamp);

            const dateKey = new Date(activityDate.getFullYear(), activityDate.getMonth(), activityDate.getDate());
            const routeKey = activity.route.id;

            if (!activitiesByDayAndRoute[dateKey]) {
                activitiesByDayAndRoute[dateKey] = {};
            }

            if (!activitiesByDayAndRoute[dateKey][routeKey]) {
                activitiesByDayAndRoute[dateKey][routeKey] = [];
            }

            activitiesByDayAndRoute[dateKey][routeKey].push(activity);
        }

        return activitiesByDayAndRoute;
    };

    shallowClone() {
        const clone = new User(this.email, this.nickname, this.imageString, this.activities, this.isOwner, this.isTemporary, this.isVerified, this.isGod);
        clone.routesWiredUp = this.routesWiredUp;
        return clone;
    }

    clone() {
        const clone = new User(this.email, this.nickname, this.imageString, this.activities.slice(), this.isOwner, this.isTemporary, this.isVerified, this.isGod);
        clone.routesWiredUp = this.routesWiredUp;
        return clone;
    }
}

class RouteStateType {
    static Attempted = 0;
    static Climbed = 1;
    static Flashed = 2;
    static Untouched = "untouched";

    static Icon(state) {
        switch (state) {
            case RouteStateType.Untouched:
                return faQuestion;
            case RouteStateType.Attempted:
                return faExclamation;
            case RouteStateType.Climbed:
                return faMountain;
            case RouteStateType.Flashed:
                return faBolt;
            default:
                throw new Error(`Type ${state} is not valid!`);
        }
    }

    static Color(state) {
        switch (state) {
            case RouteStateType.Untouched:
                return UIColor.Default;
            case RouteStateType.Attempted:
                return UIColor.Red;
            case RouteStateType.Climbed:
                return UIColor.Green;
            case RouteStateType.Flashed:
                return UIColor.DarkGreen;
            default:
                return UIColor.Default;
        }
    }
}

class UserActivityType {
    static Attempt = 0;
    static Climb = 1;
    static Flash = 2;
    static Bookmark = 3;
    static Like = 4;
    static Dislike = 5;
    static Soft = 6;
    static Hard = 7;
    static Repeat = 8;

    static Icon(state, full = false) {
        switch (state) {
            case UserActivityType.Attempt:
                return faExclamation;
            case UserActivityType.Climb:
                return faMountain;
            case UserActivityType.Flash:
                return faBolt;
            case UserActivityType.Bookmark:
                return faBookmark;
            case UserActivityType.Like:
                return full ? faThumbsUp : farThumbsUp;
            case UserActivityType.Dislike:
                return full ? faThumbsDown : farThumbsDown;
            case UserActivityType.Soft:
                return full ? faFaceSmileBeam : farFaceSmileBeam;
            case UserActivityType.Hard:
                return full ? faFaceTired : farFaceTired;
            case UserActivityType.Repeat:
                return faRepeat;
            default:
                throw new Error(`Type ${state} doesn't have an icon!`);
        }
    }

    static Color(type) {
        switch (type) {
            case UserActivityType.Attempt:
                return UIColor.Red;
            case UserActivityType.Climb:
                return UIColor.Green;
            case UserActivityType.Flash:
                return UIColor.DarkGreen;
            case UserActivityType.Repeat:
                return UIColor.Default;
            default:
                return UIColor.Default;
        }
    }
}

class UserActivity {
    constructor({id, type, timestamp, route, isOnWall}) {
        AssertUtils.assertDefined(type, timestamp, route);

        // assign random IDs if none is provided
        if (!id)
            id = Math.random().toString(36).slice(2, 16);

        this.id = id;
        this.type = type;
        this.timestamp = timestamp;
        this.route = route;

        // Whether this activity points to a route that is currently on the wall
        this.isOnWall = isOnWall;
    }

    static getTrackingId(route, activityType, actionType) {
        if (!route && !activityType && !actionType) return undefined;

        return `${route.id || ''}-${activityType || ''}-${actionType || ''}`;
    }

    clone() {
        return new UserActivity({
            id: this.id,
            type: this.type,
            timestamp: this.timestamp,
            route: this.route,
            isOnWall: this.isOnWall,
        });
    }
}

export {User, UserActivity, UserActivityType, RouteStateType};