import {createContext} from "react";

import {backend, BackendResult} from "../model/Backend";
import {unimplementedFunction} from "../common/Utils";
import {ObjectWithTracker} from "./ContextUtils";
import {User} from "../model/User";
import {ActionWithSideEffects, SimpleAction, simulateBackendSuccess, useReducer} from "./StateManagement";

const UserContext = createContext(null);
const UserDispatchContext = createContext(unimplementedFunction);

class UserActions {
    static Login = "login";
    static TryRestoreLogin = "try_restore_login";
    static Logout = "logout";
    static Register = "register";
    static AddActivities = "add_activities";
    static RemoveActivities = "remove_activities";
    static ModifyActivities = "modify_activities";
    static DeleteErrors = "delete_errors";
    static SubmitFeedback = "submit_feedback";
    static SaveUserMetadata = "save_user_metadata";
    static UpdatePassword = "update_password";
    static DeleteAccount = "delete_account";
    static CreateTemporaryAccount = "create_temporary_account";
    static ForgotPassword = "forgot_password";
    static ResendVerificationEmail = "send_verification_email";
    static VerifyEmail = "verify_email";
    static ResetPassword = "reset_password";
}

function createUserAction(user, actionData) {
    if (actionData.type === UserActions.Login) {
        return new ActionWithSideEffects(
            backend.login(actionData.loginData),
            UserActions.Login,
            (user, result) => {
                user.value = result.data
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.Register) {
        return new ActionWithSideEffects(
            backend.register(actionData.registerData),
            UserActions.Register,
            (user, result) => {
                user.value = result.data
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.DeleteAccount) {
        return new ActionWithSideEffects(
            backend.deleteAccount(actionData.data),
            actionData.type,
            (user, result) => {
                return new ObjectWithTracker(null);
            },
        );
    } else if (actionData.type === UserActions.CreateTemporaryAccount) {
        return new SimpleAction(oldUser => {
                let user = new ObjectWithTracker(User.createTemporary());

                actionData.activities?.forEach((activity) => {
                    user.value.addActivity(activity);
                });

                return user;
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.TryRestoreLogin) {
        return new ActionWithSideEffects(
            backend.checkIfLoggedIn(),
            actionData.type,
            (user, result) => {
                user.value = result.data

            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.Logout) {
        if (user.value) {
            if (user.value.isTemporary) {
                // here we have to manually remove the activities since these are saved locally
                // (i.e. removing them should decrease the counts for likes/dislikes, etc...)
                user.value.removeAllActivities()
            } else {
                backend.logout();
            }
        }

        return new SimpleAction(oldUser => {
                if (oldUser.value === null) {
                    console.warn("Logged out event though wasn't logged in.");
                }

                return new ObjectWithTracker(null);
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.DeleteErrors) {
        return new SimpleAction(oldUser => {
            const user = oldUser.clone();
            if (user.value === null)
                return user;
            user.status.deleteAllErrors();
            return user;
        });
    } else if (actionData.type === UserActions.AddActivities) {
        return new ActionWithSideEffects(
            simulateBackendSuccess(
                user.value.isTemporary,
                backend.addUserActivities.bind(backend),
                actionData.activities,
            ),
            actionData.trackingId,
            (user, result) => {
                actionData.activities.forEach((activity, index) => {
                    // if activity id is returned, use it; otherwise temporary account
                    if (Array.isArray(result.data)) {
                        activity.id = result.data[index].id;
                    }

                    user.value.addActivity(activity);
                });

            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.RemoveActivities) {
        return new ActionWithSideEffects(
            simulateBackendSuccess(
                user.value.isTemporary,
                backend.removeUserActivities.bind(backend),
                actionData.activities,
            ),
            actionData.trackingId,
            (user, result) => {
                actionData.activities.forEach((activity) => {
                    user.value.removeActivity(activity);
                });
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ModifyActivities) {
        return new ActionWithSideEffects(
            simulateBackendSuccess(
                user.value.isTemporary,
                backend.modifyUserActivities.bind(backend),
                actionData.activities,
            ),
            actionData.trackingId,
            (user, result) => {
                actionData.activities.forEach((activity) => {
                    user.value.modifyActivity(activity);
                });
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.SaveUserMetadata) {
        return new ActionWithSideEffects(
            backend.saveUserMetadata(actionData.metadata),
            actionData.type,
            (user, result) => {
                user.value.nickname = actionData.metadata.nickname ?? user.value.nickname;
                user.value.imageString = actionData.metadata.photo ?? user.value.imageString;
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.UpdatePassword) {
        return new ActionWithSideEffects(
            backend.updatePassword(actionData.data),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.SubmitFeedback) {
        return new ActionWithSideEffects(
            backend.submitFeedback(actionData.text),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ForgotPassword) {
        return new ActionWithSideEffects(
            backend.forgotPassword(actionData.email),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ResendVerificationEmail) {
        return new ActionWithSideEffects(
            backend.resendVerificationEmail(),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.VerifyEmail) {
        return new ActionWithSideEffects(
            backend.verifyEmail(actionData.token, actionData.userId),
            actionData.type,
            () => {
            },
            actionData.postSuccessHooks
        );
    } else if (actionData.type === UserActions.ResetPassword) {
        return new ActionWithSideEffects(
            backend.resetPassword(actionData.token, actionData.userId, actionData.password),
            actionData.type,
            (user, result) => {
            },
            actionData.postSuccessHooks
        );
    } else {
        throw Error("Unknown action: " + actionData.type);
    }
}

function useUserReducer() {
    return useReducer(createUserAction, null, true);
}

export {UserContext, UserDispatchContext, UserActions, useUserReducer};
