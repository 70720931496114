import React, {useRef, useState} from "react";

const VerticalCarousel = ({images}) => {
    const [activeIndex, setActiveIndex] = useState(1);
    const intervalRef = useRef(null);

    // const startAutoCycle = () => {
    //     clearInterval(intervalRef.current);
    //     intervalRef.current = setInterval(() => {
    //         setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
    //     }, 3000); // Auto-cycle every 3 seconds
    // };

    // useEffect(() => {
    //     startAutoCycle();
    //     return () => clearInterval(intervalRef.current); // Cleanup on unmount
    // }, [images.length]);

    const handleClick = (index) => {
        setActiveIndex(index);
        // startAutoCycle(); // Reset timer when user clicks
    };

    return (
        <div className="w-full flex flex-col transition-transform duration-700 items-center gap-5">
            {images.map((image, index) => (
                <div
                    key={index}
                    className={`transition-all duration-700 brightness-125 ${
                        index === activeIndex
                            ? "w-full opacity-100 drop-shadow-[0_0px_25px_rgba(10,10,10,0.5)]"
                            : "w-1/4 opacity-50 shadow-small"
                    }`}
                    onClick={() => handleClick(index)}
                >
                    <img
                        src={image}
                        alt={`Carousel ${index}`}
                        className="w-full h-full object-cover rounded-lg"
                    />
                </div>
            ))}
        </div>
    );
};

export default VerticalCarousel;
