class ObjectWithTracker {
    constructor(value, loadingTracker = null) {
        this.value = value;
        this.status = loadingTracker ?? new LoadingTracker();
    }

    clone() {
        return new ObjectWithTracker(
            this.value === null ? null : this.value.clone(),
            this.status.clone()
        );
    }
}

class LoadingTracker {
    defaultId = "loadingtracker-defaultid";

    constructor(loading = null, errors = null) {
        this._loading = loading ?? new Set();
        this._errors = errors ?? new Map();
    }

    startLoading(id = this.defaultId) {
        this._loading.add(id);
    }

    stopLoading(id = this.defaultId) {
        this._loading.delete(id);
    }

    isLoading(id = this.defaultId) {
        return this._loading.has(id);
    }

    setError(errorData, id = this.defaultId) {
        this._errors.set(id, errorData);
    }

    getError(id = this.defaultId) {
        return this._errors.get(id) ?? null;
    }

    getErrors() {
        return this._errors;
    }

    deleteError(id) {
        this._errors.delete(id);
    }

    deleteAllErrors() {
        this._errors = new Map();
    }

    clone() {
        return new LoadingTracker(new Set(this._loading), new Map(this._errors));
    }
}

export {ObjectWithTracker, LoadingTracker};