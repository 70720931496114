import {OrbitControls, Raycasting} from "./Controls";
import {useContext, useEffect} from "react";
import {RouteFocusState, TopDownState} from "../canvas/CanvasState";
import RouteInfoPanel from "../overlay/RouteInfoPanel";
import {WallContext} from "../../contexts/WallContext";
import {CanvasContext, CanvasStateContext, SetCanvasStateContext} from "../../contexts/CanvasContext";
import * as THREE from "three";
import OutlineType from "../canvas/OutlineType";
import {AppContext} from "../../AppContext";
import * as config from "../../Config";
import {LOW_OPACITY, SMALL_LOLLIPOP_SIZE} from "../../Config";
import {RouteStateType} from "../../model/User";
import {UserContext} from "../../contexts/UserContext";
import {HoldType, Route} from "../../model/Wall";
import PreviousNextButton, {PreviousNextButtonType} from "./PreviousNextButton";

export default function OrbitController({viewedRoutes}) {
    const wall = useContext(WallContext);
    const canvas = useContext(CanvasContext);

    const canvasState = useContext(CanvasStateContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    const route = canvasState.route;

    const routeFilteredOut = !viewedRoutes.some(r => r.id === route.id);

    const viewedRoutesWithoutDuplicates = routeFilteredOut ? [...viewedRoutes, route] : viewedRoutes;

    const user = useContext(UserContext);
    let routes = Route.sortByPosition(viewedRoutesWithoutDuplicates);

    let categories = new Array(routes.length).fill(RouteStateType.Untouched);
    if (user.value)
        categories = user.value.getRoutesClimbingStates(routes)

    const currentIndex = routes.indexOf(route);

    const currentCategory = categories[currentIndex];

    const previousIndex = (currentIndex - 1 + routes.length) % routes.length;
    const nextIndex = (currentIndex + 1) % routes.length;

    const previousCategory = categories[previousIndex];
    const previousColor = RouteStateType.Color(previousCategory);

    const nextCategory = categories[nextIndex];
    const nextColor = RouteStateType.Color(nextCategory);

    const outlineMapping = {
        [RouteStateType.Climbed]: OutlineType.CLIMBED_ROUTE,
        [RouteStateType.Attempted]: OutlineType.ATTEMPTED_ROUTE,
        default: OutlineType.HIGHLIGHT
    };

    const activeOutline = outlineMapping[currentCategory] || outlineMapping.default;

    const routeMeshes = route.holds.map(hold => hold.holdMesh);

    useEffect(() => {
        // make other routes' holds less visible
        for (const hold of wall.value.holds.values()) {
            const opacity = hold.type !== HoldType.Hold || hold.route?.id === route.id ? 1 : LOW_OPACITY;
            canvas.markForOpacityChange(hold.holdMesh, opacity);
        }

        // and completely hide filtered-out routes
        // TODO: duplicit with TopDownController
        for (const route of wall.value.routes) {
            if (!routes.includes(route)) {
                for (const hold of route.holds) {
                    canvas.markForOpacityChange(hold.holdMesh, 0);
                }
            }
        }

        canvas.startOpacityChange()
    }, [canvas, route, routes, wall])

    useEffect(() => {
        if (wall.value === null || canvas === null)
            return;

        function onControlsUpdate() {
            canvas.render();
        }

        /**
         * Highlight other routes (while still highlighting the current one).
         */
        function onRouteHover(raycastEvent) {
            const result = raycastEvent.result;
            if (result.hit) {

                if (AppContext.Mutable.isCanvasDragging)
                    return;

                const hitRoute = result.target.object;

                canvas.setOutlinedObjects(
                    OutlineType.HOVER,
                    [...new Set([...hitRoute.holds.map(hold => hold.holdMesh)])]
                );

                canvas.render();
                return;
            }

            canvas.render();
        }

        const controls = new OrbitControls(
            canvas.getCanvasElement(), canvas.camera, canvas.cameraAnimation
        );

        controls.addEventListener("change", onControlsUpdate);

        let routeOrbitTarget = route.getCenter();
        let routeCameraPosition = route.getViewingPoint((config.MIN_ROUTE_ZOOM_DISTANCE + config.MAX_ROUTE_ZOOM_DISTANCE) / 2);
        let routeViewingDirection = route.getNormal().multiplyScalar(-1);
        let currentHold = null;

        controls.register(
            routeOrbitTarget, routeCameraPosition, routeViewingDirection,
            config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
        );

        controls.addEventListener("control_escape_event", onZoomOut);

        function onZoomOut(event) {
            if (currentHold !== null) {
                controls.changeTarget(
                    routeOrbitTarget, routeCameraPosition, routeViewingDirection,
                    config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
                );

                currentHold = null;
            } else {
                setCanvasState(new TopDownState());
            }
        }

        function onRouteClick(raycastEvent) {
            const result = raycastEvent.result;
            const clickedRoute = result.target.object;

            let holdMesh = null;
            if (raycastEvent.type === "object_approach_click")
                holdMesh = raycastEvent.spherePart;
            else if (raycastEvent.type === "object_click")
                holdMesh = result.part;

            let hold = null;
            for (let h of route.holds) {
                if (h.holdMesh === holdMesh) {
                    hold = h;
                }
            }

            // clicking on a hold of the route zooms to it
            if (clickedRoute === route) {
                // if we're already looking at the hold, clicking at it again goes back to the entire route
                if (currentHold === hold) {
                    controls.changeTarget(
                        routeOrbitTarget, routeCameraPosition, routeViewingDirection,
                        config.MIN_ROUTE_ZOOM_DISTANCE, config.MAX_ROUTE_ZOOM_DISTANCE,
                    );

                    currentHold = null;
                } else {
                    // TODO: ewwwww duplicate
                    let center = hold.getCenter();
                    let normal = hold.getNormal();

                    let cameraPosition = center.clone().addScaledVector(
                        normal,
                        (config.MIN_HOLD_ZOOM_DISTANCE + config.MAX_HOLD_ZOOM_DISTANCE) / 2
                    );

                    controls.changeTarget(
                        center, cameraPosition, normal.multiplyScalar(-1),
                        config.MIN_HOLD_ZOOM_DISTANCE, config.MAX_HOLD_ZOOM_DISTANCE,
                    );

                    currentHold = hold;
                }
            } else {
                setCanvasState(new RouteFocusState(clickedRoute));
            }
        }

        const raycasting = new Raycasting(canvas.getCanvasElement(), canvas.camera, true);

        raycasting.addEventListener("object_hover", onRouteHover);
        raycasting.addEventListener("object_approach", onRouteHover);
        raycasting.addEventListener("object_click", onRouteClick);
        raycasting.addEventListener("object_approach_click", onRouteClick);
        raycasting.addEventListener("object_miss_click", onZoomOut);

        // TODO: duplicit with TopDown except lollipop size
        for (const route of routes) {
            const holds = route.holds;
            raycasting.addTarget(route, holds.map(hold => hold.holdMesh));
            raycasting.addApproachTarget(route, holds.map(hold => hold.holdMesh), holds.map(hold => new THREE.Sphere(hold.getCenter(), SMALL_LOLLIPOP_SIZE)));
        }

        raycasting.register();

        return () => {
            raycasting.unregister();
            controls.unregister();
        }
    }, [wall, route, canvas, setCanvasState]);

    useEffect(() => {
        canvas.setOutlinedObjects(activeOutline, routeMeshes);
        canvas.setOutlinedObjects(OutlineType.CLIMBED_ROUTE, activeOutline === OutlineType.CLIMBED_ROUTE ? routeMeshes : []);
        canvas.setOutlinedObjects(OutlineType.ATTEMPTED_ROUTE, activeOutline === OutlineType.ATTEMPTED_ROUTE ? routeMeshes : []);
        canvas.setOutlinedObjects(OutlineType.HIGHLIGHT, activeOutline === OutlineType.HIGHLIGHT ? routeMeshes : []);

        canvas.setOutlinedObjects(OutlineType.HOVER, routeMeshes);

        canvas.render();
    }, [canvas, user, wall, routes, activeOutline, routeMeshes]);

    function onBackToTopView() {
        setCanvasState(new TopDownState(route.getCenter()));
    }

    if (currentIndex === -1) {
        // this should never happen
        throw new Error("The route object is not in the array.");
    }

    function onPreviousRoute() {
        setCanvasState(new RouteFocusState(routes[previousIndex]));
    }

    function onNextRoute() {
        setCanvasState(new RouteFocusState(routes[nextIndex]));
    }

    return <>
        <PreviousNextButton
            type={PreviousNextButtonType.Left}
            buttonColor={previousColor}
            onOne={onPreviousRoute}
        />

        <PreviousNextButton
            type={PreviousNextButtonType.Right}
            buttonColor={nextColor}
            onOne={onNextRoute}
        />

        <RouteInfoPanel
            route={route}
            onClickBack={onBackToTopView}
            routeFilteredOut={routeFilteredOut}
        />
    </>;
}