import ModalWindow from "../../common/ModalWindow";
import {useContext, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {BackendResult, ErrorType} from "../../model/Backend";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {UiUtils} from "../../common/Utils";
import {useTranslation} from "react-i18next";

export default function ChangePasswordModal({onClose, show}) {
    const oldUserPassword = useRef(null);
    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    const userDispatch = useContext(UserDispatchContext);

    const [validationError, setValidationError] = useState(null);
    const [modified, setModified] = useState(false);

    const {t} = useTranslation();

    const [updateText, setUpdateText] = useState(t("password.change_password"));

    const user = useContext(UserContext);

    function changePasswords() {
        setUpdateText(t("password.change_password"));

        if (oldUserPassword.current.value || userPassword.current.value || userPasswordRepeat.current.value)
            setModified(true);
        else
            setModified(false);
    }

    async function onCloseWrapper() {
        if (modified && !await UiUtils.confirmAsync(t("warning.not_finished")))
            return;

        onClose();
    }

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setValidationError(BackendResult.FromError(ErrorType.PasswordsDontMatch));
            return;
        }

        setValidationError(null);

        userDispatch({
            type: UserActions.UpdatePassword,
            data: {
                oldPassword: oldUserPassword.current.value,
                newPassword: userPassword.current.value,
            },
            postSuccessHooks: () => {
                oldUserPassword.current.value = "";
                userPassword.current.value = "";
                userPasswordRepeat.current.value = "";

                setUpdateText(t("password.password_changed"));
                setModified(false);
            }
        });
    }

    return <ModalWindow
        title={t("password.change_password")}
        handleClose={onCloseWrapper}
        show={show}
    >
        <form onSubmit={onSubmit}>
            <BasicTextInput ref={oldUserPassword} type="password" name="password" required={true}
                            label={t("password.old_password")} autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-8"></div>
            <BasicTextInput ref={userPassword} type="password" name="password" required={true}
                            label={t("password.new_password")} autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-4"></div>
            <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                            label={t("password.confirm_password")} autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-8"></div>
            <BasicButton
                type="submit"
                buttonColor={UIColor.Blue}
                className="w-full"
                disabled={!modified}
            >
                {user.status.isLoading(UserActions.UpdatePassword) ?
                    <SpinLoadingIndicator/> : <>{updateText}</>}
            </BasicButton>
            <ErrorFromBackendResult data={validationError}/>
        </form>
    </ModalWindow>;
}
