import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft, faFilter} from '@fortawesome/free-solid-svg-icons';
import {useCallback, useContext, useEffect} from "react";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {RouteStateType, UserActivity, UserActivityType} from "../../model/User";
import RouteInfoList from "./RouteInfoList";
import RouteActivityButtons from "./RouteActivityButtons";
import {Trans, useTranslation} from "react-i18next";
import {WallContext} from "../../contexts/WallContext";
import {UIColor} from "../../Config";
import CircuitTape from "../../common/CircuitTape";
import SoftHardBar from "../../common/SoftHardBar";
import LikeDislikeBar from "../../common/LikeDislikeBar";
import DraggableWindow from "../../common/DraggableWindow";
import {NotificationActions, NotificationDispatchContext, NotificationType} from "../../contexts/NotificationContext";

export default function RouteInfoPanel({route, onClickBack, routeFilteredOut = false}) {
    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    const notificationDispatch = useContext(NotificationDispatchContext);

    let state;
    if (user.value !== null) {
        state = user.value.getRouteClimbingState(route);
    } else {
        state = RouteStateType.Untouched;
    }

    const wall = useContext(WallContext);

    const {t} = useTranslation();

    function handleActivityAdd(activityType) {
        const timestamp = new Date(Date.now());

        const newActivity = new UserActivity({
            type: activityType,
            route: route,
            timestamp: timestamp,
            isOnWall: true,
        });

        if (user.value === null) {
            userDispatch(
                {
                    type: UserActions.CreateTemporaryAccount,
                    activities: [newActivity],
                    postSuccessHooks: () => {
                        notificationDispatch({
                            type: NotificationActions.Add,
                            payload: {
                                type: NotificationType.Warning,
                                message: <Trans
                                    i18nKey={"account_info.temporary_account_warning_short"}
                                    components={{strong: <strong/>}}
                                />
                            }
                        })
                    }
                },
            );
        } else {
            userDispatch(
                {
                    type: UserActions.AddActivities,
                    activities: [newActivity],
                    trackingId: UserActivity.getTrackingId(route, newActivity.type, UserActions.AddActivities),
                }
            )
        }
    }

    function handleActivityModify(fromActivityType, toActivityType) {
        if (user.value === null) {
            console.error("User is null when changing activities!")
            return;
        }

        const timestamp = new Date(Date.now());

        const activity = user.value.getActivity(fromActivityType, route);

        const newActivity = new UserActivity({
            id: activity.id,
            type: toActivityType,
            route: route,
            timestamp: timestamp,
            isOnWall: true,
        });

        userDispatch({
            type: UserActions.ModifyActivities,
            activities: [newActivity],
            trackingId: UserActivity.getTrackingId(route, newActivity.type, UserActions.ModifyActivities),
        });
    }

    function handleActivityRemove(input, onSuccess = null) {
        if (user.value === null) {
            console.error("User is null when changing activities!")
            return;
        }

        let activitiesToRemove = [];

        if (Array.isArray(input)) {
            // If input is an array, assume it's a list of activities
            activitiesToRemove = input;
        } else {
            // Otherwise, assume it's an activityType
            const activity = user.value.getActivity(input, route);
            if (activity) {
                activitiesToRemove.push(activity);
            }
        }

        if (activitiesToRemove.length === 0) {
            return;
        }

        userDispatch({
            type: UserActions.RemoveActivities,
            activities: activitiesToRemove,
            trackingId: UserActivity.getTrackingId(route, activitiesToRemove[0].type, UserActions.RemoveActivities),
            postSuccessHooks: () => {
                onSuccess?.();
            }
        });
    }

    // TODO: what the fuck was I thinking here
    function enableActivity(from, to, fromType, toType) {
        if (from) {
            handleActivityRemove(fromType)
        } else if (to) {
            handleActivityModify(toType, fromType)
        } else {
            handleActivityAdd(fromType)
        }
    }

    const bookmarked = user.value == null ? false : user.value.isBookmarked(route);

    function handleBookmarkClick() {
        if (bookmarked) {
            handleActivityRemove(UserActivityType.Bookmark);
        } else {
            handleActivityAdd(UserActivityType.Bookmark);
        }
    }

    const resetErrors = useCallback(() => {
        userDispatch({
            type: UserActions.DeleteErrors
        });
    }, [userDispatch]);

    useEffect(() => {
        return () => resetErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let grade = route.grade;
    let circuitColor = wall.value.getCircuitHexColor(route.circuit);

    return <>
        <DraggableWindow
            tint={
                (state === RouteStateType.Climbed || state === RouteStateType.Flashed) ? UIColor.Green
                    : state === RouteStateType.Attempted ? UIColor.Red
                        : undefined}
            offsets={[110, 55]}
            defaultPosition={1}
            dragSymbol={!routeFilteredOut}
        >
            <div className={"p-4 pt-10"}>
                <div className="absolute top-4 left-4 cursor-pointer flex items-center gap-x-2"
                     onClick={onClickBack}
                >
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className="text-xl"
                    />
                    <p className="font-bold">{t("route_panel.back")}</p>
                </div>
                {
                    routeFilteredOut ?
                        <div className="absolute  left-1/2 -translate-x-1/2 top-4"
                             onClick={onClickBack}
                        >
                            <p className={"font-bold truncate"}><FontAwesomeIcon
                                icon={faFilter}/> {t("route_filter_panel.filtered")}!</p>
                        </div> : <></>
                }
                {
                    (grade || circuitColor) &&
                    <div
                        className={`${UIColor.DarkInput} rounded-lg ${(grade || circuitColor) && !(grade && circuitColor) ? "w-12" : "w-20"} min-h-6 px-1.5 py-0.5 absolute top-3 right-4 flex items-center gap-x-1.5`}>
                        {grade && <p className="font-bold text-center text-xl w-full">{grade}</p>}
                        {circuitColor && <CircuitTape circuitColor={circuitColor}/>}
                    </div>
                }

                <RouteActivityButtons
                    route={route}
                    handleActivityAdd={handleActivityAdd}
                    handleActivityRemove={handleActivityRemove}
                    className="p-4"
                />
                <div className="flex flex-col items-center">
                    <div className="bg-neutral-700 py-4 px-4 rounded-xl w-full">
                        <LikeDislikeBar
                            route={route}
                            enableActivity={enableActivity}
                        />
                    </div>
                    <div className="mb-4"></div>
                    {route.grade !== null ? <>
                        <div className="bg-neutral-700 pt-2 pb-2 px-4 rounded-xl w-full">
                            <SoftHardBar
                                route={route}
                                enableActivity={enableActivity}
                            />
                        </div>
                        <div className="mb-4"></div>
                    </> : ""}
                </div>

                <RouteInfoList
                    route={route}
                    onBookmarkClick={handleBookmarkClick}
                    bookmarked={bookmarked}
                />
            </div>
        </DraggableWindow>
    </>;
}