import ModalWindow from "../../common/ModalWindow";
import BasicTextInput from "../../common/TextInput";
import {BasicButton} from "../../common/Button";
import {useContext, useRef, useState} from "react";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import {BackendResult, ErrorType} from "../../model/Backend";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {ImageUtils, UiUtils} from "../../common/Utils";
import {LeaderboardActions, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";
import {UIColor} from "../../Config";
import TextLink from "../../common/TextLink";
import PrivacyPolicy from "../../legal/PrivacyPolicy";
import TermsOfUse from "../../legal/TermsOfUse";
import {useTranslation} from "react-i18next";
import {NotificationActions, NotificationDispatchContext, NotificationType} from "../../contexts/NotificationContext";

export default function RegisterModal({onClose, text, ...props}) {
    const [validationError, setValidationError] = useState(null);
    const [isAgreed, setIsAgreed] = useState(false); // State for the checkbox
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false); // State to control privacy policy modal
    const [showTermsOfUse, setShowTermsOfUse] = useState(false); // State to control terms of use modal

    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);
    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    const [dirty, setDirty] = useState(false);

    const userEmail = useRef(null);
    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    const {t} = useTranslation();

    const notificationDispatch = useContext(NotificationDispatchContext);

    async function onCloseWrapper() {
        if (dirty && !await UiUtils.confirmAsync(t("warning.not_finished")))
            return;

        onClose();
    }

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setValidationError(BackendResult.FromError(ErrorType.PasswordsDontMatch));
            return;
        }

        setValidationError(null);

        const registerData = {
            email: userEmail.current.value,
            password: userPassword.current.value,
            photo: await ImageUtils.getRandomHoldImage(),
        };

        // attach activities if we're creating from a temporary user
        if (user.value?.isTemporary)
            registerData.activities = user.value.activities;

        userDispatch({
            type: UserActions.Register,
            registerData,
            postSuccessHooks: () => {
                leaderboardDispatch({type: LeaderboardActions.Update});

                notificationDispatch({
                    type: NotificationActions.Add,
                    payload: {
                        type: NotificationType.Success,
                        message: t("register.success")
                    }
                })

                onClose();
            },
        })
    }

    const registering = user.status.isLoading(UserActions.Register);

    return (
        <>
            <ModalWindow handleClose={onCloseWrapper} title={t("register.register")} {...props}>
                <form onSubmit={onSubmit}>
                    {text && <>
                        {text}
                        <div className="mb-6"></div>
                    </>}

                    <BasicTextInput ref={userEmail} type="email" name="email" required={true}
                                    label={t("register.email")} autoComplete="username"
                                    onChange={() => setDirty(true)}
                    />
                    <div className="mb-4"></div>
                    <BasicTextInput ref={userPassword} type="password" name="password" required={true}
                                    label={t("password.password")} autoComplete="new-password"
                                    onChange={() => setDirty(true)}
                    />
                    <div className="mb-4"></div>
                    <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                                    label={t("password.confirm_password")} autoComplete="new-password"
                                    onChange={() => setDirty(true)}
                    />
                    <div className="mb-8"></div>

                    <div className="mb-4">
                        <label className={UIColor.MinorText}>
                            <input type="checkbox" checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)}/>
                            <span className={"px-0.5"}/> {t("register.acknowledge_1")} <TextLink
                            bold={true}
                            onClick={() => setShowPrivacyPolicy(true)}>{t("register.acknowledge_privacy_policy")}
                        </TextLink> {t("register.acknowledge_2")} <TextLink
                            bold={true}
                            onClick={() => setShowTermsOfUse(true)}>{t("register.acknowledge_terms_of_use")}
                        </TextLink>.
                        </label>
                    </div>

                    <BasicButton type="submit" disabled={registering || !isAgreed} className="w-full">
                        {!registering ? t("register.register") : <SpinLoadingIndicator/>}
                    </BasicButton>
                    <ErrorFromBackendResult data={validationError}/>
                </form>
            </ModalWindow>

            <PrivacyPolicy show={showPrivacyPolicy} handleClose={() => setShowPrivacyPolicy(false)}/>
            <TermsOfUse show={showTermsOfUse} handleClose={() => setShowTermsOfUse(false)}/>
        </>
    );
}
