import {DateUtils, VectorUtils} from "../common/Utils.js";
import * as THREE from "three";
import {DEFAULT_GRADE_SYSTEM} from "./Climbing";
import {MOCK} from "../Config";

export class HoldType {
    static Hold = "HOLD";
    static Volume = "VOLUME";
    static Label = "LABEL";
}

class Wall {
    constructor(wallMesh, additionalMesh, holdsMesh, routes, holds, metadata) {
        this.wallMesh = wallMesh;
        this.additionalMesh = additionalMesh;
        this.holdsMesh = holdsMesh;

        this.routes = routes;
        this.holds = holds;

        this.metadata = metadata ?? {};

        // will be set by the blob store
        this.imageURL = null;
    }

    // Getters and Setters for metadata

    // [{name: "Red", color: "#ff0000"}, ...]
    get circuits() {
        if (MOCK) {
            return [
                {name: "Red", color: "#ff0000"},
                {name: "Green", color: "#00ff00"},
                {name: "Blue", color: "#0000ff"},
            ]
        }

        return this.metadata["circuits"] || [];
    }

    set circuits(value) {
        this.metadata["circuits"] = value;
    }

    hasCircuits() {
        return this.circuits.length !== 0;
    }

    getCircuitHexColor(colorName) {
        if (!colorName) {
            return null;
        }

        return this.circuits.find(circuit => circuit.name.toLowerCase() === (colorName ?? "").toLowerCase())?.color;
    }

    // {type: "fontainebleau", min: "6a", max: "8a"}
    get grading() {
        return this.metadata["grading"] || DEFAULT_GRADE_SYSTEM;
    }

    set grading(value) {
        this.metadata["grading"] = value;
    }

    // a base64-encoded image (downscaled); not that big we gucci
    get imageString() {
        return this.metadata["imageString"] || "";
    }

    set imageString(value) {
        this.metadata["imageString"] = value;
    }

    get openingHours() {
        return this.metadata["openingHours"] || {};
    }

    set openingHours(value) {
        this.metadata["openingHours"] = value;
    }

    get name() {
        return this.metadata["name"];
    }

    set name(value) {
        this.metadata["name"] = value;
    }

    get location() {
        return this.metadata["location"];
    }

    set location(value) {
        this.metadata["location"] = value;
    }

    get socials() {
        return this.metadata["socials"] || [];
    }

    getNonEmptySocials() {
        return this.socials.filter(
            (social) => social.website && social.url
        );
    }

    getMostRecentRoutes(inverted = false, threshold = 24 * 60 * 60 * 1000) {
        // Get the most recent timestamp
        let largestTimestamp = 0;
        let smallestTimestamp = Infinity;

        for (const route of this.routes) {
            if (route.creationUtcTimestamp > largestTimestamp) {
                largestTimestamp = route.creationUtcTimestamp;
            }

            if (route.creationUtcTimestamp < smallestTimestamp) {
                smallestTimestamp = route.creationUtcTimestamp;
            }
        }

        // Get routes created within threshold of the most recent timestamp
        const recentRoutes = [];

        for (const route of this.routes) {
            if (inverted) {
                if (route.creationUtcTimestamp - smallestTimestamp <= threshold) {
                    recentRoutes.push(route);
                }
            } else {
                if (largestTimestamp - route.creationUtcTimestamp <= threshold) {
                    recentRoutes.push(route);
                }
            }
        }

        return recentRoutes;
    }

    set socials(value) {
        this.metadata["socials"] = value;
    }

    get startingTransform() {
        return this.metadata["startingTransform"] || {
            position: new THREE.Vector3(0, 0, 0),
            quaternion: new THREE.Quaternion(0, 0, 0, 0),
        };
    }

    set startingTransform(value) {
        this.metadata["startingTransform"] = value;
    }

    static fromProposal(wall, holdsMesh, routes) {
        const holds = Hold.fromGLB(holdsMesh);

        // these route objects already have holds with IDs (form the proposal stage)
        //
        // however, since we're creating a new GLB mesh, these point to stale holds
        // which will be removed, so although this looks really weird, it should work
        //
        // TODO: this should not be necessary, we should be able to just leave those in the scene
        for (let route of routes) {
            const routeHolds = [];
            for (let hold of route.holds.values()) {
                if (!holds.has(hold.id))
                    throw new Error(`Route contains unknown hold "${hold}".`)

                // TODO: why is this necessary, why not just hold.route = ...?
                holds.get(hold.id).route = route;
                routeHolds.push(holds.get(hold.id));
            }

            route.holds = routeHolds;
        }

        return new Wall(
            wall.wallMesh, wall.additionalMesh,
            holdsMesh, routes, holds, wall.metadata
        );
    }

    static fromServerData(wallMesh, additionalMesh, holdsMesh, routesData, wallMetadata) {
        const holds = Hold.fromGLB(holdsMesh);

        let routes = Route.fromServerData(routesData, holds, holdsMesh);

        return new Wall(wallMesh, additionalMesh, holdsMesh, routes, holds, wallMetadata);
    }

    clone() {
        return new Wall(
            this.wallMesh,
            this.additionalMesh,
            this.holdsMesh,
            this.routes,
            this.holds,
            JSON.parse(JSON.stringify(this.metadata)),
        );
    }

    // TODO: fix this fuckery
    shallowClone() {
       return this.clone()
    }
}

class Hold {
    constructor(holdMesh, id) {
        this.holdMesh = holdMesh;
        this.metadata = {};
        this.id = id;

        // will be set by Route.
        this.route = null;
    }

    // Getters and Setters for metadata
    get id() {
        return this.metadata["id"];
    }

    set id(value) {
        this.metadata["id"] = value;
    }

    get normal() {
        return this.metadata["normal"];
    }

    set normal(value) {
        this.metadata["normal"] = value;
    }

    get center() {
        return this.metadata["center"];
    }

    set center(value) {
        this.metadata["center"] = value;
    }

    get type() {
        // default to everything being a hold (safer that way)
        return this.metadata["type"] || HoldType.Hold;
    }

    set type(value) {
        this.metadata["type"] = value;
    }

    get componentPosition() {
        return this.metadata["component_position"];
    }

    set componentPosition(value) {
        this.metadata["component_position"] = value;
    }

    getCenter() {
        return new THREE.Vector3(...this.center);
    }

    getViewingPoint(distance) {
        const center = this.getCenter();
        const direction = this.getNormal();

        return center.add(direction.multiplyScalar(distance));
    }

    getNormal() {
        if (!this.normal) {
            console.error(`Hold ${this.id} doesn't have a normal!`)
            return new THREE.Vector3(1, 0, 0);
        }

        return new THREE.Vector3(...this.normal);
    }

    static fromGLB(holdsMesh, visible = true) {
        const holds = new Map();

        holdsMesh.traverse(child => {
            if (child.type === "Mesh") {
                console.assert(child.children.length === 0)
                child.visible = visible;
                holds.set(child.name, new Hold(child, child.name));
            }
        });

        Hold.setMetadata(holds, holdsMesh.userData.holds);

        return holds;
    }

    static setMetadata(holds, holdsMetadata = undefined) {
        if (!holdsMetadata) {
            console.error(`holdsMetadata is undefined, things will break!`);
            return;
        }

        holdsMetadata.forEach(
            (data) => {
                if (holds.has(data.id)) {
                    Object.assign(holds.get(data.id).metadata, data);
                } else {
                    console.error(`Got metadata for non-existent hold "${data.id}".`);
                }
            }
        );
    }

    static getMostCommonComponent(holds) {
        const countMap = new Map();
        const fractionMap = new Map();

        for (const hold of holds) {
            if (!hold.componentPosition) {
                console.error(`Hold ${hold.id} missing component position!`)
                continue;
            }

            const [component, path, fraction] = hold.componentPosition;
            const key = `${component},${path}`;

            countMap.set(key, (countMap.get(key) || 0) + 1);
            fractionMap.set(key, (fractionMap.get(key) || 0) + fraction);
        }

        let mostCommon = null;
        let maxCount = 0;

        for (const [key, count] of countMap.entries()) {
            if (count > maxCount) {
                mostCommon = key;
                maxCount = count;
            }
        }

        if (mostCommon) {
            const [component, path] = mostCommon.split(',').map(Number);
            const avgFraction = fractionMap.get(mostCommon) / maxCount;
            return [component, path, avgFraction];
        }

        return [Infinity, Infinity, Infinity]; // Fallback in case no valid component is found
    }
}

class Route {
    constructor(holds, data) {
        this.holds = holds;

        this.id = data.staticData.id

        // We're using getters/setters for these so we can easily dump the route by JSONifying this.metadata
        if (data["staticData"].metadata) {
            this.metadata = JSON.parse(data["staticData"].metadata)
        } else {
            this.metadata = {}
        }

        this.dynamicData = data["dynamicData"]

        // assign random grades
        if (MOCK) {
            function getRandomElement(arr) {
                return arr[Math.floor(Math.random() * arr.length)];
            }

            let grades = ["7a+", "7b", "7c", "8a"]
            this.grade = getRandomElement(grades);

            function randomUtcTimestamp() {
                const start = new Date("2025-01-01T00:00:00Z").getTime();
                const end = new Date("2025-02-02T23:59:59Z").getTime();
                return Math.floor(Math.random() * (end - start + 1)) + start;
            }

            this.creationUtcTimestamp = randomUtcTimestamp()

            let circuits = ["Red", "Green", "Blue"]
            this.circuit = getRandomElement(circuits);
        }

        for (let hold of holds) {
            hold.route = this;
        }
    }

    // Getters and Setters for metadata
    get grade() {
        return this.metadata["grade"] ?? null;
    }

    set grade(value) {
        this.metadata["grade"] = value;
    }

    get circuit() {
        return this.metadata["circuit"] ?? null;
    }

    set circuit(value) {
        this.metadata["circuit"] = value;
    }

    // note that this is in milliseconds!
    get creationUtcTimestamp() {
        return this.metadata["creationUtcTimestamp"] ?? 0;
    }

    set creationUtcTimestamp(value) {
        this.metadata["creationUtcTimestamp"] = value;
    }

    // Getters and Setters for dynamic data
    get likes() {
        return this.dynamicData['likes'] || 0;
    }

    set likes(value) {
        this.dynamicData['likes'] = value;
    }

    get dislikes() {
        return this.dynamicData['dislikes'] || 0;
    }

    set dislikes(value) {
        this.dynamicData['dislikes'] = value;
    }

    get softRatings() {
        return this.dynamicData['softRatings'] || 0;
    }

    set softRatings(value) {
        this.dynamicData['softRatings'] = value;
    }

    get hardRatings() {
        return this.dynamicData['hardRatings'] || 0;
    }

    set hardRatings(value) {
        this.dynamicData['hardRatings'] = value;
    }

    get repeats() {
        return this.climbedCount + this.flashedCount;
    }

    get climbedCount() {
        return this.dynamicData['climbedCount'];
    }

    set climbedCount(value) {
        this.dynamicData['climbedCount'] = value;
    }

    get flashedCount() {
        return this.dynamicData['flashedCount'];
    }

    set flashedCount(value) {
        this.dynamicData['flashedCount'] = value;
    }

    get attemptedCount() {
        return this.dynamicData['attemptedCount'];
    }

    set attemptedCount(value) {
        this.dynamicData['attemptedCount'] = value;
    }

    toJSON(overrides) {
        const metadataCopy = {
            ...this.metadata,
            ...overrides,
        };

        delete metadataCopy.id;
        delete metadataCopy.metadata;

        return {
            id: this.id,
            metadata: JSON.stringify(metadataCopy),
        };
    }

    getCreationDateString() {
        if (!this.creationUtcTimestamp) {
            return null;
        } else {
            const date = new Date(this.creationUtcTimestamp);
            return DateUtils.toFormalDay(date);
        }
    }

    removeHold(hold) {
        console.assert(hold.route === this);
        const holdIdx = this.holds.indexOf(hold);
        console.assert(holdIdx >= 0);
        this.holds.splice(holdIdx, 1);
        hold.route = null;
    }

    addHold(hold) {
        console.assert(this.holds.indexOf(hold) === -1);
        this.holds.push(hold);
        hold.route = this;
    }

    addHolds(holds) {
        for (let hold of holds) {
            this.addHold(hold);
        }
    }

    getCenter() {
        const holdPositions = this.holds.map(hold => hold.getCenter());

        return VectorUtils.averageVectors3(holdPositions);
    }

    toggleHold(hold) {
        if (this.holds.includes(hold)) {
            this.removeHold(hold);
        } else {
            this.addHold(hold);
        }
    }

    getViewingPoint(distance) {
        const center = this.getCenter();
        const direction = this.getNormal();

        return center.add(direction.multiplyScalar(distance));
    }

    getNormal() {
        const holdWallNormals = [];
        for (let hold of this.holds) {
            const normal = hold.getNormal();
            if (normal !== null) {
                holdWallNormals.push(normal);
            }
        }

        if (holdWallNormals.length === 0)
            throw Error(`None of the holds of ${this.name} could be projected on the wall mesh.`)

        const normal = VectorUtils.averageVectors3(holdWallNormals);
        return normal.normalize();
    }

    static fromHolds(holds) {
        const randomString = Array.from({length: 8}, () =>
            String.fromCharCode(97 + Math.floor(Math.random() * 26))
        ).join('');

        let id = `route_${randomString}`;

        return new Route(
            holds,
            {
                staticData: {id: id},
                dynamicData: {},
            }
        )
    }

    static fromServerData(routesData, holds, holdsMesh) {
        let routes = [];

        // replaces .holds IDs with the actual object instances
        for (let routeData of routesData) {
            const routeHolds = [];

            // the routes are baked in the mesh as groups with the route ID
            holdsMesh.traverse(child => {
                if (child.name === routeData.staticData.id) {
                    for (let hold of child.children) {
                        routeHolds.push(holds.get(hold.name));
                    }
                }
            });

            if (routeHolds.length === 0) {
                console.error(`Didn't find any holds for route, skipping!`)
                continue;
            }

            routes.push(new Route(routeHolds, routeData));
        }

        return routes;
    }

    static sortByPosition(routes) {
        return routes.slice().sort((a, b) => {
            const keyA = Hold.getMostCommonComponent(a.holds);
            const keyB = Hold.getMostCommonComponent(b.holds);

            for (let i = 0; i < 3; i++) {
                if (keyA[i] !== keyB[i]) {
                    return keyA[i] - keyB[i];
                }
            }
            return 0;
        });
    }

    static sortByDate(routes) {
        return [...routes].sort((a, b) => a.creationUtcTimestamp - b.creationUtcTimestamp);
    }
}

export {Wall, Hold, Route};