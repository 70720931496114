import {useContext, useEffect} from "react";
import {
    NotificationContext,
    NotificationDispatchContext,
    NotificationActions,
    NotificationType
} from "./contexts/NotificationContext";
import Window from "./common/Window";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import ClickableFontAwesomeIcon from "./common/ClickableFontAwesomeIcon";

// Individual notification component
function Notification({notification, onClose}) {
    useEffect(() => {
        if (notification) {
            // Set up auto-removal after duration
            const disappearTimer = setTimeout(() => {
                onClose(notification.id);
            }, notification.duration ?? 5000);

            return () => {
                clearTimeout(disappearTimer);
            };
        }
    }, [notification, onClose]);

    const handleContentClick = (e) => {
        // Prevent event bubbling when clicking on content
        e.stopPropagation();

        // Call onClick handler if provided
        if (notification.onClick) {
            notification.onClick(e);
        }
    };

    return (
        <Window
            color={NotificationType.Color(notification.type)}
            className="w-full mb-2 transition-all duration-300 rounded-lg"
        >
            <div className="flex items-center gap-2 h-full" onClick={handleContentClick}>
                <span className="bg-black bg-opacity-15 px-2 py-1 self-stretch flex items-center">
                    <FontAwesomeIcon icon={NotificationType.Icon(notification.type)}/>
                </span>
                <div className={"py-1 flex-grow"}>
                    {notification.title &&
                        <span className="text-lg"> {notification.title} </span>
                    }
                    {notification.message &&
                        <div>
                            {notification.message}
                        </div>
                    }
                </div>
                <span className="px-2 py-1" onClick={(e) => {
                    e.stopPropagation();
                    onClose(notification.id);
                }}>
                        <ClickableFontAwesomeIcon icon={faClose}/>
                </span>
            </div>
        </Window>
    );
}

export default function NotificationBox() {
    const notifications = useContext(NotificationContext);
    const dispatch = useContext(NotificationDispatchContext);

    const handleClose = (id) => {
        dispatch({
            type: NotificationActions.Remove,
            payload: {id}
        });
    };

    // No notifications to show
    if (!notifications || notifications.length === 0) {
        return null;
    }

    return (
        <div className="fixed top-0 left-0 right-0 flex flex-col items-center z-50 p-3 pointer-events-none">
            <div className="flex flex-col items-center max-h-screen">
                {notifications.map((notification) => (
                    <div
                        key={notification.id}
                        className="transform transition-all duration-300 ease-in-out pointer-events-auto"
                    >
                        <Notification
                            notification={notification}
                            onClose={handleClose}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}
