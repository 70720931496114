import * as THREE from "three";
import {MathUtils, Vector3} from "three";
import Hammer from 'hammerjs';
import * as config from "../../Config"
import {FOV, MOCK} from "../../Config";
import TWEEN from "@tweenjs/tween.js";

const _changeEvent = {type: "change"};
const _objectHoverEvent = {type: "object_hover"};
const _objectClickEvent = {type: "object_click"};
const _objectApproachEvent = {type: "object_approach"};
const _objectApproachClickEvent = {type: "object_approach_click"};

// when we clicked on nothing
const _objectMissClickEvent = {type: "object_miss_click"};

// when we reach the end of the current controller (i.e. zoom out enough)
const _controlEscapeEvent = {type: "control_escape_event"};

class MouseEventUtils {
    static LEFT_BTN = 0;
    static RIGHT_BTN = 1;
    static WHEEL = 2;

    static isPressed(event, button) {
        return (event.buttons >> button) % 2 !== 0;
    }
}

class BaseControls extends THREE.EventDispatcher {
    constructor(canvasDomElement) {
        super();
        this.registered = false;
        this.canvasDomElement = canvasDomElement;

        this._canvasEvents = [
            ["mousemove", this._handleMouseMove.bind(this)],
            ["wheel", this._handleMouseWheel.bind(this)],
            ["mousedown", this._handleMouseDown.bind(this)],
            ["mouseup", this._handleMouseUp.bind(this)],
        ]

        this._domEvents = [
            ["keydown", this._handleKeyDown.bind(this)],
            ["keyup", this._handleKeyUp.bind(this)],
        ]

        this._hammer_events = [
            // this is important since we want to reset the values when starting the movement
            ["panstart pinchstart rotatestart", event => {
                this._lastPinchScale = event.scale;
                this._lastRotation = event.rotation;
                this._lastDeltaX = event.deltaX;
                this._lastDeltaY = event.deltaY;
            }],
            ["panmove pinchmove rotatemove", this._handleTouch.bind(this)],  // some fucking events are not making it
            ["press", this._handlePress.bind(this)],
        ]

        this._hammer = null;
        this._dragDistance = null;

        // this is because when starting to drag the route panel, moving to the canvas with the mouse activates it
        this._startedPan = false;

        this._lastPinchScale = null;
        this._lastRotation = null;
        this._lastDeltaX = null;
        this._lastDeltaY = null;
    }

    register() {
        console.assert(!this.registered);

        for (let [name, handler] of this._canvasEvents) {
            this.canvasDomElement.addEventListener(name, handler, {passive: false});
        }

        for (let [name, handler] of this._domEvents) {
            document.addEventListener(name, handler);
        }

        this._hammer = new Hammer.Manager(this.canvasDomElement, {inputClass: Hammer.TouchInput});

        this._hammer.add(new Hammer.Pan({threshold: 10}));
        this._hammer.add(new Hammer.Rotate({threshold: 0})).recognizeWith(this._hammer.get('pan'));
        this._hammer.add(new Hammer.Pinch({threshold: 0})).recognizeWith([this._hammer.get('pan'), this._hammer.get('rotate')]);

        for (let [name, handler] of this._hammer_events) {
            this._hammer.on(name, handler);
        }

        this.registered = true;
    }

    unregister() {
        console.assert(this.registered);
        for (let [name, handler] of this._canvasEvents) {
            this.canvasDomElement.removeEventListener(name, handler, {passive: false});
        }

        for (let [name, handler] of this._domEvents) {
            document.removeEventListener(name, handler);
        }

        for (let [name, handler] of this._hammer_events) {
            this._hammer.off(name, handler);
        }

        this._hammer.destroy();
        this._hammer = null;
        this.registered = false;
    }

    _handleMouseMove(event) {
        if (this._dragDistance !== null) {
            const [deltaX, deltaY] = this._getMovement(event);
            this._dragDistance[0] += deltaX;
            this._dragDistance[1] += deltaY;
        }
    }

    _handleMouseWheel(event) {
        // To be overridden.
    }

    _handleMouseDown(event) {
        this._dragDistance = [0, 0];

        this._startedPan = true;
    }

    _handleMouseUp(event) {
        const dragDistance = this._dragDistance === null ?
            0 :
            Math.sqrt(this._dragDistance[0] ** 2 + this._dragDistance[1] ** 2);
        if (dragDistance < 8) {
            if (!this._startedPan)
                return;

            this._handleClick(event);
        }

        this._startedPan = false;
    }

    _handleKeyUp(event) {
        // To be overridden.
        if (MOCK) {
            if (event.key === "g") {
                document.body.style.cursor = "default";
            }
        }
    }


    _handleKeyDown(event) {
        // To be overridden.
        if (MOCK) {
            if (event.key === "w") {
                this.minHeight += 0.1
            } else if (event.key === "s") {
                this.minHeight -= 0.1
            }

            if (event.key === "g") {
                document.body.style.cursor = "grabbing";
            }
        }
    }

    _handlePress(event) {
        // To be overridden.
    }

    _handleClick(event) {
        // To be overridden.
    }

    _handleTouch(event) {
        event.preventDefault();
        event.scaleDelta = event.scale / this._lastPinchScale;
        const rotationDiff = event.rotation - this._lastRotation;
        event.rotationDelta = Math.sign(rotationDiff) * Math.min(Math.abs(rotationDiff), 360 - Math.abs(rotationDiff));
        event.deltaXAdjusted = event.deltaX - this._lastDeltaX;
        event.deltaYAdjusted = event.deltaY - this._lastDeltaY;

        this._lastPinchScale = event.scale;
        this._lastRotation = event.rotation;
        this._lastDeltaX = event.deltaX;
        this._lastDeltaY = event.deltaY;

        // To be overridden.
    }

    _getMovement(event, factor = 1.0) {
        const movementX = event.movementX || event.velocityX || event.mozMovementX || event.webkitMovementX || 0;
        const movementY = event.movementY || event.velocityY || event.mozMovementY || event.webkitMovementY || 0;
        return [movementX * factor, movementY * factor];
    }

    _forwardVector() {
        const forward = new THREE.Vector3();
        this.camera.getWorldDirection(forward);
        return forward;
    }

    _localCoordinateSystem() {
        const globalUp = THREE.Object3D.DEFAULT_UP;
        const forward = this._forwardVector();
        const right = new THREE.Vector3().crossVectors(globalUp, forward).normalize();
        const up = (new THREE.Vector3().subVectors(globalUp, globalUp.clone().projectOnVector(forward))).normalize();

        return [right, up, forward];
    }
}

class TopDownControls extends BaseControls {
    constructor(
        canvasDomElement,
        camera,
        cameraAnimation,
        orbitOffset = config.REGULAR_ORBIT_OFFSET,
        defaultFlyAngle = config.DEFAULT_FLY_ANGLE,
        minFlyAngle = config.MIN_FLY_ANGLE,
        maxFlyAngle = config.MAX_FLY_ANGLE,
        minHeight = config.MIN_HEIGHT,
        maxHeight = config.MAX_HEIGHT,
    ) {
        super(canvasDomElement);
        this.camera = camera;
        this.cameraAnimation = cameraAnimation;
        this.orbitOffset = orbitOffset;
        this.defaultFlyAngle = defaultFlyAngle;
        this.minFlyAngle = minFlyAngle;
        this.maxFlyAngle = maxFlyAngle;
        this.minHeight = minHeight;
        this.maxHeight = maxHeight;

        this.flyAngle = defaultFlyAngle;
        this._orbitTarget = null;

        window.cameraDebugEvent = ({type, data}) => {
            console.debug("Debug event", type);
            console.debug("Debug data", data);

            if (type === "move") {
                this.camera.position.set(data.position[0], data.position[1], data.position[2]);
            } else if (type === "animate") {
                camera.fov = data.fov ?? FOV;
                camera.updateProjectionMatrix();

                this.cameraAnimation.animate(
                    new Vector3(...data.position),
                    new THREE.Quaternion(...data.rotation),
                    data.duration,
                );
            } else if (type === "rotate") {
                this.cameraAnimation.animateRotate(data.distance, data.angle, data.duration);
            } else if (type === "zoom_in") {
                this.cameraAnimation.animateZoomIn(data.distance, data.angle, data.duration);
            } else if (type === "save_position") {
                this.cameraAnimation.savePosition();
            } else if (type === "restore_position") {
                this.cameraAnimation.restorePosition(data.duration, data.speed);
            }
        };
    }

    register(initialTarget = null, wall = null, animate = true, orbitMultiplier = null) {
        super.register();
        this.flyAngle = this.defaultFlyAngle;

        this.boundingBox = new THREE.Box3().setFromObject(wall.additionalMesh);

        // if there is no initial target, make one by moving the camera
        if (initialTarget === null) {
            let vector = this._forwardVector().multiplyScalar(this.orbitOffset);

            if (orbitMultiplier !== null) {
                vector = vector.multiplyScalar(1 / orbitMultiplier);
            }

            initialTarget = this.camera.position.clone().add(vector);
            initialTarget.y = 0;
        }

        this._orbitTarget = initialTarget;
        this._orbitTarget.y = 0;

        let direction = this._orbitTarget.clone().sub(this.camera.position);
        direction.y = 0;
        direction = direction.normalize();

        // set camera to be orbitOffset away from the initial
        const cameraPosition = this._orbitTarget
            .clone()
            .sub(direction.multiplyScalar(this.orbitOffset))
            .setY(this._calculateFlyHeight(this.flyAngle));

        // make it look at the target
        if (animate) {
            // no we have to again recalculate the orbit Y so orbit after doesn't jump
            // TODO: this is duplicit code
            this._orbitTarget = cameraPosition.clone().add(this._forwardVector().multiplyScalar(this.orbitOffset));
            this._orbitTarget.y -= Math.tan(THREE.MathUtils.degToRad(this.flyAngle)) * this.orbitOffset;

            this.cameraAnimation.animateLookAt(cameraPosition, this._orbitTarget);
        } else {
            this.camera.position.copy(cameraPosition);
            this.camera.lookAt(this._orbitTarget);
        }
    }

    _updateOrbitTarget() {
        let newOrbitTarget = this._calculateOrbitTarget(this.camera.position);

        if (this._orbitTarget === null)
            this._orbitTarget = newOrbitTarget;
        else
            this._orbitTarget.copy(newOrbitTarget);
    }

    _calculateOrbitTarget(cameraPosition) {
        let orbitTarget = cameraPosition.clone().add(this._forwardVector().multiplyScalar(this.orbitOffset));
        orbitTarget.y = 0;

        return orbitTarget
    }

    _forwardVector() {
        const direction = new THREE.Vector3();
        this.camera.getWorldDirection(direction);
        direction.y = 0;
        return direction.normalize();
    }

    _calculateFlyHeight(angle) {
        return this.minHeight + (this.maxHeight - this.minHeight) * (angle - this.minFlyAngle) / (this.maxFlyAngle - this.minFlyAngle);
    }

    _handleMouseMove(event) {
        super._handleMouseMove(event);

        if (this.cameraAnimation.isPlaying())
            return;

        if (!this._startedPan)
            return;

        if (MouseEventUtils.isPressed(event, MouseEventUtils.RIGHT_BTN) ||
            (MouseEventUtils.isPressed(event, MouseEventUtils.LEFT_BTN) && event.altKey)) {
            this._handleOrbiting(this._getMovement(event))
        } else if (MouseEventUtils.isPressed(event, MouseEventUtils.LEFT_BTN)) {
            this._handlePanning(this._getMovement(event));
        } else {
            return;
        }

        event.preventDefault()
        this.dispatchEvent(_changeEvent);
    }

    _handleTouch(event) {
        super._handleTouch(event);

        if (this.cameraAnimation.isPlaying())
            return;

        if (event.pointers.length === 1) {
            // panning
            this._handlePanning([event.deltaXAdjusted * 3, event.deltaYAdjusted * 3]);
        } else {
            // pinch / rotation
            this._handlePanning([event.deltaXAdjusted * 3, 0]);
            this._handleOrbiting([event.rotationDelta * -5, event.deltaYAdjusted * 3]);
            this._handleZoom((event.scaleDelta - 1) * 15);
        }

        event.preventDefault();
        this.dispatchEvent(_changeEvent);
    }

    _handlePanning(movement) {
        const [deltaX, deltaY] = movement;

        // TODO: this happens when zooming out from a route... fixme
        if (isNaN(deltaX) || isNaN(deltaY))
            return;

        const [right, _, forward] = this._localCoordinateSystem()

        let dX = deltaX * 0.005;
        let dY = deltaY * 0.005;

        let newCameraPosition = this.camera.position.clone()
            .addScaledVector(right, dX)
            .addScaledVector(forward, dY);

        this.camera.position.copy(this._limitToBoundingBox(newCameraPosition));
        this._updateOrbitTarget();
    }

    _limitToBoundingBox(cameraPosition) {
        let newOrbitTarget = this._calculateOrbitTarget(cameraPosition)
        let oldOrbitTarget = newOrbitTarget.clone();

        newOrbitTarget.x = Math.max(this.boundingBox.min.x, Math.min(newOrbitTarget.x, this.boundingBox.max.x));
        newOrbitTarget.z = Math.max(this.boundingBox.min.z, Math.min(newOrbitTarget.z, this.boundingBox.max.z));

        let newCameraPosition = cameraPosition.clone()
            .addScaledVector(oldOrbitTarget, -1)
            .addScaledVector(newOrbitTarget, 1);

        newCameraPosition.y = cameraPosition.y;

        return newCameraPosition;
    }

    _moveOrbit(deltaX = 0, deltaY = 0) {
        this.flyAngle += deltaY;
        this.flyAngle = MathUtils.clamp(this.flyAngle, this.minFlyAngle, this.maxFlyAngle);

        const position = this.camera.position;
        const target = position.clone().add(this._forwardVector().multiplyScalar(this.orbitOffset));
        target.y -= Math.tan(THREE.MathUtils.degToRad(this.flyAngle)) * this.orbitOffset;

        const quat = new THREE.Quaternion().setFromUnitVectors(this.camera.up, new Vector3(0, 1, 0));
        const quatInverse = quat.clone().invert();
        const offset = new THREE.Vector3().subVectors(position, target)

        // rotate offset to "y-axis-is-up" space
        offset.applyQuaternion(quat);

        const spherical = new THREE.Spherical();
        spherical.setFromVector3(offset);

        spherical.theta += -deltaX;
        spherical.makeSafe();

        offset.setFromSpherical(spherical);
        // rotate offset back to "camera-up-vector-is-up" space
        offset.applyQuaternion(quatInverse);
        position.copy(target).add(offset);

        var new_position = position.clone()
        new_position.setY(this._calculateFlyHeight(this.flyAngle));

        return [new_position, target]
    }

    _handleOrbiting(movement) {
        const [deltaX, deltaY] = movement;
        const [position, target] = this._moveOrbit(deltaX * 0.003, deltaY * 0.1);

        this.camera.lookAt(target);
        this.camera.position.set(position.x, position.y, position.z);
    }

    _handleMouseWheel(event) {
        super._handleMouseWheel(event);
        if (this.cameraAnimation.isPlaying())
            return;

        event.preventDefault();
        this._handleZoom(event.deltaY);
        this.dispatchEvent(_changeEvent);
    }

    _handleZoom(delta) {
        // sigmoid function (turns values to [-1, 1])
        var s = 1 - 1 / (1 + Math.exp(-delta)) * 2;

        const zoomAngleMultiplier = 0.1;

        if (s < 0)
            // if zooming in, go toward 0 (i.e. even)
            this.flyAngle -= this.flyAngle * zoomAngleMultiplier * (-s);
        else
            // otherwise approach the default angle
            this.flyAngle = this.flyAngle * (1 - zoomAngleMultiplier * s) + this.defaultFlyAngle * zoomAngleMultiplier * s;

        const [position, target] = this._moveOrbit();

        this.camera.lookAt(target);
        this.camera.position.set(position.x, position.y, position.z);

        // given how close we are to 0 angle, we want to move a little / a lot
        // so it looks like we're zooming in/out
        let v = Math.sqrt(Math.abs(this.flyAngle * 0.05) + 1) * 0.2 * (-s);

        this.camera.position.add(this._forwardVector().multiplyScalar(v));

        this.camera.position.copy(this._limitToBoundingBox(this.camera.position));
        this._updateOrbitTarget();
    }
}

class OrbitControls extends BaseControls {
    constructor(
        canvasDomElement, camera, cameraAnimation,
        minZoomDistance = config.MIN_ROUTE_ZOOM_DISTANCE,
        maxZoomDistance = config.MAX_ROUTE_ZOOM_DISTANCE,
    ) {
        super(canvasDomElement);
        this.camera = camera;
        this.cameraAnimation = cameraAnimation;

        this.minZoomDistance = minZoomDistance;
        this.maxZoomDistance = maxZoomDistance;

        this._orbitTarget = null;
    }

    register(orbitTarget, cameraPosition, viewingDirection, minZoomDistance = null, maxZoomDistance = null) {
        this.changeTarget(orbitTarget, cameraPosition, viewingDirection, minZoomDistance, maxZoomDistance)
        super.register();
    }

    changeTarget(orbitTarget, cameraPosition, viewingDirection, minZoomDistance = null, maxZoomDistance = null) {
        this._orbitTarget = orbitTarget;

        if (minZoomDistance !== null)
            this.minZoomDistance = minZoomDistance;

        if (maxZoomDistance !== null)
            this.maxZoomDistance = maxZoomDistance;

        this.cameraAnimation.animateLookAt(cameraPosition, cameraPosition.clone().add(viewingDirection));
    }

    _handleMouseMove(event) {
        super._handleMouseMove(event);
        if (this.cameraAnimation.isPlaying())
            return;

        if (MouseEventUtils.isPressed(event, MouseEventUtils.LEFT_BTN)) {
            const [deltaX, deltaY] = this._getMovement(event);
            if (event.shiftKey) {
                this._handleZoom((deltaX - deltaY) * 2);
            } else {
                if (!this._startedPan)
                    return;

                this._handleOrbiting([deltaX, deltaY]);
            }
            this.dispatchEvent(_changeEvent);
        }
    }

    _handleMouseWheel(event) {
        super._handleMouseWheel(event);
        if (this.cameraAnimation.isPlaying())
            return;

        event.preventDefault();
        this._handleZoom(event.deltaY);
        this.dispatchEvent(_changeEvent);
    }

    _handleTouch(event) {
        super._handleTouch(event);
        if (this.cameraAnimation.isPlaying())
            return;

        this._handleOrbiting([event.deltaXAdjusted * 4, event.deltaYAdjusted * 4]);
        // TODO: this is bananas
        this._handleZoom((event.scaleDelta - 1) * 2500);

        event.preventDefault();
        this.dispatchEvent(_changeEvent);
    }

    _handleOrbiting([deltaX, deltaY]) {

        const position = this.camera.position;
        const target = this._orbitTarget;

        const quat = new THREE.Quaternion().setFromUnitVectors(this.camera.up, new Vector3(0, 1, 0));
        const quatInverse = quat.clone().invert();
        const offset = new THREE.Vector3().subVectors(position, target);
        // rotate offset to "y-axis-is-up" space
        offset.applyQuaternion(quat);

        const spherical = new THREE.Spherical();
        spherical.setFromVector3(offset);

        spherical.theta += -deltaX * 0.003;
        spherical.phi += -deltaY * 0.003;
        spherical.makeSafe();

        offset.setFromSpherical(spherical);
        // rotate offset back to "camera-up-vector-is-up" space
        offset.applyQuaternion(quatInverse);
        position.copy(target).add(offset);
        this.camera.lookAt(target);
    }

    _handleZoom(delta) {
        const position = this.camera.position;
        const target = this._orbitTarget;

        const offset = new THREE.Vector3().subVectors(position, target);
        const offsetDelta = offset.clone().normalize().multiplyScalar(-delta * 0.001);
        const newOffset = offset.add(offsetDelta).clampLength(this.minZoomDistance, this.maxZoomDistance);
        const newPosition = this._orbitTarget.clone().add(newOffset);
        this.camera.position.copy(newPosition);

        if (Math.abs(newOffset.length() - this.maxZoomDistance) < 0.0001) {
            this.dispatchEvent({
                ..._controlEscapeEvent,
            });
        }

    }
}


class Raycasting extends BaseControls {
    static Target = class Target {
        constructor(object, parts) {
            this.object = object;
            this.parts = parts;
        }
    }

    static Result = class Result {
        constructor(target, part) {
            this.target = target;
            this.part = part;
            this.hit = target !== null;
        }

        equals(otherResult) {
            return this.target === otherResult.target && this.part === otherResult.part;
        }
    }

    constructor(canvasDomElement, camera) {
        super(canvasDomElement);
        this._camera = camera;
        this._pointerPosition = new THREE.Vector2();

        this.reset();
    }

    addTarget(object, parts) {
        const target = new Raycasting.Target(object, parts)

        this._allParts.push(...parts);

        for (let part of parts) {
            this._partToTarget.set(part, target);
        }
    }

    addApproachTarget(object, parts, spheres) {
        const target = new Raycasting.Target(object, spheres)

        this._allApproachSpheres.push(...spheres);

        for (let i = 0; i < parts.length; i++) {
            let part = parts[i];
            let sphere = spheres[i];

            this._partToTarget.set(sphere, target);
            this._spheresToParts.set(sphere, part);
        }
    }

    reset() {
        this._allParts = [];
        this._allApproachSpheres = [];
        this._spheresToParts = new Map();
        this._partToTarget = new Map();
        this._lastResult = new Raycasting.Result(null, null);
        this._lastApproach = new Raycasting.Result(null, null);
    }

    _handleMouseMove(event) {
        super._handleMouseMove(event);
        this._updatePointerPosition(event);

        const result = this._raycast();
        if (!result.equals(this._lastResult)) {
            this.dispatchEvent({..._objectHoverEvent, result: result});
            this._lastResult = result;
        }

        if (!result.hit) {
            const approachResult = this._raycastNearestApproach();
            if (!approachResult.equals(this._lastApproach)) {
                this.dispatchEvent({..._objectApproachEvent, result: approachResult});
                this._lastApproach = approachResult;
            }
        }
    }

    _handlePress(event) {
        super._handlePress(event);
        this._updatePointerPosition(event);

        this._handlePressAndClick(event);
    }

    _handleClick(event) {
        super._handleClick(event);
        this._updatePointerPosition(event);

        this._handlePressAndClick(event);
    }

    _handlePressAndClick(event) {
        const result = this._raycast();

        if (result.hit) {
            this.dispatchEvent({..._objectClickEvent, result: result, screen_pointer: this._getScreenPosition(event)});
        }

        if (!result.hit) {
            const approachResult = this._raycastNearestApproach();

            if (approachResult.hit) {
                this.dispatchEvent({
                    ..._objectApproachClickEvent,
                    result: approachResult,
                    spherePart: this._spheresToParts.get(approachResult.part)
                });
            } else {
                this.dispatchEvent({..._objectMissClickEvent});
            }
        }
    }

    _createRaycaster(point = this._pointerPosition) {
        const raycaster = new THREE.Raycaster();
        raycaster.params.Line.threshold = 0.05;
        raycaster.setFromCamera(point, this._camera);
        return raycaster;
    }

    _raycast(point = this._pointerPosition) {
        const raycaster = this._createRaycaster(point);
        const intersects = raycaster.intersectObjects(this._allParts);
        const intersect = intersects.length === 0 ? null : intersects[0].object;

        if (intersect === null)
            return new Raycasting.Result(null, null);
        const target = this._partToTarget.get(intersect);
        return new Raycasting.Result(target, intersect);
    }

    _raycastNearestApproach(point = this._pointerPosition) {
        const raycaster = this._createRaycaster(point);
        const ray = raycaster.ray;

        let minDistance = null;
        let approachedPart = null;
        for (let sphere of this._allApproachSpheres) {
            const distance = ray.distanceSqToPoint(sphere.center);
            if (distance > sphere.radius)
                continue;
            if (minDistance === null || distance < minDistance) {
                minDistance = distance;
                approachedPart = sphere;
            }
        }
        if (approachedPart === null)
            return new Raycasting.Result(null, null);
        const target = this._partToTarget.get(approachedPart);
        return new Raycasting.Result(target, approachedPart);
    }

    _updatePointerPosition(event) {
        let x = null;
        let y = null;

        if (event.type === "press") {
            x = event.center.x;
            y = event.center.y;
        } else {
            // assumes this is finger press
            x = event.clientX;
            y = event.clientY;
        }

        this._pointerPosition.x = (x / window.innerWidth) * 2 - 1;
        this._pointerPosition.y = -(y / window.innerHeight) * 2 + 1;
    }

    _getScreenPosition(event) {
        if (event.type === "press") {
            return new THREE.Vector2(event.center.x, event.center.y);
        } else {
            return new THREE.Vector2(event.clientX, event.clientY);
        }
    }
}

export {OrbitControls, TopDownControls, Raycasting};