import {createContext, useState} from "react";
import {unimplementedFunction} from "../common/Utils";

const UiContext = createContext(null);
const UiDispatchContext = createContext(unimplementedFunction);

class UiActions {
    static OpenSidePanel = "open_side_panel";
    static CloseSidePanel = "close_side_panel";
    static SetSidePanelPageIdx = "set_side_panel_page_idx";
}

class UiState {
    constructor(sidePanelOpen, sidePanelPageIdx, sidePanelPageName = undefined) {
        this.sidePanelOpen = sidePanelOpen;
        this.sidePanelPageIdx = sidePanelPageIdx;
        this.sidePanelPageName = sidePanelPageName;
    }
}

function useUiReducer() {
    const [state, setState] = useState(new UiState(false, 0));

    function uiDispatch(action) {
        setState(prevState => _uiReducer(prevState, action));
    }

    return [state, uiDispatch];
}

function _uiReducer(state, action) {
    switch (action.type) {
        case UiActions.OpenSidePanel:
            // here we want to open to whatever the action said
            return new UiState(true, action.pageIdx);
        case UiActions.CloseSidePanel:
            // whereas here we want to close and preserve the animation
            return new UiState(false, state.sidePanelPageIdx);
        case UiActions.SetSidePanelPageIdx:
            return new UiState(true, action.pageIdx, action.pageName);
        default:
            throw Error("Unknown action: " + action.type);
    }
}

export {UiContext, UiDispatchContext, UiActions, useUiReducer};