import ModalWindow from "../../common/ModalWindow";
import {useContext, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {BackendResult, ErrorType} from "../../model/Backend";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {UiUtils} from "../../common/Utils";
import {Trans, useTranslation} from "react-i18next";

export default function DeleteAccountModal({onClose, show}) {
    const userPassword = useRef(null);
    const userPasswordRepeat = useRef(null);

    const userDispatch = useContext(UserDispatchContext);

    const [validationError, setValidationError] = useState(null);

    const [modified, setModified] = useState(false);

    const user = useContext(UserContext);

    const {t, i18n} = useTranslation();

    function changePasswords() {
        if (userPassword.current.value || userPasswordRepeat.current.value) {
            setModified(true);
        } else {
            setModified(false);
        }
    }

    async function onCloseWrapper() {
        if (modified && !await UiUtils.confirmAsync(t("warning.not_finished")))
            return;

        onClose();
    }

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (userPassword.current.value !== userPasswordRepeat.current.value) {
            setValidationError(BackendResult.FromError(ErrorType.PasswordsDontMatch));
            return;
        }

        setValidationError(null);

        userDispatch({
            type: UserActions.DeleteAccount,
            data: {password: userPassword.current.value},
        });
    }

    return <ModalWindow
        title={t("delete_account.delete_account")}
        handleClose={onCloseWrapper}
        topColor={UIColor.Red}
        show={show}
    >
        <form onSubmit={onSubmit}>
            <BasicTextInput ref={userPassword} type="password" name="password" required={true}
                            label={t("password.password")} autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-4"></div>
            <BasicTextInput ref={userPasswordRepeat} type="password" name="password_repeat" required={true}
                            label={t("password.confirm_password")} autoComplete="new-password"
                            onChange={changePasswords}
            />
            <div className="mb-8"></div>
            <p className={`${UIColor.MinorText} text-sm`}>
                <Trans
                    i18nKey="delete_account.warning"
                    components={{strong: <strong/>}}
                />
            </p>
            <div className="mb-2"></div>
            <BasicButton
                type="submit"
                buttonColor={UIColor.Red}
                className="w-full"
                disabled={!modified}
            >
                {user.status.isLoading(UserActions.DeleteAccount) ?
                    <SpinLoadingIndicator/> : <p>{t("delete_account.delete_account")}</p>}
            </BasicButton>
            <ErrorFromBackendResult data={validationError}/>
        </form>
    </ModalWindow>;
}
