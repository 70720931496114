import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {ErrorPage, GlobalInfoOverlay} from "./GlobalInfoOverlay";
import ResetPasswordModal from "./side_panel/account/ResetPasswordModal";
import VerifyEmailModal from "./side_panel/account/VerifyEmailModal";
import PrivacyPolicy from "./legal/PrivacyPolicy";
import TermsOfUse from "./legal/TermsOfUse";
import LandingPage from "./LandingPage";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: "/",
        element: <LandingPage/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "/app/*",
        element: <App/>,
        errorElement: <ErrorPage/>,
    },
    {
        path: "account/reset_password",
        element:
            <GlobalInfoOverlay header={false} footer={false}>
                <ResetPasswordModal/>
            </GlobalInfoOverlay>,
    },
    {
        path: "account/verify_email",
        element:
            <GlobalInfoOverlay header={false} footer={false}>
                <VerifyEmailModal/>
            </GlobalInfoOverlay>,
    },
    {
        path: "privacy_policy",
        element:
            <GlobalInfoOverlay header={false} footer={false}>
                <PrivacyPolicy/>
            </GlobalInfoOverlay>,
    },
    {
        path: "terms_of_use",
        element:
            <GlobalInfoOverlay header={false} footer={false}>
                <TermsOfUse/>
            </GlobalInfoOverlay>,
    },
]);

root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);
