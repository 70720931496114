import {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {t} from "i18next";
import {UserActivity, UserActivityType} from "../model/User";
import {UserActions, UserContext} from "../contexts/UserContext";

const sigmoid = (z) => 2 * (1 / (1 + Math.exp(-z))) - 1;

const SoftHardBar = ({route, enableActivity}) => {
    const user = useContext(UserContext);

    const [animatedValue, setAnimatedValue] = useState(0);

    const softActivity = user?.value?.getActivity(UserActivityType.Soft, route);
    const hardActivity = user?.value?.getActivity(UserActivityType.Hard, route);

    const setSoft = () => enableActivity(softActivity, hardActivity, UserActivityType.Soft, UserActivityType.Hard);
    const setHard = () => enableActivity(hardActivity, softActivity, UserActivityType.Hard, UserActivityType.Soft);

    const delta = route.hardRatings - route.softRatings;
    const div = 3;

    const value = sigmoid(delta / div) * 100;
    const position = (value + 100) / 2;


    // Animate to the new value
    useEffect(() => {
        setAnimatedValue(value);
    }, [value]);

    const isPositive = animatedValue > 0;
    const neutralPosition = 50;

    // Determine gradient colors and direction
    const gradientStart = isPositive ? neutralPosition : position;
    const gradientEnd = isPositive ? position : neutralPosition;

    // Calculate width of the gradient
    const gradientWidth = Math.abs(gradientEnd - gradientStart);

    let isLoading = false;

    for (const activityType of [UserActivityType.Soft, UserActivityType.Hard]) {
        for (const actionType of [UserActions.AddActivities, UserActions.RemoveActivities, UserActions.ModifyActivities]) {
            isLoading ||= user.status.isLoading(UserActivity.getTrackingId(route, activityType, actionType));
        }
    }

    return (
        <div key={"soft-hard-bar"} className="m-auto">
            <div className="flex items-end gap-3 justify-center">
                <div
                    className={`text-white text-opacity-100 hover:text-opacity-50 ${isLoading || hardActivity ? 'opacity-50' : 'opacity-100'} transition-all flex items-center ${isLoading ? "" : "cursor-pointer"} min-w-0`}
                    onClick={isLoading ? undefined : setSoft}>
                    <FontAwesomeIcon icon={UserActivityType.Icon(UserActivityType.Soft, softActivity)}
                                     className="text-xl pr-1"/>
                    <p className={"truncate"}>
                        {t("user_activity_type")[UserActivityType.Soft]}
                    </p>
                </div>
                <div className="h-5 border-l-2 border-white opacity-10"/>
                <p className="text-2xl font-bold">{route.grade}</p>
                <div className="h-5 border-l-2 border-white opacity-10"/>
                <div
                    className={`text-white text-opacity-100 hover:text-opacity-50 ${isLoading || softActivity ? 'opacity-50' : 'opacity-100'} transition-all flex items-center ${isLoading ? "" : "cursor-pointer"} min-w-0`}
                    onClick={isLoading ? undefined : setHard}>
                    <p className={"truncate"}>
                        {t("user_activity_type")[UserActivityType.Hard]}
                    </p>
                    <FontAwesomeIcon icon={UserActivityType.Icon(UserActivityType.Hard, hardActivity)}
                                     className="text-xl pl-1"/>
                </div>
            </div>

            <div className={"mb-2"}/>

            <div className="relative h-1.5 bg-gray-500 rounded-full overflow-visible">
                {/* Gradient line */}
                <div
                    className={`absolute top-0 bottom-0 transition-all bg-gradient-to-l ${isPositive ? 'from-neutral-200 to-neutral-500' : 'to-neutral-200 from-neutral-500'}`}
                    style={{
                        left: `${gradientStart}%`,
                        width: `${gradientWidth}%`,
                    }}
                />

                {/* Animated dot */}
                <div
                    className={`absolute bg-neutral-200 top-1/2 h-2 w-2 rounded-full -translate-y-1/2 -translate-x-1/2 transition-all ease-out shadow-md flex items-center justify-center`}
                    style={{left: `${position}%`}}
                />
            </div>
            <div className="mb-2"/>
        </div>
    );
};

export default SoftHardBar;
