import './Policies.css';
import ModalWindow from "../common/ModalWindow";
import {Contact} from "./Utilities";
import {ScreenDimensions, useScreenDimensions} from "../common/CustomHooks";
import Spacer from "../common/Spacer";

export default function TermsOfUse({...props}) {
    const [screenWidth, _] = useScreenDimensions();

    let width;
    if (screenWidth >= ScreenDimensions.MOBILE_WIDTH) {
        width = "w-[40rem] max-w-[calc(100%-2rem)]";
    } else {
        width = null;
    }

    return <ModalWindow title={"Terms of Use"} width={width} {...props}>
        <div className={"policy"}>
            <p>Effective Date: <strong>26. 2. 2025</strong></p>

            <p>Welcome to Climbuddy, provided by Climber Tools s.r.o. ("we", "our", "us"). These Terms of Service
                ("Terms") govern your use of our services, including our website and applications. By accessing or using
                Climbuddy, you agree to be bound by these Terms. Please read them carefully.</p>

            <Spacer/>

            <h2>1. Services Provided</h2>
            <p>We provide users with the ability to view and log climbed routes, compete in a global leaderboard, and
                interact socially with other users through features such as liking/disliking routes, commenting, and
                sharing achievements. These services are designed to enhance your climbing experience and help you track
                your progress over time.</p>

            <Spacer/>

            <h2>2. User Responsibilities</h2>
            <p>By using our platform, you agree to log your progress accurately. This includes correctly recording your
                climbed routes, repeated routes, attempted routes, and any other relevant activities within the app.</p>

            <Spacer/>

            <h2>3. Prohibited Activities</h2>
            <ul>
                <li>Posting vulgar, explicit, or offensive content in your username, profile picture, or any other part
                    of the platform.
                </li>
                <li>Engaging in fake logging of climbing activities that you have not participated in.</li>
                <li>Posting spam or engaging in any form of unsolicited promotion.</li>
                <li>Posting copyrighted content without permission or proper authorization.</li>
                <li>Engaging in any activity that violates the law, including but not limited to harassment, fraud, or
                    illegal conduct.
                </li>
                <li>Decompiling, reverse engineering, disassembling or decoding any portion of the platform (including
                    any underlying idea or algorithm), or attempt to do any of the same (except to the extent such
                    restriction is expressly prohibited by applicable law).
                </li>
            </ul>
            <p>Any violation of these guidelines may result in the termination of your account and access to the
                platform. We reserve the right to take action if we deem any activity harmful to our community or
                service.</p>

            <Spacer/>

            <h2>4. Intellectual Property</h2>
            <p>The platform contains information, data, software, photographs, graphs, graphics, and other features and
                functionalities (collectively, the "<strong>Content</strong>") protected by copyrights, trademarks,
                trade secrets, rights in databases, and/or other proprietary rights.</p>

            <p> Subject to the conditions of these Terms, we grant you a limited, revocable, non-exclusive,
                non-transferable, non-sublicensable, license to access, view and use the platform and its Content for
                your personal, non-commercial use only.</p>

            <p> Except as expressly stated herein, you are not allowed to copy, modify, create derivative a work of,
                reproduce, distribute, republish, download, display, post, send, transmit or otherwise use (including
                “mirroring” and “framing” on other devices or servers) the Content or any portion of the platform itself
                without prior written permission of Climber Tools s.r.o. or the copyright owner (if different from
                Climber Tools s.r.o.). </p>

            <Spacer/>

            <h2>5. Disclaimers and Limitation of Liability</h2>
            <p>Your use of the platform is at your sole risk. The platform is provided on an "as is" and "as available"
                basis. We do not warrant that use of the platform will be uninterrupted or error-free.</p>

            <p>The platform is provided without warranties and representations of any kind, whether expressed or
                implied, including, but not limited to warranty of the accuracy, integrity, or completeness of the
                Content provided on the platform. Under no circumstances shall any information on the platform or
                provided to you by us constitute professional advice, unless explicitly stated so.</p>
            <p>In no event shall Climber Tools s.r.o., its directors, employees, partners, agents, suppliers, or
                affiliates, be liable for any direct, indirect, incidental, special, or consequential damages that
                result from the use of or inability to use the platform, including but not limited to reliance by a user
                on any information obtained at the platform, or that result from mistakes, omissions, interruptions,
                deletion of files or e-mail, errors, defects, viruses, delays in operation or transmission, or any
                failure of performance.</p>

            <p>You agree to defend, indemnify and hold harmless Climber Tools s.r.o. and its licensee and licensors, and
                their employees, contractors, agents, officers and directors, harmless from and against every
                third-party claim and expense, including reasonable attorneys’ fees, related in any way to your use of
                the platform or your violation of these Terms.</p>

            <p>We do not control or endorse user-generated content, and therefore, we cannot be held liable for any
                content that may be seen as inappropriate or disturbing by some users. We encourage you to report any
                violations of the platform's guidelines to us.</p>

            <p>Climbuddy is a digital platform that facilitates tracking and social interaction related to climbing. It
                does not provide training, safety advice, or recommendations for engaging in climbing or any other
                physical activity. Your decision to participate in climbing or related activities is entirely your own
                and at your own risk. We are not liable for any accidents, injuries, or other damages that may occur
                while climbing or engaging in any activity at a climbing facility, gym, or outdoor location. Climbing is
                inherently dangerous, and you acknowledge that it is your responsibility to assess risks, follow safety
                guidelines, and seek appropriate training before participating.</p>

            <h3>Links to Third-Party Websites</h3>
            <p>We may contain links to third-party websites or resources. These links are provided for convenience and
                informational purposes only. We do not control, endorse, or take responsibility for the content, privacy
                practices, or services of any third-party websites. Accessing these external sites is done at your own
                risk.</p>
            <p>We are not responsible for the availability, accuracy, or legality of any external content or the actions
                of third-party websites. We encourage you to review the terms of use and privacy policies of any
                third-party sites you visit.</p>

            <Spacer/>
            <h3>6. Governing Law</h3>
            <p>These Terms of Use are governed by and construed in accordance with the laws of <strong>Czechia</strong>,
                without regard to its conflict of law principles. Any disputes arising from or relating to these terms,
                the platform, or its services shall be subject to the exclusive jurisdiction of the courts
                in <strong>Czechia</strong>. By using the platform, you agree to submit to the jurisdiction of these
                courts for any legal matters arising from your use of the service.</p>
            <Spacer/>

            <h2>7. Account Disputes</h2>
            <p>In the event of a dispute regarding account ownership or control, we reserve the right to make the final
                determination based on a number of factors, including but not limited to account activity, registration
                information, and communication history.</p>
            <p>We may request additional information or documentation to resolve the dispute. Our decision will be made
                in accordance with our policies, and we reserve the right to suspend or terminate accounts if necessary
                to protect the integrity of the platform and ensure fair use.</p>

            <Spacer/>

            <h2>8. Privacy Policy</h2>
            <p>Your privacy is important to us. Please refer to our Privacy Policy for
                detailed information on how we collect, use, and protect your personal data.</p>

            <Spacer/>

            <Contact/>
        </div>
    </ModalWindow>;
}
