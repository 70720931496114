import {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {BasicButton} from "./Button";
import {UIColor} from "../Config";
import Expandable from "./Expandable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLanguage} from "@fortawesome/free-solid-svg-icons";

const LanguageButton = ({ lang, flag, label, selected, onClick, round }) => {
    return (
        <BasicButton
            padding="py-1 px-2"
            onClick={onClick}
            buttonColor={selected ? UIColor.Blue : UIColor.Default}
            round={round}
        >
            <div className="flex gap-2 items-center">
                <span className="text-xl">{flag}</span>
                <p>{label}</p>
            </div>
        </BasicButton>
    );
};

const LanguageSelector = ({ gap = "gap-3", direction = "horizontal", round = true, onChange = undefined }) => {
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        onChange?.()
    };

    const flexDirection = direction === "horizontal" ? "flex-wrap" : "flex-col";

    return (
        <div className={`flex ${flexDirection} justify-center ${gap} ${round ? "rounded-md" : ""}`}>
            <LanguageButton lang="en" flag="🇺🇸" label="English" selected={language === "en"} onClick={() => changeLanguage("en")} round={round} />
            <LanguageButton lang="de" flag="🇩🇪" label="Deutsch" selected={language === "de"} onClick={() => changeLanguage("de")} round={round} />
            <LanguageButton lang="cs" flag="🇨🇿" label="Čeština" selected={language === "cs"} onClick={() => changeLanguage("cs")} round={round} />
            {/* <LanguageButton lang="fr" flag="🇫🇷" label="Français" selected={language === "fr"} onClick={() => changeLanguage("fr")} round={round} /> */}
        </div>
    );
};

export const ExpandableLanguageSelector = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        const handleScroll = () => {
            setIsDropdownOpen(false); // Close dropdown on scroll
        };

        // Add event listeners for clicks and scroll
        document.addEventListener("click", handleClickOutside);
        window.addEventListener("scroll", handleScroll);

        // Cleanup event listeners on component unmount
        return () => {
            document.removeEventListener("click", handleClickOutside);
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return <div ref={dropdownRef}>
        <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="flex items-center gap-3 p-3 hover:text-gray-300"
        >
            <FontAwesomeIcon icon={faLanguage} className="text-xl"/>
        </button>

        <div className={`absolute right-0 w-fit ${UIColor.Default} shadow-medium z-30`}>
            <Expandable isOpen={isDropdownOpen}>
                <LanguageSelector
                    gap={""}
                    direction={"vertical"}
                    round={false}
                    onChange={() => setIsDropdownOpen(false)}
                />
            </Expandable>
        </div>
    </div>;
}

export default LanguageSelector;
