import {
    faArrowPointer,
    faMinus,
    faObjectGroup,
    faPlus,
    faPlusSquare,
    faTrash,
    faTrashRestore
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import Window from "../../common/Window";
import {Trans, useTranslation} from "react-i18next";
import DraggableWindow from "../../common/DraggableWindow";

class HoldPopupType {
    static CurrentRoute = "current_route";
    static DifferentRoute = "different_route";
    static NoRoute = "no_route";
    static SomeRoute = "some_route";
    static ToBeDeleted = "to_be_deleted";
}

function HoldPanel({onCancel, onView, onNew, onCombine, onInclude, onExclude, onDelete, onUndelete, type}) {
    const {t} = useTranslation();

    const buttonConfigs = [
        {
            label: t("rebuild.add_phase.hold_panel.button.view"),
            icon: faArrowPointer,
            buttonColor: undefined,
            onClick: onView
        },
        {
            label: t("rebuild.add_phase.hold_panel.button.new"),
            icon: faPlusSquare,
            buttonColor: UIColor.Green,
            onClick: onNew
        },
        {
            label: t("rebuild.add_phase.hold_panel.button.include"),
            icon: faPlus,
            buttonColor: UIColor.Green,
            onClick: onInclude
        },
        {
            label: t("rebuild.add_phase.hold_panel.button.merge"),
            icon: faObjectGroup,
            buttonColor: UIColor.Green,
            onClick: onCombine
        },
        {
            label: t("rebuild.add_phase.hold_panel.button.exclude"),
            icon: faMinus,
            buttonColor: UIColor.Red,
            onClick: onExclude
        },
        {
            label: t("rebuild.add_phase.hold_panel.button.delete"),
            icon: faTrash,
            buttonColor: UIColor.Red,
            onClick: onDelete
        },
        {
            label: t("rebuild.add_phase.hold_panel.button.undo_delete"),
            icon: faTrashRestore,
            buttonColor: UIColor.Red,
            onClick: onUndelete
        }
    ];

    return (
        <DraggableWindow
            offsets={[]}
            overflow={"visible"}
        >
            <div className={"pb-4"}/>

            <div className={`flex gap-2 px-3 flex-wrap items-center justify-center`}>

                {buttonConfigs.map(({label, icon, buttonColor, onClick}) => {
                    if (onClick) {
                        return (
                            <BasicButton
                                padding={"py-1 px-3"}
                                className={"flex-grow"}
                                buttonColor={buttonColor}
                                onClick={() => {
                                    onClick();
                                    onCancel();
                                }}
                                key={label}>
                                <div className={"flex flex-row gap-2 items-center"}>
                                    <FontAwesomeIcon className={"w-5"} icon={icon}/>
                                    <p className={"text-nowrap"}>{label}</p>
                                </div>
                            </BasicButton>
                        );
                    }
                })}
            </div>

            <div className={"pb-2"}/>

            <p className={`px-10 hyphens-auto text-center`}>
                <Trans
                    i18nKey={`rebuild.add_phase.hold_panel.dialog.${type}`}
                    components={{
                        strong: <strong/>,
                        minor: <span className={UIColor.MinorText}/>,
                    }}
                />
            </p>

            <div className={"pb-4"}/>
        </DraggableWindow>
    );
}

export {HoldPanel, HoldPopupType};