import {MOCK} from "../Config";

class LeaderboardUser {
    constructor({nickname, photo, rating, rank, current}) {
        this.nickname = nickname;

        this.imageString = photo;

        this.rating = rating * 100; // server sums to 1
        this.rank = rank + 1; // server indexes from 0
        this.current = current;
    }
}

async function loadMock() {
    const response = await fetch('/mock/leaderboard.json');
    if (!response.ok) {
        throw new Error(`Failed to load JSON: ${response.statusText}`);
    }
    return response.json();
}

class Leaderboard {
    constructor(entries) {
        this.entries = entries;
    }

    static async fromList(data) {
        if (MOCK) {
            data = await loadMock();
        }

        const entries = data.map(entry => new LeaderboardUser(entry));

        return new Leaderboard(entries);
    }

    sortedEntries(limit) {
        limit ??= 10;

        const currentEntries = this.entries.filter(entry => entry.current);
        const nonCurrentEntries = this.entries.filter(entry => !entry.current).sort((a, b) => a.rank - b.rank);

        const limitedNonCurrentEntries = limit ? nonCurrentEntries.slice(0, Math.max(0, limit - currentEntries.length)) : nonCurrentEntries;

        const combinedEntries = [...currentEntries, ...limitedNonCurrentEntries];

        return combinedEntries.sort((a, b) => a.rank - b.rank);
    }

    clone() {
        return new Leaderboard(this.entries);
    }
}

export {Leaderboard, LeaderboardUser};
