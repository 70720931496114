import {Fragment, useContext, useEffect, useState} from "react";
import {BasicButton} from "../../common/Button";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBookmark,
    faCalendar,
    faEye,
    faEyeSlash,
    faGear,
    faRightFromBracket,
    faUser,
    faUserEdit
} from '@fortawesome/free-solid-svg-icons'
import {UserActivityType} from "../../model/User";
import {DateUtils} from "../../common/Utils";
import {SetCanvasStateContext} from "../../contexts/CanvasContext";
import {RouteFocusState} from "../../scene/canvas/CanvasState";
import {ActivityCard, BookmarkCard} from "./ActivityCards";
import {ScreenDimensions, useScreenDimensions} from "../../common/CustomHooks";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import ModifyActivitiesModal from "./ModifyActivitiesModal";
import SettingsModal from "./SettingsModal";
import {InteractiveProgressGrid} from "./ProgressGrid";
import ProfilePicture from "./ProfilePicture";
import {LeaderboardActions, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {UIColor} from "../../Config";
import RegisterModal from "./RegisterModal";
import {WarningMessage} from "../../common/WarningMessage";
import {Trans, useTranslation} from "react-i18next";
import Spacer from "../../common/Spacer";

const TemporaryAccountWarning = () => {
    const {t} = useTranslation();

    return <WarningMessage
        title={t("account_info.temporary_account")}
        text={<Trans
            i18nKey="account_info.temporary_account_warning"
            components={{strong: <strong/>}}
        />}/>
}


const EmailNotVerifiedWarning = () => {
    const {t} = useTranslation();
    const [countdown, setCountdown] = useState(0);

    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setInterval(() => {
                setCountdown(countdown - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [countdown]);

    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    function sendVerificationEmail() {
        if (user.status.isLoading(UserActions.ResendVerificationEmail))
            return;

        userDispatch({
            type: UserActions.ResendVerificationEmail,
            postSuccessHooks: () => {
                setCountdown(30);
            }
        });

    }

    return <>
        <div className="mb-5"></div>
        <WarningMessage
            title={t("account_info.email_not_verified")}
            text={<Trans
                i18nKey="account_info.email_not_verified_warning"
                components={{strong: <strong/>}}
            />}>
            <BasicButton
                buttonColor={UIColor.Yellow}
                padding="py-1 px-3"
                className="w-full"
                onClick={sendVerificationEmail}
                disabled={countdown > 0}
            >
                {countdown > 0 ? `${t("info.sent")} (${countdown})` : t("account_info.email_resend")}
            </BasicButton>
        </WarningMessage>
    </>
}

const UserInfo = () => {
    const user = useContext(UserContext);

    const {t} = useTranslation();

    const [imageEnabledObject, setImageEnabledObject] = useState(null);

    useEffect(() => {
        setImageEnabledObject(user.value);
    }, [user])

    return <div className={`${UIColor.DarkInput} rounded-xl p-3`}>
        <div className="flex gap-x-5 items-center">
            <ProfilePicture
                imageEnabledObject={imageEnabledObject}
                pictureSize="w-16 h-16"
            />
            <div className="min-w-0 grow">
                <p className="text-xl font-bold truncate">{user.value.email ?? t("account_info.email_not_set")}</p>
                <div className="mb-2"></div>
                {
                    user.value.nickname ? <div className="flex items-center">
                        <FontAwesomeIcon icon={faUser} className="w-3 pr-2"></FontAwesomeIcon>
                        <p className="truncate">{user.value.nickname}</p>
                    </div> : <div className="flex items-center">
                        <FontAwesomeIcon icon={faUser} className="w-3 pr-2"></FontAwesomeIcon>
                        <p className={UIColor.MinorText}>{t("account_info.username_not_set")}</p>
                    </div>
                }
            </div>
        </div>
    </div>;
}

const PossibleUserActions = () => {
    const {t} = useTranslation();

    const user = useContext(UserContext);

    const userDispatch = useContext(UserDispatchContext);
    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    const [registeringOpen, setRegisteringOpen] = useState(false);
    const [editProfile, setEditProfile] = useState(false);

    const uiStateDispatch = useContext(UiDispatchContext);

    function signOut() {
        userDispatch({
            type: UserActions.Logout,
            postSuccessHooks: () => {
                uiStateDispatch({type: UiActions.CloseSidePanel})
            }
        });
        leaderboardDispatch({type: LeaderboardActions.Update});
    }

    return <>
        <div className="flex gap-x-3">
            <BasicButton onClick={signOut} className="w-full">
                <div className="flex flex-col">
                            <span>{t("account_info.sign_out")}
                                <FontAwesomeIcon icon={faRightFromBracket} className="pl-2"/>
                            </span>
                    {user.value.isTemporary ?
                        <span
                            className={`opacity-50 text-sm`}>{t("account_info.sign_out_data_loss")}</span> : ""
                    }
                </div>
            </BasicButton>
            {
                user.value.isTemporary ?
                    <BasicButton
                        className="w-full"
                        buttonColor={UIColor.DarkYellow}
                        onClick={() => setRegisteringOpen(true)}
                    >
                        <div className="flex flex-col">
                                    <span>{t("register.register")}
                                        <FontAwesomeIcon icon={faUserEdit} className="pl-2"/>
                                    </span>
                            <span
                                className={`opacity-50 text-sm`}>{t("account_info.register_saves_activities")}
                                    </span>
                        </div>
                    </BasicButton>
                    : <BasicButton onClick={() => setEditProfile(true)}
                                   className="w-full"> {t("account_info.settings")}
                        <FontAwesomeIcon icon={faUserEdit} className="pl-2"/>
                    </BasicButton>
            }
        </div>
        {/* Not implemented as show={} since we want to reset settings on open/close. */}
        {
            editProfile &&
            <SettingsModal user={user} onClose={() => setEditProfile(false)}/>
        }
        <RegisterModal onClose={() => setRegisteringOpen(false)}
                       topColor="bg-yellow-700"
                       text={<p><Trans
                           i18nKey="account_info.register_saves_activities_modal"
                           components={{strong: <strong/>}}
                       /></p>}
                       show={registeringOpen}
        />
    </>
}

const BookmarksList = () => {
    const setCanvasState = useContext(SetCanvasStateContext);

    const {t} = useTranslation();

    const user = useContext(UserContext);

    const userDispatch = useContext(UserDispatchContext);
    const uiStateDispatch = useContext(UiDispatchContext);

    const [screenWidth, _] = useScreenDimensions();

    let bookmarksList = [];

    // TODO: duplicate
    function goToRoute(activity) {
        if (activity.isOnWall) {
            setCanvasState(new RouteFocusState(activity.route));

            if (screenWidth <= ScreenDimensions.MOBILE_WIDTH) {
                uiStateDispatch({type: UiActions.CloseSidePanel});
            }
        }
    }

    function removeBookmark(activity) {
        console.assert(activity.type === UserActivityType.Bookmark);

        userDispatch({
            type: UserActions.RemoveActivities,
            activities: [activity],
        });
    }

    for (let i = user.value.activities.length - 1; i >= 0; i--) {
        const bookmark = user.value.activities[i];

        if (bookmark.type !== UserActivityType.Bookmark)
            continue;

        // skip bookmarks to non-existent routes
        if (!bookmark.isOnWall)
            continue;

        const key = `${i}${bookmark.route.id}`;
        bookmarksList.push(
            <Fragment key={"bookmark" + key}>
                <BookmarkCard
                    bookmark={bookmark}
                    routeState={user.value.getRouteClimbingState(bookmark.route)}
                    onClick={goToRoute}
                    onRemove={removeBookmark}/>
            </Fragment>
        );
    }

    return bookmarksList.length === 0
        ? <p className={UIColor.MinorText}>{t("account_info.no_bookmarks")}</p>
        : bookmarksList;
}

const ActivitiesList = ({setModifyActivities, setShowModifyActivities}) => {
    const {t, i18n} = useTranslation();
    const user = useContext(UserContext);
    const setCanvasState = useContext(SetCanvasStateContext);
    const activitiesByDayAndRoute = user.value.activitiesByDayAndRoute();
    const [screenWidth, _] = useScreenDimensions();
    const uiStateDispatch = useContext(UiDispatchContext);

    // Initialize with all days collapsed
    const initialCollapsedState = Object.keys(activitiesByDayAndRoute).reduce((acc, dateKey) => {
        const date = new Date(dateKey);
        const now = new Date();

        if (!(
            date.getFullYear() === now.getFullYear() &&
            date.getMonth() === now.getMonth() &&
            date.getDate() === now.getDate()
        )) {
            acc[dateKey] = true; // true means collapsed
        }

        return acc;
    }, {});

    const [collapsedDays, setCollapsedDays] = useState(initialCollapsedState);

    if (Object.keys(activitiesByDayAndRoute).length === 0) {
        return <p className={UIColor.MinorText}>{t("account_info.no_sessions")}</p>;
    }

    // Toggle collapsed state for a specific day
    const toggleDayCollapse = (dateKey) => {
        setCollapsedDays(prev => ({
            ...prev,
            [dateKey]: !prev[dateKey]
        }));
    };

    // TODO: duplicate
    function goToRoute(activity) {
        if (activity.isOnWall) {
            setCanvasState(new RouteFocusState(activity.route));
            if (screenWidth <= ScreenDimensions.MOBILE_WIDTH) {
                uiStateDispatch({type: UiActions.CloseSidePanel});
            }
        }
    }

    return (
        <>
            {Object.entries(activitiesByDayAndRoute).map(([dateKey, routes]) => {
                const jsDate = new Date(dateKey);
                const thisDayActivities = Object.values(routes).flat();
                const isCollapsed = collapsedDays[dateKey] || false;

                return (
                    <Fragment key={"date" + dateKey}>
                        <div className="mb-6"></div>
                        <div className="flex items-center justify-between">
                            <div className="flex items-center gap-2"
                                 onClick={() => toggleDayCollapse(dateKey)}
                            >
                                {DateUtils.toPrettyDay(jsDate, i18n.language)}
                            </div>
                            <div className={"flex items-center gap-2"}>
                                {
                                    !isCollapsed ?
                                        <ClickableFontAwesomeIcon
                                            icon={faGear}
                                            onClick={() => {
                                                setModifyActivities(thisDayActivities);
                                                setShowModifyActivities(true);
                                            }}
                                            className={`${thisDayActivities.length === 0 ? "invisible" : "visible"} text-xl ml-2`}
                                            inverseHover={true}
                                        /> : <></>
                                }
                                <ClickableFontAwesomeIcon
                                    icon={isCollapsed ? faEye : faEyeSlash}
                                    onClick={() => toggleDayCollapse(dateKey)}
                                    className={"text-lg"}
                                />
                            </div>
                        </div>
                        <div className="m-1"></div>
                        <div
                            className={`flex ${!isCollapsed ? "flex-col" : "flex-wrap"} gap-2`}>
                            {Object.entries(routes).map(([routeKey, routeActivities]) => (
                                <div key={"activity" + routeKey} className={!isCollapsed ? `flex-grow` : ""}>
                                    <ActivityCard
                                        activities={routeActivities}
                                        onActivityClick={goToRoute}
                                        onActivityModify={() => {
                                            if (routeActivities[0].isOnWall) {
                                                setModifyActivities(routeActivities);
                                                setShowModifyActivities(true);
                                            }
                                        }}
                                        editable={routeActivities[0].isOnWall}
                                        collapsed={isCollapsed}
                                    />
                                </div>
                            ))}
                        </div>
                    </Fragment>
                );
            })}
        </>
    );
};

export default function AccountInfo() {
    const user = useContext(UserContext);

    const [modifyActivities, setModifyActivities] = useState(null);
    const [showModifyActivities, setShowModifyActivities] = useState(false);

    const {t} = useTranslation();

    return (
        <>
            <div id="account-info">
                <h2 className="text-4xl text-center"> {t("account_info.profile")} </h2>
                <div className="mb-5"></div>

                {user.value.isTemporary ?
                    <TemporaryAccountWarning/>
                    : <UserInfo/>
                }

                <div className="mb-3"></div>

                <PossibleUserActions/>

                {(!user.value.isVerified && !user.value.isTemporary) ? <EmailNotVerifiedWarning/> : ""}

                <Spacer/>

                <InteractiveProgressGrid/>

                <Spacer/>

                <h3 className="text-2xl">
                    <span className="absolute"><FontAwesomeIcon icon={faBookmark}/></span>
                    <span className="pl-8">{t("account_info.bookmarks")}</span>
                </h3>
                <div className="mb-2"></div>

                <BookmarksList/>

                <Spacer/>

                <h3 className="text-2xl">
                    <span className="absolute"><FontAwesomeIcon icon={faCalendar}/></span>
                    <span className="pl-8">{t("account_info.sessions")}</span>
                </h3>
                <div className="mb-2"></div>

                <ActivitiesList
                    setModifyActivities={setModifyActivities}
                    setShowModifyActivities={setShowModifyActivities}
                />
            </div>

            <ModifyActivitiesModal
                activities={modifyActivities}
                onClose={() => setShowModifyActivities(false)}
                show={showModifyActivities}
            />
        </>
    );
}