import {useTranslation} from "react-i18next";
import Footer from "../../common/Footer";

export default function SideAboutUs() {
    const {t} = useTranslation();

    return (
        <>
            <h2 className="text-4xl text-center">{t("about_us.about_us")}</h2>
            <div className="mb-6"></div>

            <div className="text-justify hyphens-auto">
                <p>{t("about_us.aim_1")}</p>
                <div className="mb-3"></div>
                <p>{t("about_us.aim_2")}</p>
                <div className="mb-5"></div>

                <Footer tight={true} padding={"pt-2"}/>
            </div>
        </>
    );
}
