import {Bar, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Window from "../../../common/Window";
import {UIColor} from "../../../Config";
import {useTranslation} from "react-i18next";
import {WallContext} from "../../../contexts/WallContext";
import {useContext} from "react";
import {BasicButton} from "../../../common/Button";
import {UiActions, UiDispatchContext} from "../../../contexts/UiContext";

const HOVER_COLOR = "#404040";

function getContrastingColor(hex) {
    // Convert hex to RGB
    let r = parseInt(hex.substring(1, 3), 16);
    let g = parseInt(hex.substring(3, 5), 16);
    let b = parseInt(hex.substring(5, 7), 16);

    // Calculate relative luminance
    let luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black for bright colors, white for dark colors
    return luminance > 0.5 ? UIColor.Window : UIColor.LightInput;
}

export default function CircuitBreakdownChart() {
    const {t} = useTranslation();
    const wall = useContext(WallContext);

    const uiStateDispatch = useContext(UiDispatchContext);

    const routes = wall.value.routes.filter(route => route.circuit);

    if (!wall.value.hasCircuits()) {
        return <>
            <h3 className="text-2xl text-center">{t("statistics.color_breakdown_chart.name")}</h3>
            <p className={`${UIColor.MinorText} italic text-center`}>{t("statistics.color_breakdown_chart.description")}</p>
            <div className="p-2"></div>
            <div className="text-center p-4">
                <BasicButton
                    onClick={() => {
                        uiStateDispatch({type: UiActions.SetSidePanelPageIdx, pageName: 'management'});
                    }}
                >{t("statistics.color_breakdown_chart.add_circuits")}</BasicButton>
            </div>
        </>;
    }

    const colorData = wall.value.circuits.map(circuit => {
        const filteredRoutes = routes.filter(route => route.circuit === circuit.name);

        return {
            [circuit.name]: filteredRoutes.length,
            colorHex: circuit.color,
        };
    });

    const customTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            const dataPoint = payload[0].payload;
            const [name, value] = Object.entries(dataPoint)[0];

            return (
                <Window className="p-3" width="w-fit" color={getContrastingColor(dataPoint.colorHex)}>
                    <p className="text-xl pb-2" style={{color: dataPoint.colorHex}}>
                        <span className="font-bold">{name}</span> ({value})
                    </p>
                </Window>
            );
        }
        return null;
    };

    return (
        <>
            <h3 className="text-2xl text-center">{t("statistics.color_breakdown_chart.name")}</h3>
            <p className={`${UIColor.MinorText} italic text-center`}>{t("statistics.color_breakdown_chart.description")}</p>
            <div className="p-2"></div>
            <ResponsiveContainer className="w-full" height={300}>
                <BarChart data={colorData}>
                    <XAxis/>
                    <YAxis allowDecimals={false}/>
                    <Tooltip content={customTooltip} cursor={{fill: HOVER_COLOR}}/>
                    {colorData.map(key => {
                        const [name, value] = Object.entries(key)[0];
                        return <Bar key={name} dataKey={name} fill={key.colorHex} stackId="grades"/>
                    })}
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}
