import {BasicButton} from "./Button";
import {UIColor} from "../Config";
import SpinLoadingIndicator from "./SpinLoadingIndicator";
import {useTranslation} from "react-i18next";

export default function SubmitDiscardButtons(
    {
        onSubmit,
        onDiscard,
        submitEnabled = true,
        discardEnabled = true,
        isLoading = false,
    }
) {
    const {t} = useTranslation();

    return (
        <div className="flex gap-2">
            <BasicButton
                buttonColor={UIColor.Green}
                disabled={!submitEnabled || isLoading}
                onClick={onSubmit}
                className="w-full transition-colors"
                type="submit"
            >
                {isLoading ? <SpinLoadingIndicator/> : <>{t("submit_discard_buttons.submit")}</>}
            </BasicButton>

            <BasicButton
                buttonColor={UIColor.Red}
                className="w-full transition-colors"
                onClick={onDiscard}
                disabled={!discardEnabled || isLoading}
                type="button"
            >
               {t("submit_discard_buttons.discard")}
            </BasicButton>
        </div>
    );
}
