import {useContext, useEffect, useState} from "react";
import {WallContext} from "../contexts/WallContext";
import {UIColor} from "../Config";
import {useTranslation} from "react-i18next";

export default function CircuitPicker(
    {
        onChange,
        selectedCircuit,
        setSelectedCircuit,
        multi = false
    }
) {
    const wall = useContext(WallContext);
    const {t} = useTranslation();

    const [circuits, setCircuits] = useState([]);
    const [internalSelectedCircuits, setInternalSelectedCircuits] = useState(multi ? [] : null);

    useEffect(() => {
        setCircuits(wall.value.circuits);
    }, [wall]);

    useEffect(() => {
        const newCircuit = multi ? [] : null;
        if (!selectedCircuit) {
            setInternalSelectedCircuits(newCircuit);
        }
    }, [selectedCircuit, setSelectedCircuit, multi]);

    function handleSelect(circuit) {
        let newValue;
        if (selectedCircuit === undefined) {
            if (multi) {
                newValue = internalSelectedCircuits.includes(circuit)
                    ? internalSelectedCircuits.filter(c => c !== circuit)
                    : [...internalSelectedCircuits, circuit];
            } else {
                newValue = internalSelectedCircuits === circuit ? null : circuit;
            }
            setInternalSelectedCircuits(newValue);
            onChange?.(newValue);
        } else {
            // Controlled mode
            if (multi) {
                newValue = selectedCircuit.includes(circuit)
                    ? selectedCircuit.filter(c => c !== circuit)
                    : [...selectedCircuit, circuit];
            } else {
                newValue = selectedCircuit === circuit ? null : circuit;
            }
            setSelectedCircuit?.(newValue);
            onChange?.(newValue);
        }
    }

    const selected = selectedCircuit !== undefined ? selectedCircuit : internalSelectedCircuits;

    return (
        <div className="flex gap-2 rounded w-full">
            {
                circuits.length === 0 ? (
                    <div className={`${UIColor.MinorText} w-full`}>{t("circuit_picker.no_circuits")}</div>
                ) : (
                    circuits.map((circuit, index) => (
                        <div
                            key={index}
                            className={`w-6 h-6 cursor-pointer rounded transition-all
                              ${multi ? ((selected.includes(circuit.name) || selected.length === 0) ? "flex-grow" : "opacity-35 hover:opacity-50")
                                : (selected === circuit.name || selected === null ? "flex-grow" : "opacity-35 hover:opacity-50")}
                              ${(selected === null || (multi && selected.length === 0)) ? "opacity-35 hover:opacity-50" : ""}
                            `}
                            style={{backgroundColor: circuit.color}}
                            onClick={() => handleSelect(circuit.name)}
                        />
                    ))
                )
            }
        </div>
    );
}
