import {UIColor} from "../Config";
import React, {forwardRef} from "react";


const Window = forwardRef(
    ({
         children,
         className = "",
         id,
         justify = false,
         color = UIColor.Window,
         overflow = "scroll",
         shadow = "shadow-large",
         width,
         rounded = "rounded-2xl",
         onClick,
         tint,
         ...kwargs
     }, ref) => {
        return (
            <div
                onClick={onClick}
                ref={ref}
                className={`${className} ${justify ? 'text-justify hyphens-auto' : ''} text-white max-h-[calc(100%-2rem)] ${
                    width ?? 'w-[calc(100%-2rem)] sm:w-96'
                } ${color} ${rounded} ${shadow} overflow-${overflow}`}
                id={id}
                key={id}
                {...kwargs}
            >
                <div className={`transition-all ${tint ? `${tint} bg-opacity-10` : "bg-opacity-0"}`}>
                    {children}
                </div>
            </div>
        );
    });

export default Window;