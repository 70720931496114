import {useContext, useState} from "react";
import TextInput from "../common/TextInput";
import {UIColor} from "../Config";
import BasicSelect from "../common/Select";
import {useTranslation} from "react-i18next";
import SubmitDiscardButtons from "../common/SubmitDiscardButtons";
import {WallActions, WallContext, WallDispatchContext} from "../contexts/WallContext";
import {GRADE_SYSTEMS} from "../model/Climbing";
import Expandable from "../common/Expandable";

export default function GradingSystemSelector() {
    const {t} = useTranslation();

    const wall = useContext(WallContext);
    const wallDispatch = useContext(WallDispatchContext);

    const [grading, setGrading] = useState(wall.value.grading);

    const [validationState, setValidationState] = useState({
        min: {parse: false, error: false},
        max: {parse: false, error: false},
    });

    const [initialGrading, setInitialGrading] = useState(grading);

    const handleTypeChange = (e) => {
        const type = e.target.value;
        const newGrading = {
            type,
            min: GRADE_SYSTEMS[type].defaultMin,
            max: GRADE_SYSTEMS[type].defaultMax,
        };
        setGrading(newGrading);
        setValidationState({
            min: {parse: true, error: false},
            max: {parse: true, error: false},
        });
    };

    const handleGradeChange = (field, value) => {
        const system = GRADE_SYSTEMS[grading.type];
        const isValid = system.isValid(value);

        setValidationState((prevValidation) => {
            const updatedValidation = {
                ...prevValidation,
                [field]: {parse: isValid, error: !isValid},
            };

            if (updatedValidation.min.parse && updatedValidation.max.parse) {
                if (field === "min") {
                    updatedValidation.max.error = updatedValidation.min.error = system.compare(value, grading.max) >= 0;
                } else {
                    updatedValidation.min.error = updatedValidation.max.error = system.compare(grading.min, value) >= 0;
                }
            }

            return updatedValidation;
        });

        setGrading((prev) => ({...prev, [field]: value}));
    };

    async function onSubmit(event) {
        event.preventDefault();

        let newWall = wall.value.shallowClone();
        newWall.grading = grading;

        wallDispatch({
            type: WallActions.SetMetadata,
            metadata: newWall.metadata,
            postSuccessHooks: () => {
                setInitialGrading(grading);
            }
        });
    }

    const onDiscard = () => {
        setGrading(initialGrading);
        setValidationState({
            min: {parse: false, error: false},
            max: {parse: false, error: false},
        });
    };

    const gradingOptions = [
        {value: "fontainebleau", label: t("grade_system_selector.fontainebleau")},
        {value: "v", label: t("grade_system_selector.v")},
        {value: "numeric", label: t("grade_system_selector.numeric")},
    ];

    const hasValidationErrors = validationState.min.error || validationState.max.error;
    const isModified = JSON.stringify(grading) !== JSON.stringify(initialGrading);

    const loading = wall.status.isLoading(WallActions.SetMetadata);

    return <div className="rounded-xl bg-neutral-700 px-3 py-3">
        <form>
            <div className="flex gap-2 items-center">
                <label>{t("grade_system_selector.system")}:</label>
                <BasicSelect
                    name="grading"
                    width="min-w-16 flex-grow"
                    color={UIColor.Default}
                    shadow={true}
                    padding="px-2 py-1"
                    value={grading.type}
                    onChange={handleTypeChange}
                    options={gradingOptions}
                />
            </div>
            <div className="m-2"/>
            <div className="flex gap-2 items-center">
                <label>{t("grade_system_selector.from")}</label>
                <TextInput
                    value={grading.min}
                    shadow={true}
                    className="flex-grow transition-colors"
                    padding="px-2 py-1"
                    width="min-w-8"
                    onChange={(e) => handleGradeChange("min", e.target.value)}
                    placeholder={t("grade_system_selector.min_grade")}
                    color={validationState.min.error ? UIColor.Red : UIColor.Default}
                />
                <label>{t("grade_system_selector.to")}</label>
                <TextInput
                    value={grading.max}
                    className="flex-grow transition-colors"
                    padding="px-2 py-1"
                    width="min-w-8"
                    shadow={true}
                    onChange={(e) => handleGradeChange("max", e.target.value)}
                    placeholder={t("grade_system_selector.max_grade")}
                    color={validationState.max.error ? UIColor.Red : UIColor.Default}
                />
            </div>

            <Expandable isOpen={isModified}>
                <div className={"m-5"}/>
                <SubmitDiscardButtons
                    onSubmit={onSubmit}
                    onDiscard={onDiscard}
                    submitEnabled={!hasValidationErrors && isModified}
                    discardEnabled={!loading && isModified}
                    isLoading={loading}
                />
            </Expandable>
        </form>
    </div>;
}
