import {createContext} from "react";
import {backend} from "../model/Backend";
import {unimplementedFunction} from "../common/Utils";
import {ActionWithSideEffects, SimpleAction, useReducer} from "./StateManagement";

const LeaderboardContext = createContext(null);
const LeaderboardDispatchContext = createContext(unimplementedFunction);

class LeaderboardActions {
    static Update = "update";
}

function createLeaderboardAction(leaderboard, actionData) {
    if (actionData.type === LeaderboardActions.Update) {
        return new ActionWithSideEffects(
            backend.leaderboard(),
            actionData.trackingId ?? LeaderboardActions.Update,
            (leaderboard, result) => {
                leaderboard.value = result.data;
                return leaderboard;
            },
            actionData.postSuccessHooks
        );
    }

    console.error("Unknown action type:", actionData.type);
    return new SimpleAction(state => state);
}

function useLeaderboardReducer() {
    // Using our generic useReducer with tracking enabled
    return useReducer(createLeaderboardAction, null, true);
}

export { LeaderboardContext, LeaderboardDispatchContext, LeaderboardActions, useLeaderboardReducer };
