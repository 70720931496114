const Expandable = ({isOpen, children, duration="duration-300"}) => {
    return (
        <div
            className={`overflow-hidden transition-all ${duration} ${
                isOpen ? "opacity-100 max-h-screen" : "opacity-0 max-h-0"
            }`}
        >
            {children}
        </div>
    );
};

export default Expandable;
