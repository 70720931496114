import {useContext, useEffect, useState} from "react";
import {GRADE_SYSTEMS} from "../model/Climbing";
import {WallContext} from "../contexts/WallContext";
import {DualRangeSlider} from "./RangeSliders";

export default function GradeRangePicker({onChange, selectedGrades, setSelectedGrades}) {
    const wall = useContext(WallContext);

    const [values, setValues] = useState([]);

    useEffect(() => {
        let grading = wall.value.grading;

        const system = GRADE_SYSTEMS[grading.type];
        const values = system.enumerate(grading.min, grading.max);

        setValues(values);
    }, [wall]);

    return <DualRangeSlider
        allValues={values}
        values={selectedGrades}
        setValues={setSelectedGrades}
        onChange={onChange}
    />
}
