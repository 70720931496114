import {BasicButton} from "../../common/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default function PhasePanelButton({color, text, icon, ...props}) {
    return (
        <BasicButton
            className="text-xl shadow-medium rounded-xl"
            round={false}
            buttonColor={color}
            {...props}
        >
            <p className={"flex gap-3 items-center truncate"}>
                <span>{text}</span>
                <FontAwesomeIcon icon={icon}/>
            </p>
        </BasicButton>
    );
}
