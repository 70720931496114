import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Window from "../../../common/Window";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteStateType} from "../../../model/User";
import {UIColor} from "../../../Config";
import {useTranslation} from "react-i18next";
import {WallContext} from "../../../contexts/WallContext";
import {useContext} from "react";
import {GRADE_SYSTEMS} from "../../../model/Climbing";

const KEYS = [RouteStateType.Attempted, RouteStateType.Climbed, RouteStateType.Flashed];

const HEX_COLORS = {
    [RouteStateType.Attempted]: "#ef4444",
    [RouteStateType.Climbed]: "#059669",
    [RouteStateType.Flashed]: "#047857",
    "count": "#e5e7eb",  // no data
};

const HOVER_COLOR = "#404040";

const TEXT_COLORS = {
    [RouteStateType.Attempted]: "text-rose-400",
    [RouteStateType.Climbed]: "text-emerald-300",
    [RouteStateType.Flashed]: "text-emerald-500",
};

export default function GradeBreakdownChart({top = true}) {
    const {t} = useTranslation();

    const wall = useContext(WallContext);

    const validRoutes = wall.value.routes.filter(route => route.grade);

    // default to font if wall doesn't have it set, not much to do otherwise
    let system = GRADE_SYSTEMS[wall.value.grading.type];

    const allGrades = system.enumerate(wall.value.grading.min, wall.value.grading.max);

    // Accumulate routes per difficulty
    const gradeCounts = validRoutes.reduce((acc, route) => {
        acc[route.grade] = (acc[route.grade] || 0) + 1;
        return acc;
    }, {});

    // Then accumulate their states
    const stateCountsByGrade = validRoutes.reduce((acc, route) => {
        if (!acc[route.grade]) {
            acc[route.grade] = {
                [RouteStateType.Flashed]: 0,
                [RouteStateType.Climbed]: 0,
                [RouteStateType.Attempted]: 0
            };
        }

        acc[route.grade][RouteStateType.Flashed] += route.flashedCount || 0;
        acc[route.grade][RouteStateType.Climbed] += route.climbedCount || 0;
        acc[route.grade][RouteStateType.Attempted] += route.attemptedCount || 0;

        return acc;
    }, {});

    const formattedData = allGrades.map(grade => {
        const flashed = stateCountsByGrade[grade]?.[RouteStateType.Flashed] || 0;
        const climbed = stateCountsByGrade[grade]?.[RouteStateType.Climbed] || 0;
        const attempted = stateCountsByGrade[grade]?.[RouteStateType.Attempted] || 0;
        const total = flashed + climbed + attempted;

        const allZero = total === 0;

        const flashedPercentage = allZero ? 0 : (flashed / total);
        const climbedPercentage = allZero ? 0 : (climbed / total);
        const attemptedPercentage = allZero ? 0 : (attempted / total);

        // I want to display percentages if some are non-zero, and routes if they all are
        // the bar looks at data.count, the labels look at data.countZero
        const countZero = gradeCounts[grade] || 0;

        return {
            grade,
            count: allZero ? countZero : 0,
            countZero,
            [RouteStateType.Flashed]: countZero * flashedPercentage,
            [RouteStateType.Climbed]: countZero * climbedPercentage,
            [RouteStateType.Attempted]: countZero * attemptedPercentage,
            flashedPercentage,
            climbedPercentage,
            attemptedPercentage,
            allZero
        };
    });

    const customTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            const dataPoint = payload[0].payload;

            return (
                <Window className={"p-3"} width={"w-fit"}>
                    <p className={"text-xl pb-2"}>
                        <span className={"font-bold"}>{dataPoint.grade}</span> ({dataPoint.countZero})
                    </p>
                    {dataPoint.allZero ? (
                        <p>{t("statistics.grade_breakdown_chart.no_data")}</p>
                    ) : (
                        <>
                            <p>
                                <span
                                    className={TEXT_COLORS[RouteStateType.Flashed]}>{t("route_state_type")[RouteStateType.Flashed]}:</span>{" "}
                                {(dataPoint.flashedPercentage * 100).toFixed(1)}%
                            </p>
                            <p>
                                <span
                                    className={TEXT_COLORS[RouteStateType.Climbed]}>{t("route_state_type")[RouteStateType.Climbed]}:</span>{" "}
                                {(dataPoint.climbedPercentage * 100).toFixed(1)}%
                            </p>
                            <p>
                                <span
                                    className={TEXT_COLORS[RouteStateType.Attempted]}>{t("route_state_type")[RouteStateType.Attempted]}:</span>{" "}
                                {(dataPoint.attemptedPercentage * 100).toFixed(1)}%
                            </p>
                        </>
                    )}
                    <div className={"p-1"}/>
                    <p className={`${UIColor.MinorText} whitespace-pre-line`}>{t("statistics.grade_breakdown_chart.tooltip_explanation_1")}</p>
                    <p className={`${UIColor.MinorText} whitespace-pre-line`}>{t("statistics.grade_breakdown_chart.tooltip_explanation_2")}</p>
                </Window>
            );
        }
        return null;
    };

    const customLegend = () => (
        <div className="flex flex-wrap justify-center gap-x-5 gap-y-1 mt-2">
            {KEYS.map((key) => (
                <div key={key} className={`flex items-center gap-2 ${TEXT_COLORS[key]}`}>
                    <FontAwesomeIcon icon={RouteStateType.Icon(key)}/>
                    <span>{t("route_state_type")[key]}</span>
                </div>
            ))}
        </div>
    );

    return (
        <>
            {top ? <>
                <h3 className="text-2xl text-center">{t("statistics.grade_breakdown_chart.name")}</h3>
                <p className={`${UIColor.MinorText} italic text-center`}>{t("statistics.grade_breakdown_chart.description")}</p>
            </> : <></>}
            <div className="p-2"></div>
            <ResponsiveContainer className="w-full" height={300}>
                <BarChart data={formattedData}>
                    <XAxis dataKey="grade"/>
                    <YAxis allowDecimals={false}/>
                    <Tooltip content={customTooltip} cursor={{fill: HOVER_COLOR}}/>
                    <Legend content={customLegend}/>
                    {KEYS.map((key) => (
                        <Bar key={key} dataKey={key} stackId="grades" fill={HEX_COLORS[key]}/>
                    ))}
                    <Bar key={"count"} dataKey={"count"} stackId="grades" fill={HEX_COLORS["count"]}/>
                </BarChart>
            </ResponsiveContainer>
        </>
    );
}
