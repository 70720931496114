import ModalWindow from "../../common/ModalWindow";
import {useEffect} from "react";
import {UserActions, useUserReducer} from "../../contexts/UserContext";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {useTranslation} from "react-i18next";

export default function VerifyEmailModal() {
    const [user, userDispatch] = useUserReducer();

    const {t, i18n} = useTranslation();

    const urlParams = new URLSearchParams(window.location.search);

    let token = null;
    if (urlParams.has("token")) {
        token = urlParams.get("token");
    }

    let userId = null;
    if (urlParams.has("userId")) {
        userId = urlParams.get("userId");
    }

    useEffect(() => {
        if (token !== null && userId !== null) {
            userDispatch({
                type: UserActions.VerifyEmail,
                token: token,
                userId: userId,
            });
        }
    }, []);

    let error = user.status.getError(UserActions.VerifyEmail);
    let verifying = user.status.isLoading(UserActions.VerifyEmail);

    let sheep = `
 ______________
< Go climbing! >
 --------------
  \\
   \\
       __
      UooU\\.'@@@@@@\`.
      \\__/(@@@@@@@@@@)
           (@@@@@@@@)
           \`YY~~~~YY'
            ||    ||
    `

    let title;
    if (verifying) {
        title = <div className="flex gap-3 items-center">
            <p className="text-2xl">{t("verify_email.verifying")}</p>
            <div className="w-fit">
                <SpinLoadingIndicator/>
            </div>
        </div>;
    } else if (token === null || userId === null) {
        title = <p className="text-2xl">{t("verify_email.verify_email")}</p>;
    } else if (!verifying && !error) {
        title = <p className="text-2xl">{t("verify_email.success")}</p>;
    } else {
        title = <p className="text-2xl">{t("verify_email.failure")}</p>;
    }

    return <ModalWindow titleElement={<div className="flex justify-around">{title}</div>}>
        {
            (token === null || userId === null) ? <p>
                <span className='font-bold text-xl'>{t("error.no_token_or_user_id")}</span>
                <div className="mb-4"></div>
                <span>{t("error.how_did_you_get_here")}</span>
                <div className="flex items-center justify-center">
                <pre>
                    {sheep}
                </pre>
                </div>
            </p> : <>
                {verifying ? <>
                    <p>{t("verify_email.verifying_longer")}</p>
                    <div className="mb-4"></div>
                    <p className={`${UIColor.MinorText} text-xs`}><span className="pr-2">Token:</span><code
                        className={`bg-neutral-900 rounded py-0.5 px-1`}>{token}</code></p>
                    <div className="mb-1"></div>
                    <p className={`${UIColor.MinorText} text-xs`}><span className="pr-2">User ID:</span><code
                        className={`bg-neutral-900 rounded py-0.5 px-1`}>{userId}</code></p>
                </> : ''}
                {error ? <ErrorFromBackendResult data={error} spacing={""}/> : ''}
                {(!verifying && !error) ? <>
                    <p className="font-bold">{t("verify_email.success_longer_1")}</p>
                    <div className="mb-4"></div>
                    <p>{t("verify_email.success_longer_2")}</p>
                </> : ''}
            </>
        }

    </ModalWindow>;
}
