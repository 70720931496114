import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {UserActivity, UserActivityType} from "../model/User";
import {faThumbsDown, faThumbsUp,} from '@fortawesome/free-solid-svg-icons';
import {faThumbsDown as farThumbsDown, faThumbsUp as farThumbsUp} from "@fortawesome/free-regular-svg-icons";
import {UserActions, UserContext} from "../contexts/UserContext";
import {useContext} from "react";

const LikeDislikeBar = ({route, enableActivity}) => {
    const user = useContext(UserContext);

    const likeActivity = user?.value?.getActivity(UserActivityType.Like, route);
    const dislikeActivity = user?.value?.getActivity(UserActivityType.Dislike, route);

    const totalCount = route.likes + route.dislikes;
    const likePercentage = `${(route.likes / (totalCount === 0 ? 1 : totalCount)) * 100}%`;

    const setLike = () => enableActivity(likeActivity, dislikeActivity, UserActivityType.Like, UserActivityType.Dislike);
    const setDislike = () => enableActivity(dislikeActivity, likeActivity, UserActivityType.Dislike, UserActivityType.Like);

    let isLoading = false;

    for (const activityType of [UserActivityType.Like, UserActivityType.Dislike]) {
        for (const actionType of [UserActions.AddActivities, UserActions.RemoveActivities, UserActions.ModifyActivities]) {
            isLoading ||= user.status.isLoading(UserActivity.getTrackingId(route, activityType, actionType));
        }
    }

    return (
        <div key={"like-dislike-bar"} className="flex flex-col items-center">
            <div className="text-2xl flex divide-x-2 divide-white divide-opacity-20">
                <div
                    className={`text-white text-opacity-100 hover:text-opacity-50 ${(isLoading || dislikeActivity) ? 'opacity-50' : 'opacity-100'} transition-all flex items-center px-5 gap-2 ${isLoading ? "" : "cursor-pointer"}`}
                    onClick={isLoading ? undefined : setLike}>
                    <FontAwesomeIcon icon={likeActivity ? faThumbsUp : farThumbsUp}/>
                    <span className="text-xl font-bold">{route.likes}</span>
                </div>
                <div
                    className={`text-white text-opacity-100 hover:text-opacity-50 ${isLoading || likeActivity ? 'opacity-50' : 'opacity-100'} transition-all flex items-center px-5 gap-2 ${isLoading ? "" : "cursor-pointer"}`}
                    onClick={isLoading ? undefined : setDislike}>
                    <FontAwesomeIcon icon={dislikeActivity ? faThumbsDown : farThumbsDown}/>
                    <span className="text-xl font-bold">{route.dislikes}</span>
                </div>
            </div>
            <div className="mb-2"></div>
            <div className="rounded-full h-1.5 bg-gray-500" style={{width: '100%'}}>
                <div className="h-1.5 rounded-full transition-all bg-gray-200" style={{width: likePercentage}}></div>
            </div>
        </div>
    );
};

export default LikeDislikeBar;
