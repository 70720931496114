import React, {useContext, useRef} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faArrowLeft,
    faBars,
    faChartColumn,
    faCircleQuestion,
    faGears,
    faHouse,
    faLanguage,
    faRankingStar,
    faUser,
    faUserClock
} from '@fortawesome/free-solid-svg-icons'

import SideOverview from "./overview/SideOverview";
import SidePersonal from "./account/SidePersonal";
import {UserContext} from "../contexts/UserContext";
import OwnerManagement from "./account/OwnerManagement";
import {ProposalContext} from "../contexts/ProposalContext";
import {UiActions, UiContext, UiDispatchContext} from "../contexts/UiContext";
import Window from "../common/Window";
import SideAboutUs from "./account/SideAboutUs";
import {BasicButton} from "../common/Button";
import SideLeaderboard from "./account/SideLeaderboard";
import {ScreenDimensions, useScreenDimensions} from "../common/CustomHooks";
import {UIColor} from "../Config";
import OwnerStats from "./account/OwnerStats";
import SideLanguages from "./account/SideLanguages";
import Logo from "../common/Logo";

export default function SidePanel() {
    const [uiState, uiStateDispatch] = [useContext(UiContext), useContext(UiDispatchContext)];
    const user = useContext(UserContext);
    const proposal = useContext(ProposalContext);

    const sidePanel = useRef();

    const pages = [
        {
            element: <SideOverview/>,
            icon: faHouse,
            id: 'name',
        },
        {
            element: <SidePersonal/>,
            icon: faUser,
            id: 'personal',
        },
        {
            element: <SideLeaderboard/>,
            icon: faRankingStar,
            id: 'leaderboard',
        },
        null,  // spacer
        {
            element: <SideLanguages/>,
            icon: faLanguage,
            id: 'languages',
            small: true,
        },
        {
            element: <SideAboutUs/>,
            icon: faCircleQuestion,
            id: 'about',
            small: true,
        },
    ];

    if (user.value?.isOwner) {
        pages.splice(
            pages.length - 2,
            0,
            {
                element: <OwnerManagement/>,
                icon: faGears,
                id: 'management',
            },
            {
                element: <OwnerStats/>,
                icon: faChartColumn,
                id: 'stats',
            },
            null,  // spacer
        );
    }

    let index = 0;
    if (uiState.sidePanelPageIdx) {
        index = uiState.sidePanelPageIdx;
    } else if (uiState.sidePanelPageName) {
        index = pages.findIndex(page => page?.id === uiState.sidePanelPageName);

        if (index === -1) {
            console.error("Unknown side panel page name: " + uiState.sidePanelPageName);
            index = 0;
        }
    }

    // Safeguard for when the user logs out while the side panel is mounted.
    if (index >= pages.length) {
        uiStateDispatch({type: UiActions.SetSidePanelPageIdx, pageIdx: pages.length - 1});
    }

    function onCloseWrapper(e) {
        if (e.target !== e.currentTarget)
            return;

        uiStateDispatch({type: UiActions.CloseSidePanel})
    }

    let window = <Window
        id="side-navigation"
        className={`absolute z-20 ${uiState.sidePanelOpen ? "left-4" : "-left-full opacity-0 sm:-left-96 select-none"} top-4 overflow-x-hidden transition-all`}>
        <div id="side-navigation-bar" className="sticky top-0 z-20">
            <div
                className={`${UIColor.Default} pt-4 px-5 pb-5 flex justify-between items-center space-x-4`}
            >
                <Logo/>
                <FontAwesomeIcon icon={faArrowLeft} size="2x" className="cursor-pointer pr-2"
                                 onClick={() => uiStateDispatch({type: UiActions.CloseSidePanel})}
                />
            </div>
            <div id="bottom-navigation"
                 className="box-border flex justify-between flex-row align-baseline w-full text-center divide-x-2 divide-neutral-600"
            >
                {pages.map((item, idx) => {
                    if (item === null) {
                        return <div key={`spacer-${idx}`} className={`w-3 ${UIColor.Default}`}/>; // Spacer styling
                    }

                    // last sections are shrunk a bit since they're not important
                    return (
                        <button
                            key={item.id}
                            className={`${item.small ? "w-10" : "flex-1"} cursor-pointer nav-btn flex flex-col items-stretch transition-colors py-3 ${
                                idx === index ? "bg-neutral-800" : "bg-neutral-700 hover:bg-neutral-500"
                            }`}
                            onClick={() => uiStateDispatch({type: UiActions.SetSidePanelPageIdx, pageIdx: idx})}
                        >
                            <FontAwesomeIcon icon={item.icon} size="lg"/>
                        </button>
                    );
                })}
            </div>
        </div>
        <div id={`side-content-${pages[index].id}`} className="p-6">
            {pages[index].element}
        </div>
    </Window>;

    const [screenWidth, screenHeight] = useScreenDimensions();
    const onMobile = (screenWidth <= ScreenDimensions.MOBILE_WIDTH);

    const reviewingProposal = proposal !== null && proposal.beingReviewed;

    // should signify the state -- not logged in, temporary, logged in
    let buttonIcon = user.value === null ? faBars :
        user.value.isTemporary ? faUserClock : faUser;

    let buttonColor = UIColor.Default;
    if (user.value) {
        if (user.value.isTemporary) {
            buttonColor = UIColor.DarkYellow;
        } else {
            buttonColor = UIColor.Default;
        }
    }

    return (
        <>
            {!reviewingProposal && !uiState.sidePanelOpen &&
                <BasicButton
                    key="open-navigation-button"
                    padding="px-3 py-1.5"
                    // Default the page index to home page when not logged in, otherwise default to profile
                    onClick={() => uiStateDispatch({
                        type: UiActions.OpenSidePanel,
                        pageIdx: user.value === null ? 0 : 1
                    })}
                    className="text-2xl absolute top-4 left-4 rounded-2xl z-0 shadow-medium"
                    buttonColor={buttonColor}
                >
                    <FontAwesomeIcon
                        icon={buttonIcon}
                    />
                </BasicButton>
            }
            {!reviewingProposal &&
                (
                    <div
                        className={(uiState.sidePanelOpen && onMobile) ? "fixed top-0 left-0 w-full h-full z-40 flex justify-center items-center bg-black/50" : ""}
                        ref={sidePanel}
                        onClick={onCloseWrapper}
                    >
                        {window}
                    </div>
                )
            }

        </>);
}