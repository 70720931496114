import {faBookmark, faPenToSquare} from "@fortawesome/free-solid-svg-icons";
import {faBookmark as farBookmark} from "@fortawesome/free-regular-svg-icons";
import {useContext, useEffect, useState} from "react";
import {UserActions, UserContext} from "../../contexts/UserContext";
import {RouteStateType, UserActivity, UserActivityType} from "../../model/User";
import {DateUtils} from "../../common/Utils";
import ModifyActivitiesModal from "../../side_panel/account/ModifyActivitiesModal";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {useTranslation} from "react-i18next";
import {WallActions, WallContext, WallDispatchContext} from "../../contexts/WallContext";
import CircuitTape from "../../common/CircuitTape";
import CircuitPicker from "../../settings/CircuitPicker";
import {UIColor} from "../../Config";
import GradePicker from "../../settings/GradePicker";
import SubmitDiscardButtons from "../../common/SubmitDiscardButtons";
import Expandable from "../../common/Expandable";

export default function RouteInfoList({route, onBookmarkClick, bookmarked}) {
    const user = useContext(UserContext);

    const wall = useContext(WallContext);
    const wallDispatch = useContext(WallDispatchContext);

    const labelStyles = "py-1 pr-6 font-semibold text-lg text-right align-middle truncate max-w-1 ";
    const valueStyles = "py-1 whitespace-nowrap ";
    const [showModifyActivity, setShowModifyActivity] = useState(null);

    const [dirty, setDirty] = useState(false);
    const [grade, setGrade] = useState(route.grade);
    const [circuit, setCircuit] = useState(route.circuit);

    const {t} = useTranslation();

    let routeCompletionActivity = null;
    if (user.value !== null) {
        let state = user.value.getRouteClimbingState(route);

        if (state === RouteStateType.Climbed || state === RouteStateType.Flashed)
            routeCompletionActivity = user.value.getActivity(state, route);
    }

    let circuitColor = wall.value.getCircuitHexColor(route.circuit);

    let editable = user.value?.isOwner;

    let isBookmarkLoading = false;
    for (const actionType of [UserActions.AddActivities, UserActions.RemoveActivities]) {
        isBookmarkLoading ||= user.status.isLoading(UserActivity.getTrackingId(route, UserActivityType.Bookmark, actionType));
    }

    useEffect(() => {
        setGrade(route.grade);
        setCircuit(route.circuit);
        setDirty(false);
    }, [route])

    async function onAccept(event) {
        event.preventDefault();

        wallDispatch({
            type: WallActions.UpdateRoute,
            routeId: route.id,
            metadata: {
                circuit: circuit,
                grade: grade,
            },
            postSuccessHooks: () => setDirty(false)
        });
    }

    return (
        <>
            <div id="route-info-list" className={`${UIColor.DarkInput} px-5 py-3 rounded-lg`}>
                <table className={"w-full"}>
                    <colgroup>
                        <col width="50%"/>
                        <col width="50%"/>
                    </colgroup>
                    <tbody>
                    {
                        route.creationUtcTimestamp ? <tr>
                            <td className={labelStyles}>{t("route_panel.set")}</td>
                            <td className={valueStyles}> {route.getCreationDateString()} </td>
                        </tr> : <></>
                    }
                    {
                        routeCompletionActivity !== null ? <tr>
                            <td className={labelStyles}>
                                {t("route_state_type")[routeCompletionActivity.type]}</td>
                            <td className={valueStyles}>
                                {DateUtils.toFormalDay(routeCompletionActivity.timestamp)}
                                <ClickableFontAwesomeIcon
                                    icon={faPenToSquare}
                                    className="text-xl pl-3 cursor-pointer"
                                    onClick={() => setShowModifyActivity(true)}
                                />
                            </td>
                        </tr> : <></>
                    }
                    <tr>
                        <td className={labelStyles + (isBookmarkLoading ? "" : "cursor-pointer")}
                            onClick={onBookmarkClick}>
                            {t("route_panel.bookmark")}
                        </td>
                        <td className={`${valueStyles} ${isBookmarkLoading ? "opacity-50" : "opacity-100"} transition-opacity`}>
                            <ClickableFontAwesomeIcon
                                icon={bookmarked ? faBookmark : farBookmark}
                                className={`${isBookmarkLoading ? "" : " cursor-pointer"} text-xl`}
                                onClick={onBookmarkClick}
                            />
                        </td>
                    </tr>
                    {
                        (circuitColor && !editable) ?
                            <tr>
                                <td className={labelStyles}>{t("route_panel.circuit")}</td>
                                <td className={`${valueStyles}`}>
                                    <div className={"w-full flex gap-2 items-baseline"}>
                                        <p className={"text-lg"} style={{color: circuitColor}}>{route.circuit}</p>
                                        <CircuitTape circuitColor={circuitColor}/>
                                    </div>
                                </td>
                            </tr> : <></>
                    }
                    {
                        editable ?
                            <>
                                <div className={"py-1.5"}/>
                                <tr>
                                    <td className={`${labelStyles} p-0 border-b-2 border-dashed border-white border-opacity-25`}/>
                                    <td className={`${valueStyles} p-0 border-b-2 border-dashed border-white border-opacity-25`}/>
                                </tr>
                                <div className={"py-1.5"}/>
                                {
                                    wall.value.hasCircuits() ?
                                        <tr>
                                            <td className={labelStyles}>{t("route_panel.circuit")}</td>
                                            <td className={`${valueStyles}`}>
                                                <CircuitPicker
                                                    selectedCircuit={circuit}
                                                    setSelectedCircuit={setCircuit}
                                                    onChange={() => setDirty(true)}
                                                />
                                            </td>
                                        </tr> : <></>
                                }
                                <tr>
                                    <td className={labelStyles}>{t("rebuild.add_phase.grade")}</td>
                                    <td className={`${valueStyles} pt-2`}>
                                        <GradePicker
                                            selectedGrade={grade}
                                            setSelectedGrade={setGrade}
                                            onChange={() => setDirty(true)}
                                        />
                                    </td>
                                </tr>
                            </>
                            : <></>
                    }
                    </tbody>
                </table>
            </div>

            <Expandable isOpen={dirty}>
                <div className={"py-1"}/>
                <SubmitDiscardButtons
                    onSubmit={onAccept}
                    onDiscard={() => {
                        setDirty(false);
                        setGrade(route.grade);
                        setCircuit(route.circuit);
                    }}
                    isLoading={wall.status.isLoading(WallActions.UpdateRoute)}
                />
            </Expandable>

            {
                routeCompletionActivity !== null ?
                    <ModifyActivitiesModal
                        activities={[routeCompletionActivity]}
                        onClose={() => setShowModifyActivity(false)}
                        show={showModifyActivity}
                    /> : <></>
            }
        </>
    );
}
