class CanvasState {
}

class TopDownState extends CanvasState {
    constructor(animate = true) {
        super();
        this.animate = animate;
    }
}

class RouteFocusState extends CanvasState {
    constructor(route) {
        super();
        this.route = route;
    }
}

class ProposalReviewState extends CanvasState {
}

export {CanvasState, TopDownState, RouteFocusState, ProposalReviewState};