import Window from "../../common/Window";
import {UIColor} from "../../Config";
import {faCheck, faGear, faTrash} from "@fortawesome/free-solid-svg-icons";
import Spacer from "../../common/Spacer";
import {useCallback, useContext, useRef, useState} from "react";
import PhasePanelButton from "./PhasePanelButton";
import CircuitPicker from "../../settings/CircuitPicker";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import GradePicker from "../../settings/GradePicker";
import ModalWindow from "../../common/ModalWindow";
import CircuitSystemSelector from "../../settings/CircuitSystemSelector";
import GradingSystemSelector from "../../settings/GradingSystemSelector";
import {Trans, useTranslation} from "react-i18next";
import {BasicButton} from "../../common/Button";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import {WallActions, WallContext, WallDispatchContext} from "../../contexts/WallContext";
import {backend} from "../../model/Backend";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import DynamicUploadButton from "../../common/DynamicUploadButton";
import TextLink from "../../common/TextLink";
import DraggableWindow from "../../common/DraggableWindow";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";

class UploadState {
    static Idle = 0;
    static Uploading = 1;
}


export default function AddPhasePanel({route, routeOrder}) {
    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const wallDispatch = useContext(WallDispatchContext);

    const wall = useContext(WallContext);

    const [state, setState] = useState(UploadState.Idle);

    const newHoldsMeshRef = useRef(null);
    const newRoutesRef = useRef(null);

    const [showCircuitAndGradeModal, setShowCircuitAndGradeModal] = useState(false);
    const [showAcceptProposalModal, setShowAcceptProposalModal] = useState(false);
    const [showRejectProposalModal, setShowRejectProposalModal] = useState(false);

    const [error, setError] = useState(null);

    const {t} = useTranslation();

    const onGradeChange = useCallback(grade => {
        if (route === null)
            return;

        route.grade = grade;
    }, [route]);

    const onCircuitChange = useCallback(circuit => {
        if (route === null)
            return;

        route.circuit = circuit;
    }, [route]);

    if (!routeOrder)
        return;

    let position = routeOrder.indexOf(route)
    let total = routeOrder.length;

    let last = position === total - 1;

    let addingRouteCount = proposal.getAddedRouteIDs().length;
    let removingRouteCount = proposal.getRemovedRouteIDs().length;

    let addingHoldCount = proposal.getAddedHoldIDs().length;
    let removingHoldCount = proposal.getRemovedHoldIDs().length;

    // TODO: to dispatch?
    async function onReject(event) {
        event.preventDefault();

        setState(UploadState.Uploading);
        const result = await backend.rejectProposal(proposal.metadata.id);
        setState(UploadState.Idle);

        if (!result.isSuccess()) {
            setError(result);
        } else {
            setError(null);
            setShowRejectProposalModal(false);

            proposalDispatch({type: ProposalActions.Reject});
        }
    }

    function uploadFunction(...args) {
        const newHoldsMesh = proposal.getNewMesh(wall.value);
        const newRoutes = proposal.getNewRoutes(wall.value);

        newHoldsMeshRef.current = newHoldsMesh;
        newRoutesRef.current = newRoutes;

        return backend.uploadProposal(proposal.metadata.id, newHoldsMesh, newRoutes, ...args)
    }

    return <>
        <ModalWindow
            title={t("rebuild.add_phase.grades_and_circuits")}
            show={showCircuitAndGradeModal}
            handleClose={() => setShowCircuitAndGradeModal(false)}
        >
            <GradingSystemSelector/>
            <div className={"m-5"}/>
            <CircuitSystemSelector/>
        </ModalWindow>

        <ModalWindow
            title={t("rebuild.add_phase.accept.title")}
            topColor={UIColor.Green} show={showAcceptProposalModal}
            handleClose={() => setShowAcceptProposalModal(false)}
        >
            <p className={"text-lg text-justify"}>
                <Trans
                    i18nKey="rebuild.add_phase.accept.effect"
                    components={{strong: <strong/>}}
                />
            </p>

            <Spacer spacing={"mt-6 mb-2"}/>

            <table className={"mx-auto text-lg border-spacing-0 border-separate"}>
                <tbody>
                <tr>
                    <td className={`border-none text-right pr-2 ${UIColor.RedText}`}>{t("rebuild.add_phase.accept.removing")}</td>
                    <td className={`border-none text-xl text-center`}><strong>{removingRouteCount}</strong></td>
                    <td className="border-none text-left pl-1">{t("rebuild.objects.route", {count: removingRouteCount})}</td>
                </tr>
                <tr>
                    <td></td>
                    <td className={`border-none text-xl text-center pb-3`}><strong>{removingHoldCount}</strong></td>
                    <td className="border-none text-left pl-1 pb-3">{t("rebuild.objects.hold", {count: removingHoldCount})}</td>
                </tr>
                <tr>
                    <td className={`border-none text-right pr-2 ${UIColor.GreenText}`}>{t("rebuild.add_phase.accept.adding")}</td>
                    <td className={`border-none text-xl text-center`}><strong>{addingRouteCount}</strong></td>
                    <td className="border-none text-left pl-1">{t("rebuild.objects.route", {count: addingRouteCount})}</td>
                </tr>
                <tr>
                    <td></td>
                    <td className={`border-none text-xl text-center`}><strong>{addingHoldCount}</strong></td>
                    <td className="border-none text-left pl-1">{t("rebuild.objects.hold", {count: addingHoldCount})}</td>
                </tr>
                </tbody>
            </table>

            <Spacer spacing={"mt-3 mb-8"}/>

            <div className="flex gap-2">
                <DynamicUploadButton
                    uploadFunction={uploadFunction}
                    buttonText={t("rebuild.add_phase.accept.accept")}
                    onStart={() => setState(UploadState.Uploading)}
                    onError={error => {
                        setError(error);
                        setState(UploadState.Idle);
                    }}
                    onAbort={() => {
                        setError(null);
                        setState(UploadState.Idle)
                    }}
                    onSuccess={() => {
                        setState(UploadState.Idle)

                        setShowAcceptProposalModal(false);

                        proposalDispatch({type: ProposalActions.Publish});
                        wallDispatch({
                            type: WallActions.OnProposalSuccess,
                            holdsMesh: newHoldsMeshRef.current,
                            routes: newRoutesRef.current
                        });
                    }}
                    className={"w-full"}
                />
                {
                    state !== UploadState.Uploading ?
                        <BasicButton className={"w-full"} onClick={() => setShowAcceptProposalModal(false)}>
                            {t("rebuild.add_phase.accept.cancel")}
                        </BasicButton> : <></>
                }
            </div>

            <ErrorFromBackendResult data={error}/>
        </ModalWindow>

        <ModalWindow
            title={t("rebuild.add_phase.reject.title")}
            topColor={UIColor.Red} show={showRejectProposalModal}
            handleClose={() => setShowRejectProposalModal(false)}
        >
            <p className={"text-lg text-justify"}>
                <Trans
                    i18nKey="rebuild.add_phase.reject.effect"
                    components={{
                        strong: <strong/>,
                        TextLink: <TextLink href={"mailto:contact@climbuddy.com"}/>
                    }}
                />
            </p>

            <div className="flex gap-2 pt-4">
                <BasicButton disabled={state === UploadState.Uploading} buttonColor={UIColor.Red} onClick={onReject}
                             className={"w-full"}>
                    {state !== UploadState.Uploading ? t("rebuild.add_phase.reject.reject") : <SpinLoadingIndicator/>}
                </BasicButton>
                <BasicButton className={"w-full"} onClick={() => setShowRejectProposalModal(false)}>
                    {t("rebuild.add_phase.reject.cancel")}
                </BasicButton>
            </div>

            <ErrorFromBackendResult data={error}/>
        </ModalWindow>

        <DraggableWindow
            overflow={"visible"}
            offsets={[30, 70]}
        >
            <div
                className={`absolute -translate-y-1/2 flex justify-center w-full gap-4`}>
                <PhasePanelButton
                    color={UIColor.Green}
                    onClick={() => setShowAcceptProposalModal(true)}
                    text={t("rebuild.add_phase.accept.accept")}
                    icon={faCheck}
                    disabled={!last}
                />

                <PhasePanelButton
                    color={UIColor.Red}
                    onClick={() => setShowRejectProposalModal(true)}
                    text={t("rebuild.add_phase.reject.reject")}
                    icon={faTrash}
                    disabled={!last}
                />
            </div>

            <div className={"px-3 py-6 pt-8"}>
                {
                    route ? <>
                            <p className={"text-lg text-center"}>
                                {t("rebuild.objects.route", {count: 1})}
                                <span className={"pl-1"}/>
                                <span className={"text-xl"}>
                        <strong>{position + 1}</strong> / {total}
                    </span>
                                <span className={`${UIColor.MinorText} pl-3`}>
                        (<strong>{route.holds.length}</strong> {t("rebuild.objects.hold", {count: route.holds.length})})
                    </span>
                            </p>
                            <Spacer spacing={"mt-4 mb-4"}/>

                            <div className={"w-full"}>

                                <div className={"flex gap-2 p-2 items-center"}>
                                    <p>{t("rebuild.add_phase.circuit")}:</p>
                                    <CircuitPicker
                                        selectedCircuit={route.circuit}
                                        setSelectedCircuit={onCircuitChange}
                                    />
                                    <ClickableFontAwesomeIcon
                                        icon={faGear}
                                        onClick={() => setShowCircuitAndGradeModal(true)}
                                    />
                                </div>

                                <div className={"flex gap-2 px-2 pt-2"}>
                                    <p>{t("rebuild.add_phase.grade")}:</p>
                                    <GradePicker
                                        selectedGrade={route.grade}
                                        setSelectedGrade={onGradeChange}
                                    />
                                    <ClickableFontAwesomeIcon
                                        icon={faGear}
                                        onClick={() => setShowCircuitAndGradeModal(true)}
                                    />
                                </div>
                            </div>
                        </>
                        : <Trans
                            i18nKey={"rebuild.add_phase.no_routes"}
                            components={{strong: <strong/>}}
                        />
                }

            </div>
        </DraggableWindow>
    </>;
}
