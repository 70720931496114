import {createContext} from "react";
import {unimplementedFunction} from "../common/Utils";
import {Wall} from "../model/Wall";
import {ObjectWithTracker} from "./ContextUtils";
import {ActionWithSideEffects, SimpleAction, useReducer} from "./StateManagement";
import {backend} from "../model/Backend";

const WallContext = createContext(null);
const WallDispatchContext = createContext(unimplementedFunction);

class WallActions {
    static OnLoaded = "on_loaded";
    static OnProposalSuccess = "on_proposal_success";
    static SetMetadata = "set_metadata";
    static SetRoutes = "set_routes";
    static UpdateRoute = "update_route";
}

// Action creator for wall actions
function createWallAction(wall, actionData) {
    switch (actionData.type) {
        case WallActions.OnLoaded: {
            return new SimpleAction(() => {
                return new ObjectWithTracker(actionData.wall);
            }, actionData.postSuccessHooks);
        }

        case WallActions.SetMetadata: {
            return new ActionWithSideEffects(
                backend.saveWallMetadata(actionData.metadata),
                WallActions.SetMetadata,
                (wall, result) => {
                    const newWall = wall.clone();
                    newWall.value.metadata = actionData.metadata;
                    return newWall;
                },
                actionData.postSuccessHooks
            );
        }

        case WallActions.UpdateRoute: {
            const updatedRoutes = wall.value.routes.map(route => {
                if (route.id === actionData.routeId) {
                    return route.toJSON(actionData.metadata);
                } else {
                    return route.toJSON();
                }
            });

            return new ActionWithSideEffects(
                backend.setRoutes(updatedRoutes),
                WallActions.UpdateRoute,
                (wall, result) => {
                    const newWall = wall.clone();

                    newWall.value.routes = newWall.value.routes.map(route => {
                        if (route.id === actionData.routeId) {
                            route.metadata = {
                                ...route.metadata,
                                ...actionData.metadata,
                            }
                        }
                        return route;
                    });
                    return newWall;
                },
                actionData.postSuccessHooks
            );
        }

        case WallActions.SetRoutes: {
            return new ActionWithSideEffects(
                backend.setRoutes(actionData.routes),
                WallActions.SetRoutes,
                (wall, result) => {
                    const newWall = wall.clone();
                    newWall.value.routes = actionData.routes;
                    return newWall;
                },
                actionData.postSuccessHooks
            );
        }

        case WallActions.OnProposalSuccess: {
            return new SimpleAction(wall => {
                const newWall = wall.clone();
                newWall.value = Wall.fromProposal(
                    wall.value,
                    actionData.holdsMesh,
                    actionData.routes
                );
                return newWall;
            }, actionData.postSuccessHooks);
        }

        default:
            console.error("Unknown action type:", actionData.type);
            return new SimpleAction(state => state);
    }
}

function useWallReducer() {
    // Using true for withTracker to enable tracking of loading/error states
    return useReducer(createWallAction, null, true);
}

export {WallContext, WallDispatchContext, useWallReducer, WallActions};
