import ModalWindow from "../../common/ModalWindow";
import {useContext, useRef, useState} from "react";
import BasicTextInput from "../../common/TextInput";
import {UserActions, UserContext, UserDispatchContext} from "../../contexts/UserContext";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import {Trans, useTranslation} from "react-i18next";

export default function ForgotPasswordModal({onClose, ...props}) {
    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    const userEmail = useRef(null);

    const [modified, setModified] = useState(false);
    const [sent, setSent] = useState(false);

    const {t, i18n} = useTranslation();

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        userDispatch({
            type: UserActions.ForgotPassword,
            email: userEmail.current.value,
            postSuccessHooks: () => {
                setSent(true);
                setModified(false);
                userEmail.current.value = "";
            }
        });
    }

    return <ModalWindow
        title={sent ? t("info.sent") : t("password.forgot_password")}
        handleClose={onClose}
        {...props}
    >
        <form onSubmit={onSubmit}>
            {
                !sent ? <>
                    <p>
                        <Trans
                            i18nKey="password.forgot_password_instructions"
                            components={{strong: <strong/>}}
                        />
                    </p>
                    <div className="mb-8"></div>
                    <BasicTextInput ref={userEmail} type="email" name="email" required={true}
                                    label={t("register.email")}
                                    autoComplete="username"
                                    onChange={() => setModified(true)}
                    />
                </> : <>
                    <p>
                        <Trans
                            i18nKey="password.forgot_password_instructions_after_sent_1"
                            components={{strong: <strong/>}}
                        />
                    </p>
                    <div className="mb-4"></div>
                    <p>
                        <Trans
                            i18nKey="password.forgot_password_instructions_after_sent_2"
                            components={{strong: <strong/>}}
                        />
                    </p>
                </>
            }
            <div className="mb-8"></div>
            <BasicButton
                type="submit"
                buttonColor={UIColor.Blue}
                className="w-full"
                disabled={!modified}
            >
                {user.status.isLoading(UserActions.ForgotPassword) ?
                    <SpinLoadingIndicator/> : <>{t("password.submit")}</>}
            </BasicButton>
            <ErrorFromBackendResult data={user.status.getError(UserActions.ForgotPassword)}/>
        </form>
    </ModalWindow>;
}
