import {RouteStateType, UserActivityType} from "../../model/User";
import {Fragment, useContext} from "react";
import {faCircle, faGear, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ClickableUIStyle, UIColor} from "../../Config";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WallContext} from "../../contexts/WallContext";
import CircuitTape from "../../common/CircuitTape";

function ActivityLine({activities, overrideIconState, collapsed = false}) {
    const wall = useContext(WallContext);

    // all activities should be from the same route
    let activity = activities[0];

    let colorFunction = overrideIconState ? RouteStateType.Color : UserActivityType.Color;
    let iconFunction = overrideIconState ? RouteStateType.Icon : UserActivityType.Icon;

    let grade = activity.route.grade;
    let circuitColor = wall.value.getCircuitHexColor(activity.route.circuit);

    return <div
        className="flex items-center gap-3 w-full"
    >
        <div className="flex gap-2">
            {
                activities.reverse().map(activity => (
                    <Fragment key={activity.id}>
                        <div
                            className={`${colorFunction(overrideIconState ?? activity.type)} drop-shadow-lg w-fit px-1.5 py-0.5 rounded`}>
                            {<FontAwesomeIcon
                                icon={iconFunction(overrideIconState ?? activity.type)}
                                className="w-4"
                            ></FontAwesomeIcon>}
                        </div>
                    </Fragment>
                ))
            }
        </div>
        {!collapsed ?
            <>
                {(grade || circuitColor) && <FontAwesomeIcon icon={faCircle} className="text-[15%] opacity-25"/>}
                {grade && <span className="font-bold text-lg">{grade}</span>}
                {circuitColor && <CircuitTape circuitColor={circuitColor}/>}
            </> : <></>}
    </div>

}


export function ActivityCard(
    {
        activities,
        onActivityClick,
        onActivityModify,
        className = "",
        editable = true,
        collapsed = false
    }) {
    return (
        <div className={`${className} flex justify-between items-center`}>
            <div
                className={`flex ${!collapsed ? 'flex-grow' : ""}  justify-between items-center px-2 py-1.5 rounded-lg ${ClickableUIStyle[UIColor.DarkInput]} ${editable ? 'cursor-pointer' : 'cursor-default opacity-40'}`}
                onClick={() => onActivityClick(activities[0])}
            >
                <ActivityLine activities={activities} collapsed={collapsed}/>
            </div>
            <ClickableFontAwesomeIcon
                icon={faGear}
                onClick={onActivityModify}
                className={`pl-2 pr-0.5 py-3 ${editable ? 'visible' : 'invisible'} ${collapsed ? "hidden" : ""}`}
                inverseHover={true}
            />
        </div>);
}

export function BookmarkCard({bookmark, routeState, onClick, onRemove}) {
    return (
        <div className="flex justify-between items-center">
            <div
                className={`px-2 py-1.5 rounded-lg mb-1.5 ${ClickableUIStyle[UIColor.DarkInput]} cursor-pointer flex flex-grow justify-between items-center`}
                onClick={() => onClick(bookmark)}
            >
                <ActivityLine activities={[bookmark]} overrideIconState={routeState}/>
            </div>
            <ClickableFontAwesomeIcon
                icon={faTimes}
                onClick={() => onRemove(bookmark)}
                className="pl-2 pr-0.5 py-3 text-xl"
                inverseHover={true}
            />
        </div>);
}
