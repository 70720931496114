import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {ImageUtils} from "../../common/Utils";
import {useBlobUrl} from "../../contexts/BlobURLContext";

// imageEnabledObject is an object that has .imageString and carries a .imageURL
export default function ProfilePicture({imageEnabledObject, pictureSize, onClick = null, overrideURL = null}) {
    const [imageURL, setImageURL] = useState(null);

    const {setBlobUrl, getBlobUrl} = useBlobUrl();

    const {t} = useTranslation();

    useEffect(() => {
        if (!imageEnabledObject) {
            return;
        }

        const key = overrideURL || imageEnabledObject.imageString;

        if (!key) {
            return;
        }

        const value = getBlobUrl(key);

        if (value) {
            setImageURL(value);
        } else {
            if (overrideURL) {
                setImageURL(overrideURL);
                setBlobUrl(overrideURL, overrideURL);
            } else if (imageEnabledObject.imageString) {
                // or they don't have it, and we dynamically create it
                console.log("Creating image from string");

                ImageUtils.base64ToBlob(imageEnabledObject.imageString).then(blob => {
                    const url =URL.createObjectURL(blob)

                    setImageURL(url);
                    setBlobUrl(key, url);
                })
            }
        }
    }, [imageEnabledObject, overrideURL])

    return <div className="w-fit border-2 border-neutral-500 rounded-full" onClick={onClick}>
        <div
            className={`${pictureSize} relative overflow-hidden rounded-full ${onClick ? 'hover:opacity-50' : ''}`}>
            {
                imageURL ?
                    <img src={imageURL} className="w-full h-full" alt={t("account_settings.picture_alt")}/> :
                    <div className="w-full h-full bg-black"></div>
            }
        </div>
    </div>;
}
