import {createContext} from "react";
import {SpecialStates, unimplementedFunction} from "../common/Utils";
import RebuildProposal from "../model/RebuildProposal";
import {Route} from "../model/Wall";
import {SimpleAction, useReducer} from "./StateManagement";

const ProposalContext = createContext(null);
const ProposalDispatchContext = createContext(unimplementedFunction);

class ProposalActions {
    static ServerError = "server_error";
    static SetFromServer = "set_from_server";  // server responded
    static EnteredNewRebuild = "entered_new_rebuild";  // client started rebuild -> set not ready immediately, no need to ask the server

    static StartReviewing = "start_reviewing";  // start the proposal review
    static StopReviewing = "stop_reviewing";  // stop the proposal review

    static SetPhase = "set_phase";  // go to the next step of the proposal
    static NextPhase = "next_phase";  // go to the next step of the proposal

    // first part of the review goes through objects to remove
    // during this step, all we can do is click on things to toggle their removal
    static ToggleRemoveHold = "toggle_remove_hold";
    static ToggleRemoveRoute = "toggle_remove_route";

    // second part does adding/removal of things from routes
    static ToggleRouteHold = "toggle_route_hold";  // add/remove from route
    static ToggleHold = "toggle_hold";  // add/remove in general
    static CombineRoutes = "merge_routes";  // merge two routes
    static NewRoute = "new_route";  // create new route

    static Publish = "publish";
    static Reject = "reject";
}

function createProposalAction(proposal, actionData) {
    switch (actionData.type) {
        case ProposalActions.ServerError:
            return new SimpleAction(() => SpecialStates.CreateErrorState(actionData.error),
                actionData.postSuccessHooks);

        case ProposalActions.SetFromServer:
            return new SimpleAction(() => actionData.proposal,
                actionData.postSuccessHooks);

        case ProposalActions.EnteredNewRebuild:
            return new SimpleAction(() => RebuildProposal.NotReady({
                readyForRevision: false,
            }), actionData.postSuccessHooks);

        case ProposalActions.StartReviewing:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                newProposal.beingReviewed = true;
                newProposal.phase = 0;
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.StopReviewing:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                newProposal.beingReviewed = false;
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.SetPhase:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                newProposal.phase = actionData.phase;
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.NextPhase:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                newProposal.phase += 1;
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.ToggleRemoveHold:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                newProposal.toggleRemovedHold(actionData.hold);
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.ToggleRemoveRoute:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                newProposal.toggleRemovedRoute(actionData.route);
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.ToggleRouteHold:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                const {hold, route} = actionData;

                if (hold.route !== null) {
                    // if it belongs to a different route, first remove it from that one
                    if (hold.route !== route) {
                        hold.route.toggleHold(hold);
                        newProposal.toggleAddHold(hold.id);
                    }
                }

                newProposal.toggleAddHold(hold.id);
                route.toggleHold(hold);

                newProposal.filterEmptyRoutes();
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.ToggleHold:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                const {hold} = actionData;

                if (hold.route !== null) {
                    // if it was a part of a route, toggling it effectively marks it as removed
                    // since it will not be in diff.holds.added
                    hold.route.toggleHold(hold);
                } else {
                    // if it was not, we can happily toggle it to mark it removed/unremoved
                    newProposal.toggleAddHold(hold.id);
                }

                newProposal.filterEmptyRoutes();
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.CombineRoutes:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                const {route1, route2} = actionData;

                console.assert(route1 !== route2);
                console.assert(newProposal.getAddedRouteIDs().includes(route1.id));
                console.assert(newProposal.getAddedRouteIDs().includes(route2.id));

                // nuke route 2
                newProposal.toggleAddRoute(route2.id);
                newProposal.routes.splice(newProposal.routes.indexOf(route2), 1);

                console.assert(!newProposal.getAddedRouteIDs().includes(route2.id));

                route1.addHolds(route2.holds); // add holds to route 1

                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.NewRoute:
            return new SimpleAction(proposal => {
                const newProposal = proposal.shallowClone();
                const {hold, setRoute} = actionData;

                // first, remove from any route
                if (hold.route !== null) {
                    hold.route.toggleHold(hold);
                    newProposal.toggleAddHold(hold.id);
                }

                // second, remove from added holds
                console.assert(newProposal.getAddedHoldIDs().includes(hold.id));
                newProposal.toggleAddHold(hold.id);

                // form a new route
                let newRoute = Route.fromHolds([hold]);

                newProposal.routes.push(newRoute);
                newProposal.toggleAddRoute(newRoute.id);

                if (setRoute) {
                    setRoute(newRoute);
                }

                newProposal.filterEmptyRoutes();
                return newProposal;
            }, actionData.postSuccessHooks);

        case ProposalActions.Publish:
        case ProposalActions.Reject:
            return new SimpleAction(() => SpecialStates.NonePending,
                actionData.postSuccessHooks);

        default:
            console.error("Unknown action type:", actionData.type);
            throw Error("Unknown action: " + actionData.type);
    }
}

function useProposalReducer() {
    return useReducer(createProposalAction, SpecialStates.ToBeLoaded, false);
}

export {ProposalContext, ProposalDispatchContext, ProposalActions, useProposalReducer};
