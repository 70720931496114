import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTurnUp} from "@fortawesome/free-solid-svg-icons";
import {BasicButton} from "../../common/Button";
import {UIColor} from "../../Config";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import {useCallback, useContext} from "react";
import {RebuildPhase} from "../../model/RebuildProposal";
import {useTranslation} from "react-i18next";
import {CanvasContext} from "../../contexts/CanvasContext";

export const PhaseButtons = () => {
    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);

    const canvas = useContext(CanvasContext);

    const {t} = useTranslation();

    function onBackClick() {
        for (const hold of proposal.holds.values()) {
            canvas.markForOpacityChange(hold.holdMesh, 0);
        }

        canvas.startOpacityChange()

        proposalDispatch({
            type: ProposalActions.StopReviewing,
        });
    }

    let isRemoving = proposal?.phase === RebuildPhase.Remove;

    const removeClick = useCallback(() => {
        if (proposal?.phase === RebuildPhase.Remove)
            return;

        proposalDispatch({
            type: ProposalActions.SetPhase,
            phase: RebuildPhase.Remove,
        });
    }, [proposal, proposalDispatch]);

    const addClick = useCallback(() => {
        if (proposal?.phase === RebuildPhase.Add)
            return;

        proposalDispatch({
            type: ProposalActions.SetPhase,
            phase: RebuildPhase.Add,
        });
    }, [proposal, proposalDispatch]);

    return (
        <div
            className="flex flex-col items-center absolute left-1/2 -translate-x-1/2 top-4 transition-opacity duration-1000">
            <div className="inline-flex text-lg rounded">
                <div
                    onClick={removeClick}
                    className={`rounded-l ${UIColor.Red} shadow-small ${isRemoving ? "" : "brightness-50 cursor-pointer"}`} >
                    <p className={`truncate px-3 py-2 text-white`}>
                        <span>{isRemoving ? `${t("rebuild.phase_buttons.removing")}...` : t("rebuild.phase_buttons.remove")}</span>
                    </p>
                </div>
                <div
                    onClick={addClick}
                    className={`rounded-r ${UIColor.Green} shadow-small ${!isRemoving ? "" : "brightness-50 cursor-pointer"}`} >
                    <p className={`truncate px-3 py-2 text-white`}>
                        <span>{!isRemoving ? `${t("rebuild.phase_buttons.adding")}...` : t("rebuild.phase_buttons.add")}</span>
                    </p>
                </div>
            </div>
            <BasicButton
                round={false}
                className="rounded-b-xl"
                padding="py-1 px-3"
                onClick={onBackClick}
            >
                {t("rebuild.phase_buttons.back")} <FontAwesomeIcon icon={faTurnUp} className={"pl-1"}/>
            </BasicButton>
        </div>
    );
};

