import { forwardRef } from "react";
import BasicInputLabel from "./InputLabel";
import { ClickableUIStyle, UIColor } from "../Config";

const BasicSelect = forwardRef(function BasicSelect(
    {
        className = "",
        name,
        value,
        onChange,
        label,
        width = "w-full",
        shadow = false,
        padding = "py-1.5 px-4",
        disabled,
        required = false,
        options = [],
        color = UIColor.LightInput,
        textSize = "text-lg",
    },
    ref
) {
    const classes = `${className} ${width} ${padding} ${shadow ? "shadow-neutral-800 shadow-medium" : ""} rounded ${ClickableUIStyle[color]} ${textSize}`;

    const selectElement = (
        <select
            name={name}
            value={value}
            onChange={onChange}
            className={classes}
            disabled={disabled}
            required={required}
            ref={ref}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    );

    if (label) {
        return (
            <>
                <BasicInputLabel label={label} />
                <div>{selectElement}</div>
            </>
        );
    }
    return selectElement;
});

export default BasicSelect;
