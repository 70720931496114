import {useState, useEffect, useRef} from 'react';

export default function RangeSlidersDemo() {
    const values = [null, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

    // State for single slider
    const [singleValue, setSingleValue] = useState('D');

    // State for dual slider
    const [dualValues, setDualValues] = useState(['C', 'G']);

    // State to demonstrate the onChange callback
    const [isDirty, setDirty] = useState(false);

    return (
        <div className={"non-draggable px-4"}>
            <div className="flex flex-col gap-12 p-8 max-w-md mx-auto">
                <div>
                    <h2 className="text-xl font-bold mb-4">Single Range Slider</h2>
                    <SingleRangeSlider
                        values={values}
                        value={singleValue}
                        setValue={setSingleValue}
                        onChange={() => setDirty(true)}
                    />
                    <div className="mt-2">
                        Selected value: {singleValue}
                        {isDirty && <span className="ml-2 text-green-600">(Changed)</span>}
                    </div>
                </div>

                <div>
                    <h2 className="text-xl font-bold mb-4">Dual Range Slider</h2>
                    <DualRangeSlider
                        allValues={values}
                        values={dualValues}
                        setValues={setDualValues}
                        onChange={() => setDirty(true)}
                    />
                    <div className="mt-2">
                        Selected range: {dualValues[0]} to {dualValues[1]}
                        {isDirty && <span className="ml-2 text-green-600">(Changed)</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function SingleRangeSlider({values, value, setValue, onChange}) {
    const [isDragging, setIsDragging] = useState(false);
    const trackRef = useRef(null);

    // Find the index of the current value
    const currentIndex = value ? values.indexOf(value) : 0;

    const handlePointerDown = (e) => {
        setIsDragging(true);
        updatePosition(e);
        e.target.setPointerCapture(e.pointerId);
    };

    const handlePointerUp = (e) => {
        setIsDragging(false);
        e.target.releasePointerCapture(e.pointerId);
    };

    const handlePointerMove = (e) => {
        if (isDragging) {
            updatePosition(e);
        }
    };

    const updatePosition = (e) => {
        if (!trackRef.current) return;

        const track = trackRef.current;
        const rect = track.getBoundingClientRect();
        const relativeX = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
        const percentage = relativeX / rect.width;
        const index = Math.min(
            Math.max(0, Math.round(percentage * (values.length - 1))),
            values.length - 1
        );

        const newValue = values[index];
        if (newValue !== value) {
            setValue(newValue);
            if (onChange) onChange(newValue);
        }
    };

    const percentage = values.length > 1 ?
        (currentIndex / (values.length - 1)) * 100 : 0;

    return (
        <div className={"non-draggable px-4"}>
            <div className={`relative w-full h-8 ${value === null ? 'opacity-50' : ''} transition-opacity`}>
                <div
                    ref={trackRef}
                    className="absolute w-full h-2 bg-neutral-500 rounded-full top-3"
                    onClick={updatePosition}
                >
                    <div
                        className="absolute h-2 bg-neutral-200 rounded-full top-0"
                        style={{width: `${percentage}%`}}
                    />
                </div>

                {/* Thumb */}
                <div
                    className="absolute w-8 h-8 rounded-full bg-neutral-200 flex items-center justify-center text-black font-bold shadow-md cursor-pointer top-0 transform -translate-x-1/2"
                    style={{left: `${percentage}%`}}
                    onPointerDown={handlePointerDown}
                    onPointerUp={handlePointerUp}
                    onPointerMove={handlePointerMove}
                >
                    <p className={"text-sm"}>
                        {value}
                    </p>
                </div>
            </div>
        </div>
    );
}

export function DualRangeSlider({allValues, values: [minValue, maxValue], setValues, onChange}) {
    const [activeDot, setActiveDot] = useState(null);
    const trackRef = useRef(null);
    const lastPosRef = useRef(null);

    // Find indexes of current min and max values
    const minIndex = allValues.indexOf(minValue);
    const maxIndex = allValues.indexOf(maxValue);

    const handlePointerDown = (dot, e) => {
        setActiveDot(dot);
        lastPosRef.current = e.clientX;
        e.target.setPointerCapture(e.pointerId);
    };

    const handlePointerUp = (e) => {
        setActiveDot(null);
        lastPosRef.current = null;
        e.target.releasePointerCapture(e.pointerId);
    };

    const handlePointerMove = (e) => {
        if (!activeDot || !trackRef.current) return;

        const track = trackRef.current;
        const rect = track.getBoundingClientRect();
        const relativeX = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
        const percentage = relativeX / rect.width;
        const index = Math.min(
            Math.max(0, Math.round(percentage * (allValues.length - 1))),
            allValues.length - 1
        );

        // Determine direction of movement
        const direction = lastPosRef.current ? e.clientX - lastPosRef.current : 0;
        lastPosRef.current = e.clientX;

        let newMinValue = minValue;
        let newMaxValue = maxValue;

        // When handles are at the same position, dynamically determine which one to move
        if (minIndex === maxIndex) {
            if (direction > 0) {
                // Moving right, adjust the max value
                newMaxValue = allValues[Math.max(index, minIndex)];
            } else if (direction < 0) {
                // Moving left, adjust the min value
                newMinValue = allValues[Math.min(index, maxIndex)];
            }
        } else if (activeDot === 'min') {
            // Normal case - adjust min value
            newMinValue = allValues[Math.min(index, maxIndex)];
        } else {
            // Normal case - adjust max value
            newMaxValue = allValues[Math.max(index, minIndex)];
        }

        if (newMinValue !== minValue || newMaxValue !== maxValue) {
            const newValues = [newMinValue, newMaxValue];
            setValues(newValues);
            if (onChange) onChange(newValues);
        }
    };

    // Handle clicks on the track
    const handleTrackClick = (e) => {
        if (!trackRef.current) return;

        const track = trackRef.current;
        const rect = track.getBoundingClientRect();
        const relativeX = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
        const percentage = relativeX / rect.width;
        const clickedIndex = Math.min(
            Math.max(0, Math.round(percentage * (allValues.length - 1))),
            allValues.length - 1
        );

        // Determine which handle is closer to the click
        const distToMin = Math.abs(clickedIndex - minIndex);
        const distToMax = Math.abs(clickedIndex - maxIndex);

        let newMinValue = minValue;
        let newMaxValue = maxValue;

        if (distToMin <= distToMax) {
            // Min handle is closer or equidistant
            newMinValue = allValues[Math.min(clickedIndex, maxIndex)];
        } else {
            // Max handle is closer
            newMaxValue = allValues[Math.max(clickedIndex, minIndex)];
        }

        if (newMinValue !== minValue || newMaxValue !== maxValue) {
            const newValues = [newMinValue, newMaxValue];
            setValues(newValues);
            if (onChange) onChange(newValues);
        }
    };

    const minPercentage = allValues.length > 1 ?
        (minIndex / (allValues.length - 1)) * 100 : 0;
    const maxPercentage = allValues.length > 1 ?
        (maxIndex / (allValues.length - 1)) * 100 : 100;

    return (
        <div className={"non-draggable px-4"}
             onClick={handleTrackClick}
        >
            <div
                className={`relative w-full h-8 ${minIndex === 0 && maxIndex === allValues.length - 1 ? 'opacity-50' : ''} transition-opacity`}>
                <div
                    ref={trackRef}
                    className="absolute w-full h-2 bg-neutral-500 rounded-full top-3"
                >
                    <div
                        className="absolute h-2 bg-neutral-200 rounded-full top-0"
                        style={{
                            left: `${minPercentage}%`,
                            width: `${maxPercentage - minPercentage}%`
                        }}
                    />
                </div>

                {/* Min thumb */}
                <div
                    className="absolute w-8 h-8 rounded-full bg-neutral-200 flex items-center justify-center text-black font-bold shadow-md cursor-pointer top-0 transform -translate-x-1/2 z-10"
                    style={{left: `${minPercentage}%`}}
                    onPointerDown={(e) => handlePointerDown('min', e)}
                    onPointerUp={handlePointerUp}
                    onPointerMove={handlePointerMove}
                >
                    {minValue}
                </div>

                {/* Max thumb */}
                <div
                    className="absolute w-8 h-8 rounded-full bg-neutral-200 flex items-center justify-center text-black font-bold shadow-md cursor-pointer top-0 transform -translate-x-1/2 z-10"
                    style={{left: `${maxPercentage}%`}}
                    onPointerDown={(e) => handlePointerDown('max', e)}
                    onPointerUp={handlePointerUp}
                    onPointerMove={handlePointerMove}
                >
                    {maxValue}
                </div>
            </div>
        </div>
    );
}
