import {useContext, useEffect, useState} from "react";

import THREECanvas from "./ThreeCanvas";
import TopDownController from "../controls/TopDownController";
import OrbitController from "../controls/OrbitController";
import {ProposalReviewState, RouteFocusState, TopDownState} from "./CanvasState";
import RemovePhaseController from "../controls/RemovePhaseController";
import {WallContext} from "../../contexts/WallContext";
import {ProposalContext} from "../../contexts/ProposalContext";
import {CanvasContext, CanvasStateContext, SetCanvasContext, SetCanvasStateContext} from "../../contexts/CanvasContext";
import {AppContext} from "../../AppContext";
import OutlineType from "./OutlineType";
import {SetReadyContext} from "../../contexts/ReadyContext";
import {RebuildPhase} from "../../model/RebuildProposal";
import FeedbackModal from "../../feedback/FeedbackModal";
import AddPhaseController from "../controls/AddPhaseController";
import RouteFilterPanel from "../overlay/RouteFilterPanel";


export default function Canvas() {
    const wall = useContext(WallContext);
    const canvasState = useContext(CanvasStateContext);
    const setCanvasState = useContext(SetCanvasStateContext);

    const canvas = useContext(CanvasContext);
    const setCanvas = useContext(SetCanvasContext);

    const setReady = useContext(SetReadyContext);

    const proposal = useContext(ProposalContext);

    const [filteredRoutes, setFilteredRoutes] = useState(wall.value.routes);

    useEffect(() => {
        const newCanvas = new THREECanvas();
        newCanvas.enableOutline(OutlineType.HIGHLIGHT, OutlineType.HIGHLIGHT.params);
        newCanvas.enableOutline(OutlineType.HOVER, OutlineType.HOVER.params);
        newCanvas.enableOutline(OutlineType.ATTEMPTED_ROUTE, OutlineType.ATTEMPTED_ROUTE.params);
        newCanvas.enableOutline(OutlineType.CLIMBED_ROUTE, OutlineType.CLIMBED_ROUTE.params);

        setCanvas(newCanvas);

        function setCanvasDragging() {
            AppContext.Mutable.isCanvasDragging = true;
        }

        function unsetCanvasDragging() {
            AppContext.Mutable.isCanvasDragging = false;
        }

        function consumeIfDragging(e) {
            if (AppContext.Mutable.isCanvasDragging) {
                e.preventDefault();
            }
        }

        newCanvas.getCanvasElement().addEventListener("mousedown", setCanvasDragging);
        document.addEventListener("mouseup", unsetCanvasDragging);
        document.addEventListener("mousemove", consumeIfDragging);
        return () => {
            newCanvas.getCanvasElement().removeEventListener("mousedown", setCanvasDragging);
            document.removeEventListener("mouseup", unsetCanvasDragging);
            document.removeEventListener("mousemove", consumeIfDragging);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // initial state/position
    useEffect(() => {
        if (canvas === null || wall.value === null)
            return;

        // default to canvas state
        if (canvasState === null) {
            if (wall.value.startingTransform) {
                canvas.camera.position.set(...wall.value.startingTransform.position);
                canvas.camera.quaternion.set(...wall.value.startingTransform.quaternion);
            }

            setCanvasState(new TopDownState());
        }
    }, [canvas, wall, canvasState, setCanvasState]);

    useEffect(() => {
        if (canvas === null || wall.value === null || canvasState === null)
            return;

        if (canvasState instanceof ProposalReviewState && proposal !== null) {
            canvas.setProposal(proposal);

        } else {
            canvas.setWall(wall.value);

            setReady(true);
        }
    }, [canvas, canvasState, proposal, wall]);

    useEffect(() => {
        if (canvas === null || wall.value === null || proposal === null)
            return;

        if (proposal.beingReviewed && !(canvasState instanceof ProposalReviewState)) {
            setCanvasState(new ProposalReviewState())
        }

        if (!proposal.beingReviewed && canvasState instanceof ProposalReviewState) {
            setCanvasState(new TopDownState());
        }
    }, [canvas, wall, proposal, canvasState, setCanvasState]);


    return (
        <>
            {!(canvasState instanceof ProposalReviewState) && <FeedbackModal/>}

            <div hidden={!(canvasState instanceof TopDownState)}>
                <RouteFilterPanel
                    onFilteredRoutesChange={setFilteredRoutes}
                />
            </div>

            <canvas id="model-canvas" onContextMenu={e => e.preventDefault()}></canvas>
            {
                canvas !== null && canvasState !== null &&
                <>
                    {canvasState instanceof TopDownState && <TopDownController viewedRoutes={filteredRoutes}/>}
                    {canvasState instanceof RouteFocusState && <OrbitController viewedRoutes={filteredRoutes}/>}
                    {canvasState instanceof ProposalReviewState &&
                        (
                            proposal.phase === RebuildPhase.Remove
                                ? <RemovePhaseController/>
                                : proposal.phase === RebuildPhase.Add
                                    ? <AddPhaseController/>
                                    : <></>
                        )
                    }
                </>
            }
        </>
    );
}
