import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {backend, ErrorType, BackendResult} from "../../model/Backend";
import {SpecialStates} from "../../common/Utils";
import NewRebuildModal from "../../settings/NewRebuildModal";
import {BasicButton} from "../../common/Button";
import {ProposalActions, ProposalContext, ProposalDispatchContext} from "../../contexts/ProposalContext";
import ErrorFromBackendResult from "../../common/ErrorFromBackendResult";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import GradingSystemSelector from "../../settings/GradingSystemSelector";
import CircuitSystemSelector from "../../settings/CircuitSystemSelector";
import Spacer from "../../common/Spacer";
import {Trans, useTranslation} from "react-i18next";
import {UIColor} from "../../Config";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import YellowGreenProgressBar from "../../settings/YellowGreenProgressBar";
import DownloadableQRCode from "../../common/DownloadableQRCode";

export default function OwnerManagement() {
    const [newRebuildOpen, setNewRebuildOpen] = useState(false);

    const [rejecting, setRejecting] = useState(false);
    const [rejectError, setRejectError] = useState(null);

    const intervalRef = useRef(null);

    const proposal = useContext(ProposalContext);
    const proposalDispatch = useContext(ProposalDispatchContext);
    const uiStateDispatch = useContext(UiDispatchContext);

    const {t} = useTranslation();

    const loadProposal = useCallback(async () => {
        try {
            const result = await backend.getPendingProposal();
            if (!result.isSuccess()) {
                proposalDispatch({type: ProposalActions.ServerError, error: result});
            } else {
                proposalDispatch({type: ProposalActions.SetFromServer, proposal: result.data});
            }
        } catch (e) {
            console.error("Error loading proposal: ", e);
            proposalDispatch({
                type: ProposalActions.ServerError,
                error: BackendResult.FromError(ErrorType.UnexpectedError)
            });
        }
    }, [proposalDispatch]);

    // TODO: this should be dispatch
    async function onReject(event) {
        event.preventDefault();

        setRejecting(true);
        setRejectError(null);
        const result = await backend.rejectProposal(proposal.metadata.id);
        setRejecting(false);

        if (!result.isSuccess()) {
            setRejectError(result);
        } else {
            setRejectError(null);

            clearInterval(intervalRef.current);
            intervalRef.current = null;

            proposalDispatch({type: ProposalActions.Reject});
        }
    }

    useEffect(() => {
        if (!proposal.metadata || !proposal.metadata.readyForRevision) {
            loadProposal();
        }
    }, []);

    useEffect(() => {
        // either to be loaded or it's not yet ready for a revision
        if ((proposal === SpecialStates.ToBeLoaded || (proposal.metadata && !proposal.metadata.readyForRevision))) {
            if (!intervalRef.current) {
                intervalRef.current = setInterval(loadProposal, 5000);
            }
        } else {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }

        return () => {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    }, [loadProposal, proposal]);

    function onProposalReviewRequest() {
        uiStateDispatch({type: UiActions.CloseSidePanel});
        proposalDispatch({type: ProposalActions.StartReviewing});
    }

    let step = proposal.metadata?.metadata?.step_current ?? 0;
    let total = proposal.metadata?.metadata?.step_total ?? 1;

    let percentage = (step / total) * 100;
    let overrideMessage = t(
        `rebuild.progress.${step}`,
        t("rebuild.progress.default")
    ) + "...";

    return (
        <>
            <h2 className="text-4xl text-center">{t("management.name")}</h2>
            <Spacer spacing={"mt-6 mb-6"}/>

            {(() => {
                if (proposal instanceof SpecialStates.ErrorState) {
                    return (
                        <>
                            <div className="mb-3"></div>
                            <ErrorFromBackendResult data={proposal.error}/>
                        </>
                    );
                } else if (proposal === SpecialStates.ToBeLoaded) {
                    return <div className={"m-auto"}>
                        <SpinLoadingIndicator/>
                    </div>
                } else if (proposal === SpecialStates.NonePending) {
                    return (
                        <>
                            <p className="text-center">{t("rebuild.no_rebuilds")}</p>
                            <div className="m-4"/>
                            <div className="flex justify-center">
                                <BasicButton
                                    onClick={() => setNewRebuildOpen(true)}
                                    buttonColor={UIColor.Green}
                                    className="text-xl"
                                >
                                    <strong>{t("rebuild.add_new_routes")}</strong>
                                </BasicButton>
                            </div>
                        </>
                    );
                }

                // no more special states at this point
                if (!proposal.metadata.readyForRevision) {
                    return (
                        <>
                            <p className="text-center">
                                <Trans i18nKey="rebuild.waiting_for_rebuild" components={{strong: <strong/>}}/>
                            </p>
                            <div className="mb-4"></div>
                            <div className="flex justify-center gap-2 w-full">
                                <YellowGreenProgressBar
                                    overrideMessage={overrideMessage}
                                    yellowProgress={percentage}
                                    aborting={rejecting}
                                />
                                <BasicButton
                                    onClick={onReject}
                                    disabled={rejecting}
                                    buttonColor={UIColor.Red}
                                    className={`w-fit`}
                                    padding={'py-1 px-3'}
                                >
                                    {!rejecting ? t("rebuild.new_rebuild.cancel") : <SpinLoadingIndicator/>}
                                </BasicButton>
                            </div>
                            <ErrorFromBackendResult data={rejectError}/>

                            <div className="mb-3"></div>
                        </>
                    );
                }

                return (
                    <>
                        <p className="text-center">
                            <Trans i18nKey="rebuild.rebuild_ready" components={{strong: <strong/>}}/>
                        </p>
                        <div className="mb-4"></div>
                        <div className="flex justify-center">
                            <BasicButton
                                onClick={onProposalReviewRequest}
                                buttonColor={UIColor.Yellow}
                                className="text-xl"
                            >
                                <strong>{t("rebuild.review")}</strong>
                            </BasicButton>
                        </div>
                    </>
                );
            })()}

            <NewRebuildModal show={newRebuildOpen} handleClose={() => setNewRebuildOpen(false)}/>

            <Spacer spacing={"mt-6 mb-6"}/>

            <h3 className="text-2xl">{t("grade_system_selector.title")}</h3>
            <div className="m-4"/>
            <GradingSystemSelector/>
            <div className="m-8"/>

            <h3 className="text-2xl">{t("circuit_system_selector.title")}</h3>
            <div className="m-4"/>
            <CircuitSystemSelector/>

            <Spacer spacing={"mt-6 mb-6"}/>
            <h3 className="text-2xl">{t("qr.title")}</h3>
            <div className="m-2"/>
            <p className={UIColor.MinorText}>{<Trans i18nKey={"qr.instructions"} components={{strong: <strong/>}}/>}</p>
            <div className="m-4"/>
            <DownloadableQRCode
                value={`${window.location.origin}/app/${backend.wallName}/`}
            />
        </>
    );
}
