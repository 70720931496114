import {useContext, useState} from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faComment, faHeart, faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import {UserActions, UserContext, UserDispatchContext} from "../contexts/UserContext";
import ModalWindow from "../common/ModalWindow";
import {BasicButton} from "../common/Button";
import {UIColor} from "../Config";
import SpinLoadingIndicator from "../common/SpinLoadingIndicator";
import TextLink from "../common/TextLink";
import {Trans, useTranslation} from "react-i18next";

export default function FeedbackModal() {
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [thankYouModal, setThankYouModal] = useState(false);
    const [feedbackValue, setFeedbackValue] = useState(null);

    const user = useContext(UserContext);
    const userDispatch = useContext(UserDispatchContext);

    const {t} = useTranslation();

    async function onSubmit(submitEvent) {
        submitEvent.preventDefault();

        if (user.status.isLoading(UserActions.SubmitFeedback))
            return;

        userDispatch({
            type: UserActions.SubmitFeedback,
            text: feedbackValue,
            postSuccessHooks: () => {
                setFeedbackModal(false);
                setThankYouModal(true);

                // TODO: reset feedback text
            }
        });
    }

    return (
        <>
            <BasicButton
                key="open-navigation-button"
                buttonColor={UIColor.Blue}
                className="text-white text-2xl absolute top-4 right-4 rounded-2xl shadow-medium z-0"
                padding="px-3 py-1.5"
                onClick={() => setFeedbackModal(true)}
            >
                <FontAwesomeIcon
                    icon={faComment}
                />
            </BasicButton>
            <ModalWindow
                handleClose={() => setThankYouModal(false)}
                title={t("info.sent")}
                show={thankYouModal}
            >
                <p className="text-xl">{t("feedback_modal.thanks")} <FontAwesomeIcon icon={faHeart}
                                                                                     className="ml-1"></FontAwesomeIcon>
                </p>
                <div className="mb-4"></div>
                <p>{t("feedback_modal.more_thanks")}</p>
                <div className="mb-2"></div>
                <p className={`text-right ${UIColor.MinorText}`}>– {t("feedback_modal.from")}</p>
            </ModalWindow>
            <ModalWindow
                handleClose={() => setFeedbackModal(false)}
                title="Feedback"
                topColor={UIColor.Blue}
                show={feedbackModal}
            >
                <form method="post" onSubmit={onSubmit}>
                    <p>
                        <Trans
                            i18nKey="feedback_modal.description"
                            components={{strong: <strong/>}}
                        />
                    </p>
                    <div className="mb-4"></div>
                    <textarea
                        placeholder={t("feedback_modal.let_us_know")}
                        className="w-full p-2 bg-neutral-700 rounded min-h-[5rem]"
                        onChange={(event) =>
                            setFeedbackValue(event.target.value)
                        }
                    />
                    <div className="mb-3"></div>
                    <p className={`${UIColor.MinorText} text-sm`}><Trans
                        i18nKey="feedback_modal.anonymous"
                        components={{strong: <strong/>, TextLink: <TextLink href="mailto:feedback@climbuddy.com"/>}}
                    /></p>

                    <div className="mb-5"></div>

                    <BasicButton
                        type="submit"
                        buttonColor={UIColor.Blue}
                        className="w-full"
                        disabled={feedbackValue === null || feedbackValue.length === 0}
                    >
                        {user.status.isLoading(UserActions.SubmitFeedback) ? <SpinLoadingIndicator/> :
                            <> {t("feedback_modal.send")} <FontAwesomeIcon icon={faPaperPlane}
                                                                           className="ml-1"></FontAwesomeIcon> </>}
                    </BasicButton>
                </form>
            </ModalWindow>
        </>
    );
}