import React from 'react';
import {UIColor} from "../Config";

const TextLink = ({ onClick, href, bold = false, color=UIColor.BlueText, children }) => {
    const commonProps = {
        className: `${color} hover:opacity-75 hover:underline ${bold ? 'font-bold' : ''} cursor-pointer`,
        onClick,
    };

    if (href) {
        return (
            <a {...commonProps} href={href}>
                {children}
            </a>
        );
    } else {
        return (
            <button type="button" {...commonProps}>
                {children}
            </button>
        );
    }
};

export default TextLink;
