import {useState} from "react";
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import DateInput from "../../../common/DateInput";
import {BasicButton} from "../../../common/Button";
import {UIColor} from "../../../Config";
import Window from "../../../common/Window";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UserActivityType} from "../../../model/User";
import {useTranslation} from "react-i18next";

// TODO: ewwwww, all of this ewwww

const KEYS = [UserActivityType.Attempt, UserActivityType.Climb, UserActivityType.Flash, UserActivityType.Repeat];

const COLORS = {
    [UserActivityType.Attempt]: "#be123c",
    [UserActivityType.Climb]: "#059669",
    [UserActivityType.Flash]: "#047857",
    [UserActivityType.Repeat]: "#525252",
};

// TODO: duplicit
const HOVER_COLOR = "#404040";

const TEXT_COLORS = {
    [UserActivityType.Attempt]: "text-rose-400",
    [UserActivityType.Climb]: "text-emerald-300",
    [UserActivityType.Flash]: "text-emerald-500",
    [UserActivityType.Repeat]: "text-gray-400",
};


export default function ActivityChart({data}) {
    const {t, i18n} = useTranslation();

    const [granularity, setGranularity] = useState(t("statistics.user_activity_chart.day_of_the_week"));

    const minDate = data.length ? data.reduce((min, {date}) => (date < min ? date : min), data[0].date) : null;
    const maxDate = data.length ? data.reduce((max, {date}) => (date > max ? date : max), data[0].date) : null;

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const filteredData = data.filter(({date}) => (startDate === null || date >= startDate) && (endDate === null || date <= endDate));
    const aggregatedData = aggregateData(filteredData, granularity);

    function aggregateData(data, granularity) {
        const aggregated = {};

        // Predefine all possible days of the week (Monday as 0, Sunday as 6)
        const allDaysOfWeek = t("statistics.user_activity_chart.weekdays");

        // Update: Represent months as numeric (1 for January, 2 for February, etc.)
        const allMonthsOfYear = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

        data.forEach(({date, ...values}) => {
            const d = new Date(date);
            let key;
            let distinctKey;

            switch (granularity) {
                case t("statistics.user_activity_chart.week"):
                    const startOfYear = new Date(d.getFullYear(), 0, 1);
                    const days = Math.floor((d - startOfYear) / (24 * 60 * 60 * 1000));
                    const weekNumber = Math.ceil((days + 1) / 7);
                    key = `${d.getFullYear()}-W${weekNumber}`;
                    distinctKey = d.getFullYear();
                    break;
                case t("statistics.user_activity_chart.month"):
                    key = `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, "0")}`;
                    distinctKey = d.getFullYear();
                    break;
                case t("statistics.user_activity_chart.year"):
                    key = `${d.getFullYear()}`;
                    distinctKey = null;
                    break;
                case t("statistics.user_activity_chart.day_of_the_week"):
                    // Adjust for Monday being 0, Sunday being 6
                    const dayOfWeek = (d.getDay() === 0 ? 6 : d.getDay() - 1); // Sunday (0) -> 6, Monday (1) -> 0, ...
                    key = allDaysOfWeek[dayOfWeek];
                    distinctKey = d.getFullYear(); // Use the year for distinct count
                    break;
                case t("statistics.user_activity_chart.month_of_the_year"):
                    // Now months are numeric (1 for January, 2 for February, etc.)
                    key = d.getMonth() + 1; // Months are 0-indexed in JavaScript, so we add 1
                    distinctKey = d.getFullYear(); // Use the year for distinct count
                    break;
                default:
                    key = date;
                    distinctKey = null;
            }

            if (!aggregated[key]) {
                aggregated[key] = {
                    date: key,
                    [UserActivityType.Attempt]: 0,
                    [UserActivityType.Climb]: 0,
                    [UserActivityType.Flash]: 0,
                    [UserActivityType.Repeat]: 0,
                    distinctKeys: new Set()
                };
            }

            Object.keys(values).forEach((activity) => {
                aggregated[key][activity] += values[activity];
            });

            if (distinctKey !== null) {
                aggregated[key].distinctKeys.add(distinctKey);
            }
        });

        // Ensure all days of the week or months of the year are present
        if (granularity === t("statistics.user_activity_chart.day_of_the_week")) {
            allDaysOfWeek.forEach(day => {
                if (!aggregated[day]) {
                    aggregated[day] = {
                        date: day,
                        [UserActivityType.Attempt]: 0,
                        [UserActivityType.Climb]: 0,
                        [UserActivityType.Flash]: 0,
                        [UserActivityType.Repeat]: 0,
                        distinctKeys: new Set()
                    };
                }
            });
        }

        if (granularity === t("statistics.user_activity_chart.month_of_the_year")) {
            allMonthsOfYear.forEach(month => {
                if (!aggregated[month]) {
                    aggregated[month] = {
                        date: month,
                        [UserActivityType.Attempt]: 0,
                        [UserActivityType.Climb]: 0,
                        [UserActivityType.Flash]: 0,
                        [UserActivityType.Repeat]: 0,
                        distinctKeys: new Set()
                    };
                }
            });
        }

        // Average the values over the distinct weeks or years
        Object.values(aggregated).forEach(item => {
            const distinctCount = item.distinctKeys.size;
            if (distinctCount > 0) {
                Object.keys(item).forEach(key => {
                    if (key !== "date" && key !== "distinctKeys") {
                        item[key] /= distinctCount;
                    }
                });
            }
        });

        // Sort the aggregated data based on the granularity
        let sortedAggregatedData;
        switch (granularity) {
            case t("statistics.user_activity_chart.day_of_the_week"):
                sortedAggregatedData = allDaysOfWeek.map(day => aggregated[day]);
                break;
            case t("statistics.user_activity_chart.month_of_the_year"):
                sortedAggregatedData = allMonthsOfYear.map(month => aggregated[month]);
                break;
            case t("statistics.user_activity_chart.year"):
                sortedAggregatedData = Object.values(aggregated).sort((a, b) => {
                    return a.date - b.date; // Sort by year numerically
                });
                break;
            case t("statistics.user_activity_chart.week"):
                sortedAggregatedData = Object.values(aggregated).sort((a, b) => {
                    // Sort by week number (e.g., 2025-W1, 2025-W2)
                    const [yearA, weekA] = a.date.split("-W");
                    const [yearB, weekB] = b.date.split("-W");
                    return parseInt(yearA) - parseInt(yearB) || parseInt(weekA) - parseInt(weekB);
                });
                break;
            case t("statistics.user_activity_chart.month"):
                sortedAggregatedData = Object.values(aggregated).sort((a, b) => {
                    // Sort by month numerically
                    const [yearA, monthA] = a.date.split("-");
                    const [yearB, monthB] = b.date.split("-");
                    return parseInt(yearA) - parseInt(yearB) || parseInt(monthA) - parseInt(monthB);
                });
                break;
            default:
                sortedAggregatedData = Object.values(aggregated);
        }

        return sortedAggregatedData;
    }

    const customTooltip = ({active, payload}) => {
        if (active && payload && payload.length) {
            const monthNames = t("statistics.user_activity_chart.months");

            const dateValue = payload[0].payload.date;
            const monthName = (dateValue >= 1 && dateValue <= 12) ? monthNames[dateValue - 1] : dateValue;

            return (
                <Window className={"p-3"} width={"w-fit"}>
                    <p className={"text-xl pb-2"}>
                        <span className={"font-bold"}>{monthName}</span>
                    </p>
                    {KEYS.map((key) => (
                        <p key={key}>
                        <span
                            className={TEXT_COLORS[key]}>{t("user_activity_type")[key]}:</span> {payload[0].payload[key]}
                        </p>
                    ))}
                </Window>
            );
        }
        return null;
    };

    const customLegend = () => (
        <div className="flex flex-wrap justify-center gap-x-5 gap-y-1 mt-2">
            {KEYS.map((key) => (
                <div key={key} className={`flex items-center gap-2 ${TEXT_COLORS[key]}`}>
                    <FontAwesomeIcon icon={UserActivityType.Icon(key)}/>
                    <span>{t("user_activity_type")[key]}</span>
                </div>
            ))}
        </div>
    );

    return (
        <>
            <h3 className="text-2xl text-center">{t("statistics.user_activity_chart.name")}</h3>
            <p className={`${UIColor.MinorText} italic text-center`}>{t("statistics.user_activity_chart.description")}</p>
            <div className={"p-2"}></div>
            <ResponsiveContainer className={"w-full"} height={300}>
                <BarChart data={aggregatedData}>
                    <XAxis dataKey="date"/>
                    <YAxis allowDecimals={false}/>
                    <Tooltip content={customTooltip} cursor={{fill: HOVER_COLOR}}/>
                    <Legend content={customLegend}/>
                    {KEYS.map((key) => (
                        <Bar key={key} dataKey={key} stackId="activities" fill={COLORS[key]}/>
                    ))}
                </BarChart>
            </ResponsiveContainer>
            <div className={"p-2"}></div>
            <div className="w-full flex space-x-2">
                {[t("statistics.user_activity_chart.day_of_the_week"), t("statistics.user_activity_chart.month_of_the_year")].map((g) => (
                    <BasicButton
                        key={g}
                        className={"w-full"}
                        buttonColor={`${granularity === g ? UIColor.Blue : UIColor.Default}`}
                        onClick={() => setGranularity(g)}
                    >
                        {g}
                    </BasicButton>
                ))}
            </div>
            <div className={"p-1"}></div>
            <div className="w-full flex space-x-2">
                {[t("statistics.user_activity_chart.day"), t("statistics.user_activity_chart.week"), t("statistics.user_activity_chart.month"), t("statistics.user_activity_chart.year")].map((g) => (
                    <BasicButton
                        key={g}
                        className={"w-full"}
                        buttonColor={`${granularity === g ? UIColor.Blue : UIColor.Default}`}
                        onClick={() => setGranularity(g)}
                    >
                        {g}
                    </BasicButton>
                ))}
            </div>
            <div className={"p-1"}></div>
            <div className="w-full flex flex-col gap-2">

                <div className="flex w-full gap-2 items-center">
                    <div className={"text-nowrap"}>
                        <p className={"w-16 text-right"}>{t("statistics.user_activity_chart.from")}:</p>
                    </div>
                    <DateInput background={UIColor.Default} className={"w-full"}
                               minValue={minDate} maxValue={maxDate} value={startDate}
                               onChange={(e) => setStartDate(e.target.value)}/>
                </div>
                <div className="flex w-full gap-2 items-center">
                    <div className={"text-nowrap"}>
                        <p className={"w-16 text-right"}>{t("statistics.user_activity_chart.to")}:</p>
                    </div>
                    <DateInput background={UIColor.Default} className={"w-full"}
                               minValue={minDate} maxValue={maxDate} value={endDate}
                               onChange={(e) => setEndDate(e.target.value)}/>
                </div>
            </div>
        </>
    );
}
