import {useRef, useState} from "react";
import YellowGreenProgressBar from "../settings/YellowGreenProgressBar";
import {BasicButton} from "./Button";
import {UIColor} from "../Config";
import {useTranslation} from "react-i18next";
import {ErrorType} from "../model/Backend";


class UploadState {
    static Idle = 0;
    static Uploading = 1;
    static Aborting = 2;
    static Success = 3;
    static Errored = 4;
}

export default function DynamicUploadButton(
    {
        uploadFunction,
        buttonText,
        onStart,
        onSuccess,
        onAbort,
        onError,
        disabled = false,
        textSize = "",
        className = ""
    }) {
    const {t} = useTranslation();

    const [state, setState] = useState(UploadState.Idle);

    const [zipProgress, setZipProgress] = useState(0);
    const [uploadProgress, setUploadProgress] = useState(0);

    function handleAbort() {
        setState(UploadState.Aborting);
        token.current.aborted = true;
        token.current?.xhr?.abort();
    }

    async function handleUpload() {
        setState(UploadState.Uploading);
        onStart?.();

        setZipProgress(0);
        setUploadProgress(0);

        const result = await uploadFunction(
            progress => setZipProgress(progress),
            progress => setUploadProgress(progress),
            token,
        );

        if (result.isSuccess()) {
            setState(UploadState.Success);
            onSuccess?.();
        } else {
            result.filterErrors(ErrorType.UserAborted)

            if (state === UploadState.Aborting) {
                onAbort?.();
            } else {
                onError?.(result);
                setState(UploadState.Errored);
            }
        }

        token.current.aborted = false;
    }

    const token = useRef({aborted: false});

    return <div className="flex justify-center gap-2 w-full">
        {
            (state === UploadState.Uploading || state === UploadState.Aborting)
                ? <>
                    <YellowGreenProgressBar
                        yellowProgress={zipProgress}
                        greenProgress={uploadProgress}
                        aborting={state === UploadState.Aborting}
                    />
                    <BasicButton
                        onClick={handleAbort}
                        buttonColor={UIColor.Red}
                        disabled={state === UploadState.Aborting}
                        className={`${textSize} w-fit`}
                    >
                        {t("rebuild.new_rebuild.cancel")}
                    </BasicButton>
                </>
                : <BasicButton
                    onClick={handleUpload}
                    disabled={disabled}
                    buttonColor={UIColor.Green}
                    className={`${textSize} ${className}`}
                >
                    {buttonText}
                </BasicButton>

        }
    </div>
}
