export const GRADE_SYSTEMS = {
    fontainebleau: {
        parse: (grade) => grade.toLowerCase(),
        format: (grade) => grade,
        isValid: (grade) => /^[0-9]+[abc][+]?$/.test(grade),
        compare: (a, b) => a.localeCompare(b, undefined, {numeric: true}),
        defaultMin: "6a",
        defaultMax: "8b+",
        enumerate: (min, max) => {
            const parseGrade = (grade) => {
                const match = grade.match(/^(\d+)([abc]?)([+-]?)$/);
                return {
                    number: parseInt(match[1], 10),
                    letter: match[2] || "a",
                    plus: match[3] || "",
                };
            };

            const formatGrade = (grade) => {
                return `${grade.number}${grade.letter}${grade.plus}`;
            };

            const start = parseGrade(min);
            const end = parseGrade(max);

            const result = [];

            const letters = ["a", "b", "c"];
            const pluses = ["", "+"];

            for (let i = start.number; i <= end.number; i++) {
                let j = 0;
                let j_to = letters.length - 1;

                if (i === start.number) {
                    j = letters.indexOf(start.letter);
                }

                if (i === end.number) {
                    j_to = letters.indexOf(end.letter);
                }

                for (; j <= j_to; j++) {
                    let k = 0;
                    let k_to = pluses.length - 1;

                    if (i === start.number) {
                        k = pluses.indexOf(start.plus);
                    }

                    if (i === end.number) {
                        k_to = pluses.indexOf(end.plus);
                    }

                    for (; k <= k_to; k++) {
                        result.push(formatGrade({number: i, letter: letters[j], plus: pluses[k]}));
                    }
                }
            }

            return result;
        },
    },
    v: {
        parse: (grade) => grade.toUpperCase(),
        format: (grade) => grade,
        isValid: (grade) => /^V[0-9]+$/.test(grade),
        compare: (a, b) => parseInt(a.substring(1)) - parseInt(b.substring(1)),
        defaultMin: "V0",
        defaultMax: "V12",
        enumerate: (min, max) => {
            const start = parseInt(min.substring(1));
            const end = parseInt(max.substring(1));
            const result = [];
            for (let i = start; i <= end; i++) {
                result.push(`V${i}`);
            }
            return result;
        },
    },
    numeric: {
        parse: (grade) => parseInt(grade, 10),
        format: (grade) => grade,
        isValid: (grade) => /^[0-9]+$/.test(grade),
        compare: (a, b) => parseInt(a) - parseInt(b),
        defaultMin: "1",
        defaultMax: "9",
        enumerate: (min, max) => {
            const start = parseInt(min, 10);
            const end = parseInt(max, 10);
            const result = [];
            for (let i = start; i <= end; i++) {
                result.push(`${i}`);
            }
            return result;
        },
    },
};

export const DEFAULT_GRADE_SYSTEM = {
    type: "fontainebleau",
    min: GRADE_SYSTEMS.fontainebleau.defaultMin,
    max: GRADE_SYSTEMS.fontainebleau.defaultMax,
};

