import * as THREE from "three";

export default class OutlineType {
    static HIGHLIGHT = new OutlineType("highlight", {
        edgeStrength: 3.0,
        edgeThickness: 0.5,
        edgeGlow: 1.0,
        color: new THREE.Color(0xffffff),
        hiddenColor: new THREE.Color(0xffffff),
        blending: THREE.AdditiveBlending,
    });
    static ATTEMPTED_ROUTE = new OutlineType("attempted_route", {
        edgeStrength: 3.0,
        edgeThickness: 0.5,
        edgeGlow: 1.0,
        color: new THREE.Color('red'),
        hiddenColor: new THREE.Color('red'),
    });
    static CLIMBED_ROUTE = new OutlineType("climbed_route", {
        edgeStrength: 6.0,
        edgeThickness: 0.5,
        edgeGlow: 1.0,
        color: new THREE.Color('green'),
        hiddenColor: new THREE.Color('green'),
    });
    static HOVER = new OutlineType("hover", {
        edgeStrength: 3.0,
        edgeThickness: 1.0,
        edgeGlow: 1.0,
        color: new THREE.Color(0x666666),
        hiddenColor: new THREE.Color(0x666666),
        blending: THREE.NoBlending,
    });

    constructor(typeId, params) {
        this.typeId = typeId;
        this.params = params;
    }
}
