import {useEffect, useReducer, useState} from "react";

class ScreenDimensions {
    static MOBILE_WIDTH = 640;  // Tailwind's SM; please don't fuck around with this
}

function useScreenDimensions() {
    const [dimensions, setDimensions] = useState([window.innerWidth, window.innerHeight]);

    function onResize() {
        setDimensions([window.innerWidth, window.innerHeight]);
    }

    useEffect(() => {
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    return dimensions;
}

function useForceUpdate() {
    return useReducer(x => x + 1, 0)[1];
}

export {useScreenDimensions, ScreenDimensions, useForceUpdate};
