import {useEffect, useState} from "react";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {faRotateRight} from "@fortawesome/free-solid-svg-icons";
import UserActivityChart from "./charts/UserActivityChart";
import PopularityChart from "./charts/PopularityChart";
import GradeBreakdownChart from "./charts/GradeBreakdownChart";
import {UserActivityType} from "../../model/User";
import {useTranslation} from "react-i18next";
import Spacer from "../../common/Spacer";
import CircuitBreakdownChart from "./charts/CircuitBreakdownChart";


export function generateMockData(n, m, k) {
    const activities = [];
    const routes = {};
    const grades = {};

    // Helper function to generate a valid date within February 2025
    function generateValidDate(dayOffset) {
        const baseDate = new Date(2025, 1, 18); // Start from 2025-02-18
        baseDate.setDate(baseDate.getDate() + dayOffset);
        return baseDate.toISOString().split('T')[0]; // Format as YYYY-MM-DD
    }

    // Generate activities
    const activityTypes = [UserActivityType.Attempt, UserActivityType.Climb, UserActivityType.Flash, UserActivityType.Repeat];
    for (let i = 0; i < n; i++) {
        const date = generateValidDate(i);
        const activityData = {};
        activityTypes.forEach(type => {
            activityData[type] = Math.floor(Math.random() * 50); // Random number between 0 and 50
        });
        activities.push({date, ...activityData});
    }

    // Generate routes
    for (let i = 1; i <= m; i++) {
        const routeName = `route${i + 1}`;
        const routeData = {};
        Object.values(UserActivityType).slice(4, 8).forEach(type => {
            routeData[type] = Math.floor(Math.random() * 20); // Random number between 0 and 20
        });
        routes[routeName] = routeData;
    }

    // Generate grades
    for (let i = 0; i < k; i++) {
        const grade = `5.${i + 1}`;
        grades[grade] = {
            count: Math.floor(Math.random() * 20 + 5), // Random number of attempts
            breakdown: {
                flashed_fraction: Math.random(),
                climbed_fraction: Math.random(),
                attempted_fraction: Math.random()
            }
        };

        if (Math.random() < 0.25) {
            grades[grade].breakdown = {
                flashed_fraction: 0,
                climbed_fraction: 0,
                attempted_fraction: 0
            };
        }

        // Normalize the breakdown to sum to 1
        const sum = Object.values(grades[grade].breakdown).reduce((a, b) => a + b, 0);
        Object.keys(grades[grade].breakdown).forEach(key => {
            if (sum !== 0) {
                grades[grade].breakdown[key] /= sum;
            }
        });
    }

    return {activities, routes, grades};
}

const mockData = generateMockData(200, 30, 12);


export default function OwnerStats() {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(mockData);

    const {t, i18n} = useTranslation();

    const refreshData = () => {
        setLoading(true);
        setTimeout(() => {
            setData(mockData); // Replace this with real data fetching logic
            setLoading(false);
        }, 1000);
    };

    useEffect(() => {
        refreshData();
    }, []);

    return (
        <>
            <div className="absolute right-6 text-xl">
                {loading ? (
                    <SpinLoadingIndicator/>
                ) : (
                    <ClickableFontAwesomeIcon
                        icon={faRotateRight}
                        className="p-1"
                        onClick={refreshData}
                    />
                )}
            </div>
            <h2 className="text-4xl text-center">{t("statistics.statistics")}</h2>
            <Spacer/>
            <UserActivityChart data={data.activities}/>
            <Spacer/>
            <PopularityChart/>
            <Spacer/>
            <GradeBreakdownChart top={true}/>
            <Spacer/>
            <CircuitBreakdownChart/>
        </>
    );
}
