import React, { createContext, useContext, useState } from 'react';

const BlobUrlContext = createContext();

export const useBlobUrl = () => {
    return useContext(BlobUrlContext);
};

// This is a disgusting memory leak
// Implemented like this since I don't want to bother with proplerly working with URLs

// Should be ok, since the only newly added URLs are when changing profile pictures
// or perhaps on leaderboard updates; can be fixed later when there is time.
export const BlobUrlProvider = ({ children }) => {
    const [blobUrlMap, setBlobUrlMap] = useState(new Map());

    const setBlobUrl = (key, value) => {
        setBlobUrlMap(prevMap => new Map(prevMap).set(key, value));
    };

    const getBlobUrl = (key) => {
        return blobUrlMap.get(key);
    };

    return (
        <BlobUrlContext.Provider value={{ setBlobUrl, getBlobUrl }}>
            {children}
        </BlobUrlContext.Provider>
    );
};
