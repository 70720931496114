import {BasicButton} from "../../common/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {UIColor} from "../../Config";
import {faCalendar, faFilter, faFilterCircleXmark, faHourglassStart} from "@fortawesome/free-solid-svg-icons";
import {RouteStateType} from "../../model/User";
import DraggableWindow from "../../common/DraggableWindow";
import {useTranslation} from "react-i18next";
import {useCallback, useContext, useEffect, useState} from "react";
import {UserContext} from "../../contexts/UserContext";
import {WallContext} from "../../contexts/WallContext";
import CircuitPicker from "../../settings/CircuitPicker";
import {Route} from "../../model/Wall";
import GradeRangePicker from "../../settings/GradeRangePicker";
import {GRADE_SYSTEMS} from "../../model/Climbing";
import {SetCanvasStateContext} from "../../contexts/CanvasContext";
import {RouteFocusState} from "../canvas/CanvasState";

class TimeType {
    static Newest = "newest";
    static Oldest = "oldest";
}

export default function RouteFilterPanel({onFilteredRoutesChange}) {
    const {t} = useTranslation();

    const setCanvasState = useContext(SetCanvasStateContext);

    const user = useContext(UserContext);
    const wall = useContext(WallContext);

    const [circuits, setCircuits] = useState([]);
    const [routeStateType, setRouteStateType] = useState([]);
    const [timeType, setTimeType] = useState(null);

    const [selectedGrades, setSelectedGrades] = useState([wall.value.grading.min, wall.value.grading.max]);

    const [filteredRoutes, setFilteredRoutes] = useState(wall.value.routes);


    useEffect(() => {
        setSelectedGrades([wall.value.grading.min, wall.value.grading.max]);
    }, [wall])

    useEffect(() => {
        let routes;
        if (timeType !== null) {
            routes = wall.value.getMostRecentRoutes(timeType === TimeType.Oldest)
        } else {
            routes = Route.sortByPosition(wall.value.routes);
        }

        if (circuits.length !== 0) {
            routes = routes.filter(route => circuits.includes(route.circuit));
        }

        if (routeStateType.length !== 0) {
            routes = routes.filter(route => routeStateType.includes(user.value.getRouteClimbingState(route)));
        }

        if (selectedGrades[0] !== wall.value.grading.min
            || selectedGrades[1] !== wall.value.grading.max) {
            const system = GRADE_SYSTEMS[wall.value.grading.type];

            routes = routes.filter(route =>
                route.grade
                && system.compare(selectedGrades[0], route.grade) <= 0
                && system.compare(route.grade, selectedGrades[1]) <= 0
            );
        }

        setFilteredRoutes(routes);
        onFilteredRoutesChange?.(routes)
    }, [user, wall, circuits, routeStateType, onFilteredRoutesChange, timeType, selectedGrades])

    const onClear = useCallback(() => {
        setCircuits([]);
        setRouteStateType([]);
        setTimeType(null);
        setSelectedGrades([wall.value.grading.min, wall.value.grading.max])
    }, [setTimeType, setCircuits, setRouteStateType, setSelectedGrades, wall]);

    const filtersOn = (
        circuits.length !== 0
        || timeType !== null
        || routeStateType.length !== 0
        || selectedGrades[0] !== wall.value.grading.min
        || selectedGrades[1] !== wall.value.grading.max
    );

    const loggedIn = user.value !== null;
    const hasCircuits = wall.value.hasCircuits();

    const areNewestOldestSame = wall.value.getMostRecentRoutes().length === wall.value.routes.length;

    const notSelected = 'transition-opacity bg-opacity-35 opacity-50';

    return <DraggableWindow
        dragSymbol={true}
        offsets={[60]}
        defaultPosition={1}
    >
        {
            !filtersOn ?
                <div className="absolute top-4 left-4 ">
                    <p className={"flex gap-2"}>
                        <FontAwesomeIcon
                            icon={faFilter}
                            className="text-xl"
                        />
                        <span className="font-bold">{t("route_filter_panel.filters")}</span>
                    </p>
                </div> :
                <BasicButton
                    padding={"py-1.5 px-1.5"} className="absolute top-3 left-3 "
                    onClick={onClear}
                >
                    <div>
                        <p className={"flex gap-2"}>
                            <FontAwesomeIcon
                                icon={faFilterCircleXmark}
                                className="text-xl"
                            />
                            <span className="font-bold">{t("route_filter_panel.clear")}</span>
                        </p>
                    </div>
                </BasicButton>
        }
        <BasicButton
            className="absolute top-3 right-3"
            padding={"py-1.5 px-1.5"}
            onClick={() => setCanvasState(new RouteFocusState(filteredRoutes[0]))}
            disabled={filteredRoutes.length === 0}
        >
            <pre className={"font-bold"}>{filteredRoutes.length}/{wall.value.routes.length}</pre>
        </BasicButton>
        <div className={"p-6"}/>
        <div className={"p-4 flex flex-col gap-2"}>
            {
                loggedIn ?
                    <>
                        <p className={"text-center font-bold"}>{t("route_filter_panel.attempts")}</p>
                        <div className={"flex w-full gap-2"}>
                            {
                                [
                                    RouteStateType.Untouched,
                                    RouteStateType.Attempted,
                                    RouteStateType.Climbed,
                                    RouteStateType.Flashed,
                                ].map(type =>
                                    <BasicButton
                                        buttonColor={RouteStateType.Color(type)}
                                        onClick={() => {
                                            setRouteStateType(prev =>
                                                prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
                                            );
                                        }}
                                        className={`w-full min-w-0 ${routeStateType.includes(type) ? '' : notSelected}`}
                                        key={`filter-${type}`}
                                    >
                                        <p className={"truncate"}>
                                            {t("route_state_type")[type]}
                                            <FontAwesomeIcon className={"pl-2"} icon={RouteStateType.Icon(type)}/>
                                        </p>
                                    </BasicButton>
                                )
                            }
                        </div>
                        <div className={"p-1"}/>
                    </> : <></>
            }
            {
                !areNewestOldestSame ?
                    <>
                        <p className={"text-center font-bold"}>{t("route_filter_panel.time")}</p>
                        <div className={"flex w-full gap-2"}>
                            <BasicButton
                                buttonColor={UIColor.DarkYellow}
                                className={`w-full min-w-0 ${timeType === TimeType.Newest ? "" : notSelected}`}
                                onClick={() => {
                                    if (timeType === TimeType.Newest) {
                                        setTimeType(null)
                                    } else {
                                        setTimeType(TimeType.Newest)
                                    }
                                }}
                            >
                                <p className={"truncate"}>
                                    {t("route_filter_panel.newest")}
                                    <FontAwesomeIcon className={"pl-2"} icon={faCalendar}/>
                                </p>
                            </BasicButton>
                            <BasicButton
                                className={`w-full min-w-0 ${timeType === TimeType.Oldest ? "" : notSelected}`}
                                onClick={() => {
                                    if (timeType === TimeType.Oldest) {
                                        setTimeType(null)
                                    } else {
                                        setTimeType(TimeType.Oldest)
                                    }
                                }}
                            >
                                <p className={"truncate"}>
                                    {t("route_filter_panel.oldest")}
                                    <FontAwesomeIcon className={"pl-2"} icon={faHourglassStart}/>
                                </p>
                            </BasicButton>
                        </div>
                        <div className={"p-1"}/>
                    </> : <></>
            }
            <p className={"text-center font-bold"}>{hasCircuits ? `${t("route_filter_panel.circuits")}/` : ''}{t("route_filter_panel.difficulty")}</p>
            {hasCircuits ? <CircuitPicker selectedCircuit={circuits} onChange={setCircuits} multi={true}/> : <> </>}
            <GradeRangePicker selectedGrades={selectedGrades} setSelectedGrades={setSelectedGrades}/>
        </div>
    </DraggableWindow>;
}
