import { createContext } from "react";
import { unimplementedFunction } from "../common/Utils";
import { UIColor } from "../Config";
import {faCheck, faExclamation, faInfo, faX} from "@fortawesome/free-solid-svg-icons";
import { ErrorFormatter } from "../common/ErrorFromBackendResult";
import {SimpleAction, useReducer} from "./StateManagement";

const NotificationContext = createContext([]);
const NotificationDispatchContext = createContext(unimplementedFunction);

class NotificationType {
    static Success = "Success";
    static Warning = "Warning";
    static Error = "Error";

    static Info = "Info";

    static Color(type) {
        switch (type) {
            case NotificationType.Success:
                return UIColor.Green;
            case NotificationType.Error:
                return UIColor.Red;
            case NotificationType.Warning:
                return UIColor.Yellow;
            default:
                return UIColor.Window;
        }
    }

    static Icon(type) {
        switch (type) {
            case NotificationType.Success:
                return faCheck;
            case NotificationType.Warning:
                return faExclamation;
            case NotificationType.Error:
                return faX;
            default:
                return faInfo;
        }
    }
}

class NotificationData {
    constructor(id, title, message, duration = null, type = null, onClick = null) {
        this.id = id;
        this.title = title;
        this.message = message;
        this.duration = duration;
        this.type = type ?? NotificationType.Info;
        this.onClick = onClick;
    }
}

class NotificationActions {
    static Add = "add";
    static Remove = "remove";
    static RemoveAll = "remove_all";
}

function createNotificationAction(notifications, actionData) {
    switch (actionData.type) {
        case NotificationActions.Add:
            return new SimpleAction(notifications => {
                const id = actionData.payload.id ||
                    `notification-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;

                const newNotification = new NotificationData(
                    id,
                    actionData.payload.title,
                    actionData.payload.error ?
                        <ErrorFormatter message={actionData.payload.error}/> :
                        actionData.payload.message,
                    actionData.payload.duration,
                    actionData.payload.type,
                    actionData.payload.onClick
                );

                // Return a new array with the new notification added
                return [...notifications, newNotification];
            }, actionData.postSuccessHooks);

        case NotificationActions.Remove:
            return new SimpleAction(notifications => {
                // Filter out the notification with the specified ID
                return notifications.filter(
                    notification => notification.id !== actionData.payload.id
                );
            }, actionData.postSuccessHooks);

        case NotificationActions.RemoveAll:
            return new SimpleAction(() => {
                // Clear all notifications
                return [];
            }, actionData.postSuccessHooks);

        default:
            console.error("Unknown action type:", actionData.type);
            throw Error("Unknown action: " + actionData.type);
    }
}

function useNotificationReducer() {
    // The initial state is an empty array, and we don't need tracking for notifications
    return useReducer(createNotificationAction, [], false);
}

export {
    NotificationContext,
    NotificationDispatchContext,
    NotificationData,
    NotificationActions,
    useNotificationReducer,
    NotificationType,
};
