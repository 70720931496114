import './Policies.css';
import ModalWindow from "../common/ModalWindow";
import {Contact} from "./Utilities";
import {ScreenDimensions, useScreenDimensions} from "../common/CustomHooks";
import Spacer from "../common/Spacer";

export default function PrivacyPolicy({...props}) {
    const [screenWidth, _] = useScreenDimensions();

    let width;
    if (screenWidth >= ScreenDimensions.MOBILE_WIDTH) {
        width = "w-[40rem] max-w-[calc(100%-2rem)]";
    } else {
        width = null;
    }

    return <ModalWindow title={"Privacy Policy"} width={width} {...props}>
        <div className={"policy"}>
            <p>Effective Date: <strong>26. 2. 2025</strong></p>

            <p>Welcome to Climbuddy, provided by Climber Tools s.r.o. ("we", "our", "us"). We are committed to
                protecting your privacy. This privacy policy explains how we collect, use, and disclose your personal
                information when you use our services, including our website and applications.</p>

            <Spacer/>

            <h2>1. Data Collection</h2>
            <p>We collect the following types of personal information when you use our services:</p>

            <h3>Personal Data</h3>
            <p>Personal data is information that we keep secure and do not share with anyone, except as required by law
                or with your explicit consent. This includes:</p>
            <ul>
                <li><strong>Email address:</strong> We collect your email address when you create an account or sign up
                    for our services. This is used for account management, notifications, and communication purposes.
                </li>
                <li><strong>Password hashes:</strong> For authentication, we store password hashes. Passwords are
                    securely processed using industry-standard cryptographic hashing techniques to ensure they cannot be
                    accessed or retrieved in plain text.
                </li>
            </ul>

            <h3>Public Data</h3>
            <p>Public data is information that you provide voluntarily and may be visible to other users or the public.
                This includes:</p>
            <ul>
                <li><strong>Nickname/Username:</strong> You may choose a nickname or username that will be visible to
                    other users within our platform.
                </li>
                <li><strong>Profile picture:</strong> You may upload a profile picture, which will be displayed publicly
                    on your profile.
                </li>
                <li><strong>User-generated content:</strong> This includes any content you voluntarily provide, such as
                    comments, feedback, or photos that may be shared within the platform or with other users.
                </li>
                <li><strong>Activity data:</strong> Information about your interactions with the platform, such as
                    climbing activities, routes completed, and progress, which may be visible to other users or shared
                    in public areas of the platform.
                </li>
            </ul>

            <Spacer/>

            <h2>2. How We Use Your Data</h2>
            <p>We use your data for the following purposes:</p>

            <h3>Personal Data</h3>
            <p>Personal data, such as your email address, is used solely for the following purposes:</p>
            <ul>
                <li><strong>Account management:</strong> To create, manage, and secure your account.</li>
                <li><strong>Communication:</strong> To send important account-related notifications, such as password
                    resets, updates, or service changes. We may also use your email to respond to support requests or
                    inquiries.
                </li>
                <li><strong>Security:</strong> To protect your account from unauthorized access and ensure the integrity
                    of our platform.
                </li>
            </ul>
            <p>Your personal data is not used for marketing or shared with third parties, except as required by law or
                with your explicit consent.</p>

            <h3>Public Data</h3>
            <p>Public data, such as your nickname, profile picture, user-generated content, and activity data, is used
                for the following purposes:</p>
            <ul>
                <li><strong>Platform interaction:</strong> To display your profile, activity, and user-generated content
                    to other users and the public.
                </li>
                <li><strong>Community features:</strong> To enable interactions with other users on the platform, such
                    as comments, feedback, and sharing of climbing achievements or experiences.
                </li>
                <li><strong>Improvement of services:</strong> To analyze user behavior and preferences, helping us
                    improve and personalize the platform for all users.
                </li>
            </ul>

            <Spacer/>

            <h2>3. Third-Party Sharing</h2>
            <p>We may share your data with trusted third parties, but only in the following cases:</p>

            <h3>Personal Data</h3>
            <p>Your personal data, such as your email address, is never shared with third parties except:</p>
            <ul>
                <li><strong>For legal compliance:</strong> If required by law, regulation, or legal process.</li>
                <li><strong>With your explicit consent:</strong> If you opt-in for any service or feature that requires
                    sharing your personal data.
                </li>
            </ul>

            <h3>Public Data</h3>
            <p>Your public data, such as your nickname, profile picture, user-generated content, and activity data, may
                be shared with:</p>
            <ul>
                <li><strong>Other users:</strong> Public data such as your nickname, profile picture, and activity data
                    may be visible to other users of the platform.
                </li>
                <li><strong>Climbing staff:</strong> For analytics purposes to improve the service at specific climbing
                    locations. This includes your activity data, achievements, and other public profile information.
                </li>
            </ul>


            <Spacer/>

            <h2>4. Data Retention</h2>

            <p>We retain your personal data for as long as your account remains active. If you decide to delete your
                account, we will remove your personal data in accordance with our data retention policy, unless we are
                required to keep it for legal or regulatory purposes. Data associated with your account may be retained
                in
                backup archives for a limited period as part of our system management.</p>

            <Spacer/>

            <h2>5. Your Rights</h2>
            <p>You have the following rights regarding your personal data:</p>
            <ul>
                <li><strong>Access:</strong> You can request access to the personal data we hold about you.</li>
                <li><strong>Correction:</strong> If any of your personal data is incorrect or incomplete, you have the
                    right to request correction.
                </li>
                <li><strong>Deletion:</strong> You can request the deletion of your personal data. Please note that if
                    you choose to delete your account, certain data may be retained for legal or operational purposes.
                </li>
                <li><strong>Portability:</strong> You may request that your personal data be
                    provided in a structured, commonly used, and machine-readable format for transfer to another service
                    provider.
                </li>
            </ul>

            <Spacer/>

            <h2>6. Security</h2>
            <p>We take appropriate technical and organizational measures to protect your personal data from unauthorized
                access, alteration, disclosure, or destruction. This includes encryption and secure storage practices.
                However, no method of transmission over the internet or method of electronic storage is 100% secure, so
                while we strive to protect your personal information, we cannot guarantee its absolute security.</p>

            <Spacer/>

            <h2>7. Cookies</h2>
            <p>We use essential cookies that are required for the website to function properly. These cookies allow you
                to navigate our website and use its features, such as accessing secure areas and remembering your
                preferences. These cookies are necessary for the website's basic functionality and cannot be
                disabled.</p>
            <p>We do not use any non-essential cookies or similar technologies for tracking or analytics purposes. You
                can control the use of cookies through your browser settings, but please note that disabling essential
                cookies may affect the functionality of the website.</p>

            <Spacer/>

            <h2>8. Changes to This Policy</h2>
            <p>We may update this privacy policy from time to time. Any changes will be posted on this page with an
                updated effective date.</p>

            <Spacer/>

            <Contact/>
        </div>
    </ModalWindow>;
}
