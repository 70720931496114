import {Hold, Route, Wall} from "./Wall.js";
import {User} from "./User";
import RebuildProposal, {RebuildStatus} from "./RebuildProposal";
import {CookieUtils, ImageUtils, NumberUtils, SpecialStates} from "../common/Utils";

import * as THREE from "three";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader.js";
import {DRACOLoader, GLTFExporter} from "three/addons";
import JSZip from "jszip";
import {Leaderboard} from "./Leaderboard";

class BackendResult {
    constructor(result, errors) {
        this.data = result;
        this.errors = errors;
    }

    static FromError(error) {
        return BackendResult.FromErrors([error]);
    }

    static FromErrors(errors) {
        return new BackendResult(null, errors);
    }

    static FromSuccess(result) {
        return new BackendResult(result, null);
    }

    static EmptySuccess() {
        return new BackendResult({}, null);
    }

    isSuccess() {
        return this.errors === null || this.errors.length === 0;
    }

    filterErrors(errorTypeToRemove) {
        if (this.errors) {
            this.errors = this.errors.filter(error => error !== errorTypeToRemove);
        }
    }
}

class ErrorType {
    static ConnectionError = "connection_error";
    static UnexpectedError = "unexpected_error";
    static CredentialsError = "credentials_error";
    static UnauthorizedError = "unauthorized_error";
    static EndpointNotFound = "endpoint_not_found";
    static UserAborted = "user_aborted";
    static WallDataFailed = "wall_data_failed";
    static PageDoesNotExist = "page_does_not_exist";
    static PasswordsDontMatch = "passwords_dont_match";
    static NotAgreedToTos = "not_agreed_to_tos";
}

class Backend {
    constructor(useLocalFiles = false) {
        const devEnv = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

        this.baseUrl = window.location.origin + "/api";

        if (devEnv) {
            this.baseUrl = "http://localhost:5130";

            if (process.env.REACT_APP_USE_PROD_SERVER) {
                console.warn("Developing with production backend!")
                this.baseUrl = "https://climbuddy.com/api";
            } else if (process.env.REACT_APP_USE_TEST_SERVER) {
                this.baseUrl = "https://test.climbuddy.com/api";
            }
        }

        // this will be loaded by using initWallId
        this.valid = false;
        this.wallId = null;
    }

    extractWallName() {
        const match = window.location.pathname.match(/^\/app\/([^\/]+)/);
        return match ? match[1] : null;
    }

    async initWallId() {
        this.wallName = this.extractWallName() ?? "demo";

        return new Promise(async (resolve, reject) => {
            if (!this.wallName) {
                reject(new Error(`Wall name ${this.wallName} not found in URL.`));
                return;
            }

            try {
                this.wallId = await this._getWallId(this.wallName);

                console.log("Wall ID: " + this.wallId);
                this.valid = true;
                resolve(this.wallId);
            } catch (error) {
                reject(new Error("Failed to fetch Wall ID: " + error.message));
            }
        });
    }

    async _getWallId(wallName) {
        let result = await this._loadJson(`${this.baseUrl}/wall/get_wall_id/${wallName}`);

        return result.id;
    }

    async loadWall(onProgress, t) {
        const progressWeights = [70, 5, 10, 10, 5];
        const partialProgress = [0, 0, 0, 0, 0];
        const progressMottos = t("loading.mottos");

        onProgress(0.0);

        function onPartialProgress(objectIdx, progress) {
            partialProgress[objectIdx] = progress;
            const totalProgress = NumberUtils.weightedAverage(partialProgress, progressWeights);

            const mottoIdx = Math.trunc(totalProgress * progressMottos.length);

            onProgress(totalProgress, progressMottos[mottoIdx]);
        }

        const holdsPromise = this._loadBackendGLTF(
            `${this.baseUrl}/wall/get_holds_mesh/${this.wallId}`,
            p => onPartialProgress(0, p),
            false,
            false,
        );

        const wallPromise = this._loadBackendGLTF(
            `${this.baseUrl}/wall/get_wall_mesh/${this.wallId}`,
            p => onPartialProgress(1, p),
            true,
            true,
            false,
        );
        const additionalMeshPromise = this._loadBackendGLTF(
            `${this.baseUrl}/wall/get_additional_mesh/${this.wallId}`,
            p => onPartialProgress(2, p),
            false,
            true,
            false,
        );
        const routesPromise = this._loadJson(`${this.baseUrl}/wall/get_routes/${this.wallId}`);
        const metadataPromise = this._loadJson(`${this.baseUrl}/wall/get_wall_metadata/${this.wallId}`);

        await routesPromise;
        await metadataPromise;

        const [holdsMesh, wallMesh, additionalMesh, routes, metadata] =
            await Promise.all([holdsPromise, wallPromise, additionalMeshPromise, routesPromise, metadataPromise]);
        onPartialProgress(3, 1.0);
        onPartialProgress(4, 1.0);

        const wall = Wall.fromServerData(wallMesh, additionalMesh, holdsMesh, routes, metadata);
        onProgress(1.0);
        return wall;
    }

    // Wrapper for _loadGLTF that captures the common use cases for the displayed meshes
    async _loadBackendGLTF(url, onProgress = null, forceDoubleSided = false, single = false, transparent = true) {
        const wallObject = await this._loadGLTF(url, onProgress);
        console.assert(wallObject.scenes.length === 1);
        const wallGroup = wallObject.scene;
        console.assert(wallGroup.children.length === 1);

        wallGroup.traverse(node => {
            if (node.material) {
                if (forceDoubleSided) {
                    node.material.side = THREE.DoubleSide;
                }

                if (transparent) {
                    node.material.transparent = true;
                }

                node.material.metalness = 0;
            }
        });

        if (single) {
            return wallGroup.children[0];
        } else {
            return wallGroup;
        }
    }

    _loadGLTF(modelUrl, onProgress = null) {
        return new Promise(async (resolve, reject) => {
            const loader = new GLTFLoader();

            const draco = new DRACOLoader();
            draco.setDecoderConfig({type: 'js'});
            draco.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');
            loader.setDRACOLoader(draco);

            console.log(`Loading mesh from "${modelUrl}".`);
            loader.load(
                modelUrl,
                mesh => {
                    resolve(mesh);
                },
                xhr => {
                    if (onProgress === null)
                        return;
                    if (xhr.lengthComputable) {
                        onProgress(xhr.loaded / xhr.total);
                    }
                },
                error => {
                    console.error("Error loading the model: " + error);
                    reject(error);
                });
        });
    }

    _saveGLTF(mesh, fileName) {
        return new Promise((resolve, reject) => {
            const exporter = new GLTFExporter();

            exporter.parse(
                mesh,
                gltf => {
                    let file;
                    if (gltf instanceof ArrayBuffer) {
                        file = new File([gltf], fileName, {type: 'model/gltf-binary'});
                    } else {
                        file = new File([JSON.stringify(gltf)], fileName, {type: 'application/json'});
                    }
                    resolve(file);
                },
                error => {
                    console.error('Error exporting the model:', error);
                    reject(error);
                },
                {binary: true}
            );
        });
    }

    _loadJson(url) {
        return fetch(url)
            .then(async res => {
                if (!res.ok)
                    throw new Error(await res.text())
                return await res.json();
            })
            .catch(error => {
                throw error;
            });
    }

    async login(loginData) {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/login/${this.wallId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(ErrorType.ConnectionError);
        }
        if (response.status === 401) {
            return BackendResult.FromError(ErrorType.CredentialsError);
        }
        if (!response.ok) {
            const error = await response.text();
            console.error(error);
            return BackendResult.FromError(ErrorType.UnexpectedError);
        }

        return BackendResult.FromSuccess(await User.fromMetadata(await response.json()));
    }

    async leaderboard() {
        const leaderboardData = await this._loadJson(`${this.baseUrl}/wall/get_leaderboard/${this.wallId}`);

        const leaderboard = await Leaderboard.fromList(leaderboardData.entries);

        return BackendResult.FromSuccess(leaderboard);
    }

    async updatePassword(passwordData) {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/update_password`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(passwordData),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(ErrorType.ConnectionError);
        }

        if (!response.ok) {
            let errors;
            try {
                console.log(response);
                errors = await response.json();
            } catch (error) {
                console.log(error);
                return BackendResult.FromError(ErrorType.UnexpectedError);
            }
            console.warn(errors);
            return BackendResult.FromErrors(errors);
        }

        return BackendResult.EmptySuccess();
    }

    async deleteAccount(data) {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/delete_account`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(ErrorType.ConnectionError);
        }

        if (!response.ok) {
            let errors;
            try {
                errors = await response.json();
            } catch (error) {
                console.log(error);
                return BackendResult.FromError(ErrorType.UnexpectedError);
            }
            console.warn(errors);
            return BackendResult.FromErrors(errors);
        }

        CookieUtils.deleteCookie("session_signature");
        return BackendResult.EmptySuccess();
    }

    async register(registerData) {
        if (registerData.activities) {
            registerData.activities = registerData.activities.map(activity => ({
                routeId: activity.route.id,
                type: activity.type,
                timestampUtc: activity.timestamp.getTime(),
            }));
        }

        let response;
        try {
            if (registerData.photo instanceof Blob) {
                registerData.photo = await ImageUtils.blobToBase64(registerData.photo);
            }

            response = await fetch(`${this.baseUrl}/account/register/${this.wallId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(registerData),
            });
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(ErrorType.ConnectionError);
        }

        if (!response.ok) {
            let errors;
            try {
                errors = await response.json();
            } catch (error) {
                console.log(error);
                return BackendResult.FromError(ErrorType.UnexpectedError);
            }
            console.warn(errors);
            return BackendResult.FromErrors(errors);
        }

        return BackendResult.FromSuccess(await User.fromMetadata(await response.json()));
    }

    async checkIfLoggedIn() {
        let response;
        try {
            response = await fetch(`${this.baseUrl}/account/get_climber_info/${this.wallId}`);
        } catch {
            // Do nothing.
            return BackendResult.FromSuccess(null);
        }
        if (response.ok) {
            return BackendResult.FromSuccess(await User.fromMetadata(await response.json()));
        }
        return BackendResult.FromSuccess(null);
    }

    logout() {
        fetch(`${this.baseUrl}/account/logout`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        }).then(response => {
            if (!response.ok) {
                response.text().then(e => console.error(e));
            }
        }).catch(e => {
            console.error(e);
        })
        CookieUtils.deleteCookie("session_signature");
    }

    async addUserActivities(activities) {
        const activityData = activities.map(activity => ({
            routeId: activity.route.id,
            type: activity.type,
            timestampUtc: activity.timestamp.getTime(),
        }));

        return await this._postJsonRequest("climber/add_activity", activityData);
    }

    async modifyUserActivities(activities) {
        const activityData = activities.map(activity => ({
            id: activity.id,
            routeId: activity.route.id,
            type: activity.type,
            timestampUtc: activity.timestamp.getTime(),
        }));

        return await this._postJsonRequest("climber/modify_activity", activityData);
    }

    async removeUserActivities(activities) {
        const activityData = activities.map(activity => ({id: activity.id}));

        return await this._postJsonRequest("climber/remove_activity", activityData);
    }

    async saveUserMetadata(newMetadata) {
        return await this._postJsonRequest("climber/set_metadata", {
            nickname: newMetadata.nickname,
            photo: newMetadata.photo,
        })
    }

    async submitFeedback(feedbackText) {
        return await this._postJsonRequest("feedback/send", {text: feedbackText});
    }

    async forgotPassword(email) {
        return await this._postJsonRequest("account/forgot_password", {email: email});
    }

    async resendVerificationEmail() {
        return await this._postJsonRequest("account/resend_verification_email");
    }

    async verifyEmail(token, userId) {
        return await this._postJsonRequest("account/verify_email", {token: token, userId: userId});
    }

    async resetPassword(token, userId, password) {
        return await this._postJsonRequest("account/reset_password", {
            token: token,
            userId: userId,
            password: password
        });
    }

    async _postJsonRequest(relativeUrl, data = null) {
        let response;
        try {
            const url = `${this.baseUrl}/${relativeUrl}`;
            if (data == null) {
                response = await fetch(url, {method: "POST"});
            } else {
                response = await fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                });
            }
        } catch (error) {
            console.error(error);
            return BackendResult.FromError(ErrorType.ConnectionError);
        }

        if (!response.ok) {
            if (response.status === 401) {
                return BackendResult.FromError(ErrorType.UnauthorizedError);
            } else if (response.status === 404) {
                return BackendResult.FromError(ErrorType.EndpointNotFound);
            } else {
                const error = await response.text();
                console.error(error);
                return BackendResult.FromError(ErrorType.UnexpectedError);
            }
        }

        try {
            return BackendResult.FromSuccess(await response.json());
        } catch (error) {
            return BackendResult.EmptySuccess();
        }
    }

    async saveWallMetadata(newMetadata) {
        return await this._postJsonRequest(
            `wall_owner/set_wall_metadata/${this.wallId}`,
            {data: JSON.stringify(newMetadata)}
        );
    }

    async getPendingProposal() {
        let proposalMetadata, holdsPromise, routesPromise, diffPromise;

        if (this.useLocalFiles) {
            proposalMetadata = {
                id: 42,
                name: "Test Proposal",
                readyForRevision: true,

                metadata: {
                    step_current: 0,
                    step_total: 10,
                    status: RebuildStatus.Running,
                    timestamp: 1743077266.800448,
                }
            };

            holdsPromise = this._loadBackendGLTF("/mock/data/proposal/new_mesh.glb");
            routesPromise = this._loadJson("/mock/data/proposal/new_routes.json");
            diffPromise = this._loadJson("/mock/data/proposal/diff.json");
        } else {
            let proposalRequest;

            try {
                proposalRequest = await fetch(`${this.baseUrl}/wall_owner/proposal/get/${this.wallId}`);
            } catch (error) {
                console.error(error);
                return BackendResult.FromError(ErrorType.ConnectionError);
            }

            if (!proposalRequest.ok) {
                console.error(await proposalRequest.text());
                return BackendResult.FromError(ErrorType.UnexpectedError);
            }

            proposalMetadata = await proposalRequest.json();
            if (!proposalMetadata.exists) {
                return BackendResult.FromSuccess(SpecialStates.NonePending);
            }

            if (!proposalMetadata.readyForRevision)
                return BackendResult.FromSuccess(RebuildProposal.NotReady(proposalMetadata));

            holdsPromise = this._loadBackendGLTF(
                `${this.baseUrl}/wall_owner/proposal/get_holds_mesh/${this.wallId}/${proposalMetadata.id}`
            );

            routesPromise = this._loadJson(
                `${this.baseUrl}/wall_owner/proposal/get_routes/${this.wallId}/${proposalMetadata.id}`
            );

            diffPromise = this._loadJson(
                `${this.baseUrl}/wall_owner/proposal/get_diff/${this.wallId}/${proposalMetadata.id}`
            );
        }

        const holdsMesh = await holdsPromise;
        const holds = Hold.fromGLB(holdsMesh, false);
        const routes = Route.fromServerData((await routesPromise).map(route => ({
            staticData: route,
            dynamicData: {}
        })), holds, holdsMesh);

        return BackendResult.FromSuccess(new RebuildProposal(
            proposalMetadata,
            holds,
            holdsMesh,
            routes,
            await diffPromise,
        ));
    }

    async uploadNewRebuild(files, onProgress = null, onUploadProgress = null, signal) {
        const endpoint = `${this.baseUrl}/wall_owner/new_rebuild/${this.wallId}`;

        const additionalFormData = {rebuildName: "automaticProposal"};

        return await this.uploadFile(
            files, endpoint, "photosArchive", "photos.zip", additionalFormData,
            onProgress, onUploadProgress, signal
        );
    }

    async uploadProposal(proposalId, newHoldsMesh, newRoutes, onProgress = null, onUploadProgress = null, signal) {
        let endpoint = `${this.baseUrl}/wall_owner/proposal/upload/${this.wallId}/${proposalId}`;

        const newHoldsFile = this._saveGLTF(newHoldsMesh, "holds.glb");

        const jsonString = JSON.stringify(newRoutes.map(route => route.toJSON()));
        const newRoutesFile = new File([jsonString], "routes.json", {type: "application/json"});

        return await this.uploadFile(
            [newHoldsFile, newRoutesFile], endpoint, "proposal", "proposal.zip", {},
            onProgress, onUploadProgress, signal
        )
    }

    async setRoutes(jsonRoutes) {
        return await this._postJsonRequest(
            `wall_owner/set_routes/${this.wallId}`,
            {routes: jsonRoutes}
        );
    }

    async uploadFile(files, endpoint, fileKey, fileName, additionalFormData = {}, onProgress = null, onUploadProgress = null, signal = {current: {}}) {
        if (signal.current?.aborted) {
            return BackendResult.FromError(ErrorType.UserAborted);
        }

        const zip = new JSZip();

        for (let i = 0; i < files.length; i++) {
            zip.file(files[i].name, files[i]);
        }

        let filesDone = 0;
        let lastFile = null;

        try {
            const blob = await zip.generateAsync({
                type: "blob",
                compression: "DEFLATE",
                compressionOptions: {level: 9},
            }, (updateMetadata) => {
                if (signal.current?.aborted) throw new Error();

                if (updateMetadata.currentFile === null) return;
                if (lastFile !== null && updateMetadata.currentFile !== lastFile) {
                    filesDone++;
                }
                const progress = ((filesDone * 100) + updateMetadata.percent) / (files.length * 100) * 100;
                onProgress?.(progress);

                console.debug("zipping:", progress);
                lastFile = updateMetadata.currentFile;
            });

            if (signal.current?.aborted) {
                return BackendResult.FromError(ErrorType.UserAborted);
            }

            console.log("Uploading blob of size " + blob.size);
            const formData = new FormData();

            for (const [key, value] of Object.entries(additionalFormData)) {
                formData.append(key, value);
            }

            formData.append(fileKey, blob, fileName);

            return await new Promise((resolve) => {
                const xhr = new XMLHttpRequest();
                if (signal.current) signal.current.xhr = xhr; // Store the request so it can be aborted externally

                xhr.open("POST", endpoint, true);

                xhr.upload.onprogress = (event) => {
                    if (event.lengthComputable) {
                        const percent = (event.loaded / event.total) * 100;
                        onUploadProgress?.(percent);
                        console.debug("uploading:", percent);
                    }
                };

                xhr.onload = () => {
                    if (xhr.status === 200) {
                        resolve(BackendResult.EmptySuccess());
                    } else if (xhr.status === 413) {
                        resolve(BackendResult.FromError(ErrorType.UploadTooLarge));
                    } else {
                        resolve(BackendResult.FromError(ErrorType.UnexpectedError));
                    }
                };

                xhr.onabort = () => {
                    resolve(BackendResult.FromError(ErrorType.UserAborted));
                };

                xhr.onerror = () => {
                    resolve(BackendResult.FromError(ErrorType.ConnectionError));
                };

                xhr.send(formData);

                // Check for cancellation and abort if needed
                if (signal.current?.aborted) {
                    xhr.abort();
                    resolve(BackendResult.FromError(ErrorType.UserAborted));
                }
            });
        } catch (error) {
            console.error(signal.current);
            return BackendResult.FromError(ErrorType.UserAborted);
        } finally {
            if (signal.current?.xhr) {
                delete signal.current.xhr; // Clean up the stored request
            }
        }
    }

    async rejectProposal(proposalId) {
        return await this._postJsonRequest(
            `wall_owner/proposal/reject/${this.wallId}/${proposalId}`,
        );
    }
}

const backend = new Backend(false);

export {Backend, backend, BackendResult, ErrorType};