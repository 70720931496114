import {useTranslation} from "react-i18next";
import LanguageSelector from "../../common/LanguageSelector";

export default function SideLanguages() {
    const { t } = useTranslation();

    return (
        <div className="text-center">
            <h2 className="text-4xl">{t("languages.language")}</h2>
            <div className="mb-6"></div>

            <LanguageSelector/>
        </div>
    );
}
