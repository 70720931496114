import React, {useRef, useState} from 'react';
import QRCode from 'react-qr-code';
import {BasicButton} from "./Button";
import {UIColor} from "../Config";
import {useTranslation} from "react-i18next";

const DownloadableQRCode = ({value, logoUrl = "/icon.png"}) => {
    const [inverted, setInverted] = useState(false);

    const qrRef = useRef(null);

    const {t} = useTranslation();

    const handleDownload = (padding, fill) => {
        const svgElement = qrRef.current.querySelector('svg');

        console.log(svgElement)
        if (svgElement) {

            const svgData = new XMLSerializer().serializeToString(svgElement);
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const img = new Image();
            img.onload = () => {
                canvas.width = img.width + padding * 2;
                canvas.height = img.height + padding * 2;
                ctx.fillStyle = fill;
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.drawImage(img, padding, padding);

                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = 'qrcode.png';
                link.click();
            };
            img.src = `data:image/svg+xml;base64,${window.btoa(decodeURIComponent(encodeURIComponent(svgData)))}`;
        }
    };

    return (
        <div className="flex flex-col items-center gap-3">
            <div ref={qrRef} className="w-full">
                <QRCode
                    className={`w-3/4 max-w-64 mx-auto h-auto p-2 rounded-xl ${inverted ? UIColor.Black : UIColor.White}`}
                    value={value}
                    fgColor={inverted ? "#ffffff" : "#000000"}
                    bgColor={!inverted ? "#ffffff" : "#000000"}
                />
            </div>
            <div className={"flex gap-3"}>
                <BasicButton
                    buttonColor={inverted ? UIColor.Black : UIColor.White}
                    onClick={() => handleDownload(10, !inverted ? "#ffffff" : "#000000")}
                    transition={false}
                >
                    <p className={`${inverted ? UIColor.WhiteText : UIColor.BlackText} font-bold`}>
                        {t("qr.download")}
                    </p>
                </BasicButton>
                <BasicButton
                    onClick={() => setInverted(!inverted)}
                >
                    {t("qr.invert")}
                </BasicButton>
            </div>
        </div>
    );
};

export default DownloadableQRCode;
