import {Fragment, useContext, useEffect, useState} from "react";
import ModalWindow from "../../common/ModalWindow";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowUpWideShort,
    faBolt,
    faCrown,
    faEllipsisV,
    faMountain,
    faRotateRight,
    faUserSecret
} from "@fortawesome/free-solid-svg-icons";
import {LeaderboardActions, LeaderboardContext, LeaderboardDispatchContext} from "../../contexts/LeaderboardContext";
import SpinLoadingIndicator from "../../common/SpinLoadingIndicator";
import ProgressGrid from "./ProgressGrid";
import {UiActions, UiDispatchContext} from "../../contexts/UiContext";
import {RouteStateType} from "../../model/User";
import ProfilePicture from "./ProfilePicture";
import {UserContext} from "../../contexts/UserContext";
import {WallContext} from "../../contexts/WallContext";
import ClickableFontAwesomeIcon from "../../common/ClickableFontAwesomeIcon";
import {UIColor} from "../../Config";
import TextLink from "../../common/TextLink";
import {Trans, useTranslation} from "react-i18next";

function LeaderboardUser({entry, setCurrentUserClicked}) {
    const getPictureSize = (rank) => {
        if (rank === 1) return "w-7 h-7";
        if (rank === 2 || rank === 3) return "w-6 h-6";
        return "w-5 h-5";
    };

    const getTextSize = (rank) => {
        if (rank === 1) return 'text-[135%]';
        if (rank === 2) return 'text-[125%]';
        if (rank === 3) return 'text-[115%]';
        return 'text-[100%]';
    };

    let nameStyle = entry.current ? 'font-bold' : '';
    let rankStyle = (entry.rank <= 3 ? "" : UIColor.MinorText) + " w-6";

    let color = entry.current ? "border-4 border-slate-300 bg-slate-500 hover:bg-slate-600 hover:border-slate-500" : "bg-slate-700";

    return (
        <div
             className={`${getTextSize(entry.rank)} flex items-center ${entry.current ? 'cursor-pointer' : ''}`}
             onClick={
                 () => {
                     if (entry.current)
                         setCurrentUserClicked();
                 }
             }
        >
            <div className="text-right">
                {(entry.rank !== 1) ? <p className={rankStyle}>{entry.rank}.</p> : <FontAwesomeIcon icon={faCrown}/>}
            </div>
            <div className={`flex grow min-w-0 items-center gap-1.5 rounded-xl ml-2 px-2 py-1 ${color}`}>
                <ProfilePicture
                    imageEnabledObject={entry}
                    pictureSize={getPictureSize(entry.rank)}
                />
                <div className="min-w-0 flex-1">
                    <p className={`${nameStyle} truncate`}>
                        {entry.nickname ?? <FontAwesomeIcon icon={faUserSecret}/>}
                    </p>
                </div>
                <p className={nameStyle}><code>{entry.rating.toFixed(0)}</code></p>
            </div>
        </div>
    );
}

function Leaderboard({onCurrentClick}) {
    const leaderboard = useContext(LeaderboardContext);

    let leaderboardList = [];
    if (leaderboard.value !== null) {
        let lastEntry = null;
        for (let entry of leaderboard.value.sortedEntries()) {
            // Spacer dots if there are spaces in the rankings
            if (lastEntry !== null && Math.abs(entry.rank - lastEntry.rank) > 1) {
                leaderboardList.push(
                    <div key={`ellipsis-spacer-${entry.rank}`} className={`m-1 text-center ${UIColor.MinorText}`}>
                        <FontAwesomeIcon icon={faEllipsisV} className="pl-6"/>
                    </div>
                )
            }

            leaderboardList.push(
                <Fragment key={`leaderboard-${entry.rank}-${entry.nickname}`}>
                    <LeaderboardUser
                        entry={entry}
                        setCurrentUserClicked={onCurrentClick}
                    />
                    <div className="pb-1.5"></div>
                </Fragment>
            )

            lastEntry = entry;
        }

        leaderboardList.push(
            <div key={`ellipsis-spacer-last`} className={`m-1 text-center ${UIColor.MinorText}`}>
                <FontAwesomeIcon icon={faEllipsisV} className="pl-6"/>
            </div>
        )
    }

    return <>
        {leaderboardList}
    </>;
}

function ScoreBreakdownModal({onClose, ...props}) {
    const [sorted, setSorted] = useState(false);

    const uiStateDispatch = useContext(UiDispatchContext);

    const wall = useContext(WallContext);
    const user = useContext(UserContext);

    const {t, i18n} = useTranslation();

    if (user.value === null)
        return <></>;

    let points = user.value.getRoutesPoints(wall.value.routes);

    return <ModalWindow title={t("leaderboard.your_rating")} handleClose={onClose} {...props}>
        <ClickableFontAwesomeIcon
            icon={faArrowUpWideShort}
            className="absolute right-6 text-xl"
            onClick={() => setSorted(!sorted)}
            isClicked={sorted}
        />
        <p>{t("leaderboard.points_1")} <strong><code>{points.toFixed(0)}</code></strong> {t("leaderboard.points_2")}.
        </p>
        <div className="mb-4"></div>
        <ProgressGrid usePoints={true} sortPoints={sorted} onCellClick={
            () => {
                // clicking on cells closes the modal, side panel and focuses on the route
                uiStateDispatch({type: UiActions.CloseSidePanel});
                onClose()
            }}
        />
    </ModalWindow>;
}


function ScoreExplanationModal({onClose, ...props}) {
    const {t, i18n} = useTranslation();

    return <ModalWindow title={t("leaderboard.point_system")} handleClose={onClose} justify={true} {...props}>
        <p>
            <Trans
                i18nKey="leaderboard.point_system_1"
                components={{strong: <strong/>, code: <code/>}}
            />
        </p>
        <div className="mb-4"></div>
        <p>
            {t("leaderboard.point_system_2")}
            {" "}
            <span
                className={RouteStateType.Color(RouteStateType.Climbed) + ' py-0.5 px-1 rounded font-bold'}><FontAwesomeIcon
                icon={faMountain}
                className="pr-1"/>{t("leaderboard.point_system_3")}</span> {t("leaderboard.point_system_4")}
        </p>
        <div className="mt-2 text-center"><code>100 / 6
            = <strong>16.6</strong></code> {t("leaderboard.point_system_5")}.
        </div>
        <div className="mb-4"></div>
        <p>
            <Trans
                i18nKey="leaderboard.point_system_6"
                components={{strong: <strong/>, em: <em/>}}
            />
        </p>
        <div className="mb-4"></div>
        <p>
            {t("leaderboard.point_system_7")} <span
            className={RouteStateType.Color(RouteStateType.Flashed) + ' py-0.5 px-1 rounded font-bold'}><FontAwesomeIcon
            icon={faBolt} className="pr-1"/>{t("leaderboard.point_system_8")}</span> <Trans
            i18nKey="leaderboard.point_system_9"
            components={{strong: <strong/>, code: <code/>}}
        />
        </p>
        <div
            className="mt-2 text-center"><code>16.6 * 1.5
            = <strong>25</strong></code> {t("leaderboard.point_system_5")}.
        </div>
    </ModalWindow>;
}

export default function SideLeaderboard() {
    const [scoreExplanationOpen, setScoreExplanationOpen] = useState(false);
    const [scoreBreakdownOpen, setScoreBreakdownOpen] = useState(false);

    const user = useContext(UserContext);

    const leaderboard = useContext(LeaderboardContext);
    const leaderboardDispatch = useContext(LeaderboardDispatchContext);

    const {t} = useTranslation();

    useEffect(() => {
        leaderboardDispatch(
            {
                type: LeaderboardActions.Update,
                trackingId: "doNotTrack",
            }
        )
    }, [user]);

    let explanation = <>
        <div className="mb-2"></div>
        <div className={`text-center ${UIColor.MinorText}`}><p><TextLink
            onClick={() => setScoreExplanationOpen(true)} bold={true}>{t("leaderboard.how")}</TextLink>
        </p></div>
    </>;

    return (
        <>
            <div className="absolute right-6 text-xl">
                {leaderboard.status.isLoading(LeaderboardActions.Update) ?
                    <SpinLoadingIndicator/>
                    : <ClickableFontAwesomeIcon
                        icon={faRotateRight}
                        className="p-1"
                        onClick={() => leaderboardDispatch({type: LeaderboardActions.Update})}
                    />
                }
            </div>
            <h2 className="text-4xl text-center">{t("leaderboard.name")}</h2>
            <div className="mb-6"></div>

            {(leaderboard.value !== null) ?
                <>
                    <Leaderboard onCurrentClick={() => setScoreBreakdownOpen(true)}/>
                    {explanation}
                </> : ''}

            <ScoreBreakdownModal onClose={() => setScoreBreakdownOpen(false)} show={scoreBreakdownOpen}/>
            <ScoreExplanationModal onClose={() => setScoreExplanationOpen(false)} show={scoreExplanationOpen}/>
        </>
    )
}