import {forwardRef} from "react";
import BasicInputLabel from "./InputLabel";
import {UIColor} from "../Config";

const BasicDateInput = forwardRef(function BasicDateInput(
    {
        className = "",
        defaultValue = null,
        minValue = null,
        maxValue = null,
        background = UIColor.DarkInput,
        label,
        onChange,
        onBlur,
    },
    ref
) {
    function dateToString(date) {
        return date === null ? null : new Date(date).toLocaleDateString('en-CA');
    }

    const classes = `w-fit py-1.5 px-4 ${background} rounded text-lg ${className}`;
    const inputElement =
        <input
            type="date"
            defaultValue={dateToString(defaultValue)}
            min={dateToString(minValue)}
            max={dateToString(maxValue)}
            className={classes}
            onChange={onChange}
            onBlur={onBlur}
            ref={ref}
        />;
    if (label) {
        return (<>
                <BasicInputLabel label={label}/>
                <div>
                    {inputElement}
                </div>
            </>
        )
    }
    return inputElement;
});

export default BasicDateInput;