import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWarning} from '@fortawesome/free-solid-svg-icons';
import {UIColor} from "../Config";

export function WarningMessage({children, title, text}) {
    return <div className={`${UIColor.DarkYellow} rounded-xl p-4`}>
        <p className="text-lg">
            <FontAwesomeIcon icon={faWarning} className="text-xl"></FontAwesomeIcon>
            <strong className="pl-2">{title}</strong>
        </p>
        <div className="m-2"></div>
        <p className={"text-justify"}> {text} </p>
        {children ?
            <>
                <div className="m-4"></div>
                {children}
            </> : ""
        }
    </div>;
}
