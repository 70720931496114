import {Trans, useTranslation} from "react-i18next";
import {useEffect, useMemo, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartBar,
    faChartLine,
    faCheck,
    faEye,
    faListCheck,
    faRankingStar,
    faUpload
} from "@fortawesome/free-solid-svg-icons";
import {UIColor} from "./Config";
import {BasicButton} from "./common/Button";
import VerticalCarousel from "./common/VerticalCarousel";
import ModalWindow from "./common/ModalWindow";
import {ExpandableLanguageSelector} from "./common/LanguageSelector";
import Footer from "./common/Footer";
import TextLink from "./common/TextLink";
import {Link} from "react-router-dom";
import Logo from "./common/Logo";


function ct(key) {
    return <Trans i18nKey={key} components={{
        strong: <strong/>,
        em: <em/>,
        code: <code/>,
        sup: <sup/>,
        skip: <span className={"p-0.5"}/>,
        point: <span className={"opacity-50 pr-2"}/>,
        logo: <span className={"inline-block h-[1.15em] align-center"}>
            <Logo className={"h-[1.3em]"}/>
        </span>,
        TextLink: <TextLink href={"mailto:sales@climbuddy.com"}/>
    }}/>
}


const ProductIntro = () => {
    return (
        <section id="product-intro" className="relative bg-slate-900 text-center p-8 text-white pb-16 overflow-hidden">
            {/* Background Video */}
            <video
                className="absolute top-1/2 left-1/2 w-auto h-auto min-w-full min-h-full max-w-none transform -translate-x-1/2 -translate-y-1/2 opacity-10"
                autoPlay
                loop
                muted
                playsInline
            >
                <source src="/landing_page/videos/backdrop.webm" type="video/mp4"/>
            </video>

            {/* Content */}
            <div className="relative z-10">
                <div className="flex items-center justify-center mb-6">
                    <Logo className={"w-56 md:w-1/2"}/>
                </div>
                <h2 className="text-2xl font-bold mb-6">{ct("landing_page.product_intro.subtitle")}</h2>
                <p className="text-lg text-justify">{ct("landing_page.product_intro.text")}</p>
                <div className="p-2"/>
                <div className={"flex justify-center"}>
                    <table className="border-spacing-1 border-separate text-xl">
                        <tbody>
                        <tr>
                            <td className="text-right pr-1">
                                <FontAwesomeIcon icon={faCheck} className="pr-1"/>
                                <strong>{ct("landing_page.product_intro.log.1")}</strong>
                            </td>
                            <td className="text-left"> {ct("landing_page.product_intro.log.2")}</td>
                        </tr>
                        <tr>
                            <td className="text-right pr-1">
                                <FontAwesomeIcon icon={faChartLine} className="pr-1"/>
                                <strong>{ct("landing_page.product_intro.track.1")}</strong>
                            </td>
                            <td className="text-left"> {ct("landing_page.product_intro.track.2")}</td>
                        </tr>
                        <tr>
                            <td className="text-right pr-1">
                                <FontAwesomeIcon icon={faRankingStar} className="pr-1"/>
                                <strong>{ct("landing_page.product_intro.compete.1")}</strong>
                            </td>
                            <td className="text-left"> {ct("landing_page.product_intro.compete.2")}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="p-1"/>
                <p className={`text-lg ${UIColor.MinorText}`}>{ct("landing_page.product_intro.and_more")}</p>
                <div className="p-4"/>
                <Link to={"/app/demo/"}>
                    <BasicButton
                        className="text-2xl"
                        buttonColor={UIColor.Blue}
                    >
                        {ct("landing_page.product_intro.button")}
                    </BasicButton>
                </Link>
            </div>
        </section>
    );
};


const TryForFree = () => {
    const [showTryForFree, setShowTryForFree] = useState(false);

    return (
        <div className="bg-radial flex flex-col items-center w-full py-8 px-4 gap-3 bg-slate-900 ">
            <BasicButton
                className="text-2xl"
                buttonColor={UIColor.Blue}
                onClick={() => setShowTryForFree(true)}
            >
                {ct("landing_page.try_for_free.button")}
            </BasicButton>
            <p className={"text-center text-xl"}>
                {ct("landing_page.try_for_free.price")}
            </p>

            {/*
            <p className={`${UIColor.MinorText} text-sm`}><sup>*</sup><strong>€49</strong> if your gym has at most 3 rebuilds per month.</p>
            */}

            <ModalWindow
                show={showTryForFree}
                title={ct("landing_page.try_for_free.modal.title")}
                topColor={UIColor.Blue}
                handleClose={() => setShowTryForFree(false)}
            >
                <p className="pb-4">{ct("landing_page.try_for_free.modal.text_1")}</p>
                <p>{ct("landing_page.try_for_free.modal.text_2")}</p>
            </ModalWindow>
        </div>
    );
};

export default function LandingPage() {
    const {t} = useTranslation();

    const categories = useMemo(() => [
                {
                    id: "for-climbers",
                    title: ct("landing_page.for_climbers.title"),
                    sections: [
                        {
                            id: "view-boulders",
                            title: ct("landing_page.for_climbers.view_boulders.title"),
                            icon: faEye,
                            subtitle: ct("landing_page.for_climbers.view_boulders.subtitle"),
                            text: ct("landing_page.for_climbers.view_boulders.text"),
                            end: ct("landing_page.for_climbers.view_boulders.end"),
                            video: "/landing_page/videos/view-boulders.webm",
                        },
                        {
                            id: "track-and-compete",
                            title: ct("landing_page.for_climbers.track_and_compete.title"),
                            icon: faListCheck,
                            subtitle: ct("landing_page.for_climbers.track_and_compete.subtitle"),
                            text: ct("landing_page.for_climbers.track_and_compete.text"),
                            end: ct("landing_page.for_climbers.track_and_compete.end"),
                            video: "/landing_page/videos/track-and-compete.webm",
                        },
                    ]
                },
                {
                    id: "for-gyms",
                    title: ct("landing_page.for_gyms.title"),
                    sections: [
                        {
                            id: "upload-pictures",
                            title: ct("landing_page.for_gyms.upload_pictures.title"),
                            icon: faUpload,
                            text: ct("landing_page.for_gyms.upload_pictures.text"),
                            end: ct("landing_page.for_gyms.upload_pictures.end"),
                            video: "/landing_page/videos/upload-pictures.webm"
                        },
                        {
                            id: "that-is-it",
                            title: ct("landing_page.for_gyms.that_is_it.title"),
                            icon: faCheck,
                            text: ct("landing_page.for_gyms.that_is_it.text"),
                            end: ct("landing_page.for_gyms.that_is_it.end"),
                            video: "/landing_page/videos/that-is-it.webm",
                        },
                        {
                            id: "statistics",
                            title: ct("landing_page.for_gyms.statistics.title"),
                            icon: faChartBar,
                            text: ct("landing_page.for_gyms.statistics.text"),
                            end: ct("landing_page.for_gyms.statistics.end"),
                            not_video: <div className="container mx-auto">
                                <VerticalCarousel images={[
                                    "/landing_page/images/difficulty.png",
                                    "/landing_page/images/popularity.png",
                                    "/landing_page/images/activity.png"
                                ]}/>
                            </div>,
                        },
                    ]
                }
                ,
            ],
            [t]
        )
    ;

    const videoRefs = useRef({});

    const [visibleSections, setVisibleSections] = useState([]);
    const [visibleCategories, setVisibleCategories] = useState([]);

    const [activeCategory, setActiveCategory] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    for (const category of categories) {
                        for (const section of category.sections) {
                            if (section.id === entry.target.id) {
                                setActiveCategory(category.id);
                                console.log(category.id)
                            }
                        }
                    }

                    setVisibleSections((prev) => {
                        if (!prev.includes(entry.target.id)) {
                            return [...prev, entry.target.id];
                        }
                        return prev;
                    });

                    // Check if the section belongs to a category and update visibleCategories
                    for (const category of categories) {
                        for (const section of category.sections) {
                            if (section.id === entry.target.id) {
                                // Mark category as visible if any section within it is visible
                                setVisibleCategories((prevCategories) => {
                                    if (!prevCategories.includes(category.id)) {
                                        return [...prevCategories, category.id];
                                    }
                                    return prevCategories;
                                });
                                break; // No need to check further once the category is found
                            }
                        }
                    }
                }
            });
        }, {threshold: 0.15});

        // Observe each section
        const sections = document.querySelectorAll("section");
        sections.forEach((section) => observer.observe(section));

        return () => {
            observer.disconnect();
        };
    }, [categories]);

    useEffect(() => {
        categories.forEach((category) => {
            category.sections.forEach((section) => {
                const video = videoRefs.current[section.id];
                if (video) {
                    if (visibleSections.includes(section.id)) {
                        video.play().catch(error => console.error("Autoplay prevented:", error));
                    } else {
                        video.pause();
                        video.currentTime = 0; // Reset when hidden
                    }
                }
            })
        });
    }, [visibleSections, categories]);

    return (<>
            <svg width={0} height={0}>
                <clipPath id="svgPath" clipPathUnits="objectBoundingBox">
                    <path
                        d="M0.499,0 C0.17,0,0.152,0,0.143,0.001 C0.125,0.003,0.107,0.005,0.094,0.009 C0.075,0.014,0.059,0.021,0.047,0.03 c-0.002,0.001,-0.004,0.003,-0.004,0.003 c-0.001,0,-0.001,0.001,-0.001,0.001 c0,0,-0.007,0.006,-0.008,0.007 c-0.001,0.001,-0.012,0.014,-0.014,0.018 C0.016,0.063,0.011,0.076,0.01,0.079 C0.01,0.08,0.009,0.084,0.008,0.088 C0.007,0.089,0.007,0.105,0.007,0.123 l0,0.034,-0.003,0,-0.003,0,0,0.023,0,0.023 h0.002 c0.005,0,0.004,-0.001,0.004,0.02 v0.019 l-0.003,0,-0.003,0,0,0.042,0,0.042 h0.002 c0.004,0,0.004,0,0.004,0.011 v0.011 L0.003,0.35,0,0.35 l0,0.042,0,0.042 h0.002 c0.001,0,0.002,0,0.003,0 c0.001,0,0.001,0.031,0.001,0.099 h0 c0,0.006,0,0.005,0,0.011 c0,0.219,0,0.359,0.001,0.363 c0,0.003,0.001,0.007,0.002,0.009 c0.001,0.002,0.001,0.005,0.001,0.006 c0,0.001,0,0.002,0.001,0.002 c0,0,0.001,0.003,0.002,0.005 c0.001,0.003,0.003,0.006,0.004,0.008 c0.001,0.002,0.002,0.003,0.002,0.003 c0.001,0.002,0.011,0.014,0.016,0.018 c0.002,0.002,0.004,0.004,0.003,0.004 c0,0,0,0,0.001,0.001 c0.001,0,0.004,0.003,0.007,0.005 c0.005,0.004,0.009,0.006,0.016,0.009 c0.002,0.001,0.003,0.002,0.003,0.002 c0,0,0,0,0.001,0 c0.001,0,0.003,0.001,0.005,0.002 c0.009,0.004,0.026,0.009,0.04,0.012 c0.005,0.001,0.009,0.002,0.01,0.002 c0.001,0,0.002,0,0.003,0 c0.001,0,0.004,0,0.007,0.001 c0.003,0,0.007,0.001,0.008,0.001 c0.001,0,0.005,0,0.007,0.001 c0.003,0,0.007,0.001,0.01,0.001 c0.003,0,0.006,0,0.006,0 c0.001,0,0.68,0,0.686,-0.001 c0.024,-0.001,0.043,-0.005,0.062,-0.01 c0.028,-0.008,0.049,-0.021,0.063,-0.038 c0.008,-0.01,0.014,-0.021,0.017,-0.035 l0.002,-0.006,0,-0.375 v-0.001 l0,-0.127,0.004,0 L1,0.407 v-0.066 c0,-0.053,0,-0.066,-0.001,-0.066 c-0.001,0,-0.002,0,-0.004,0 l-0.003,0,0,-0.091,0,-0.091,-0.002,-0.006 C0.986,0.07,0.981,0.059,0.973,0.049 C0.951,0.022,0.912,0.006,0.856,0.001 C0.847,0,0.834,0,0.499,0"></path>
                </clipPath>
            </svg>

            <div className={"bg-neutral-700"}>
                <div className="max-w-4xl mx-auto font-sans text-white shadow-large">
                    {/* Navigation Bar */}
                    <nav
                        className={`sticky top-0 left-0 w-full h-12 bg-slate-800 shadow-medium z-20 flex justify-start items-center`}>
                        <div className="flex items-center">
                            {categories.map((category) => (
                                <a
                                    key={category.id} href={`#${category.id}`}
                                    className={`p-3 transition-all h-full hover:opacity-50 ${activeCategory === category.id ? UIColor.DarkInput : ""}`}
                                >
                                    {category.title}
                                </a>
                            ))}
                        </div>

                        <div className={"flex-grow"}/>

                        <ExpandableLanguageSelector/>
                    </nav>

                    {/* Product Intro Section */}
                    <ProductIntro/>

                    {/* Content Sections */}
                    <div>
                        {categories.map((category) => (
                            <div key={category.id} id={category.id} className={`scroll-mt-12 ${UIColor.Window}`}>
                                <h2 className={`text-3xl font-bold text-center py-6 ${UIColor.DarkInput}`}>
                                    <span
                                        className={`${visibleCategories.includes(category.id) ? "opacity-100" : "opacity-0"} duration-500`}>
                                    {category.title}
                                        </span>
                                </h2>
                                {category.sections.map((section, index) => (
                                    <section
                                        key={section.id}
                                        id={section.id}
                                        className={`md:h-[38rem] transition-opacity duration-500 mx-auto md:gap-8 p-6 flex flex-col-reverse md:flex-row items-center ${
                                            index % 2 === 0 ? "md:flex-row-reverse" : ""
                                        } ${visibleSections.includes(section.id) ? "opacity-100" : "opacity-0"}`}
                                    >

                                        {/* Video */}
                                        <div
                                            className={`md:w-1/2 p-4  ${index === 0 ? "md:self-start" : index === category.sections.length - 1 ? "md:self-end" : "md:self-center"}`}>
                                            {
                                                section.video ?
                                                    <div className={"drop-shadow-[0_0px_25px_rgba(10,10,10,0.7)]"}>
                                                        <video
                                                            style={{clipPath: "url(#svgPath)"}}
                                                            ref={(el) => (videoRefs.current[section.id] = el)}
                                                            className="w-full" autoPlay loop muted>
                                                            <source src={section.video} type="video/mp4"/>
                                                        </video>
                                                    </div> : <>{section.not_video}</>
                                            }
                                        </div>

                                        {/* Text */}
                                        <div className={`rounded-3xl md:w-1/2 text-left p-6`}>
                                            <h3 className="text-2xl font-bold">
                                                {section.title} {section.icon ?
                                                <FontAwesomeIcon className={"opacity-50 pl-2"}
                                                                 icon={section.icon}/> : null}
                                            </h3>
                                            <div
                                                className={"my-4 border-b-4 border-dashed border-opacity-10 border-white"}/>
                                            <p className={"text-lg mb-2"}>{section.subtitle}</p>
                                            <p className={UIColor.MinorText}>{section.text}</p>
                                            {section.end ? <p className={"mt-2 text-right"}>{section.end}</p> : <></>}
                                            <div className={"p-4"}/>
                                        </div>
                                    </section>
                                ))}
                            </div>
                        ))}

                        <TryForFree/>
                    </div>

                    <Footer color="bg-slate-800"/>
                </div>
            </div>
        </>
    );
}
